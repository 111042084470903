import { Injectable } from '@angular/core';
import { Menu, SubMenu } from './menu-models';

enum UserRoles {
  FULL_ACCESS = 'company'
}

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  menus = {};
  userRole;

  constructor() { }

  addMenu(menu: Menu): void {
    if (this.canView(menu.roles)) {
      this.menus[menu.key] = menu;
    }
  }

  addSubMenu(key: string, subMenu: SubMenu) {
    if ((this.menus[key] && this.canView(subMenu.roles))) {
      const subMenus = this.menus[key].subMenus;
      if (!subMenus) {
        this.menus[key].subMenus = [];
      }
      this.menus[key].subMenus.push(subMenu);
    }
  }

  getMenu(userRole): object {
    this.menus = {};
    this.userRole = userRole;
    this.addMenus();
    return this.menus;
  }

  addMenus() {
    // Dispatch Center
    this.addMenu({
      title: "Home",
      url: "home",
      icon: this.getIcon("home"),
      key: "home",
      position: 1,
      roles: [UserRoles.FULL_ACCESS],
    });

    this.addSubMenu('home', {
      title: "Dispatch Center",
      url: "dispatch",
      position: 1,
      roles: [UserRoles.FULL_ACCESS]
    });

    this.addSubMenu('home', {
      title: "Timeline Load",
      url: "timeline",
      position: 2,
      roles: [UserRoles.FULL_ACCESS]
    });

    // Dashboard
    this.addMenu({
      title: "Dashboard",
      url: "dashboard",
      icon: this.getIcon("dashboard"),
      key: "dashboard",
      position: 2,
      roles: [UserRoles.FULL_ACCESS],
    });

    // Trips
    this.addMenu({
      title: "Trips",
      url: "trips",
      icon: this.getIcon("trips"),
      key: "trips",
      position: 3,
      roles: [UserRoles.FULL_ACCESS],
    });

    this.addSubMenu('trips', {
      title: "Trips",
      url: "trips",
      position: 1,
      roles: [UserRoles.FULL_ACCESS]
    });

    this.addSubMenu('trips', {
      title: "Drivers Load",
      url: "trips/drivers-load",
      position: 2,
      roles: [UserRoles.FULL_ACCESS]
    });

    this.addSubMenu('trips', {
      title: "Standing Orders",
      url: "trips/standing-orders",
      position: 3,
      roles: [UserRoles.FULL_ACCESS]
    });

    // this.addSubMenu('trips', {
    //   title: "Outstanding",
    //   url: "trips/outstanding",
    //   position: 4,
    //   roles: [UserRoles.FULL_ACCESS]
    // });

    this.addSubMenu('trips', {
      title: "Dirt Road",
      url: "trips/dirt-road",
      position: 5,
      roles: [UserRoles.FULL_ACCESS]
    });

    // Credentails
    this.addMenu({
      title: "Credentials",
      url: "credentials",
      icon: this.getIcon("credentials"),
      key: "credentials",
      position: 4,
      roles: [UserRoles.FULL_ACCESS],
    });

    this.addSubMenu('credentials', {
      title: "Drivers",
      url: "credentials/drivers",
      position: 1,
      roles: [UserRoles.FULL_ACCESS]
    });

    this.addSubMenu('credentials', {
      title: "Fleet",
      url: "credentials/fleet",
      position: 2,
      roles: [UserRoles.FULL_ACCESS]
    });

    this.addSubMenu('credentials', {
      title: "Staff",
      url: "credentials/staff",
      position: 3,
      roles: [UserRoles.FULL_ACCESS]
    });

    // this.addSubMenu('credentials', {
    //   title: "Members",
    //   url: "credentials/members",
    //   position: 4,
    //   roles: [UserRoles.FULL_ACCESS]
    // });

    this.addSubMenu('credentials', {
      title: "Corporate Users",
      url: "credentials/corporates",
      position: 5,
      roles: [UserRoles.FULL_ACCESS]
    });

    // Fares
    this.addMenu({
      title: "Fares",
      url: "fares",
      icon: this.getIcon("fares"),
      key: "fares",
      position: 5,
      roles: [UserRoles.FULL_ACCESS],
    });

    // Billing
    this.addMenu({
      title: "Billing",
      url: "billing",
      icon: this.getIcon("fares"),
      key: "billing",
      position: 5.1,
      roles: [UserRoles.FULL_ACCESS],
    });

    // Reports
    this.addMenu({
      title: "Reports",
      url: "reports",
      icon: this.getIcon("reports"),
      key: "reports",
      position: 6,
      roles: [UserRoles.FULL_ACCESS],
    });

    // Support
    this.addMenu({
      title: "Support",
      url: "support",
      icon: this.getIcon("support"),
      key: "support",
      position: 7,
      roles: [UserRoles.FULL_ACCESS],
    });
  }

  getIcon(menu) {
    switch (menu) {
      case "home":
        return `<svg
          class="text-nav-svg-light h-6 w-6"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            d="M6 19v1q0 .425-.287.712Q5.425 21 5 21H4q-.425 0-.712-.288Q3 20.425 3 20v-8l2.1-6q.15-.45.538-.725Q6.025 5 6.5 5h11q.475 0 .863.275q.387.275.537.725l2.1 6v8q0 .425-.288.712Q20.425 21 20 21h-1q-.425 0-.712-.288Q18 20.425 18 20v-1Zm-.2-9h12.4l-1.05-3H6.85ZM5 12v5Zm2.5 4q.625 0 1.062-.438Q9 15.125 9 14.5t-.438-1.062Q8.125 13 7.5 13t-1.062.438Q6 13.875 6 14.5t.438 1.062Q6.875 16 7.5 16Zm9 0q.625 0 1.062-.438Q18 15.125 18 14.5t-.438-1.062Q17.125 13 16.5 13t-1.062.438Q15 13.875 15 14.5t.438 1.062Q15.875 16 16.5 16ZM5 17h14v-5H5Z"
          />
        </svg>`
      case 'dashboard':
        return `<svg
          class="text-nav-svg-dark group-hover:text-nav-light h-6 w-6"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 32 32"
        >
        <path
          fill="currentColor"
          d="M24 21h2v5h-2zm-4-5h2v10h-2zm-9 10a5.006 5.006 0 0 1-5-5h2a3 3 0 1 0 3-3v-2a5 5 0 0 1 0 10z"
        />
        <path
          fill="currentColor"
          d="M28 2H4a2.002 2.002 0 0 0-2 2v24a2.002 2.002 0 0 0 2 2h24a2.003 2.003 0 0 0 2-2V4a2.002 2.002 0 0 0-2-2Zm0 9H14V4h14ZM12 4v7H4V4ZM4 28V13h24l.002 15Z"
        />
      </svg>`
      case 'trips':
        return `<svg
        class="text-nav-svg-dark group-hover:text-nav-light h-6 w-6"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path
          fill="currentColor"
          d="M14.844 20H6.5C5.121 20 4 18.879 4 17.5S5.121 15 6.5 15h7c1.93 0 3.5-1.57 3.5-3.5S15.43 8 13.5 8H8.639a9.812 9.812 0 0 1-1.354 2H13.5c.827 0 1.5.673 1.5 1.5s-.673 1.5-1.5 1.5h-7C4.019 13 2 15.019 2 17.5S4.019 22 6.5 22h9.593a10.415 10.415 0 0 1-1.249-2zM5 2C3.346 2 2 3.346 2 5c0 3.188 3 5 3 5s3-1.813 3-5c0-1.654-1.346-3-3-3zm0 4.5a1.5 1.5 0 1 1 .001-3.001A1.5 1.5 0 0 1 5 6.5z"
        />
        <path
          fill="currentColor"
          d="M19 14c-1.654 0-3 1.346-3 3c0 3.188 3 5 3 5s3-1.813 3-5c0-1.654-1.346-3-3-3zm0 4.5a1.5 1.5 0 1 1 .001-3.001A1.5 1.5 0 0 1 19 18.5z"
        />
      </svg>`
      case 'credentials':
        return `<svg class="text-nav-svg-dark group-hover:text-nav-light h-6 w-6" xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32 32">
        <path fill="currentColor" d="M16 22a4 4 0 1 0-4-4a4 4 0 0 0 4 4zm0-6a2 2 0 1 1-2 2a2 2 0 0 1 2-2zM14 6h4v2h-4z" />
        <path fill="currentColor"
          d="M24 2H8a2.002 2.002 0 0 0-2 2v24a2.002 2.002 0 0 0 2 2h16a2.003 2.003 0 0 0 2-2V4a2.002 2.002 0 0 0-2-2Zm-4 26h-8v-2a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1Zm2 0v-2a3 3 0 0 0-3-3h-6a3 3 0 0 0-3 3v2H8V4h16v24Z" />
      </svg>`
      case 'fares':
        return `<svg
        class="text-nav-svg-dark group-hover:text-nav-light h-6 w-6"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M16.5 6v.75m0 3v.75m0 3v.75m0 3V18m-9-5.25h5.25M7.5 15h3M3.375 5.25c-.621 0-1.125.504-1.125 1.125v3.026a2.999 2.999 0 010 5.198v3.026c0 .621.504 1.125 1.125 1.125h17.25c.621 0 1.125-.504 1.125-1.125v-3.026a2.999 2.999 0 010-5.198V6.375c0-.621-.504-1.125-1.125-1.125H3.375z"
        />
      </svg>`
      case 'reports':
        return `<svg
        class="text-nav-svg-dark group-hover:text-nav-light h-6 w-6"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M7.5 14.25v2.25m3-4.5v4.5m3-6.75v6.75m3-9v9M6 20.25h12A2.25 2.25 0 0020.25 18V6A2.25 2.25 0 0018 3.75H6A2.25 2.25 0 003.75 6v12A2.25 2.25 0 006 20.25z"
        />
      </svg>`
      case 'support':
        return `<svg
        class="text-nav-svg-dark group-hover:text-nav-light h-6 w-6"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-6 h-6"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"
        />
      </svg>`
      default:
        return '<svg class="navbar-svg-default mr-4 flex-shrink-0 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" /></svg>';
    }
  }

  canView(menuRoles) {
    if (Array.isArray(this.userRole)) {
      // In case if user has multiple roles assigned to them.
      // Logic will be added here if needed.
      return false;
    }

    return menuRoles.includes(this.userRole);
  }
}
