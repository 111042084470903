import { throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

// Error handling
export function errorHandl(error: HttpErrorResponse) {
    let errorMessage = '';
    // console.log(error.instanceof());
    if (error.error instanceof ErrorEvent) {

        // Get client-side error
        errorMessage = error.error.message;
    } else {
        // Get server-side error
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;

        if (error.error) {
            console.log("if error", error)
            if(error.error.message) {
                errorMessage = error.error.message;
            }
        }
    }
    return throwError(errorMessage);
}

// setting form data
export function setFormData(imgFiles, staffData, dataRef) {
    const formData = new FormData();
    Object.keys(imgFiles).forEach(key => {
            if (imgFiles[key] !== null) {
                formData.append(key, imgFiles[key], imgFiles[key].name);
            }
        });
    Object.keys(staffData).forEach(key => formData.append(`${dataRef}[${key}]`, staffData[key]));
    return formData;
}

export function setOnlyFieldFormData( staffData, dataRef) {
    const formData = new FormData();
    Object.keys(staffData).forEach(key => formData.append(`${dataRef}[${key}]`, staffData[key]));
    return formData;
}
