import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { sweetAlert } from '../../../utils/swal';
import { downloadFile } from 'src/app/utils/pdf-downloader';
import * as moment from 'moment-timezone';
import { ActivatedRoute } from '@angular/router';
import { urls } from 'src/app/utils/url-utils';
import { SignaturePad } from 'angular2-signaturepad/signature-pad';
import * as _ from 'underscore';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
import { AssigTripsService } from '../assigntrips-service/assigntrips-service';
import { DispatchCenterService } from '../../dispatch-center/dispatch-center-service/dispatch-center-service';
import { FleetService } from '../../fleet/fleet-service/fleet.service';
@Component({
  selector: 'app-verified-trip-logs',
  templateUrl: './verified-trip-logs.component.html',
  styleUrls: ['./verified-trip-logs.component.css']
})
export class VerifiedTripLogsComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(SignaturePad, { static: false }) signaturePad: SignaturePad;
  // tslint:disable-next-line: ban-types
  public signaturePadOptions: Object = { // passed through to szimek/signature_pad constructor
    minWidth: 5,
    canvasWidth: 750,
    canvasHeight: 300
  };

  isOdometerRequest = false;
  brockerType = '';
  isMedical = false;
  // for selecter
  selectedCorporateUser = '';
  selectedDriver = '';
  projectedOdometer = '';

  adjustedTrips = [];
  drivers = [];
  selectedTrips = [];
  selectedTripJobIds = [];
  status = 'triplogs';
  user;
  corporateUsers;
  searchDate;
  search = '';
  odometerBegin;
  odometerStart;
  odometerStop;
  popupAssignTrip: any;
  bankedType;
  alertMessage = '';
  filteredAdjustedTrips = [];
  timeZone;
  selectedBroker;
  selectedBrokerLogo;
  brokers;
  dateRange;
  jobType = 'assigned';
  type = null;
  tempCompanyImage = 'https://s3.amazonaws.com/neighborhood-dev/assets/brokers/MixandMatch.png';
  companyImage = this.tempCompanyImage;
  fleet;
  tempVin;

  constructor(
    private assignTripService: AssigTripsService,
    private dispatchCenterService: DispatchCenterService,
    private fleetService: FleetService,
    private sharedDataService: SharedDataService,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    if (this.activatedRoute.snapshot.url.length === 3) {
      this.status = 'adjustedtriplogs';
    } else {
      this.status = this.activatedRoute.snapshot.url[1].path;
    }

    this.status = 'adjustedtriplogs';
    this.brokers = this.getBrokers();
    this.getUser();
    this.getCorporateUsers();
  }

  ngAfterViewInit() {
    // // this.signaturePad is now available
    this.signaturePad.set('minWidth', 5); // set szimek/signature_pad options at runtime
    this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
  }

  getUser() {
    this.sharedDataService
      .getUser()
      .subscribe(user => {
        if (user) {
          this.timeZone = user.timeZone;
          this.user = user;
          this.searchDate = moment().startOf('day').toISOString();
          this.getAdjustedTrips();
        }
      });
  }

  getBrokers() {
    return [
      {
        name: 'callthecar',
        logoUrl: 'https://neighborhood-dev.s3.amazonaws.com/broker-logos/callthecar.png'
      },
      {
        name: 'tncarrier',
        logoUrl: 'https://neighborhood-dev.s3.amazonaws.com/broker-logos/tncarrier.png'
      },
      {
        name: 'saferide',
        logoUrl: 'https://neighborhood-dev.s3.amazonaws.com/broker-logos/saferide.png'
      },
      {
        name: 'logisticscare',
        logoUrl: 'https://neighborhood-dev.s3.amazonaws.com/broker-logos/logisticare.png'
      },
      {
        name: 'welltrans',
        logoUrl: 'https://neighborhood-dev.s3.amazonaws.com/broker-logos/welltrans.png'
      },
      {
        name: 'medtrans',
        logoUrl: 'https://neighborhood-dev.s3.amazonaws.com/broker-logos/medtrans-logo.png'
      },
      {
        name: 'united',
        logoUrl: 'https://neighborhood-dev.s3.amazonaws.com/broker-logos/SouthETrans.png'
      },
      {
        name: 'veyo',
        logoUrl: 'https://neighborhood-dev.s3.amazonaws.com/broker-logos/veyo-logo.png'
      },
      {
        name: 'first',
        logoUrl: 'https://neighborhood-dev.s3.amazonaws.com/broker-logos/mtm-logo.png'
      },
      {
        name: 'epic',
        logoUrl: 'https://neighborhood-dev.s3.amazonaws.com/broker-logos/epic.png'
      },
      {
        name: 'lcp',
        logoUrl: 'https://neighborhood-dev.s3.amazonaws.com/broker-logos/LCP.png'
      },
      {
        name: 'mas',
        logoUrl: 'https://neighborhood-dev.s3.amazonaws.com/broker-logos/mas.png'
      },
      {
        name: 'medicaid',
        logoUrl: 'https://neighborhood-dev.s3.amazonaws.com/broker-logos/medicaid.png'
      },
      {
        name: 'amera',
        logoUrl: 'https://neighborhood-dev.s3.amazonaws.com/broker-logos/amera.png'
      },
      {
        name: 'vapremier',
        logoUrl: 'https://neighborhood-dev.s3.amazonaws.com/broker-logos/vapremier.png'
      },
      {
        name: 'onecall',
        logoUrl: 'https://neighborhood-dev.s3.amazonaws.com/broker-logos/onecall.png'
      },
      {
        name: 'americanLogistics',
        logoUrl: 'https://neighborhood-dev.s3.amazonaws.com/broker-logos/americanLogistics.png'
      },
      {
        name: 'access2Ride',
        logoUrl: 'https://neighborhood-dev.s3.amazonaws.com/broker-logos/access2Ride.png'
      }
    ]
  }

  getCorporateUsers() {
    this.assignTripService
      .getCorporateUserList()
      .subscribe(data => {
        if (data) {
          this.corporateUsers = data;
        }
      });
  }

  getAdjustedTrips() {
    let queryParams = {
      status: this.status,
      scheduleTime: this.searchDate,
      jobType: this.jobType,
    };

    if (this.selectedBroker) {
      queryParams['companyType'] = this.selectedBroker;
    }

    if (this.isMedical) {
      queryParams['isMedical'] = this.isMedical;
    }

    if (this.selectedCorporateUser) {
      queryParams['cooperate'] = this.selectedCorporateUser;
    }

    if (this.jobType === 'cooperate' && (this.dateRange && this.dateRange.length === 2)) {
      queryParams['scheduleTime'] = this.dateRange[0];
      queryParams['endDate'] = this.dateRange[1];
    }

    this.assignTripService
      .getAdjustedTrips(queryParams)
      .subscribe(data => {
        if (data) {
          this.adjustedTrips = data;
          this.filteredAdjustedTrips = data;
          this.adjustedTrips.forEach((trip, i) => {
            const isFound = this.drivers.some(driver => driver._id === trip.driver._id)
            if (!isFound) {
              this.drivers.push(trip.driver);
            }
          });
        }
      });
  }

  onSearchDateChange(event) {
    this.searchDate = event.toISOString();
    this.getAdjustedTrips();
  }

  onSelectBroker(broker, logoUrl) {
    this.jobType = 'assigned';
    this.selectedCorporateUser = '';

    if (this.selectedBroker === broker) {
      this.selectedBroker = null;
      this.companyImage = this.tempCompanyImage;
      this.getAdjustedTrips();
      return false;
    }

    this.selectedBroker = broker;
    this.companyImage = logoUrl;
    this.getAdjustedTrips();
  }

  selectMedicalType(isMedical) {
    this.jobType = 'assigned';
    this.selectedCorporateUser = '';

    if (this.isMedical === isMedical) {
      this.isMedical = false;
      this.getAdjustedTrips();
      return false;
    }

    this.isMedical = isMedical;
    if (!this.isMedical) {
      this.selectedBroker = null;
      this.companyImage = this.user.profileImageURL;
    }
    this.getAdjustedTrips();
  }

  onSelectCorporateUser() {
    this.jobType = 'cooperate';
    this.isMedical = false;
    this.selectedBroker = null;
    const cUser = this.corporateUsers.find(user => user._id === this.selectedCorporateUser);
    if (cUser) {
      this.companyImage = cUser.profileImageURL;
    }
    this.getAdjustedTrips();
  }

  onSelectDriver() {
    let trip;
    // vm.clearSelection();
    this.filteredAdjustedTrips = this.adjustedTrips;
    if (!this.selectedDriver) {
      delete this.selectedDriver;
      return false;
    } else {
      this.filteredAdjustedTrips = this.adjustedTrips
        .filter(trip => trip.driver._id === this.selectedDriver);
      
      if (this.filteredAdjustedTrips.length) {
        trip = this.filteredAdjustedTrips[0];
      }

      if (trip && trip.job && trip.job.fleet) {
        const fleetId = trip.job.fleet;
        this.projectedOdometer = null;
        this.fleetService
          .getFleetDataById(fleetId)
          .subscribe(data => {
            if (data) {
              this.fleet = data;
              this.projectedOdometer = this.fleet[this.selectedBroker];
            }
          });
      }
    }
  }

  onDateRangeChange(event) {
    console.log(event);
    if (event && event.length) {
      this.dateRange = [
        event[0].toISOString(),
        event[1].endOf("day").toISOString()
      ];
    }
    this.getAdjustedTrips();
  }

  adjustOdometer() {
    const payload = {
      _ids: this.selectedTrips, 
      scheduleTime: this.searchDate, 
      companyImage: this.companyImage,
      driver: this.selectedDriver, 
      fleet: this.fleet._id, 
      projectedOdometer: this.projectedOdometer, 
      companyType: this.selectedBroker
    }
    this.assignTripService
      .adjustTripOdometer(payload)
      .subscribe(data => {
        if (data) {
          sweetAlert('Success', 'Updated Successfully', 'success', 'OK');
        }
      });
  }

  nextOdometer() {
    const payload = {
      _ids: this.selectedTrips, 
      scheduleTime: this.searchDate, 
      companyImage: this.companyImage,
      driver: this.selectedBroker, 
      fleet: this.fleet._id, 
      projectedOdometer: this.projectedOdometer, 
      companyType: this.selectedBroker
    }

    this.assignTripService
      .adjustAllTripsOdometer(payload)
      .subscribe(data => {
        if (data) {
          sweetAlert('Success', 'Updated Successfully', 'success', 'OK');
        }
      });
  }

  callthecarInvoice() {
    const payload = { 
      type: this.status, 
      _ids: this.selectedTrips, 
      scheduleTime: this.searchDate, 
      companyImage: this.companyImage 
    }
    this.assignTripService
      .donwloadCallTheCarInvoice(payload)
      .subscribe(data => {
        if (data) {
          let name = data.excelName;
          let url = urls.SERVER_URL + '/excel/' + name;
          downloadFile(url, '', name);
        }
      });
  }

  manifest() {
    // United (Southeastrans)
    const payload = { 
      type: this.status, 
      _ids: this.selectedTrips, 
      scheduleTime: this.searchDate, 
      companyImage: this.companyImage 
    }

    this.assignTripService
      .donwloadSoutheastransManifest(this.selectedDriver, payload)
      .subscribe(data => {
        if (data) {
          let name = data.pdfName;
          let url = urls.SERVER_URL + '/pdf/' + name;
          downloadFile(url, '', name);
        }
      });
  }

  manifestLogistic() {
    const payload = { 
      tempVin: this.tempVin, 
      type: this.status, 
      _ids: this.selectedTrips, 
      scheduleTime: this.searchDate,
      companyImage: this.companyImage 
    }

    this.assignTripService
      .donwloadLogisticareManifest(payload)
      .subscribe(data => {
        if (data) {
          let name = data.pdfName;
          let url = urls.SERVER_URL + '/pdf/' + name;
          downloadFile(url, '', name);
        }
      });
  }

  manifestLcp() {
    const payload = { 
      type: this.status, 
      _ids: this.selectedTrips, 
      scheduleTime: this.searchDate, 
      companyImage: this.companyImage 
    }

    this.assignTripService
      .donwloadLcpManifest(payload)
      .subscribe(data => {
        if (data) {
          let name = data.pdfName;
          let url = urls.SERVER_URL + '/pdf/' + name;
          downloadFile(url, '', name);
        }
      });

  }

  manifestAmera() {
    const payload = { 
      type: this.status, 
      _ids: this.selectedTrips, 
      scheduleTime: this.searchDate, 
      companyImage: this.companyImage 
    }

    this.assignTripService
      .donwloadAmeraManifest(payload)
      .subscribe(data => {
        if (data) {
          let name = data.pdfName;
          let url = urls.SERVER_URL + '/pdf/' + name;
          downloadFile(url, '', name);
        }
      });
  }

  corporateInvoiceReport() {
    let payload = {
      type: this.status, 
      _ids: this.selectedTrips, 
      scheduleTime: this.searchDate, 
      companyImage: this.companyImage
    }

    if (this.dateRange && this.dateRange.length) {
      payload['startDate'] = this.dateRange[0];
      payload['endDate'] = this.dateRange[1];
    }

    this.assignTripService
      .donwloadCorporateInvoiceReport(this.selectedCorporateUser, payload)
      .subscribe(data => {
        if (data) {
          let name = data.pdfName;
          let url = urls.SERVER_URL + '/pdf/' + name;
          downloadFile(url, '', name);
        }
      });
  }

  corporateInvoice() {
    // Corporate TRF
    const payload = { 
      type: this.status, 
      _ids: this.selectedTrips, 
      scheduleTime: this.searchDate, 
      companyImage: this.companyImage 
    }

    this.assignTripService
      .donwloadCorporateInvoice(this.selectedCorporateUser, payload)
      .subscribe(data => {
        if (data) {
          let name = data.pdfName;
          let url = urls.SERVER_URL + '/pdf/' + name;
          downloadFile(url, '', name);
        }
      });

    
  }

  nmnDriverLog() {
    const payload = { 
      type: this.status, 
      _ids: this.selectedTrips, 
      scheduleTime: this.searchDate, 
      companyImage: this.companyImage 
    }

    this.assignTripService
      .donwloadMedtransDriverLog(this.selectedDriver, payload)
      .subscribe(data => {
        if (data) {
          let name = data.pdfName;
          let url = urls.SERVER_URL + '/pdf/' + name;
          downloadFile(url, '', name);
        }
      });
  }

  mtmTRF() {
    const payload = { 
      type: this.status, 
      _ids: this.selectedTrips, 
      scheduleTime: this.searchDate, 
      companyImage: this.companyImage 
    }

    this.assignTripService
      .donwloadMtmTrf(this.selectedDriver, payload)
      .subscribe(data => {
        if (data) {
          let name = data.pdfName;
          let url = urls.SERVER_URL + '/pdf/' + name;
          downloadFile(url, '', name);
        }
      });
  }

  mtmClaim() {
    const payload = { 
      type: this.status, 
      _ids: this.selectedTrips, 
      scheduleTime: this.searchDate, 
      companyImage: this.companyImage 
    }

    this.assignTripService
      .donwloadMtmClaim(payload)
      .subscribe(data => {
        if (data) {
          let name = data.excelName;
          let url = urls.SERVER_URL + '/excel/' + name;
          downloadFile(url, '', name);
        }
      });
  }

  mtmDriverLog() {
    const payload = { 
      type: this.status, 
      _ids: this.selectedTrips, 
      scheduleTime: this.searchDate, 
      companyImage: this.companyImage 
    }

    this.assignTripService
      .donwloadDriverLog(this.selectedDriver, payload)
      .subscribe(data => {
        if (data) {
          let name = data.pdfName;
          let url = urls.SERVER_URL + '/pdf/' + name;
          downloadFile(url, '', name);
        }
      });
  }

  mtmAllDriverLogs() {
    const payload = { 
      type: this.status, 
      _ids: this.selectedTrips, 
      scheduleTime: this.searchDate, 
      companyImage: this.companyImage 
    }

    this.assignTripService
      .donwloadAllDriverLog(payload)
      .subscribe(data => {
        if (data) {
          let name = data.pdfName;
          let url = urls.SERVER_URL + '/pdf/' + name;
          downloadFile(url, '', name);
        }
      });
  }

  vapremiumDriverLog() {
    const payload = { 
      type: this.status, 
      _ids: this.selectedTrips, 
      scheduleTime: this.searchDate, 
      companyImage: this.companyImage 
    }

    
  }

  access2CareDriverLog() {

  }

  veyoDriverLog() {

  }

  tripsLog() {
  }

  logistiCareTriplogs() {

  }

  signatureUpdate(bl) {
  }

  bankedTrips() {

  }

  claimFile(bl) {

  }

  bankedMultipalTrips() {
    this.alertMessage = 'Are you sure you want to bank these trips ?';
  }

  bankedSingleTrip(assign, status) {
    this.popupAssignTrip = JSON.parse(JSON.stringify(assign));
    this.bankedType = status;
    // tslint:disable-next-line: max-line-length
    this.alertMessage = (assign.bankStatus === 'banked') ? 'Are you sure you want to appeald against this trip ?' : 'Are you sure you want to bank this trip ?';
  }

  updateTripsSignature(b) {
    const object = {
      type: 'adjustedtriplogs',
      _ids: this.selectedTrips,
      scheduleTime: this.searchDate,
      companyImage: 'https://s3.amazonaws.com/neighborhood-dev/assets/brokers/MixandMatch.png',
      isSignature: JSON.parse(b)
    };
    this.assignTripService
      .updateAllTripsSignature(object)
      .subscribe(data => {
        if (data) {
          sweetAlert('Success', 'Signatures Updated', 'success', 'OK');
          this.selectedTrips = [];
          this.selectedTripJobIds = [];
          this.getCorporateUsers();
          this.getAdjustedTrips();
        }
      });
  }

  updateTripSignature(assign) {
    this.signaturePad.clear();
    this.popupAssignTrip = JSON.parse(JSON.stringify(assign));
  }

  generateSignature() {
    const object = {
      isEmpty: false,
      dataUrl: this.signaturePad.toDataURL()
    };
    this.processSignature(object, this.popupAssignTrip._id);
  }

  removeTripSignature() {
    const object = {
      isEmpty: true,
      dataUrl: this.signaturePad.toDataURL()
    };
    this.processSignature(object, this.popupAssignTrip._id);
  }

  processSignature(obj, id) {
    this.assignTripService
      .generateSignature(obj, id)
      .subscribe(data => {
        if (data) {
          sweetAlert('Success', 'Signature Generated', 'success', 'OK');
          const splitArray = data.signatureImage.split('/');
          const splitArrayTwo = splitArray[6].split('.');
          this.filteredAdjustedTrips.filter((item2) => {
            if (item2.job._id === splitArrayTwo[0]) {
              item2.job.signatureImage = data.signatureImage;
            }
          });
        }
      });
  }

  clearSignature() {
    this.signaturePad.clear();
  }

  tripsClaimFilesDownload() {
    const object = {
      _ids: this.selectedTrips,
      companyImage: 'https://s3.amazonaws.com/neighborhood-dev/assets/brokers/MixandMatch.png',
      scheduleTime: this.searchDate
    };

    this.assignTripService
      .getAssignTripLogsFile(object, 'claimfile')
      .subscribe(data => {
        if (data) {
          //    Download File From Here
          let url = urls.SERVER_URL.toString();
          let pdfName;
          let fileExtention;
          console.log("here1")
          if (data.pdfName) {
            fileExtention = 'pdf';
            url = url + 'pdf/' + data.pdfName;
            pdfName = data.pdfName;
            console.log("here 2", pdfName)

          } else {
            fileExtention = 'xlsx';
            url = url + 'excel/' + data.excelName;
            pdfName = data.excelName;
            console.log("here 3", pdfName)

          }

          downloadFile(url, fileExtention, pdfName);
          sweetAlert('Success', data.message, 'success', 'OK');
        }
      });

  }

  tripsLogFilesDownload() {
    const object = {
      _ids: this.selectedTrips,
      companyImage: 'https://s3.amazonaws.com/neighborhood-dev/assets/brokers/MixandMatch.png',
      type: "adjustedtriplogs",
      scheduleTime: this.searchDate
    };

    this.assignTripService
      .getAssignTripLogsFile(object, 'tripslog')
      .subscribe(data => {
        if (data) {
          //    Download File From Here
          let url = urls.SERVER_URL.toString();
          let pdfName;
          let fileExtention;
          console.log("here1")
          if (data.pdfName) {
            fileExtention = 'pdf';
            url = url + 'pdf/' + data.pdfName;
            pdfName = data.pdfName;
            console.log("here 2", pdfName)

          } else {
            fileExtention = 'xlsx';
            url = url + 'excel/' + data.excelName;
            pdfName = data.excelName;
            console.log("here 3", pdfName)

          }

          downloadFile(url, fileExtention, pdfName);
          sweetAlert('Success', data.message, 'success', 'OK');
        }
      });
  }

  selectTrips(event, ind) {
    if (event.target.checked) {
      this.filteredAdjustedTrips[ind].isSelected = true;
      this.selectedTrips.push(this.filteredAdjustedTrips[ind]._id);
      this.selectedTripJobIds.push(this.filteredAdjustedTrips[ind]._id);
    } else {
      this.filteredAdjustedTrips[ind].isSelected = false;
      _.filter(this.selectedTrips, (id, i) => {
        if (id === this.filteredAdjustedTrips[ind]._id) {
          console.log(i);
          // tripInd = i;
          this.selectedTrips.splice(i, 1);
        }
        return id;
      });

      console.log(this.selectedTrips);
    }



    // const assignIndex = this.selectedTrips.indexOf(assign._id);
    // console.log(assignIndex)
    // console.log("++++++++++++++++++++++++++++")
    // if (assignIndex > -1) {
    //   assign.isSelected = false;
    //   this.selectedTrips.splice(assignIndex, 1);
    //   this.selectedTripJobIds.splice(assignIndex, 1);
    // } else {
    //   assign.isSelected = true;
    //   this.selectedTrips.push(assign._id);
    //   this.selectedTripJobIds.push(assign.job._id);
    // }
  }

  adjustOdometerPopup(assign) {
    this.popupAssignTrip = JSON.parse(JSON.stringify(assign));

    this.odometerBegin = 0;
    this.odometerStart = 0;
    this.odometerStop = 0;

    this.odometerBegin = assign.job.odometerBegin;
    this.odometerStart = assign.job.odometerStart;
    this.odometerStop = assign.job.odometerStop;
  }

  updateOdometerValues() {
    const object = {
      _id: this.popupAssignTrip.job._id,
      odometerBegin: this.odometerBegin,
      odometerStart: this.odometerStart,
      odometerStop: this.odometerStop
    };
    this.isOdometerRequest = true;
    // this.storeDispatch.dispatch(new AdjustmentDetails(urls.GET_ADJUSTMENT_DETAIL_TRIPSLOGS_URL + this.popupAssignTrip.job._id, object));
    this.dispatchCenterService
      .getAdjustmentDetail(urls.GET_ADJUSTMENT_DETAIL_TRIPSLOGS_URL + this.popupAssignTrip.job._id, object)
      .subscribe(data => {
        if (data) {
          this.isOdometerRequest = false;
          this.adjustedTrips.forEach((item, i) => {
            if (item.job._id === data._id) {
              this.adjustedTrips[i].job = data;
            }
          });
        }
      });
  }

  bankedSingleTripsModalResponse() {

    const object = {
      type: 'adjustedtriplogs',
      _ids: [this.popupAssignTrip._id],
      scheduleTime: this.searchDate,
      companyImage: 'https://s3.amazonaws.com/neighborhood-dev/assets/brokers/MixandMatch.png',
      bankStatus: this.bankedType,
      statusType: 'single'
    };

    this.assignTripService
      .bankedTrips(object)
      .subscribe(data => {
        if (data) {
          sweetAlert('Success', 'Trips Banked', 'success', 'OK');
          this.selectedTrips = [];
          this.selectedTripJobIds = [];
          this.getCorporateUsers();
          this.getAdjustedTrips();
        }
      });
  }

  btnNagtiveResponse() {

    this.popupAssignTrip = JSON.parse(JSON.stringify({}));
    this.alertMessage = '';
  }

  bankedMultipalTripsModalResponse() {
    const object = {
      type: 'adjustedtriplogs',
      _ids: this.selectedTrips,
      scheduleTime: this.searchDate,
      companyImage: 'https://s3.amazonaws.com/neighborhood-dev/assets/brokers/MixandMatch.png',
      bankStatus: 'banked'
    };

    this.assignTripService
      .bankedTrips(object)
      .subscribe(data => {
        if (data) {
          sweetAlert('Success', 'Trips Banked', 'success', 'OK');
          this.selectedTrips = [];
          this.selectedTripJobIds = [];
          this.getCorporateUsers();
          this.getAdjustedTrips();
        }
      });
  }

  selectAllTrips(event) {
    this.selectedTrips = [];

    if (event.toElement.outerText === 'Select All') {
      // tslint:disable-next-line: forin
      for (const trip in this.filteredAdjustedTrips) {
        this.filteredAdjustedTrips[trip].isSelected = true;
        this.selectedTrips.push(this.filteredAdjustedTrips[trip]._id);
      }
    } else if (event.toElement.outerText === 'Un-Select All') {
      this.unSelectAllTrips();
    }
    console.log(this.selectedTrips);




    // if (this.selectedTrips.length !== this.adjustedTrips.length) {
    //   this.selectedTrips = [];
    //   this.selectedTripJobIds = [];

    //   console.log(this.selectedTrips)
    //   console.log(this.selectedTripJobIds)
    //   console.log("+++++++++++++++++++++++++++++++++")
    //   // tslint:disable-next-line: forin
    //   for (const trip in this.adjustedTrips) {

    //     this.adjustedTrips[trip].isSelected = true;
    //     this.selectedTrips.push(this.adjustedTrips[trip]._id);
    //     this.selectedTripJobIds.push(this.adjustedTrips[trip].job._id);
    //     console.log(this.selectedTrips)
    //   console.log(this.selectedTripJobIds)
    //   }
    //   console.log(this.selectedTrips)
    //   console.log(this.selectedTripJobIds)
    // } else {
    //   this.selectedTrips = [];
    //   this.selectedTripJobIds = [];
    //   // tslint:disable-next-line: forin
    //   for (const trip in this.adjustedTrips) {
    //     this.adjustedTrips[trip].isSelected = false;
    //   }
    // }


  }

  unSelectAllTrips() {
    this.selectedTrips = [];
    // tslint:disable-next-line: forin
    for (const trip in this.filteredAdjustedTrips) {
      this.filteredAdjustedTrips[trip].isSelected = false;
    }
  }

  applyFilter() {
    this.unSelectAllTrips();
    this.unSelectAllTrips();
    if (typeof Worker !== 'undefined') {
      // Create a new
      const worker = new Worker('../../../trip-logs-worker.worker.ts', { type: 'module' });
      // tslint:disable-next-line: no-shadowed-variable
      worker.onmessage = (event) => {
        this.filteredAdjustedTrips = event.data.list;
      };

      setTimeout(() => {
        // tslint:disable-next-line: max-line-length

        worker.postMessage({
          list: this.adjustedTrips,
          corporateFilter: this.selectedCorporateUser,
          driverFilter: this.selectedDriver,
          search: this.search
        });
      }, 1000);
    } else {
      // Web Workers are not supported in this environment.
      // You should add a fallback so that your program still executes correctly.
      console.log('Web Worker are not supported');
    }
  }

  ngOnDestroy(): void {
  }
}
