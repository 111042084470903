import { AgmMap } from '@agm/core';
import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { sweetAlert } from 'src/app/utils/swal';
import { AssigTripsService } from '../assigntrips-service/assigntrips-service';

@Component({
  selector: 'app-trip-detail-model',
  templateUrl: './trip-detail-model.component.html',
  styleUrls: ['./trip-detail-model.component.css']
})
export class TripDetailModelComponent implements OnInit, AfterViewInit {
  @Input('tripDetailModalObj') trip: any;
  @ViewChild("AgmMap", { static: false }) agmElement: AgmMap;
  agmMap;
  bounds;
  latitude: number;
  longitude: number;
  zoom: number = 12;
  eta;
  contact;

  constructor(
    private assignTripService: AssigTripsService
  ) { }

  ngOnInit() {
    console.log('Data: ', this.trip);
  }

  ngAfterViewInit() {
    this.agmElement.mapReady.subscribe((map) => {
      this.agmMap = map;
      setTimeout(() => {this.setBounds();},500);
    });
  }

  setBounds() {
    if (this.agmMap) {
      let bounds = new google.maps.LatLngBounds();
      bounds.extend(
        new window["google"].maps.LatLng(
          this.trip.jobOriginLatitude,
          this.trip.jobOriginLongitude
        )
      );

      bounds.extend(
        new window["google"].maps.LatLng(
          this.trip.jobDestinationLatitude,
          this.trip.jobDestinationLongitude
        )
      );

      this.agmMap.setCenter(bounds.getCenter());
      this.agmMap.fitBounds(bounds);
      console.log(bounds);
    }
  }

  getETA(tripId) {
    this.assignTripService
      .getETA(tripId)
      .subscribe(data => {
        if (data && data.etaMessage) {
          this.eta = data.etaMessage;
        }
      });
  }

  sendSMS() {
    if (!this.contact) return;
    this.trip.contactNumber = this.contact;
    this.assignTripService
      .sendSMS(this.trip)
      .subscribe(data => {
        if (data) {
          this.contact = '';
          sweetAlert('Succcess', "SMS sent successfully", 'success', 'OK');
        }
      });
  }




}
