import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, exhaustMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import {
  OfferedTripsActionTypes, GetDashboardCount, ChangeProviderStatus, GetTripsList, GetLogsList, CommonSuccess, CommonFailure,
} from '../actions/offered-trips-actions';
import { Observable } from 'rxjs';
import { OfferedtripsService } from 'src/app/component/offered-trips/offered-trips-service/offeredtrips.service';


@Injectable()
export class OfferedTripsEffects {

  constructor(
    private actions: Actions,
    private service: OfferedtripsService
  ) { }



  // History request
  @Effect()
  GetDashboardCount: Observable<any> = this.actions.pipe(ofType(OfferedTripsActionTypes.GET_DASHBOARD_COUNT),
    map((action: OfferedTripsActionTypes) => action),
    // Use `exhaustMap` to wait for Observable respond
    exhaustMap(() =>
      this.service
        .getDashboardCount()
        .pipe(
          map((data) => {
            if (data) {
              return new CommonSuccess({ type: OfferedTripsActionTypes.GET_DASHBOARD_COUNT, data });
            } else {
              return new CommonFailure({ type: OfferedTripsActionTypes.GET_DASHBOARD_COUNT, data });
            }

          }),
          catchError((error) => {
            return of(new CommonFailure({ type: OfferedTripsActionTypes.GET_DASHBOARD_COUNT, error }));
          })
        )
    )
  );

  @Effect()
  GetTripsList: Observable<any> = this.actions.pipe(ofType(OfferedTripsActionTypes.GET_TRIPS_LIST),
    map((action: GetTripsList) => action),
    // Use `exhaustMap` to wait for Observable respond
    exhaustMap((action) =>
      this.service
        .getTripsList(action.payload)
        .pipe(
          map((data) => {
            if (data) {
              return new CommonSuccess({ type: OfferedTripsActionTypes.GET_TRIPS_LIST, data });
            } else {
              return new CommonFailure({ type: OfferedTripsActionTypes.GET_TRIPS_LIST, data });
            }
          }),
          catchError((error) => {
            return of(new CommonFailure({ type: OfferedTripsActionTypes.GET_TRIPS_LIST, error }));
          })
        )
    )
  );


  @Effect()
  ChangeProviderStatus: Observable<any> = this.actions.pipe(ofType(OfferedTripsActionTypes.CHANGE_PROVIDER_STATUS),
    map((action: ChangeProviderStatus) => action),
    // Use `exhaustMap` to wait for Observable respond
    exhaustMap((action) =>
      this.service
        .changeProviderStatus(action.payload)
        .pipe(
          map((data) => {
            if (data) {
              return new CommonSuccess({ type: OfferedTripsActionTypes.CHANGE_PROVIDER_STATUS, data });
            } else {
              return new CommonFailure({ type: OfferedTripsActionTypes.CHANGE_PROVIDER_STATUS, data });
            }
          }),
          catchError((error) => {
            return of(new CommonFailure({ type: OfferedTripsActionTypes.CHANGE_PROVIDER_STATUS, error }));
          })
        )
    )
  );
  
  @Effect()
  GetLogsList: Observable<any> = this.actions.pipe(ofType(OfferedTripsActionTypes.GET_LOGS_LIST),
    // map((action: GetLogsList) => action),
    // Use `exhaustMap` to wait for Observable respond
    exhaustMap(() =>
      this.service
        .getTripsLogs()
        .pipe(
          map((data) => {
            if (data) {
              return new CommonSuccess({ type: OfferedTripsActionTypes.GET_LOGS_LIST, data });
            } else {
              return new CommonFailure({ type: OfferedTripsActionTypes.GET_LOGS_LIST, data });
            }
          }),
          catchError((error) => {
            return of(new CommonFailure({ type: OfferedTripsActionTypes.GET_LOGS_LIST, error }));
          })
        )
    )
  );


}
