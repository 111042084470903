import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, exhaustMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import {
    ReportsActionTypes, GetReports, CommonSuccess, CommonFailure, GetDriverPdfReport, GetPaymentPdfReport
} from '../actions/reports.actions';
import { Observable } from 'rxjs';
import { ReportService } from 'src/app/component/reports/reports-service/report.service';


@Injectable()
export class ReportsEffects {

    constructor(
        private actions: Actions,
        private reportService: ReportService
    ) { }



    // History request
    @Effect()
    GetReports: Observable<any> = this.actions.pipe(ofType(ReportsActionTypes.GET_REPORTS),
        map((action: GetReports) => action.payload),
        // Use `exhaustMap` to wait for Observable respond
        exhaustMap((payload) =>
            this.reportService
                .getReportsWithNgrx(payload)
                .pipe(
                    map((data) => {
                        if (data) {
                            return new CommonSuccess({ type: ReportsActionTypes.GET_REPORTS, data });
                        } else {
                            return new CommonFailure({ type: ReportsActionTypes.GET_REPORTS, data });
                        }

                    }),
                    catchError((error) => {
                        return of(new CommonFailure({ type: ReportsActionTypes.GET_REPORTS, error }));
                    })
                )
        )
    );

    @Effect()
    GetDriverPdfReport: Observable<any> = this.actions.pipe(ofType(ReportsActionTypes.GET_DRIVER_PDF_REPORT),
        map((action: GetDriverPdfReport) => action.payload),
        // Use `exhaustMap` to wait for Observable respond
        exhaustMap((payload) =>
            this.reportService
                .getDriverPdfReport(payload)
                .pipe(
                    map((data) => {
                        if (data) {
                            return new CommonSuccess({ type: ReportsActionTypes.GET_DRIVER_PDF_REPORT, data });
                        } else {
                            return new CommonFailure({ type: ReportsActionTypes.GET_DRIVER_PDF_REPORT, data });
                        }

                    }),
                    catchError((error) => {
                        return of(new CommonFailure({ type: ReportsActionTypes.GET_DRIVER_PDF_REPORT, error }));
                    })
                )
        )
    );

    @Effect()
    GetPaymentPdfReport: Observable<any> = this.actions.pipe(ofType(ReportsActionTypes.GET_PAYMENT_REPORT),
        map((action: GetPaymentPdfReport) => action.payload),
        // Use `exhaustMap` to wait for Observable respond
        exhaustMap((payload) =>
            this.reportService
                .getPaymentPdfReport(payload)
                .pipe(
                    map((data) => {
                        if (data) {
                            return new CommonSuccess({ type: ReportsActionTypes.GET_PAYMENT_REPORT, data });
                        } else {
                            return new CommonFailure({ type: ReportsActionTypes.GET_PAYMENT_REPORT, data });
                        }

                    }),
                    catchError((error) => {
                        return of(new CommonFailure({ type: ReportsActionTypes.GET_PAYMENT_REPORT, error }));
                    })
                )
        )
    );
}
