import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { DispatchCenterState, selectDispatchCenterState } from '../../../ngrx/app.states';
import { DispatchCenterActionTypes, AdjustmentDetails, GetBrokerRate } from '../../../ngrx/store/actions/dispatch-center-actions';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/service/auth-service/auth.service';
import { sweetAlert } from 'src/app/utils/swal';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment-timezone';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { Events } from 'src/app/utils/event-utils';
import { SocketService } from 'src/app/service/socket-service/socket-service.service';
import { urls } from 'src/app/utils/url-utils';
import { DriversService } from '../../drivers/drivers-service/drivers-service';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
@Component({
  selector: 'app-adjustment',
  templateUrl: './adjustment.component.html',
  styleUrls: ['./adjustment.component.css']
})

export class AdjustmentComponent implements OnInit, OnDestroy {


  adjustmentForm: FormGroup;
  submitted = false;
  getDriverListState: Observable<any>;
  getAdjustmentState: Observable<any>;
  adjustmentDetails;
  driverList = [];
  jobId;
  timeZone;
  scheduleTime;
  appointmentTime;
  brokerRate;
  estimationData;
  escort = 'escort';
  update = false;
  isFromTripsLogs = false;
  jobCarType = '';
  user;

  constructor(
    private fb: FormBuilder, 
    private storeDispatch: Store<DispatchCenterState>,
    private activatedRoute: ActivatedRoute, 
    private socketService: SocketService,
    private authService: AuthService,
    private driverService: DriversService,
    private sharedDataService: SharedDataService
  ) {

    this.getAdjustmentState = this.storeDispatch.select(selectDispatchCenterState);
  }

  ngOnInit() {
    this.getUser();
    this.getAdjustmentState
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((state) => {
        if (state.success) {
          if (state.type === DispatchCenterActionTypes.ADJUSTMENT_DETAILS) {
            console.log(state.dispatchCenterHistoryList);
            this.adjustmentDetails = state.dispatchCenterHistoryList;

            this.adjustmentForm.patchValue({
              ...this.adjustmentDetails,
              driver: this.adjustmentDetails.driver._id ? this.adjustmentDetails.driver._id : this.adjustmentDetails.driver,
            });
            this.escort = this.adjustmentDetails.escortName;
            this.jobCarType = this.adjustmentDetails.jobCarType;
            this.scheduleTime = new Date(this.adjustmentDetails.scheduleTime);
            this.appointmentTime = new Date(this.adjustmentDetails.appointmentTime);
            this.setUpSocketListener();

            this.getAdjustmentEstimations();
            if (this.update) {
              sweetAlert('Success', 'Fare Adjusted Successfully', 'success', 'OK')
                .then(() => {
                  this.update = false;
                  this.submitted = false;
                });

            }
          }
          if (state.type === DispatchCenterActionTypes.BROKER_RATE) {
            this.brokerRate = state.dispatchCenterHistoryList.preSpecialRate;
          }
        } else if (state.errorMessage) {
          sweetAlert('Error', state.errorMessage, 'error', 'OK');
        }
      });


    this.adjustmentForm = this.fb.group({
      jobFareDetail: this.fb.group({
        totalFare: ['', Validators.required]
      }),
      driver: ['', Validators.required],
      jobCarType: [this.jobCarType],
      odometerStart: [''],
      odometerStop: [''],
      escortName: [''],
      escortRelation: [''],
      additionalInfoJob: this.fb.group({
        jobTollFee: ['', Validators.required],
        totalMiles: ['', Validators.required],
      }),
      created: ['', Validators.required],
      trackInfoJob: this.fb.group({
        jobArrived: this.fb.group({
          time: ['', Validators.required],
        }),
        jobStarted: this.fb.group({
          time: ['', Validators.required],
        }),
        jobFinished: this.fb.group({
          time: ['', Validators.required],
        }),
      }),
    });

    console.log('test100:', this.activatedRoute.snapshot.url)
    if (this.activatedRoute.snapshot.url.length === 4) {

      this.jobId = this.activatedRoute.snapshot.url[1].path;
      this.jobId = "63c51b680f56035991040932";
      this.isFromTripsLogs = true;
      this.getDriverList();
      // this is not enable for trips logs
      // this.getBrokerRate();
      this.getAdjustmentDetails();

    } else {

      this.jobId = this.activatedRoute.snapshot.url[1].path;
      this.isFromTripsLogs = false;
      this.getDriverList();
      this.getBrokerRate();
      this.getAdjustmentDetails();
    }


  }


  getUser() {
    this.sharedDataService
      .getUser()
      .subscribe(user => {
        if (user) {
          this.user = user;
          this.timeZone = this.user.timeZone;
          // this.searchDate = moment().tz(this.timeZone);
          // this.getTrips();
        }
      });
  }

  getDriverList() {
    this.driverService
      .getDrivers(this.user._id, 'active')
      .subscribe(data => {
        if (data) {
          this.driverList = data;
        }
      }, err => {
        sweetAlert('Error', err, 'error', 'OK');
      });
  }

  get form() {
    return this.adjustmentForm.controls;
  }

  onAdjustment() {
    this.submitted = true;
    console.log(this.adjustmentForm.value);
    if (this.adjustmentForm.invalid) {
      return;
    }
    if (this.escort === 'escort') {
      return;
    }
    this.update = true;


    if (this.isFromTripsLogs) {
      this.storeDispatch.dispatch(new AdjustmentDetails(urls.GET_ADJUSTMENT_DETAIL_TRIPSLOGS_URL + this.jobId, this.adjustmentForm.value));
    } else {
      this.storeDispatch.dispatch(new AdjustmentDetails(urls.GET_ADJUSTMENT_DETAIL_URL + this.jobId, this.adjustmentForm.value));
    }
  }

  getAdjustmentDetails() {
    if (this.isFromTripsLogs) {
      this.storeDispatch.dispatch(new AdjustmentDetails(urls.GET_ADJUSTMENT_DETAIL_TRIPSLOGS_URL + this.jobId, null));
    } else {
      this.storeDispatch.dispatch(new AdjustmentDetails(urls.GET_ADJUSTMENT_DETAIL_URL + this.jobId, null));
    }
  }

  getBrokerRate() {
    this.storeDispatch.dispatch(new GetBrokerRate(this.jobId));
  }

  onCreatedTimeChange() {
    if (this.adjustmentForm.value.created._d) {
      console.log(this.adjustmentForm.value.created);
      this.adjustmentForm.patchValue({
        created: this.adjustmentForm.value.created.toISOString()
      });
    }
  }

  onArrivedTimeChange() {
    if (this.adjustmentForm.value.trackInfoJob.jobArrived.time._d) {
      this.adjustmentForm.controls.trackInfoJob['controls'].jobArrived.patchValue({
        time: this.adjustmentForm.value.trackInfoJob.jobArrived.time.toISOString()
      });
    }
  }

  onStartedTimeChange() {
    if (this.adjustmentForm.value.trackInfoJob.jobStarted.time._d) {
      this.adjustmentForm.controls.trackInfoJob['controls'].jobStarted.patchValue({
        time: this.adjustmentForm.value.trackInfoJob.jobStarted.time.toISOString()
      });
    }
  }

  onCompletedTimeChange() {
    if (this.adjustmentForm.value.trackInfoJob.jobFinished.time._d) {
      this.adjustmentForm.controls.trackInfoJob['controls'].jobFinished.patchValue({
        time: this.adjustmentForm.value.trackInfoJob.jobFinished.time.toISOString()
      });
      this.adjustmentForm.controls.trackInfoJob['controls'].jobStarted.patchValue({
        // tslint:disable-next-line: max-line-length
        time: moment(this.adjustmentForm.value.trackInfoJob.jobFinished.time).subtract(this.estimationData.jobMinutes, 'minutes').toISOString()
      });
      this.adjustmentForm.controls.trackInfoJob['controls'].jobArrived.patchValue({
        time: moment(this.adjustmentForm.value.trackInfoJob.jobStarted.time).subtract(5, 'minutes').toISOString()
      });
      this.adjustmentForm.patchValue({
        created: moment(this.adjustmentForm.value.trackInfoJob.jobArrived.time).subtract(5, 'minutes').toISOString()
      });
    }
  }

  setUpSocketListener() {
    this.socketService.addListener(Events.GET_ADJUSTMENT_ESTIMATIONS_CALL)
      .subscribe(data => {
        if (data.success) {
          this.estimationData = data.results.googleFare ? data.results.googleFare.additionalInfoJob : null;
        }
        this.socketService.removeListener(Events.GET_ADJUSTMENT_ESTIMATIONS_CALL);
      });

  }

  getAdjustmentEstimations() {
    const adjustmentEstimationPayload = {
      _id: this.isFromTripsLogs ? this.adjustmentDetails.job : this.adjustmentDetails._id,
      jobFee: this.adjustmentDetails.jobFareDetail.jobFee,
      totalMiles: this.adjustmentDetails.additionalInfoJob.totalMiles,
      jobArrived: this.adjustmentDetails.trackInfoJob.jobArrived,
      jobStarted: this.adjustmentDetails.trackInfoJob.jobStarted,
      jobCompleted: this.adjustmentDetails.trackInfoJob.jobCompleted,
      jobTollFee: this.adjustmentDetails.additionalInfoJob.jobTollFee,
      created: this.adjustmentDetails.created
    };
    console.log(adjustmentEstimationPayload);
    this.socketService.emit(Events.GET_ADJUSTMENT_ESTIMATIONS_CALL, adjustmentEstimationPayload);
  }

  setEscort(event) {
    console.log(event);
    if (event.target.value === 'Helper') {
      this.adjustmentForm.patchValue({
        escortName: 'Helper',
        escortRelation: 'Support'
      });
      this.escort = 'Helper';
    } else if (event.target.value === 'escort') {
      this.adjustmentForm.patchValue({
        escortName: '',
        escortRelation: ''
      });
      this.escort = 'escort';
    } else {
      this.adjustmentForm.patchValue({
        escortName: '',
        escortRelation: ''
      });
      this.escort = '';
    }
  }

  setVehicle(event) {
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.driverList.length; i++) {
      if (event.target.value === this.driverList[i]._id) {
        this.jobCarType = this.driverList[i].driverCar;
        this.adjustmentForm.patchValue({
          jobCarType: this.jobCarType
        });
      }
    }
  }

  ngOnDestroy(): void {
  }

}
