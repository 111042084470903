import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectKpisState, KpisState } from '../../../ngrx/app.states';
import { KpisActionTypes, NemtStatistics, NemtStatisticsByDateRange,
   NemtStatisticsReportPdf } from '../../../ngrx/store/actions/kpis.actions';
import { Observable, Subscription } from 'rxjs';
import { sweetAlert } from 'src/app/utils/swal';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { downloadBase64File } from '../../../utils/pdf-downloader';
import { takeUntil } from 'rxjs/operators';
import {componentDestroyed} from '@w11k/ngx-componentdestroyed';
@Component({
  selector: 'app-nemt-statistics',
  templateUrl: './nemt-statistics.component.html',
  styleUrls: ['./nemt-statistics.component.css']
})
export class NemtStatisticsComponent implements OnInit, OnDestroy {

  getKpisState: Observable<any>;
  kpisStats;
  dateForm: FormGroup;
  constructor(private store: Store<KpisState>, private fb: FormBuilder, private router: Router) {
    this.getKpisState = this.store.select(selectKpisState);
  }

  ngOnInit() {

    this.getKpisState
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe((state) => {
      if (state.success) {
        if (state.type === KpisActionTypes.NEMT_STATISTICS || state.type === KpisActionTypes.NEMT_STATISTICS_BY_DATE_RANGE) {
          this.kpisStats = state.response.length > 0 ? state.response[0] : null;
        } else if (state.type === KpisActionTypes.NEMT_STAT_REPORT_PDF) {
          downloadBase64File(state.response.content, state.response.pdfName);
        }
      } else if (state.errorMessage) {
        sweetAlert('Error', state.errorMessage, 'error', 'Ok');
      }
    });

    this.dateForm = this.fb.group({
      dateRange: ['']
    });
    this.getNemtStats();
  }

  getNemtStats() {
    this.store.dispatch(new NemtStatistics());
  }

  getNemtStatsByDateRange() {
      const startDate = this.dateForm.value.dateRange[0].toISOString();
      const endDate = this.dateForm.value.dateRange[1].toISOString();
      this.store.dispatch(new NemtStatisticsByDateRange(startDate, endDate));
  }

  statDetails(detailType) {
    if (this.dateForm.value.dateRange[0] && this.dateForm.value.dateRange[1]) {
      // tslint:disable-next-line: max-line-length
      this.router.navigate(['/kpis/nemtstatistics/details'], { state: { detailType, dateStart: this.dateForm.value.dateRange[0].toISOString(), dateEnd: this.dateForm.value.dateRange[1].toISOString() } });
    } else {
      sweetAlert('Error', 'Please Select Date Range First.', 'error', 'Ok');
    }
  }

  getStatReport() {
    if (this.dateForm.value.dateRange[0] && this.dateForm.value.dateRange[1]) {
      const data = {
        endDate: this.dateForm.value.dateRange[1].toISOString(),
        startDate: this.dateForm.value.dateRange[0].toISOString()
      };
      this.store.dispatch(new NemtStatisticsReportPdf(data));
    }
  }

  ngOnDestroy(): void {

  }
}
