import { Action } from '@ngrx/store';


export enum KpisActionTypes {
    NEMT_STATISTICS = '[Kpis] Nemt Statistics',
    NEMT_STATISTICS_BY_DATE_RANGE = '[Kpis] Nemt Statistics By Date Range',
    NEMT_STATISTICS_DETAIL_BY_DATE_RANGE = '[Kpis] Nemt Statistics Details By Date Range',
    NEMT_STATISTICS_DETAIL_BY_MEMBER_ID = '[Kpis] Nemt Statistics By Member Id',
    NEMT_REPORTS = '[Kpis] Nemt Reports',
    NEMT_REPORTS_BY_DATE_RANGE = '[Kpis] Nemt Reports By Date Range',
    NEMT_STAT_REPORT_PDF = '[Kpis] Nemt Stat Report Pdf',
    NEMT_BUSSINESS_REPORT_PDF = '[Kpis] Nemt Bussiness Report Pdf',
    NEMT_BROKER_REPORT_PDF = '[Kpis] Nemt Broker Report Pdf',
    NEMT_CORPORATE_REPORT_PDF = '[Kpis] Nemt Corporate Report Pdf',
    NEMT_CORPORATE_TRIP_REPORT_PDF = '[Kpis] Nemt Corporate Trip Report Pdf',
    NEMT_DRIVER_REPORT_PDF = '[Kpis] Nemt Driver Report Pdf',
    DRIVER_BREAKDOWN_BY_DATE_RANGE = '[Kpis] Driver Breakdown By Date Range',
    DRIVER_BREAKDOWN = '[Kpis] Driver Breakdown',
    COMMON_SUCCESS = '[Kpis] Common Success',
    COMMON_FAILURE = '[Kpis] Common Faliure',
}

export class NemtStatistics implements Action {
    readonly type = KpisActionTypes.NEMT_STATISTICS;
    constructor() {
        console.log("nemt stat action")
    }
}

export class NemtStatisticsByDateRange implements Action {
    readonly type = KpisActionTypes.NEMT_STATISTICS_BY_DATE_RANGE;
    constructor(public startDate: any, public endDate: any){

    }
}
export class NemtStatisticsDetailByDateRange implements Action {
    readonly type = KpisActionTypes.NEMT_STATISTICS_DETAIL_BY_DATE_RANGE;
    constructor(public payload: any){

    }
}

export class NemtStatisticsReportPdf implements Action {
    readonly type = KpisActionTypes.NEMT_STAT_REPORT_PDF;
    constructor(public payload: any){

    }
}

export class NemtBussinessReportPdf implements Action {
    readonly type = KpisActionTypes.NEMT_BUSSINESS_REPORT_PDF;
    constructor(public payload: any){

    }
}

export class NemtBrokerReportPdf implements Action {
    readonly type = KpisActionTypes.NEMT_BROKER_REPORT_PDF;
    constructor(public payload: any){

    }
}

export class NemtCorporateReportPdf implements Action {
    readonly type = KpisActionTypes.NEMT_CORPORATE_REPORT_PDF;
    constructor(public payload: any){

    }
}

export class NemtCorporateTripReportPdf implements Action {
    readonly type = KpisActionTypes.NEMT_CORPORATE_TRIP_REPORT_PDF;
    constructor(public payload: any, public corporateId: any){

    }
}

export class NemtDriverReportPdf implements Action {
    readonly type = KpisActionTypes.NEMT_DRIVER_REPORT_PDF;
    constructor(public payload: any){

    }
}

export class NemtStatisticsByMemberId implements Action {
    readonly type = KpisActionTypes.NEMT_STATISTICS_DETAIL_BY_MEMBER_ID;
    constructor(public payload: any){

    }
}


export class NemtReports implements Action {
    readonly type = KpisActionTypes.NEMT_REPORTS;
    constructor() {
    }
}

export class GetDriverBreakdown implements Action {
  readonly type = KpisActionTypes.DRIVER_BREAKDOWN;
  constructor() {
  }
}

export class DriverBreakdownByDateRange implements Action {
  readonly type = KpisActionTypes.DRIVER_BREAKDOWN_BY_DATE_RANGE;
  constructor(public startDate: any, public endDate: any){

  }
}

export class NemtReportsByDateRange implements Action {
    readonly type = KpisActionTypes.NEMT_REPORTS_BY_DATE_RANGE;
    constructor(public startDate: any, public endDate: any){

    }
}


export class CommonSuccess implements Action {
    type = KpisActionTypes.COMMON_SUCCESS;
    constructor(public payload: any) {

    }
}

export class CommonFailure implements Action {
    type = KpisActionTypes.COMMON_FAILURE;
    constructor(public payload: any) {

    }
}

export type All =
    | NemtStatistics
    | NemtStatisticsByDateRange
    | NemtStatisticsDetailByDateRange
    | NemtStatisticsByMemberId
    | NemtReports
    | NemtReportsByDateRange
    | NemtStatisticsReportPdf
    | NemtBussinessReportPdf
    | NemtBrokerReportPdf
    | NemtCorporateReportPdf
    | NemtDriverReportPdf
    | NemtCorporateTripReportPdf
    | GetDriverBreakdown
    | DriverBreakdownByDateRange
    | CommonSuccess
    | CommonFailure;
