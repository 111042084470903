import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { PlannerState, selectPlannerState } from '../../../ngrx/app.states';
import { PlannerActionTypes, GetPlannerList } from '../../../ngrx/store/actions/planner-actions';
import { Observable } from 'rxjs';
import { sweetAlert } from '../../../utils/swal';
import { takeUntil } from 'rxjs/operators';
import {componentDestroyed} from '@w11k/ngx-componentdestroyed';

@Component({
  selector: 'app-view-trips',
  templateUrl: './view-trips.component.html',
  styleUrls: ['./view-trips.component.css']
})
export class ViewTripsComponent implements OnInit, OnDestroy {

  getListObserver: Observable<any>;
  dataList = [];
  todayDate = new Date().toISOString();

  constructor(
    private renderere: Renderer2,
    private store: Store<PlannerState>
  ) {
    this.getListObserver = this.store.select(selectPlannerState);
   }

  ngOnInit() {
    this.getDataList(this.todayDate);
    this.getListObserver
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe((state) => {
      console.log(state);
      if (state.type === PlannerActionTypes.GET_PLANNER_LIST) {
        if (state.success) {
          // this.dataList = state.response;

        } else if (state.errorMessage) {
          sweetAlert('Error', state.errorMessage, 'warning', 'OK');
        }
      }
    });
    this.addJsToElement('../assets/js/libs/jquery.tabledit.min.js').onload = () => {
    };
    // this.addJsToElement('../assets/js/pages/tabledit.init.js').onload = () => {
    // };
  }

  getDataList(date) {
    this.store.dispatch(new GetPlannerList(date));
  }
modelChanged(event) {
  this.getDataList(event._d.toISOString());
}
  addJsToElement(src: string): HTMLScriptElement {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    this.renderere.appendChild(document.body, script);
    return script;
  }

  ngOnDestroy(): void {
  }

}
