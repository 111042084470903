import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AssigTripsService } from '../assigntrips-service/assigntrips-service';

@Component({
  selector: 'app-biller-note-modal',
  templateUrl: './biller-note-modal.component.html',
  styleUrls: ['./biller-note-modal.component.css']
})
export class BillerNoteModalComponent implements OnInit {
  @Input() trip;
  @Output() billerNoteAdded = new EventEmitter<any>();
  
  constructor(
    private assignTripService: AssigTripsService
  ) { }

  ngOnInit() {
  }

  addBillerNote() {
    this.assignTripService
      .addBillerNote(this.trip)
      .subscribe(data => {
        if (data) {
          this.billerNoteAdded.emit(data); 
        }
      });
  }

}
