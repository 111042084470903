import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { tap, map, exhaustMap, catchError } from 'rxjs/operators';
import { KpisService } from '../../../component/kpis/kpis-service/kpis.service';
import { of } from 'rxjs';
import {
    KpisActionTypes, NemtStatistics, CommonFailure, CommonSuccess, NemtStatisticsByDateRange,
    NemtReports, NemtReportsByDateRange, NemtStatisticsDetailByDateRange, NemtStatisticsByMemberId,
    NemtStatisticsReportPdf, NemtBussinessReportPdf, NemtBrokerReportPdf, NemtCorporateReportPdf,
    NemtDriverReportPdf,NemtCorporateTripReportPdf, GetDriverBreakdown, DriverBreakdownByDateRange
} from '../actions/kpis.actions';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';



@Injectable()
export class KpisEffects {

    constructor(
        private actions: Actions,
        private kpisService: KpisService,
        private router: Router
    ) { }

    @Effect()
    NemtStatistics: Observable<any> = this.actions.pipe(ofType(KpisActionTypes.NEMT_STATISTICS),
        // Use `exhaustMap` to wait for Observable respond
        exhaustMap(() =>
            this.kpisService
                .getNemtStatistics()
                .pipe(
                    map((response) => {
                        if (response) {
                            return new CommonSuccess({ type: KpisActionTypes.NEMT_STATISTICS, response });
                        }  else {
                            return new CommonFailure({ type: KpisActionTypes.NEMT_STATISTICS, response });
                        }
                    }),
                    catchError((error) => {
                        return of(new CommonFailure({ type: KpisActionTypes.NEMT_STATISTICS, error }));
                    })
                )
        )
    );

    @Effect()
    NemtStatisticsByDateRange: Observable<any> = this.actions.pipe(ofType(KpisActionTypes.NEMT_STATISTICS_BY_DATE_RANGE),
        map((action: NemtStatisticsByDateRange)=> action),
        // Use `exhaustMap` to wait for Observable respond
        exhaustMap((action) =>
            this.kpisService
                .getNemtStatisticsByDate(action.startDate, action.endDate)
                .pipe(
                    map((response) => {
                        if (response) {
                            return new CommonSuccess({ type: KpisActionTypes.NEMT_STATISTICS_BY_DATE_RANGE, response });
                        }  else {
                            return new CommonFailure({ type: KpisActionTypes.NEMT_STATISTICS_BY_DATE_RANGE, response });
                        }
                    }),
                    catchError((error) => {
                        return of(new CommonFailure({ type: KpisActionTypes.NEMT_STATISTICS_BY_DATE_RANGE, error }));
                    })
                )
        )
    );

    @Effect()
    NemtReports: Observable<any> = this.actions.pipe(ofType(KpisActionTypes.NEMT_REPORTS),
        // Use `exhaustMap` to wait for Observable respond
        exhaustMap(() =>
            this.kpisService
                .getNemtReports()
                .pipe(
                    map((response) => {
                        if (response) {
                            return new CommonSuccess({ type: KpisActionTypes.NEMT_REPORTS, response });
                        }  else {
                            return new CommonFailure({ type: KpisActionTypes.NEMT_REPORTS, response });
                        }
                    }),
                    catchError((error) => {
                        return of(new CommonFailure({ type: KpisActionTypes.NEMT_REPORTS, error }));
                    })
                )
        )
    );

    @Effect()
    NemtReportsByDateRange: Observable<any> = this.actions.pipe(ofType(KpisActionTypes.NEMT_REPORTS_BY_DATE_RANGE),
        map((action: NemtReportsByDateRange)=> action),
        // Use `exhaustMap` to wait for Observable respond
        exhaustMap((action) =>
            this.kpisService
                .getNemtReportsByDate(action.startDate, action.endDate)
                .pipe(
                    map((response) => {
                        if (response) {
                            return new CommonSuccess({ type: KpisActionTypes.NEMT_REPORTS_BY_DATE_RANGE, response });
                        }  else {
                            return new CommonFailure({ type: KpisActionTypes.NEMT_REPORTS_BY_DATE_RANGE, response });
                        }
                    }),
                    catchError((error) => {
                        return of(new CommonFailure({ type: KpisActionTypes.NEMT_REPORTS_BY_DATE_RANGE, error }));
                    })
                )
        )
    );

    @Effect()
    NemtStatisticsDetailByDateRange: Observable<any> = this.actions.pipe(ofType(KpisActionTypes.NEMT_STATISTICS_DETAIL_BY_DATE_RANGE),
        map((action: NemtStatisticsDetailByDateRange)=> action.payload),
        // Use `exhaustMap` to wait for Observable respond
        exhaustMap((payload) =>
            this.kpisService
                .getNemtStatsDetailsByDate(payload)
                .pipe(
                    map((response) => {
                        if (response) {
                            return new CommonSuccess({ type: KpisActionTypes.NEMT_STATISTICS_DETAIL_BY_DATE_RANGE, response });
                        }  else {
                            return new CommonFailure({ type: KpisActionTypes.NEMT_STATISTICS_DETAIL_BY_DATE_RANGE, response });
                        }
                    }),
                    catchError((error) => {
                        return of(new CommonFailure({ type: KpisActionTypes.NEMT_STATISTICS_DETAIL_BY_DATE_RANGE, error }));
                    })
                )
        )
    );

    @Effect()
    NemtStatisticsByMemberId: Observable<any> = this.actions.pipe(ofType(KpisActionTypes.NEMT_STATISTICS_DETAIL_BY_MEMBER_ID),
        map((action: NemtStatisticsByMemberId)=> action.payload),
        // Use `exhaustMap` to wait for Observable respond
        exhaustMap((payload) =>
            this.kpisService
                .getNemtStatsById(payload)
                .pipe(
                    map((response) => {
                        if (response) {
                            return new CommonSuccess({ type: KpisActionTypes.NEMT_STATISTICS_DETAIL_BY_MEMBER_ID, response });
                        }  else {
                            return new CommonFailure({ type: KpisActionTypes.NEMT_STATISTICS_DETAIL_BY_MEMBER_ID, response });
                        }
                    }),
                    catchError((error) => {
                        return of(new CommonFailure({ type: KpisActionTypes.NEMT_STATISTICS_DETAIL_BY_MEMBER_ID, error }));
                    })
                )
        )
    );


    @Effect()
    NemtStatisticsReportPdf: Observable<any> = this.actions.pipe(ofType(KpisActionTypes.NEMT_STAT_REPORT_PDF),
        map((action: NemtStatisticsReportPdf)=> action.payload),
        // Use `exhaustMap` to wait for Observable respond
        exhaustMap((payload) =>
            this.kpisService
                .getNemtStatisticsReportPdf(payload)
                .pipe(
                    map((response) => {
                        if (response) {
                            return new CommonSuccess({ type: KpisActionTypes.NEMT_STAT_REPORT_PDF, response });
                        }  else {
                            return new CommonFailure({ type: KpisActionTypes.NEMT_STAT_REPORT_PDF, response });
                        }
                    }),
                    catchError((error) => {
                        return of(new CommonFailure({ type: KpisActionTypes.NEMT_STAT_REPORT_PDF, error }));
                    })
                )
        )
    );

    @Effect()
    NemtBussinessReportPdf: Observable<any> = this.actions.pipe(ofType(KpisActionTypes.NEMT_BUSSINESS_REPORT_PDF),
        map((action: NemtBussinessReportPdf)=> action.payload),
        // Use `exhaustMap` to wait for Observable respond
        exhaustMap((payload) =>
            this.kpisService
                .getNemtBusinessReportPdf(payload)
                .pipe(
                    map((response) => {
                        if (response) {
                            return new CommonSuccess({ type: KpisActionTypes.NEMT_BUSSINESS_REPORT_PDF, response });
                        }  else {
                            return new CommonFailure({ type: KpisActionTypes.NEMT_BUSSINESS_REPORT_PDF, response });
                        }
                    }),
                    catchError((error) => {
                        return of(new CommonFailure({ type: KpisActionTypes.NEMT_BUSSINESS_REPORT_PDF, error }));
                    })
                )
        )
    );

    @Effect()
    NemtBrokerReportPdf: Observable<any> = this.actions.pipe(ofType(KpisActionTypes.NEMT_BROKER_REPORT_PDF),
        map((action: NemtBrokerReportPdf)=> action.payload),
        // Use `exhaustMap` to wait for Observable respond
        exhaustMap((payload) =>
            this.kpisService
                .getNemtBrokerReportPdf(payload)
                .pipe(
                    map((response) => {
                        if (response) {
                            return new CommonSuccess({ type: KpisActionTypes.NEMT_BROKER_REPORT_PDF, response });
                        }  else {
                            return new CommonFailure({ type: KpisActionTypes.NEMT_BROKER_REPORT_PDF, response });
                        }
                    }),
                    catchError((error) => {
                        return of(new CommonFailure({ type: KpisActionTypes.NEMT_BROKER_REPORT_PDF, error }));
                    })
                )
        )
    );

    @Effect()
    NemtCorporateReportPdf: Observable<any> = this.actions.pipe(ofType(KpisActionTypes.NEMT_CORPORATE_REPORT_PDF),
        map((action: NemtCorporateReportPdf)=> action),
        // Use `exhaustMap` to wait for Observable respond
        exhaustMap((action) =>
            this.kpisService
                .getNemtCorporateReportPdf(action.payload)
                .pipe(
                    map((response) => {
                        if (response) {
                            return new CommonSuccess({ type: KpisActionTypes.NEMT_CORPORATE_REPORT_PDF, response });
                        }  else {
                            return new CommonFailure({ type: KpisActionTypes.NEMT_CORPORATE_REPORT_PDF, response });
                        }
                    }),
                    catchError((error) => {
                        return of(new CommonFailure({ type: KpisActionTypes.NEMT_CORPORATE_REPORT_PDF, error }));
                    })
                )
        )
    );


    @Effect()
    NemtDriverReportPdf: Observable<any> = this.actions.pipe(ofType(KpisActionTypes.NEMT_DRIVER_REPORT_PDF),
        map((action: NemtDriverReportPdf)=> action.payload),
        // Use `exhaustMap` to wait for Observable respond
        exhaustMap((payload) =>
            this.kpisService
                .getNemtDriverReportPdf(payload)
                .pipe(
                    map((response) => {
                        if (response) {
                            return new CommonSuccess({ type: KpisActionTypes.NEMT_DRIVER_REPORT_PDF, response });
                        }  else {
                            return new CommonFailure({ type: KpisActionTypes.NEMT_DRIVER_REPORT_PDF, response });
                        }
                    }),
                    catchError((error) => {
                        return of(new CommonFailure({ type: KpisActionTypes.NEMT_DRIVER_REPORT_PDF, error }));
                    })
                )
        )
    );

    @Effect()
    NemtCorporateTripReportPdf: Observable<any> = this.actions.pipe(ofType(KpisActionTypes.NEMT_CORPORATE_TRIP_REPORT_PDF),
        map((action: NemtCorporateTripReportPdf)=> action),
        // Use `exhaustMap` to wait for Observable respond
        exhaustMap((action) =>
            this.kpisService
                .getNemtCorporateTripReportPdf(action.payload, action.corporateId)
                .pipe(
                    map((response) => {
                        if (response) {
                            return new CommonSuccess({ type: KpisActionTypes.NEMT_CORPORATE_TRIP_REPORT_PDF, response });
                        }  else {
                            return new CommonFailure({ type: KpisActionTypes.NEMT_CORPORATE_TRIP_REPORT_PDF, response });
                        }
                    }),
                    catchError((error) => {
                        return of(new CommonFailure({ type: KpisActionTypes.NEMT_CORPORATE_TRIP_REPORT_PDF, error }));
                    })
                )
        )
    );

    @Effect()
    GetDriverBreakdown: Observable<any> = this.actions.pipe(ofType(KpisActionTypes.DRIVER_BREAKDOWN),
        // Use `exhaustMap` to wait for Observable respond
        exhaustMap(() =>
            this.kpisService
                .getDriverBreakdownDetails()
                .pipe(
                    map((response) => {
                        if (response) {
                            return new CommonSuccess({ type: KpisActionTypes.DRIVER_BREAKDOWN, response });
                        }  else {
                            return new CommonFailure({ type: KpisActionTypes.DRIVER_BREAKDOWN, response });
                        }
                    }),
                    catchError((error) => {
                        return of(new CommonFailure({ type: KpisActionTypes.DRIVER_BREAKDOWN, error }));
                    })
                )
        )
    );

    @Effect()
    DriverBreakdownByDateRange: Observable<any> = this.actions.pipe(ofType(KpisActionTypes.DRIVER_BREAKDOWN_BY_DATE_RANGE),
        map((action: DriverBreakdownByDateRange)=> action),
        // Use `exhaustMap` to wait for Observable respond
        exhaustMap((action) =>
            this.kpisService
                .getDriverBreakdownDetailsByDate(action.startDate, action.endDate)
                .pipe(
                    map((response) => {
                        if (response) {
                            return new CommonSuccess({ type: KpisActionTypes.DRIVER_BREAKDOWN_BY_DATE_RANGE, response });
                        }  else {
                            return new CommonFailure({ type: KpisActionTypes.DRIVER_BREAKDOWN_BY_DATE_RANGE, response });
                        }
                    }),
                    catchError((error) => {
                        return of(new CommonFailure({ type: KpisActionTypes.NEMT_REPORTS_BY_DATE_RANGE, error }));
                    })
                )
        )
    );


}
