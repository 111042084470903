import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DistributionState, selectDistributionState } from '../../../ngrx/app.states';
// tslint:disable-next-line: max-line-length
import { DistributionsActionTypes, GetDriverDistributionById, AddDriverDistribution, UpdateDriverDistribution } from '../../../ngrx/store/actions/distributions-actions';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AuthService } from '../../../service/auth-service/auth.service';
import { sweetAlert } from '../../../utils/swal';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { DriversService } from '../../drivers/drivers-service/drivers-service';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';

@Component({
  selector: 'app-driver-distributions-create',
  templateUrl: './driver-distributions-create.component.html',
  styleUrls: ['./driver-distributions-create.component.css']
})
export class DriverDistributionsCreateComponent implements OnInit, OnDestroy {
  addNewDistributions: FormGroup;
  submitted = false;
  getState: Observable<any>;
  distributionId = null;
  btnName = 'Create';
  driverList = [];
  user;

  constructor(
    private fb: FormBuilder,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private store: Store<DistributionState>,
    private authService: AuthService,
    private driverService: DriversService,
    private sharedDataService: SharedDataService
  ) {
    this.getState = this.store.select(selectDistributionState);
  }

  ngOnInit() {
    this.getUser();

    this.addNewDistributions = this.fb.group({
      driver: ['', Validators.required],
      driverInTake: ['0', Validators.required],
      companyInTake: ['', Validators.required],
      weeklyFee: ['', Validators.required],
      distributionId: [''],
      threshold: ['', Validators.required]
    });

    this.getState
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((state) => {
        console.log(state);
        if (state.type === DistributionsActionTypes.ADD_DRIVER_DISTRIBUTION
          || state.type === DistributionsActionTypes.GET_DRIVER_DISTRIBUTION_BY_ID
          || state.type === DistributionsActionTypes.UPDATE_DRIVER_DISTRIBUTION) {
          if (state.success) {
            if (state.type === DistributionsActionTypes.GET_DRIVER_DISTRIBUTION_BY_ID) {
              console.log('sate ', state);
              this.addNewDistributions.patchValue({ ...state.response });
            } else {
              sweetAlert('Success', this.distributionId ? 'Successfully Updated' : 'Successfully submitted', 'success', 'OK').then((x) => {
                this.addNewDistributions.reset();
                this.submitted = false;
                this.router.navigateByUrl('distributions');
              });
            }
          } else if (state.errorMessage) {
            sweetAlert('Error', state.errorMessage, 'error', 'OK');
          }
        }
      });


    if (this.activeRoute.snapshot.url.length === 3 && this.activeRoute.snapshot.url[2].path === 'edit') {
      this.distributionId = this.activeRoute.snapshot.url[1].path;
      this.store.dispatch(new GetDriverDistributionById(this.distributionId));
      this.btnName = 'Update';
    }

    this.getDriverList();

  }

  getUser() {
    this.sharedDataService
      .getUser()
      .subscribe(user => {
        if (user) {
          this.user = user;
        }
      });
  }

  get form() {
    return this.addNewDistributions.controls;
  }

  onAddNewDistributions() {
    this.submitted = true;
    if (this.addNewDistributions.invalid) {
      return;
    } else {
      if (this.distributionId) {
        this.addNewDistributions.patchValue({
          distributionId: this.distributionId
        });
        this.store.dispatch(new UpdateDriverDistribution(this.addNewDistributions.value));
      }
      this.store.dispatch(new AddDriverDistribution(this.addNewDistributions.value));
    }
  }

  getDriverList() {
    this.driverService
      .getDrivers(this.user._id, 'active')
      .subscribe(data => {
        if (data) {
          this.driverList = data;
        }
      }, err => {
        sweetAlert('Error', err, 'error', 'OK');
      });
  }

  percentage(partialValue) {
    return (100 - partialValue);
  }

  ngOnDestroy(): void {
  }

  applyPercentageToCompanyInTake() {
    const companyInTake = this.percentage(this.addNewDistributions.value.driverInTake);
    this.addNewDistributions.patchValue({ companyInTake });
  }
  applyPercentageToDriverInTake() {
    if (!this.addNewDistributions.value.companyInTake) {
      console.log('hereeee')
      this.addNewDistributions.patchValue({
        driverInTake: 0
      });
      return;
    }
    const driverInTake = this.percentage(this.addNewDistributions.value.companyInTake)
    this.addNewDistributions.patchValue({ driverInTake });
  }

}
