import { Component, OnInit } from '@angular/core';
import { sweetAlert } from '../../../utils/swal';
import { addDataTableScript } from 'src/app/utils/utils.common';
import { CorporateService } from '../corporate-service/corporate-service';

@Component({
  selector: 'app-corporate-user',
  templateUrl: './corporate-user.component.html',
  styleUrls: ['./corporate-user.component.css']
})
export class CorporateuserComponent implements OnInit {
  corporateUsers = [];
  search;
  imageModalUrl;

  constructor(
    private corporateService: CorporateService,
  ) { }

  ngOnInit() {
    this.getcorporateUsers();
  }

  getcorporateUsers() {
    this.corporateService
      .getCorporateUsers()
      .subscribe(data => {
        if (data && data.length) {
          this.corporateUsers = data;
          addDataTableScript();
        }
      }, err => {
        sweetAlert('Error', err, 'warning', 'OK');
      });
  }

  openImageModal(url) {
    this.imageModalUrl = url;
  }
}
