import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Form } from '@angular/forms';
import { Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../service/auth-service/auth.service';
import { imageProcessing, ImageSnippet } from '../../../utils/image-processing-utils';
import { sweetAlert } from '../../../utils/swal';
import { StaffService } from '../staff-service/staff.service';
@Component({
  selector: 'app-staffs-create',
  templateUrl: './staffs-create.component.html',
  styleUrls: ['./staffs-create.component.css']
})
export class StaffsCreateComponent implements OnInit {
  addNewStaff: FormGroup;
  submitted = false;
  btnName = 'Create';
  selectedFile: ImageSnippet = null;
  profileImageURL = null;
  userId = null;
  passwordToggleFlag = true;

  constructor(
    public authService: AuthService,
    private staffService: StaffService, 
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder, 
    private router: Router
  ) { }

  ngOnInit() {
    this.addNewStaff = this.fb.group({
      displayName: ['', [Validators.required, Validators.minLength(3)]],
      contactNumber: ['', Validators.required],
      address: ['', Validators.required],
      roles: ['', Validators.required],
      accountStatus: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(16)]],
      latitude: [{ value: '', disabled: false }],
      longitude: [{ value: '', disabled: false }],
      staffId: [''],
      images: ['']
    });

    this.fillForm();
  }

  fillForm() {
    if (this.activatedRoute.snapshot.url.length === 3 && this.activatedRoute.snapshot.url[2].path === 'edit') {
      this.userId = this.activatedRoute.snapshot.url[1].path;

      if(this.userId) {
        this.staffService
          .getStaffById(this.userId)
          .subscribe(data => {
            if (data) {
              this.addNewStaff.patchValue({ ...data, password: '123456' });
              this.profileImageURL = data.profileImageURL;
            }
          }, err => {
            sweetAlert('Error', err, 'error', 'Ok');
            this.router.navigateByUrl('staffs');
          });
      }

      this.btnName = 'Update';
    }
  }

  processFile(imageInput: any) {
    imageProcessing(imageInput).subscribe((result) => {
      this.selectedFile = result.file;
      this.profileImageURL = result.src;
    });
  }

  getAddress(place) {
    this.addNewStaff.patchValue({
      address: place.formatted_address,
      latitude: place.geometry.location.lat(),
      longitude: place.geometry.location.lng()
    });
  }

  togglePasswordType() {
    this.passwordToggleFlag = !this.passwordToggleFlag;
  }

  saveStaff() {
    this.submitted = true;

    if (this.addNewStaff.invalid) {
      return;
    } else if (this.selectedFile == null && this.profileImageURL == null) {
      return;
    } else {
      const image = {
        newProfilePicture: this.selectedFile
      };

      if (this.btnName === 'Create') {
        this.addNewStaff.patchValue({
          images: image
        });
        // this.store.dispatch(new AddStaff(this.addNewStaff.value));
      } else {
        delete this.addNewStaff.value.password;
        this.addNewStaff.patchValue({
          staffId: this.userId,
          images: image
        });
        // this.store.dispatch(new UpdateStaff(this.addNewStaff.value));
      }

      this.staffService
      .saveStaff(this.addNewStaff.value)
      .subscribe(data => {
        if (data) {
          const message = `Staff ${this.userId ? 'Updated' : 'Added'}  Successfully`;
          sweetAlert('Success', message, 'success', 'Ok')
          .then(() => {
            this.router.navigateByUrl('staffs');
          });
        }
      }, err => {
        sweetAlert('Error', err, 'error', 'Ok');
      });
    }
  }

  get form() {
    return this.addNewStaff.controls;
  }

}
