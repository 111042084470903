import { downloadFile } from 'src/app/utils/pdf-downloader';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';
import { selectKpisState, KpisState } from '../../../ngrx/app.states';
import {
  KpisActionTypes, NemtReports, NemtReportsByDateRange, NemtBussinessReportPdf,
  NemtBrokerReportPdf, NemtCorporateReportPdf, NemtDriverReportPdf, NemtCorporateTripReportPdf
} from '../../../ngrx/store/actions/kpis.actions';
import { Observable, from, Subscription } from 'rxjs';
import { sweetAlert } from 'src/app/utils/swal';
import { getBrokersList } from '../../../utils/utils.common';
import { getBrokerImage } from '../../../utils/utils.common';
import { downloadBase64File } from '../../../utils/pdf-downloader';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
@Component({
  selector: 'app-nemt-report',
  templateUrl: './nemt-report.component.html',
  styleUrls: ['./nemt-report.component.css']
})
export class NemtReportComponent implements OnInit, OnDestroy {

  getKpisState: Observable<any>;
  assignedKpis;
  brokerKpis;
  corporateKpis;
  driverKpis;
  nobrokerTrips = 0;
  noCorporateTrips;
  dateForm: FormGroup;
  isVisible = true; // for table coloumns
  constructor(private fb: FormBuilder, private store: Store<KpisState>) {
    this.getKpisState = this.store.select(selectKpisState);
  }

  ngOnInit() {

    this.getKpisState
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((state) => {
        if (state.success) {
          if (state.type === KpisActionTypes.NEMT_REPORTS || state.type === KpisActionTypes.NEMT_REPORTS_BY_DATE_RANGE) {
            this.nobrokerTrips = 0;
            this.assignedKpis = state.response.assignedKpis.length > 0 ? state.response.assignedKpis[0] : null;
            this.brokerKpis = state.response.brokerKpis;
            // tslint:disable-next-line: max-line-length
            this.corporateKpis = state.response.corporateKpis.length > 0 ? state.response.corporateKpis[0].earnings : state.response.corporateKpis;
            this.noCorporateTrips = state.response.corporateKpis.length > 0 ? state.response.corporateKpis[0].totalJobs : 0;
            this.driverKpis = state.response.driverKpis.length > 0 ? state.response.driverKpis[0].earnings : state.response.driverKpis;
            this.brokerKpis.forEach(element => {
              this.nobrokerTrips = this.nobrokerTrips + element.totalJobs;
            });
          } else if (state.type === KpisActionTypes.NEMT_BUSSINESS_REPORT_PDF || state.type === KpisActionTypes.NEMT_BROKER_REPORT_PDF
            || state.type === KpisActionTypes.NEMT_CORPORATE_REPORT_PDF || state.type === KpisActionTypes.NEMT_DRIVER_REPORT_PDF
            || state.type === KpisActionTypes.NEMT_CORPORATE_TRIP_REPORT_PDF) {
            downloadBase64File(state.response.content, state.response.pdfName);
          }
        } else if (state.errorMessage) {
          sweetAlert('Error', state.errorMessage, 'error', 'Ok');
        }
      });


    this.dateForm = this.fb.group({
      dateRange: ['']
    });

    this.getNemtReports();
  }

  getNemtReports() {
    this.store.dispatch(new NemtReports());
  }

  getNemtStatsByDateRange() {
    const startDate = this.dateForm.value.dateRange[0].toISOString();
    const endDate = this.dateForm.value.dateRange[1].toISOString();
    this.store.dispatch(new NemtReportsByDateRange(startDate, endDate));

  }

  getBrokerName(brokerName) {
    if (brokerName.length === 0) {
      brokerName = 'default';
    }
    return getBrokersList[brokerName];
  }
  getBrokerImg(brokerName) {
    return getBrokerImage(brokerName);
  }


  onToggleClicked() {
    this.isVisible = !this.isVisible;
  }

  downloadBusinessReport() {
    const data = {
      endDate: this.dateForm.value.dateRange ? this.dateForm.value.dateRange[1].toISOString() : null,
      startDate: this.dateForm.value.dateRange ? this.dateForm.value.dateRange[0].toISOString() : null
    };
    this.store.dispatch(new NemtBussinessReportPdf(data));
  }

  downloadBrokerReport() {
    const data = {
      endDate: this.dateForm.value.dateRange ? this.dateForm.value.dateRange[1].toISOString() : null,
      startDate: this.dateForm.value.dateRange ? this.dateForm.value.dateRange[0].toISOString() : null
    };
    this.store.dispatch(new NemtBrokerReportPdf(data));
  }

  downloadCorporateReport() {
    const data = {
      endDate: this.dateForm.value.dateRange ? this.dateForm.value.dateRange[1].toISOString() : null,
      startDate: this.dateForm.value.dateRange ? this.dateForm.value.dateRange[0].toISOString() : null
    };
    this.store.dispatch(new NemtCorporateReportPdf(data));
  }

  downloadCorporateTripReport(corporateId) {
    const data = {
      endDate: this.dateForm.value.dateRange ? this.dateForm.value.dateRange[1].toISOString() : null,
      startDate: this.dateForm.value.dateRange ? this.dateForm.value.dateRange[0].toISOString() : null
    };
    this.store.dispatch(new NemtCorporateTripReportPdf(data, corporateId));
  }

  downloadDriverReport() {
    const data = {
      endDate: this.dateForm.value.dateRange ? this.dateForm.value.dateRange[1].toISOString() : null,
      startDate: this.dateForm.value.dateRange ? this.dateForm.value.dateRange[0].toISOString() : null
    };
    this.store.dispatch(new NemtDriverReportPdf(data));
  }

  ngOnDestroy(): void {
  }
}
