import { Action } from '@ngrx/store';


export enum DistributionsActionTypes {
    LIST_DATA = '[Distributions]  list',
    ADD_DRIVER_DISTRIBUTION = '[Distributions] Add Driver Distribution User',
    GET_DRIVER_DISTRIBUTION_BY_ID = '[Distributions] Get Driver Distribution By Id',
    UPDATE_DRIVER_DISTRIBUTION = '[Distributions] update Driver Distribution',
    COMMON_SUCCESS = '[Distributions] Common Success',
    COMMON_FAILURE = '[Distributions] Common Faliure'
}

export class GetDriverDistributionList implements Action {
    readonly type = DistributionsActionTypes.LIST_DATA;
    constructor() {
    }

}
export class AddDriverDistribution implements Action {
    type = DistributionsActionTypes.ADD_DRIVER_DISTRIBUTION;
    constructor(public payload: any) {
    }
}

export class UpdateDriverDistribution implements Action {
    type = DistributionsActionTypes.UPDATE_DRIVER_DISTRIBUTION;
    constructor(public payload: any) {
    }
}

export class GetDriverDistributionById implements Action {
    type = DistributionsActionTypes.GET_DRIVER_DISTRIBUTION_BY_ID;
    constructor(public payload: any) { }
}

export class CommonSuccess implements Action {
    type = DistributionsActionTypes.COMMON_SUCCESS;
    constructor(public payload: any) {

    }
}

export class CommonFailure implements Action {
    type = DistributionsActionTypes.COMMON_FAILURE;
    constructor(public payload: any) {

    }
}

export type All =
    | GetDriverDistributionList
    | CommonSuccess
    | GetDriverDistributionById
    | AddDriverDistribution
    | UpdateDriverDistribution
    | CommonFailure;
