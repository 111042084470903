import { Component, OnInit, OnDestroy } from '@angular/core';
import { getBrokersList, loadMyScript, filterJobs, timeCalculation } from '../../../utils/utils.common';
import { Store } from '@ngrx/store';
import { selectAssignTripsState, AssignTripsState } from '../../../ngrx/app.states';
import { GetTripsForAssignDashboard, AssignTripsActionTypes } from '../../../ngrx/store/actions/assigntrips-actions';
import { Observable } from 'rxjs';
import { sweetAlert } from 'src/app/utils/swal';
import * as moment from 'moment-timezone';
import * as _ from 'underscore';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { SocketService } from 'src/app/service/socket-service/socket-service.service';
import { Events } from 'src/app/utils/event-utils';
import { AssigTripsService } from '../assigntrips-service/assigntrips-service';
declare var $;
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, OnDestroy {
  settingInterval;
  trips = [];
  time;
  currentTime;
  search = '';
  headingDate;
  selectedBroker = '';
  componentType;
  notificationSearch: any;

  proposedTrips = [];
  markedReadyTrips = [];
  alarmingTrips = [];
  inprogressTrips = [];
  scheduledTrips = [];
  cancelledTrips = [];

  notesModalObj;
  assignTripModalObj;

  assignModalObj;
  driverTripModalObject;
  tripDetailModalObj;

  carouselOptions = {
    margin: 25,
    dots: false,
    nav: true,
    // navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
    navText: ["<div class='nav-btn prev-slide pl-4'> <i class='fa fa-angle-left'></i> </div>", "<div class='nav-btn next-slide'> <i class='fa fa-angle-right'></i> </div>"],
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
        nav: true
      },
      600: {
        items: 1,
        nav: true
      },
      1000: {
        items: 2,
        nav: true,
        loop: false
      },
      1500: {
        items: 4,
        nav: true,
        loop: false
      }
    }
  }
  live;

  constructor(
    private assignTripService: AssigTripsService,
    private socketService: SocketService
  ) { }

  ngOnInit() {
    this.getAllTrips();
    this.listenSocket();
    this.settingInterval = setInterval(() => {
      this.getAllTrips();
    }, 15000);
  }

  getAllTrips() {
    this.assignTripService
      .getTripsForAssignDashboard()
      .subscribe(data => {
        if (data) {
          console.log('TEST100: All Trips ', data);
          this.currentTime = data.currentTime;
          this.trips = data.assigns;
          // this.trips = filterJobs(data.assigns, this.currentTime);
          this.filterTrips();
          console.log('TEST100: Marked Ready: ', this.markedReadyTrips);
          console.log('TEST100: Alarming: ', this.alarmingTrips);
          console.log('TEST100: Inprogress: ', this.inprogressTrips);
          console.log('TEST100: Scheduled: ', this.scheduledTrips);
          console.log('TEST100: Cancelled: ', this.cancelledTrips);
        }
      });
  }

  filterTrips() {
    this.proposedTrips = [];
    this.markedReadyTrips = [];
    this.alarmingTrips = [];
    this.inprogressTrips = [];
    this.scheduledTrips = [];
    this.cancelledTrips = [];

    this.trips.forEach((trip) => {
      const time = timeCalculation(trip, this.currentTime);
      trip.time = time;
      console.log('TEST100: SingleTrip: ', trip.scheduleTime, trip.time, trip);

      if (trip.isReadyForPickup) {
        this.markedReadyTrips.push(trip);
      }

      if (trip.isProposedReadyTime) {
        this.proposedTrips.push(trip);
      }

      if (trip.status === 'cancelled') {
        this.cancelledTrips.push(trip);
      } else if (trip.status === 'inprogress') {
        this.inprogressTrips.push(trip);
        // trip.filter = 'active';
      } else if ((time < 30) || trip.status === 'declined' || trip.status === 'bumped' || trip.status === 'cancelled') {
        trip.filter = 'alarming';
        this.alarmingTrips.push(trip);
      } else if (time <= 120 && time >= 30) {
        trip.filter = 'upcoming';
      } else if (time > 120) {
        trip.filter = 'schedule';
        this.scheduledTrips.push(trip);
      }
      // trip.scheduleDate = moment(trip.scheduleTime).format('MM/DD/YYYY');
    });
  }

  listenSocket() {
    this.socketService.addListener(Events.UPDATE_ASSIGN_TRIP)
      .subscribe(data => {
        if (data.success) {
          sweetAlert('Success', data.message, 'success', 'OK');
          this.trips.filter((item) => {
            if (data.assign._id === item._id) {
              item.isReadyForPickup = data.assign.isReadyForPickup;
            }
          })
        } else {
          sweetAlert('Error', data.message, 'warning', 'OK');
        }
      });
  }

  getBrokersList() {
    return getBrokersList;
  }

  getBroker(key) {
    return getBrokersList[key];
  }

  sortMethod(a, b) {
    return new Date(a.key) > new Date(b.key) ? 1 : -1;
  }

  countTrips(trips) {
    let count = 0;
    const todayDate = moment(this.currentTime).format('MM/DD/YYYY');
    for (var x in trips) {
      if (x === todayDate) {
        count += trips[x].length;
      }
    }
    return count;
  }

  setAssignModalObj(trip, type) {
    this.componentType = type;
    this.assignModalObj = trip;
  }

  tripAssignedResponse(tripStatus: string): void {
    if (tripStatus === "Trip Assigned" || tripStatus === 'Trip Un Assigned') {
      sweetAlert('Succcess', tripStatus, 'success', 'OK')
        .then(() => {
          this.getAllTrips();
        })
    } else if (tripStatus !== null) {
      sweetAlert('Error', tripStatus, 'error', 'OK');
    }
    this.assignModalObj = null;
    this.componentType = null;
  }

  markReadyTrip(assign) {
    const tempAssign = JSON.parse(JSON.stringify(assign));
    const payload = {
      assignId: tempAssign._id,
      isReadyForPickup: !tempAssign.isReadyForPickup
    }
    this.socketService.emit(Events.UPDATE_ASSIGN_TRIP, payload);
  }

  getETA(trip, objectName) {
    console.log(objectName);
    this.assignTripService
      .getETA(trip._id)
      .subscribe((data: any) => {
        console.log(data);
        const selectedTrip = this[objectName].find((x) => x._id === data._id);
        if (selectedTrip) {
          console.log('TEST100: SelectedTrip ', selectedTrip);
          selectedTrip.etaMessage = data.etaMessage;
        }
    });
  }

  viewNotes(trip) {
    console.log('viewNotes', trip);
    this.notesModalObj = trip;
  }

  assignTrip(trip) {
    this.assignTripModalObj = trip;
    this.componentType = 'dashboard';
  }

  openTripDetailModel(trip){
    this.tripDetailModalObj = trip;
  }

  ngOnDestroy() {
    clearInterval(this.settingInterval);
    this.socketService.removeListener(Events.UPDATE_ASSIGN_TRIP);
  }
}
