import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {
  private user = new BehaviorSubject(null);
  private subMenus = new BehaviorSubject(null);

  constructor() { }

  getUser(): Observable<any> {
    return this.user.asObservable();
  }

  saveUser(user) {
    this.user.next(user);
  }

  getSubMenus() {
    return this.subMenus.asObservable();
  }

  saveSubMenus(subMenus) {
    this.subMenus.next(subMenus);
  }
}
