import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, exhaustMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import {
  AssignTripsActionTypes, GetUnassignedList, CommonSuccess, CommonFailure,
  GetAssignedTripsList, AddSpecialRates, CalculateFare, CloneTrip, RemoveTrip,
  UpdateTripStatus, GetCorporateUserList, CreateNewTrip, GetUnassignedTripDetailById,
  GetAssignTripLogsFile, UpdateNewTrip, GetAssignTripLogsList, AssignTrip,
  GetTripsForAssignDashboard, VerifiedCancelledTrip, AddBillerNote, GenerateSignature,
  BankedTrips, UpdateAllTripsSignature, AssignMultipleTrip, GetSweepTripsList, ViewUnparsedTripsDetail,
  RemvoveSweepTrips, SendTripListEmail, GetOutstandingList
} from '../actions/assigntrips-actions';
import { Observable } from 'rxjs';
import { AssigTripsService } from 'src/app/component/assigned-trips/assigntrips-service/assigntrips-service';


@Injectable()
export class AssignTripsEffects {

  constructor(
    private actions: Actions,
    private service: AssigTripsService
  ) { }

  // main page is subscribed to the modal page.
  @Effect()
  RemoveTrip: Observable<any> = this.actions.pipe(ofType(AssignTripsActionTypes.REMOVE_TRIP),
    map((action: RemoveTrip) => action),
    // Use `exhaustMap` to wait for Observable respond
    exhaustMap((action) =>
      this.service
        .removeTrip(action.payload, action.tripId)
        .pipe(
          map((data) => {
            if (data) {
              return new CommonSuccess({ type: AssignTripsActionTypes.REMOVE_TRIP, data });
            } else {
              return new CommonFailure({ type: AssignTripsActionTypes.REMOVE_TRIP, data });
            }

          }),
          catchError((error) => {
            return of(new CommonFailure({ type: AssignTripsActionTypes.REMOVE_TRIP, error }));
          })
        )
    )
  );

  // main page is subscribed to the modal page.
  @Effect()
  UpdateTripStatus: Observable<any> = this.actions.pipe(ofType(AssignTripsActionTypes.UPDATE_TRIP_STATUS),
    map((action: UpdateTripStatus) => action),
    // Use `exhaustMap` to wait for Observable respond
    exhaustMap((action) =>
      this.service
        .UpdateTripStatusWithNgrx(action.payload)
        .pipe(
          map((data) => {
            if (data) {
              return new CommonSuccess({ type: AssignTripsActionTypes.UPDATE_TRIP_STATUS, data });
            } else {
              return new CommonFailure({ type: AssignTripsActionTypes.UPDATE_TRIP_STATUS, data });
            }

          }),
          catchError((error) => {
            return of(new CommonFailure({ type: AssignTripsActionTypes.UPDATE_TRIP_STATUS, error }));
          })
        )
    )
  );

  // Doubt
  @Effect()
  UpdateNewTrip: Observable<any> = this.actions.pipe(ofType(AssignTripsActionTypes.UPDATE_NEW_TRIP),
    map((action: UpdateNewTrip) => action),
    // Use `exhaustMap` to wait for Observable respond
    exhaustMap((action) =>
      this.service
        .createNewTrip(action.payload, action.tripId)
        .pipe(
          map((data) => {
            if (data) {
              return new CommonSuccess({ type: AssignTripsActionTypes.UPDATE_NEW_TRIP, data });
            } else {
              return new CommonFailure({ type: AssignTripsActionTypes.UPDATE_NEW_TRIP, data });
            }

          }),
          catchError((error) => {
            return of(new CommonFailure({ type: AssignTripsActionTypes.UPDATE_NEW_TRIP, error }));
          })
        )
    )
  );

  // left
  @Effect()
  AssignTrip: Observable<any> = this.actions.pipe(ofType(AssignTripsActionTypes.ASSIGN_TRIP),
    map((action: AssignTrip) => action),
    // Use `exhaustMap` to wait for Observable respond
    exhaustMap((action) =>
      this.service
        .assignTrip(action.payload)
        .pipe(
          map((data) => {
            if (data) {
              return new CommonSuccess({ type: AssignTripsActionTypes.ASSIGN_TRIP, data });
            } else {
              return new CommonFailure({ type: AssignTripsActionTypes.ASSIGN_TRIP, data });
            }

          }),
          catchError((error) => {
            return of(new CommonFailure({ type: AssignTripsActionTypes.ASSIGN_TRIP, error }));
          })
        )
    )
  );


  @Effect()
  GetTripsForAssignDashboard: Observable<any> = this.actions.pipe(ofType(AssignTripsActionTypes.GET_TRIPS_FOR_ASSIGN_DASHBOARD),
    // Use `exhaustMap` to wait for Observable respond
    exhaustMap(() =>
      this.service
        .getTripsForAssignDashboard()
        .pipe(
          map((data) => {
            if (data) {
              return new CommonSuccess({ type: AssignTripsActionTypes.GET_TRIPS_FOR_ASSIGN_DASHBOARD, data });
            } else {
              return new CommonFailure({ type: AssignTripsActionTypes.GET_TRIPS_FOR_ASSIGN_DASHBOARD, data });
            }

          }),
          catchError((error) => {
            return of(new CommonFailure({ type: AssignTripsActionTypes.GET_TRIPS_FOR_ASSIGN_DASHBOARD, error }));
          })
        )
    )
  );

  @Effect()
  AssignMultipleTrip: Observable<any> = this.actions.pipe(ofType(AssignTripsActionTypes.ASSIGN_MULTIPLE_TRIPS),
    map((action: AssignMultipleTrip) => action),
    // Use `exhaustMap` to wait for Observable respond
    exhaustMap((action) =>
      this.service
        .assignMultipleTrip(action.payload)
        .pipe(
          map((data) => {
            if (data) {
              return new CommonSuccess({ type: AssignTripsActionTypes.ASSIGN_MULTIPLE_TRIPS, data });
            } else {
              return new CommonFailure({ type: AssignTripsActionTypes.ASSIGN_MULTIPLE_TRIPS, data });
            }

          }),
          catchError((error) => {
            return of(new CommonFailure({ type: AssignTripsActionTypes.ASSIGN_MULTIPLE_TRIPS, error }));
          })
        )
    )
  );

  @Effect()
  GetOutstandingTrips: Observable<any> = this.actions.pipe(ofType(AssignTripsActionTypes.GET_OUTSTANDING_LIST),
    map((action: GetOutstandingList) => action),
    // Use `exhaustMap` to wait for Observable respond
    exhaustMap((action) =>
      this.service
        .getOutstandingTrips(action.endPoint)
        .pipe(
          map((data) => {
            if (data) {
              return new CommonSuccess({ type: AssignTripsActionTypes.GET_OUTSTANDING_LIST, data });
            } else {
              return new CommonFailure({ type: AssignTripsActionTypes.GET_OUTSTANDING_LIST, data });
            }

          }),
          catchError((error) => {
            return of(new CommonFailure({ type: AssignTripsActionTypes.GET_OUTSTANDING_LIST, error }));
          })
        )
    )
  );

  @Effect()
  GetOutstandingTripsForBucket: Observable<any> = this.actions.pipe(ofType(AssignTripsActionTypes.GET_OUTSTANDING_LIST_FOR_BUCKETS),
    map((action: GetOutstandingList) => action),
    // Use `exhaustMap` to wait for Observable respond
    exhaustMap((action) =>
      this.service
        .getOutstandingTripsForBucket(action.endPoint)
        .pipe(
          map((data) => {
            if (data) {
              return new CommonSuccess({ type: AssignTripsActionTypes.GET_OUTSTANDING_LIST_FOR_BUCKETS, data });
            } else {
              return new CommonFailure({ type: AssignTripsActionTypes.GET_OUTSTANDING_LIST_FOR_BUCKETS, data });
            }

          }),
          catchError((error) => {
            return of(new CommonFailure({ type: AssignTripsActionTypes.GET_OUTSTANDING_LIST, error }));
          })
        )
    )
  );

}
