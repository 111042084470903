import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { urls } from '../../../utils/url-utils';
import { errorHandl, setFormData } from '../../../utils/network-utils';
import { ActivatedRoute } from '@angular/router';
@Injectable({
    providedIn: 'root'
})
export class DriversService {

    constructor(private http: HttpClient, private activatedRoute: ActivatedRoute) {
    }

    getDrivers(id, endPoint): Observable<any> {
        return this.http.get<any>(urls.BASE_URL + urls.DRIVERS_URL + `/${id}/${endPoint}`)
            .pipe(
                catchError(errorHandl)
            );
    }

    saveDriver(driverData): Observable<any> {
        const imageFiles = driverData.images;
        const driverId = driverData.driverId;
        delete driverData.images;
        delete driverData.driverId;
        const formData = setFormData(imageFiles, driverData, 'driver');
        if (driverId) {
            return this.http.put<any>(urls.BASE_URL + urls.ADD_DRIVER_URL + '/' + driverId, formData).pipe(
                catchError(errorHandl)
            );
        } else {
            return this.http.post<any>(urls.BASE_URL + urls.ADD_DRIVER_URL, formData)
                .pipe(
                    catchError(errorHandl)
                );
        }

    }

    getDriverProfile(id): Observable<any> {
        return this.http.get<any>(urls.BASE_URL + urls.ADD_DRIVER_URL + `/${id}/`)
            .pipe(
                catchError(errorHandl)
            );
    }

    resetDriverPassword(driverPasswordData): Observable<any> {
        const id = driverPasswordData.driverId;
        delete driverPasswordData['driverId'];
        return this.http.put<any>(urls.BASE_URL + urls.DRIVER_RESET_PASSWORD_URL + `/${id}/`, driverPasswordData)
            .pipe(
                catchError(errorHandl)
            );
    }

    getDriversSms(data): Observable<any> {
        // console.log("path",this.activatedRoute.snapshot.url[0])
        if (data.endPoint === 'queries') {
            return this.http.put<any>(urls.BASE_URL + urls.QUERIES_URL + '/' + data.driverObj._id, data.driverObj)
                .pipe(
                    catchError(errorHandl)
                );
        } else if (data.endPoint === 'driveralerts') {
            return this.http.get<any>(urls.BASE_URL + urls.DRIVERS_SMS_URL + data.id)
                .pipe(
                    catchError(errorHandl)
                );
        }
    }

    getQueriesUserList(): Observable<any> {
        return this.http.get<any>(urls.BASE_URL + urls.QUERIES_URL)
            .pipe(
                catchError(errorHandl)
            );
    }

    getNearbyDrivers(requestData): Observable<any> {

        return this.http.post<any>(urls.BASE_URL + urls.GET_NEARBY_DRIVER_URL, requestData)
            .pipe(
                catchError(errorHandl)
            );
    }

    assignFleet(payload, endPoint): Observable<any> {

        if (typeof payload === 'string') {

        return this.http.post<any>(urls.BASE_URL + urls.DRIVER_GENERATE_QR_URL + `/${endPoint}`, { fleetId: payload })
            .pipe(
                catchError(errorHandl)
            );

        } else {
            return this.http.put<any>(urls.BASE_URL + urls.DRIVER_GENERATE_QR_URL + `/${endPoint}`, payload)
                .pipe(
                    catchError(errorHandl)
                );

        }
    }

    getDriverInvoice(payload) {   
      return this.http.post(
        urls.BASE_URL + urls.DRIVER_CALCULATE_INVOICE_URL, payload)
        .pipe(
            catchError(errorHandl)
        );
    }

    getDriverInvoiceReport(payload) {   
        return this.http.put(
          urls.BASE_URL + urls.DRIVER_CALCULATE_INVOICE_URL, payload)
          .pipe(
              catchError(errorHandl)
          );
      }

    getDriverTimelines(queryString) {
        let endpoint = '/current';
        if (queryString && queryString.dates) {
            endpoint = '';
        }

        let query = '?driver=' + queryString.driver 
        if (queryString.dates) {
            query = query + '&dates=' + encodeURI(JSON.stringify(queryString.dates))
        }

        return this.http.get<any>(urls.BASE_URL + urls.DRIVER_TIMELINES_URL + endpoint + query)
            .pipe(
                catchError(errorHandl)
            );
    }

    saveDriverBreak(payload) {
        return this.http.post<any>(urls.BASE_URL + urls.DRIVER_TIMELINES_URL, payload)
            .pipe(
                catchError(errorHandl)
            );

    }

    getDriverTimelineById(id) {
        return this.http.get<any>(urls.BASE_URL + urls.DRIVER_TIMELINES_URL + '/' + id)
            .pipe(
                catchError(errorHandl)
            );
    }

    saveDriverTimeline(payload) {
        if (payload._id) {
            return this.http.put<any>(urls.BASE_URL + urls.DRIVER_TIMELINES_URL + '/' + payload._id, payload)
            .pipe(
                catchError(errorHandl)
            );
        } else {
            return this.http.post<any>(urls.BASE_URL + urls.DRIVER_TIMELINES_URL, payload)
            .pipe(
                catchError(errorHandl)
            );
        }
        
    }

    getDriverTimelinesReport(queryString) {
        let query = '?dates=' + encodeURI(JSON.stringify(queryString.dates)) + '&isReport=true'
        if (queryString.driver) {
            query = query + '&driver=' + queryString.driver;
        }

        return this.http.get<any>(urls.BASE_URL + urls.DRIVER_TIMELINES_URL + query)
            .pipe(
                catchError(errorHandl)
            );
    }

    generateDriverQR(driverId) {
        return this.http.get<any>(urls.BASE_URL + urls.DRIVER_GENERATE_QR_URL + `/${driverId}`)
            .pipe(
                catchError(errorHandl)
            );
    }
}
