import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { DistributionState, selectDistributionState } from '../../../ngrx/app.states';
import { DistributionsActionTypes, GetDriverDistributionList } from '../../../ngrx/store/actions/distributions-actions';
import { Observable } from 'rxjs';
import { sweetAlert } from '../../../utils/swal';
import { takeUntil } from 'rxjs/operators';
import {componentDestroyed} from '@w11k/ngx-componentdestroyed';
import { addDataTableScript } from 'src/app/utils/utils.common';

@Component({
  selector: 'app-driver-distributions',
  templateUrl: './driver-distributions.component.html',
  styleUrls: ['./driver-distributions.component.css']
})
export class DriverDistributionsComponent implements OnInit, OnDestroy {

  getDistributionState: Observable<any>;
  distributionList = [];
  search: any;

  constructor(
    private store: Store<DistributionState>,
  ) {
    this.getDistributionState = this.store.select(selectDistributionState);
  }

  ngOnInit() {

    this.getDistributionList();

    this.getDistributionState
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe((state) => {
      if (state.type === DistributionsActionTypes.LIST_DATA) {
        if (state.success) {
          this.distributionList = state.response;
          addDataTableScript();
        } else if (state.errorMessage) {
          sweetAlert('Error', state.errorMessage, 'error', 'OK');
        }
      }
    });
  }

  getDistributionList() {

    this.store.dispatch(new GetDriverDistributionList());
  }

  ngOnDestroy(): void {
  }
}
