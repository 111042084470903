import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
  getCompanyLogo,
  addDataTableScript,
} from "../../../utils/utils.common";
import { sweetAlert } from "../../../utils/swal";
import { downloadBase64File } from "../../../utils/pdf-downloader";
import { takeUntil } from "rxjs/operators";
import { componentDestroyed } from "@w11k/ngx-componentdestroyed";
import { FleetService } from "../fleet-service/fleet.service";
@Component({
  selector: "app-fleet-list",
  templateUrl: "./fleet-list.component.html",
  styleUrls: ["./fleet-list.component.css"],
})
export class FleetListComponent implements OnInit {
  path = null;
  fleetList = [];
  search: any;
  status = "active";

  constructor(
    private fleetService: FleetService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.getFleetList();
  }

  getFleetList() {
    this.fleetService
      .getFleetList("isFleetSection=true&status=" + this.status)
      .subscribe(
        (data) => {
          if (data) {
            this.fleetList = data;
            addDataTableScript();
          }
        },
        (err) => {
          sweetAlert("Error", err, "error", "OK");
        }
      );
  }

  onChangeStatus() {
    this. getFleetList()
  }

  getCompanyImage(driverCar) {
    return getCompanyLogo(driverCar);
  }

  downloadSingleFleetQR(fleetId) {
    this.fleetService.getSingleFleetQR(fleetId).subscribe(
      (data) => {
        if (data) {
          downloadBase64File(data.content, data.pdfName);
        }
      },
      (err) => {
        sweetAlert("Error", err, "error", "OK");
      }
    );
  }

  downloadAllFleetQR() {
    const data = {
      status: this.path,
    };
    this.fleetService.getAllFleetQR(data).subscribe(
      (data) => {
        if (data) {
          downloadBase64File(data.content, data.pdfName);
        }
      },
      (err) => {
        sweetAlert("Error", err, "error", "OK");
      }
    );
  }
}
