import {
  Component,
  OnInit,
  Renderer2,
  OnDestroy,
  ElementRef,
  ViewChild,
} from "@angular/core";
import { DynamicMenuService } from "./dynamic-menu-service/dynamic-menu.service";
import { Store } from "@ngrx/store";
import {
  AppState,
  DispatchCenterState,
  selectAuthState,
  selectDispatchCenterState,
} from "../ngrx/app.states";
import { Observable } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { componentDestroyed } from "@w11k/ngx-componentdestroyed";
import * as _ from "underscore";
import { groupListByDate, loadMyScript } from "../utils/utils.common";
import { sweetAlert } from "../utils/swal";
import { LogOut } from "../ngrx/store/actions/auth.actions";
import {
  DispatchCenterActionTypes
} from "../ngrx/store/actions/dispatch-center-actions";
import { Events } from "../utils/event-utils";
import { SocketService } from "../service/socket-service/socket-service.service";
import { SharedDataService } from "../service/shared-service/shared-data.service";
import { DispatchCenterService } from "../component/dispatch-center/dispatch-center-service/dispatch-center-service";
declare var $: any;
declare var window: any;
console.log(`jQuery version: ${$.fn.jquery}`);
@Component({
  selector: "app-sidemenu",
  templateUrl: "./sidemenu.component.html",
  styleUrls: ["./sidemenu.component.css"],
})
export class SidemenuComponent implements OnInit, OnDestroy {
  @ViewChild("audioOption", { static: true }) audioPlayerRef: ElementRef;
  currentUser: any;
  companyCode = null;
  getNotificationsListObserver: Observable<any>;
  dataNotificationsList = [];
  imageUrl: string;
  dispalayName: string;
  menu = null;
  getState: Observable<any>;

  constructor(
    private renderere: Renderer2,
    private dynamicMenu: DynamicMenuService,
    private store: Store<AppState>,
    private storeTwo: Store<DispatchCenterState>,
    private socketService: SocketService,
    private sharedDataService: SharedDataService,
    private dispatchCenterService: DispatchCenterService
  ) {
    this.getState = this.store.select(selectAuthState);
    this.getNotificationsListObserver = this.storeTwo.select(
      selectDispatchCenterState
    );
  }

  ngOnInit() {
    this.sharedDataService
      .getUser()
      .subscribe(user => {
        if (user && !this.menu) {
          // adding jquery
          // this.addJsToElement('../assets/js/app.min.js').onload = () => {
          // };
          this.currentUser = user;
          this.companyCode = user.code;
          this.menu = this.dynamicMenu.generateMenu(user.roles);
          console.log(this.menu);
          // $.getScript('../assets/vendors/core/core.js');
          $.getScript("../assets/js/template.js");
          loadMyScript("../assets/vendors/feather-icons/feather.min.js");

          this.dispalayName = user.displayName;
          if (!this.imageUrl) {
            this.imageUrl = user.profileImageURL;
          }
        }
      });
    this.getState
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((state) => {
        if (state.user != null && this.menu == null) {
          // adding jquery
          // this.addJsToElement('../assets/js/app.min.js').onload = () => {
          // };
          this.currentUser = state.user;
          this.companyCode = state.user.code;
          this.menu = this.dynamicMenu.generateMenu(state.user.roles);
          console.log(this.menu);
          // $.getScript('../assets/vendors/core/core.js');
          $.getScript("../assets/js/template.js");
          loadMyScript("../assets/vendors/feather-icons/feather.min.js");
        }
        if (state.user != null) {
          this.dispalayName = state.user.displayName;
          if (!this.imageUrl) {
            this.imageUrl = state.user.profileImageURL;
          }
        }
      });

    this.socketService
      .addListener(Events.SEND_EXPIRE_DISPATCH)
      .subscribe((data) => {
        if (data.sucess) {
          this.onAudioPlay();
          this.getNotificationsList();
        } else {
        }
      });

    this.getNotificationsList();
  }

  getNotificationsList() {
    this.dispatchCenterService
      .getNotifications()
      .subscribe(data => {
        if (data) {
          this.dataNotificationsList = data;
        }
      }, err => {
        // sweetAlert("Error", err, "warning", "OK");
      });
  }

  onAudioPlay() {
    this.audioPlayerRef.nativeElement.play();
  }
  groupListByDate(list) {
    let pos = 0;
    return _.groupBy(list, (list) => {
      // list.push(pos)
      // pos++;
      return list.groupBy;
    });
  }
  addJsToElement(src: string): HTMLScriptElement {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = src;
    this.renderere.appendChild(document.body, script);
    return script;
  }

  sortMethod(a, b) {
    return a.value.position > b.value.position ? 1 : -1;
  }

  ngOnDestroy(): void {}

  logout() {
    sweetAlert(
      "Alert",
      "Are you sure you want to logout?",
      "warning",
      "Yes",
      "No"
    ).then((result) => {
      if (result.value) {
        this.store.dispatch(new LogOut());
      } else if (result.dismiss) {
        close();
      }
    });
  }
}
