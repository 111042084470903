import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { urls } from '../../../utils/url-utils';
import { errorHandl, setFormData } from '../../../utils/network-utils';

@Injectable({
    providedIn: 'root'
})
export class CorporateService {

    constructor(private http: HttpClient) {
    }

    getCorporateUsers(): Observable<any> {
        return this.http.get<any>(urls.BASE_URL + urls.COPORATE_USER_LIST_URL )
            .pipe(
                catchError(errorHandl)
            );
    }

    getCorporateUserById(id): Observable<any> {
        return this.http.get<any>(urls.BASE_URL + urls.COPORATE_USER_LIST_URL + '/' + id)
            .pipe(
                catchError(errorHandl)
            );
    }

    addCorporateUser(data): Observable<any> {
        const imageFiles = data.images;
        const corporateUserId = data.corporateUserId;
        delete data.images;
        delete data.addCorporateUserId;
        const formData = setFormData(imageFiles, data, 'cooperate');
        if (corporateUserId) {
            return this.http.put<any>(urls.BASE_URL + urls.COPORATE_USER_LIST_URL + '/' + corporateUserId, formData).pipe(
                catchError(errorHandl)
            );
        } else {
            return this.http.post<any>(urls.BASE_URL + urls.COPORATE_USER_LIST_URL, formData).pipe(
                catchError(errorHandl)
            );
        }
    }



}
