import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import {selectVehicleExpenseState, VehicleExpenseState} from '../../../ngrx/app.states';
import {VehicleExpenseActionTypes, GetMaintenanceStats} from '../../../ngrx/store/actions/vehicle-expense.actions';
import { Observable } from 'rxjs';
import { getCompanyLogo } from '../../../utils/utils.common';
import { takeUntil } from 'rxjs/operators';
import {componentDestroyed} from '@w11k/ngx-componentdestroyed';
@Component({
  selector: 'app-maintenance-stats',
  templateUrl: './maintenance-stats.component.html',
  styleUrls: ['./maintenance-stats.component.css']
})
export class MaintenanceStatsComponent implements OnInit, OnDestroy {


  getVehicleExpenseState: Observable<any>;
  maintenanceStats;
  constructor(private store: Store<VehicleExpenseState>) {
    this.getVehicleExpenseState = this.store.select(selectVehicleExpenseState);
   }

  ngOnInit() {

    this.getVehicleExpenseState
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe((state) => {
      console.log(state);
      if (state.success) {
        this.maintenanceStats = state.response.maintenances;
      }
    });

    this.getMaintenanceStats();
  }

  getMaintenanceStats() {
    this.store.dispatch(new GetMaintenanceStats());
  }

  getCompanyImage(driverCar) {
    return getCompanyLogo(driverCar);
   }

  getFleetMaintenance() {
    if (this.maintenanceStats) {
      if (this.maintenanceStats[0] != null && this.maintenanceStats[0].vehicleExpenses.length > 0) {
        return this.maintenanceStats[0].vehicleExpenses;
      }
      return null;
    }
    return null;
  }

  ngOnDestroy(): void {
  }
}
