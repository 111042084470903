import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DispatchCenterService } from 'src/app/component/dispatch-center/dispatch-center-service/dispatch-center-service';

@Component({
  selector: 'app-notifications-slideout',
  templateUrl: './notifications-slideout.component.html',
  styleUrls: ['./notifications-slideout.component.css']
})
export class NotificationsSlideoutComponent implements OnInit {
  @Output() close = new EventEmitter<any>();

  notifications;

  constructor(
    private dispatchCenterService: DispatchCenterService
  ) { }

  ngOnInit() {
    this.getNotifications();
  }

  getNotifications() {
    this.dispatchCenterService
      .getNotifications()
      .subscribe(data => {
        if (data) {
          this.notifications = data;
        }
      });
  }

}
