import { Component, OnInit,OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { takeUntil } from 'rxjs/operators';
import { OfferedTripsState, selectOfferedTripsState } from 'src/app/ngrx/app.states';
import {  GetLogsList, OfferedTripsActionTypes } from 'src/app/ngrx/store/actions/offered-trips-actions';
import { sweetAlert } from 'src/app/utils/swal';

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.css']
})
export class LogsComponent implements OnInit, OnDestroy   {

  getTripsObserver;
  logsList
  constructor( private store: Store<OfferedTripsState>) { 
    this.getTripsObserver = this.store.select(selectOfferedTripsState);
  }
ngOnDestroy(): void {
 
}


  ngOnInit() {
    this.getTripsObserver
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe((state) => {
      console.log(state.success)
      if (state.type === OfferedTripsActionTypes.GET_LOGS_LIST) {
        if (state.success) {
          this.logsList = state.response;
        } else if (state.errorMessage) {
          sweetAlert('Error', state.errorMessage, 'warning', 'OK');
        }
      }
      
    });

    this.getLogs();
  }


  getLogs() {
    this.store.dispatch(new GetLogsList());
  }

}
