import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { AssignTripsState, selectAssignTripsState } from '../../../ngrx/app.states';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { sweetAlert } from 'src/app/utils/swal';
import { AssignTripsActionTypes, AssignTrip, AssignMultipleTrip } from '../../../ngrx/store/actions/assigntrips-actions';
import { takeUntil } from 'rxjs/operators';
import { Events } from 'src/app/utils/event-utils';
import { SocketService } from 'src/app/service/socket-service/socket-service.service';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { AuthService } from 'src/app/service/auth-service/auth.service';
import { loadMyScript } from 'src/app/utils/utils.common';
import { ActivatedRoute } from '@angular/router';
import { DriversService } from '../../drivers/drivers-service/drivers-service';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
import { AssigTripsService } from '../assigntrips-service/assigntrips-service';


declare var $;
@Component({
  selector: 'app-trip-assign-modal',
  templateUrl: './trip-assign-modal.component.html',
  styleUrls: ['./trip-assign-modal.component.css']
})
export class TripAssignModalComponent implements OnInit, OnDestroy {
  @Input() assignModalObj: any;
  @Input() componentType: string;
  @Input() secondaryCompType: string;
  @Output() tripAssigned = new EventEmitter<string>();
  nearbyDrivers = [];
  activeDrivers = [];
  getListObserver: Observable<any>;
  selectedDriver;
  searchDriver;
  // driver = null;
  requestType = null;
  driverIndex = -1;
  prevComp = null;
  multiAssignTripNote = null;
  user;

  constructor(
    private assignStore: Store<AssignTripsState>,
    private socketService: SocketService,
    private activatedRoute: ActivatedRoute,
    private driverService: DriversService,
    private sharedDataService: SharedDataService,
    private assignTripService: AssigTripsService
  ) {
    this.getListObserver = this.assignStore.select(selectAssignTripsState);
  }

  ngOnInit() {
    this.getUser();

    this.prevComp = this.activatedRoute.snapshot.url[1] ? this.activatedRoute.snapshot.url[1].path : null;

    this.getListObserver
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((state) => {
        if (state.success) {
          if (state.type === AssignTripsActionTypes.ASSIGN_TRIP) {
            if (this.requestType === 'Un Assign') {
              this.tripAssigned.emit('Trip Un Assigned');
            } else if (this.requestType === 'Assign') {
              this.tripAssigned.emit('Trip Assigned');
            }
          } else if (state.type === AssignTripsActionTypes.ASSIGN_MULTIPLE_TRIPS) {
            if (this.requestType === 'Multiple Assign') {
              this.tripAssigned.emit('All Trips Assigned');
            }
          } 
        } else if (state.errorMessage) {
          this.tripAssigned.emit(state.errorMessage);
        }
      });

    if (this.componentType !== 'Multiple Assign') {
      this.getNearbyDriver(this.assignModalObj.jobOriginLatitude, this.assignModalObj.jobOriginLongitude);
    } else {
      this.getDriverList();
    }
  }

  getUser() {
    this.sharedDataService
      .getUser()
      .subscribe(user => {
        if (user) {
          this.user = user;
        }
      });
  }

  getNearbyDriver(lat, lng) {
    const data = {
      isNearBy: true,
      companyType: this.assignModalObj.companyType,
      isReassigned: this.assignModalObj.driver ? true : false,
      jobOriginLatitude: lat,
      jobOriginLongitude: lng
    };
    this.driverService
      .getNearbyDrivers(data)
      .subscribe(data => {
        if (data && data.drivers) {
          this.nearbyDrivers = data.drivers;
        }
      }, err => {
        sweetAlert('Error', err, 'warning', 'OK');
      });
  }

  getDriverList() {
    this.driverService
      .getDrivers(this.user._id, 'active')
      .subscribe(data => {
        if (data) {
          this.activeDrivers = data;
        }
      }, err => {
        sweetAlert('Error', err, 'warning', 'OK');
      });
  }

  onSelectDriver(driver) {
    console.log(driver);
    this.selectedDriver = driver;
  }

  assignTrip() {
    if (this.selectedDriver) {
      if (this.componentType === 'Multiple Assign') {
        this.requestType = 'Multiple Assign';
        const data = {
          selectedTrips: this.assignModalObj,
          status: 'offered',
          driver: this.selectedDriver._id,
          note: this.multiAssignTripNote
        };
        this.assignMultipleTrips(data);
      } else {
        this.requestType = 'Assign';
        this.assignModalObj.driver = this.selectedDriver._id;
        this.assignModalObj.status = 'offered';
        this.assignModalObj.jobStatus = 'offered';
        this.assignSingleTrip(this.assignModalObj);
      }
    } else {
      sweetAlert('Error', 'Select Driver', 'error', 'OK')
        .then(() => {
          this.modalClose()
        })
    }
  }

  unAssignTrip() {
    this.assignModalObj.status = 'unassigned';
    this.assignModalObj.jobStatus = 'pending';
    this.assignTripService
      .assignTrip(this.assignModalObj)
      .subscribe(data => {
        if (data) {
          this.tripAssigned.emit('Trip Un Assigned');
        }
      });
  }

  assignSingleTrip(payload) {
    this.assignTripService
      .assignTrip(payload)
      .subscribe(data => {
        if (data) {
          this.tripAssigned.emit('Trip Assigned');
        }
      });
  }

  assignMultipleTrips(payload) {
    this.assignTripService
      .assignMultipleTrip(payload)
      .subscribe(data => {
        if (data) {
          this.tripAssigned.emit('All Trips Assigned');
        }
      })
  }

  archiveTrip() {
    sweetAlert('Alert','Are you sure you want to archive the trip?','warning','Yes','No')
    .then((result) => {
      if(result.value) {
        this.assignModalObj.status = "archived";
        this.assignTripService
          .assignTrip(this.assignModalObj)
          .subscribe(data => {
          if (data) {
            console.log('Trip Archived: ', data);
          }
      });
      }
      else if(result.dismiss){
        // close();
      }
    });
  }

  setRequestType(requestType) {
    this.requestType = requestType;
  }

  getETA() {
    this.assignTripService
      .getETA(this.assignModalObj._id)
      .subscribe(data => {
        if (data) {
          console.log(data);
          this.assignModalObj.etaMessage = data.etaMessage;
        }
      });
  }

  getMiles(driverIndex, driver) {
    this.setUpSocketListener();
    this.driverIndex = -1;

    if (this.assignModalObj.jobOriginLatitude && this.assignModalObj.jobOriginLongitude && this.assignModalObj.jobDestinationLatitude && this.assignModalObj.jobDestinationLongitude) {
      const locationParams = {
        origin: this.assignModalObj.jobOriginLatitude + ' , ' + this.assignModalObj.jobOriginLongitude,
        destination: driver.coords[1] + ' , ' + driver.coords[0]
        // destination: vm.assign.jobDestinationLatitude + ' , ' + vm.assign.jobDestinationLongitude
      };
      locationParams['dbMiles'] = driver.miles || 0;
      locationParams['isLog'] = true;
      console.log(locationParams);
      this.socketService.emit(Events.GET_DRIVER_MILES_FOR_ASSIGN_MODAL, locationParams);
      // Socket.emit(‘assignTripManualFormETA’, locationParams);
      this.driverIndex = driverIndex;
    } else {
      sweetAlert('Error', 'Please Select Pickup and DropOff Address', 'warning', 'OK');
    }
  }

  setUpSocketListener() {
    this.socketService.addListener(Events.GET_DRIVER_MILES_FOR_ASSIGN_MODAL)
      .subscribe(data => {
        if (data.success) {
          console.log(data)
          this.nearbyDrivers[this.driverIndex].googleETA = data.message;
        } else {
          this.nearbyDrivers[this.driverIndex].googleETA = null;
        }
      });
  }

  modalClose() {
    // this.nearbyDrivers = [];
    $('#assignModal .close').click();
    // this.tripAssigned.emit(null);
  }

  ngOnDestroy(): void {
  }
}
