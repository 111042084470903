import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, exhaustMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import {
    PlannerActionTypes, GetPlannerList, ScrapFile, CommonSuccess, CommonFailure
} from '../actions/planner-actions';
import { Observable } from 'rxjs';
import { PlannerService } from 'src/app/component/planner/planner-service/planner-service';


@Injectable()
export class PlannerEffects {

    constructor(
        private actions: Actions,
        private pannerService: PlannerService
    ) { }



    // History request
    @Effect()
    GetPlannerList: Observable<any> = this.actions.pipe(ofType(PlannerActionTypes.GET_PLANNER_LIST),
        map((action: GetPlannerList) => action),
        // Use `exhaustMap` to wait for Observable respond
        exhaustMap((action) =>
            this.pannerService
                .getPlannerListWithNgrx(action.endPoint)
                .pipe(
                    map((data) => {
                        if (data) {
                            return new CommonSuccess({ type: PlannerActionTypes.GET_PLANNER_LIST, data });
                        } else {
                            return new CommonFailure({ type: PlannerActionTypes.GET_PLANNER_LIST, data });
                        }

                    }),
                    catchError((error) => {
                        return of(new CommonFailure({ type: PlannerActionTypes.GET_PLANNER_LIST, error }));
                    })
                )
        )
    );

    @Effect()
    ScrapFile: Observable<any> = this.actions.pipe(ofType(PlannerActionTypes.SCRAP_FILE),
        map((action: ScrapFile) => action),
        // Use `exhaustMap` to wait for Observable respond
        exhaustMap((action) =>
            this.pannerService
                .scrapTrips(action.file, action.data)
                .pipe(
                    map((data) => {
                        if (data) {
                            return new CommonSuccess({ type: PlannerActionTypes.SCRAP_FILE, data });
                        } else {
                            return new CommonFailure({ type: PlannerActionTypes.SCRAP_FILE, data });
                        }

                    }),
                    catchError((error) => {
                        return of(new CommonFailure({ type: PlannerActionTypes.GET_PLANNER_LIST, error }));
                    })
                )
        )
    );
}
