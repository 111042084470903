import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../../service/auth-service/auth.service';
import { imageProcessing } from '../../../utils/image-processing-utils';
import { ActivatedRoute, Router } from '@angular/router';
import { sweetAlert } from '../../../utils/swal';
import * as moment from 'moment-timezone';
import { FleetService } from '../fleet-service/fleet.service';
@Component({
  selector: 'app-create-fleet',
  templateUrl: './create-fleet.component.html',
  styleUrls: ['./create-fleet.component.css']
})
export class CreateFleetComponent implements OnInit {
  createFleetForm: FormGroup;
  btnName = 'Create';
  fleetId = null;
  carRegistrationImageUrl = null;
  carRegistrationSelectedFile = null;
  insuranceCardImageUrl = null;
  insuranceCardSelectedFile = null;
  stateInspectionCardImageUrl = null;
  stateInspectionCardSelectedFile = null;
  ownerImageUrl = null;
  ownerSelectedFile = null;
  submitted = false;
  imageModalUrl = null;

  constructor(
    private fleetService: FleetService,
    private authService: AuthService, 
    private fb: FormBuilder, 
    private router: Router, 
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.createFleetForm = this.fb.group({
      driverCarNumber: ['', Validators.required],
      driverCarModel: ['', Validators.required],
      driverCarColor: ['', Validators.required],
      vin: ['', Validators.required],
      setiDecall: ['', Validators.required],
      status: ['', Validators.required],
      realOdometer: ['', Validators.required],
      lcp: ['', Validators.required],
      united: ['', Validators.required],
      logisticscare: ['', Validators.required],
      first: ['', Validators.required],
      driverCar: ['Standard', Validators.required],
      images: [''],
      carRegistrationExpiry: ['', Validators.required],
      insuranceCardExpiry: ['', Validators.required],
      stateInspectionCardExpiry: ['', Validators.required],
      FleetId: ['123'],
      carRegistrationExpiryDate: [''],
      insuranceCardExpiryDate: [''],
      stateInspectionCardExpiryDate: [''],
      ownerName: [''],
      ownerAddress: [''],
      ownerPhone: [''],
      ownerBusiness: [''],
      licenseNo: [''],
      insuranceCompany: [''],
      kindOfInsurance: ['']

    });
    this.fillData();
  }

  fillData() {
    if (this.activatedRoute.snapshot.url.length === 3 && this.activatedRoute.snapshot.url[2].path === 'edit') {
      this.fleetId = this.activatedRoute.snapshot.url[1].path;

      this.fleetService
        .getFleetDataById(this.fleetId)
        .subscribe(data => {
          if (data) {
            this.createFleetForm.patchValue({
              ...data,
              carRegistrationExpiryDate: data.carRegistrationExpiry,
              insuranceCardExpiryDate: data.insuranceCardExpiry,
              stateInspectionCardExpiryDate: data.stateInspectionCardExpiry,
            });
            this.carRegistrationImageUrl = data.driverCarRegistrationImage;
            this.insuranceCardImageUrl = data.driverInsuranceCardImage;
            this.stateInspectionCardImageUrl = data.driverStateInspectionImage;
            this.ownerImageUrl = data.ownerImage;
          }
        });

      this.btnName = 'Update';
    }
  }

  getAccountStatus() {
    return this.authService.fleetAccountStatus;
  }

  selectedVehicle(vehicle: string): void {
    this.createFleetForm.patchValue({
      driverCar: vehicle
    });
  }

  processCarRegFile(ImageInput: any) {
    imageProcessing(ImageInput).subscribe((result) => {

      this.carRegistrationSelectedFile = result.file;
      this.carRegistrationImageUrl = result.src;
    });
  }

  processOwnerFile(ImageInput: any) {
    imageProcessing(ImageInput).subscribe((result) => {

      this.ownerSelectedFile = result.file;
      this.ownerImageUrl = result.src;
    });
  }

  processStateInspectionFile(ImageInput: any) {
    imageProcessing(ImageInput).subscribe((result) => {

      this.stateInspectionCardSelectedFile = result.file;
      this.stateInspectionCardImageUrl = result.src;
    });
  }

  processInsuranceFile(ImageInput: any) {
    imageProcessing(ImageInput).subscribe((result) => {

      this.insuranceCardSelectedFile = result.file;
      this.insuranceCardImageUrl = result.src;
    });
  }

  openImageModal(url) {
    this.imageModalUrl = url;
  }

  onFleetCreate() {
    this.submitted = true;
    console.log(this.createFleetForm.value);
    if (this.createFleetForm.invalid) {
      return;
    }
    if ((this.carRegistrationSelectedFile == null && this.carRegistrationImageUrl == null) ||
      (this.insuranceCardSelectedFile == null && this.insuranceCardImageUrl == null) ||
      (this.stateInspectionCardSelectedFile == null && this.stateInspectionCardImageUrl == null)) {
      return;
    }
    const image = {
      driverCarRegistrationImage: this.carRegistrationSelectedFile,
      driverInsuranceCardImage: this.insuranceCardSelectedFile,
      driverStateInspectionImage: this.stateInspectionCardSelectedFile,
      ownerImage: this.ownerSelectedFile
    };

    this.createFleetForm.patchValue({
      images: image,
      FleetId: this.fleetId
    });
    delete this.createFleetForm.value.carRegistrationExpiryDate;
    delete this.createFleetForm.value.insuranceCardExpiryDate;
    delete this.createFleetForm.value.stateInspectionCardExpiryDate;
    if (this.btnName === 'Create') {
      this.fleetService
        .addFleet(this.createFleetForm.value)
        .subscribe(data => {
          if (data) {
            sweetAlert('Success', 'Fleet Added Successfully', 'success', 'OK')
              .then(() => {
                this.router.navigateByUrl('/fleets');
              });
          }
        }, err => {
          sweetAlert('Error', err, 'error', 'OK')
              .then(() => {
                this.router.navigateByUrl('/fleets');
              });
        });
    } else {
      this.fleetService
        .addFleet(this.createFleetForm.value)
        .subscribe(data => {
          if (data) {
            sweetAlert('Success', 'Fleet Updated Successfully', 'success', 'OK')
              .then(() => {
                this.router.navigateByUrl('/fleets');
              });
          }
        }, err => {
          sweetAlert('Error', err, 'error', 'OK')
              .then(() => {
                this.router.navigateByUrl('/fleets');
              });
        });
    }
  }


  fillDummyData() {
    this.createFleetForm.patchValue({
      driverCarNumber: 'Les-123',
      driverCarModel: 'Honda Civic',
      driverCarColor: 'Red',
      vin: 'Test',
      setiDecall: 'Test',
      status: 'active',
      realOdometer: '0',
      driverCar: 'SUV'
    });
  }

  clearData() {
    this.createFleetForm.reset();
  }

  oncarRegistrationExpiryChange(event) {
    if (event) {
      this.createFleetForm.patchValue({
        carRegistrationExpiry: moment(event).toISOString()
      });
    }
  }

  onInsuranceCardExpiryChange(event) {

    if (event) {
      this.createFleetForm.patchValue({
        insuranceCardExpiry: moment(event).toISOString()
      });
    }
  }

  // }
  onStateInspectionCardExpiryChange(event) {
    if (event) {
      this.createFleetForm.patchValue({
        stateInspectionCardExpiry: moment(event).toISOString()
      });
    }
  }

  getOwnerAddress(place) {
    this.createFleetForm.patchValue({
      ownerAddress: place.formatted_address
    });
  }

  get form() {
    return this.createFleetForm.controls;
  }
}
