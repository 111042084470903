import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { Store } from '@ngrx/store';
import { selectAssignTripsState, AssignTripsState } from '../../../ngrx/app.states';
import {
  GetAssignedTripsList, AssignTripsActionTypes,
  GetCorporateUserList, CreateNewTrip, GetUnassignedTripDetailById, UpdateNewTrip
} from '../../../ngrx/store/actions/assigntrips-actions';
import { Observable } from 'rxjs';
import { sweetAlert } from 'src/app/utils/swal';
import { AuthService } from '../../../service/auth-service/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { getBrokersList, loadMyScript } from '../../../utils/utils.common';
import * as moment from 'moment-timezone';
import { templateJitUrl } from '@angular/compiler';
import { SocketService } from 'src/app/service/socket-service/socket-service.service';
import { Events } from 'src/app/utils/event-utils';
import { states } from 'src/app/utils/utils.common';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
import { AssigTripsService } from '../assigntrips-service/assigntrips-service';
import { DriversService } from '../../drivers/drivers-service/drivers-service';
import { KeyValue } from '@angular/common';

declare var $;
@Component({
  selector: 'app-new-trip',
  templateUrl: './new-trip.component.html',
  styleUrls: ['./new-trip.component.css']
})
export class NewTripComponent implements OnInit {

  legId = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M',
    'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
  noOfPassengers = 4;
  selectedFormName = 'Dispatch';
  assignedTrips;
  corporateUserList;
  getAssignTripsState: Observable<any>;
  submitted = false;
  unassignedTripDetailById = null;
  btnName = 'Create';
  unassignedTripId;
  timeZone;
  legsForm: FormArray;
  noOfLegs = 0;
  todayDate;
  googleMilage = null;
  newTripForm: FormGroup;
  search: any;
  states = states;
  estimatedFare: ''
  user;
  isStandingOrdersEnabled = false;
  selectedSchedulerRange;

  days = {
    "monday": {label: "Mon", checked: false, driver: ""},
    "tuesday": {label: "Tue", checked: false, driver: ""},
    "wednesday": {label: "Wed", checked: false, driver: ""},
    "thursday": {label: "Thu", checked: false, driver: ""},
    "friday": {label: "Fri", checked: false, driver: ""},
    "saturday": {label: "Sat", checked: false, driver: ""},
    "sunday": {label: "Sun", checked: false, driver: ""}
  }

  scheduler;

  drivers;
  radius;

  constructor(private fb: FormBuilder,
    private store: Store<AssignTripsState>, private authService: AuthService, private router: Router,
    private activatedRoute: ActivatedRoute, private socketService: SocketService,
    private sharedDataService: SharedDataService,
    private assignTripService: AssigTripsService,
    private driverService: DriversService) {
    this.getAssignTripsState = this.store.select(selectAssignTripsState);
    this.getAllDays();
    // this.timeZone = this.authService.getUserData().timeZone;
  }

  ngOnInit() {
    this.getUser();
    this.getDrivers();
    this.scheduler = this.getWeekDayList();
    console.log('TEST100: Scheduler: ', this.scheduler);
    // loadMyScript('assets/vendors/core/core.js')
    // loadMyScript('assets/vendors/feather-icons/feather.min.js')
    // loadMyScript('assets/js/template.js')

    this.todayDate = moment().tz(this.timeZone).format();
    console.log('>>>>>', this.todayDate)
    this.getAssignTripsState.subscribe((state) => {
      if (state.success && state.type === AssignTripsActionTypes.UPDATE_NEW_TRIP) {
        sweetAlert('Success', 'Trip Updated Succesfully', 'success', 'OK')
          .then(() => {
            this.router.navigateByUrl('assignstrips/unassigned');
          });
      } else if (state.errorMessage) {
        sweetAlert('Error', state.errorMessage, 'error', 'OK');
      }
    });

    this.newTripForm = this.fb.group({

      appointmentTime: [this.todayDate, Validators.required],
      company: [this.user._id, Validators.required],
      companyNote: [''],
      companyType: [''],
      jobCarType: ['Standard', Validators.required],
      jobDestinationAddress: ['', Validators.required],
      jobDestinationLatitude: [0, Validators.required],
      jobDestinationLongitude: [0, Validators.required],
      jobOriginAddress: ['', Validators.required],
      jobOriginLatitude: [0, Validators.required],
      jobOriginLongitude: [0, Validators.required],
      jobPassengerNumber: [1, Validators.required],
      jobType: ['assigned', Validators.required],
      priorityClient: this.fb.group({
        displayName: ['', Validators.required],
        contactNumber: ['', Validators.required],
        cellPhone: [''],
        clientId: ['']
      }),
      rawId: [''],
      scheduleTime: [this.todayDate, Validators.required],
      isMedical: [false],
      iSschUrgentCare: [''],
      isUrgentCare: [''],
      isWheelChair: [''],
      isCarSeat: [''],
      isLongDistance: [''],
      memberId: [''],
      tripId: [''],
      tripRequirement: ['AMB'],
      dob: [''],
      cooperate: [''],
      schedulePickupHour: ['', Validators.required],
      schedulePickupMinutes: ['', Validators.required],
      appointmentPickupHour: ['', Validators.required],
      appointmentPickupMinutes: ['', Validators.required],
      scheduledTime: [this.todayDate, Validators.required],
      milage: [null],
      tripLegs: this.fb.array([this.createLeg(0)]),
      state: ['', Validators.required],
      customerSpecialRate: [''],
      noOfEscorts: [''],
      schedulerRange: [''],
      dates: [''],
      isStandingOrder: [false]
    });

    this.getAssignTripsOfNormalDispatch();
    this.checkEditTrip();
  }

  getUser() {
    this.sharedDataService
      .getUser()
      .subscribe(user => {
        if (user) {
          this.user = user;
          this.timeZone = this.user.timeZone;
        }
      });
  }

  getDrivers() {
    this.driverService
      .getDrivers(this.user._id, 'active')
      .subscribe(data => {
        this.drivers = data;
      });
  }

  get form() {
    return this.newTripForm.controls;
  }

  getBrokersList() {
    return getBrokersList;
  }

  getOriginAddress(place) {
    this.newTripForm.patchValue({
      jobOriginAddress: place.formatted_address,
      jobOriginLatitude: place.geometry.location.lat(),
      jobOriginLongitude: place.geometry.location.lng(),
    });
    this.legsForm.at(0).patchValue({
      jobOriginAddress: place.formatted_address,
      jobOriginLatitude: place.geometry.location.lat(),
      jobOriginLongitude: place.geometry.location.lng(),
    });

  }

  getDestinationAddress(place) {

    this.newTripForm.patchValue({
      jobDestinationAddress: place.formatted_address,
      jobDestinationLatitude: place.geometry.location.lat(),
      jobDestinationLongitude: place.geometry.location.lng(),
    });

    this.legsForm.at(0).patchValue({
      jobDestinationAddress: place.formatted_address,
      jobDestinationLatitude: place.geometry.location.lat(),
      jobDestinationLongitude: place.geometry.location.lng(),
    });
  }

  getPickUpAddress(place, index) {
    this.legsForm.at(index).patchValue({
      jobOriginAddress: place.formatted_address,
      jobOriginAddressLatitude: place.geometry.location.lat(),
      jobOriginAddressLongitude: place.geometry.location.lng()
    });
  }

  getDropOffAddress(place, index) {
    this.legsForm.at(index).patchValue({
      jobDestinationAddress: place.formatted_address,
      jobDestinationLatitude: place.geometry.location.lat(),
      jobDestinationLongitude: place.geometry.location.lng()
    });
    if (this.legsForm.length > (index + 1)) {
      this.legsForm.at(index + 1).patchValue({
        jobOriginAddress: place.formatted_address,
        jobOriginLatitude: place.geometry.location.lat(),
        jobOriginLongitude: place.geometry.location.lng()
      });
    }
  }

  selectedVehicle(vehicle?: string): void {
    this.newTripForm.patchValue({
      //jobCarType: vehicle,
      jobPassengerNumber: 1
    });
    if (this.newTripForm.value.jobCarType === 'SUV') {
      this.noOfPassengers = 7;
    } else {
      this.noOfPassengers = 4;
    }
  }

  selectedMedicalVehicle(vehicle: string): void {
    this.newTripForm.patchValue({
      tripRequirement: vehicle
    });
  }

  passengerSelected(passengers: number): void {
    this.newTripForm.patchValue({
      jobPassengerNumber: passengers
    });
  }

  formSelected(name) {
    this.assignedTrips = null;
    this.selectedFormName = name;
    // this.clearTripLegs();
    if (this.selectedFormName === 'Dispatch') {
      // this.setNewTripFormValidators();
      this.newTripForm.patchValue({
        jobType: 'assigned',
        companyType: '',
        jobCarType: this.unassignedTripDetailById && !this.unassignedTripDetailById.isMedical ?
          this.unassignedTripDetailById.jobCarType : 'Standard',
        tripRequirement: this.unassignedTripDetailById && !this.unassignedTripDetailById.isMedical ?
          this.unassignedTripDetailById.tripRequirement : 'Standard',
        isMedical: false,
        jobPassengerNumber: this.unassignedTripDetailById ? this.unassignedTripDetailById.jobPassengerNumber : 1
      });
      this.getAssignTripsOfNormalDispatch();
    } else if (this.selectedFormName === 'Corporate') {

      this.setNewTripFormValidators();
      this.newTripForm.patchValue({
        jobType: 'cooperate',
        companyType: '',
        jobCarType: this.unassignedTripDetailById && !this.unassignedTripDetailById.isMedical ?
          this.unassignedTripDetailById.jobCarType : 'Standard',
        tripRequirement: this.unassignedTripDetailById && !this.unassignedTripDetailById.isMedical ?
          this.unassignedTripDetailById.tripRequirement : 'Standard',
        isMedical: false,
        jobPassengerNumber: this.unassignedTripDetailById ? this.unassignedTripDetailById.jobPassengerNumber : 1

      });

      this.assignTripService
        .getCorporateUserList()
        .subscribe(data => {
          if (data) {
            this.corporateUserList = data;
          }
        });
    } else if (this.selectedFormName === 'Medical') {
      this.setNewTripFormValidators();
      this.newTripForm.patchValue({
        jobType: 'assigned',
        companyType: this.unassignedTripDetailById ? this.unassignedTripDetailById.companyType : '',
        jobCarType: this.unassignedTripDetailById ? this.unassignedTripDetailById.jobCarType : '',
        tripRequirement: this.unassignedTripDetailById && this.unassignedTripDetailById.isMedical ?
          this.unassignedTripDetailById.tripRequirement : 'AMB',
        isMedical: true
      });
    }
  }

  onNewTripSubmit() {
    // console.log(this.newTripForm.value);
    this.submitted = true;
    // check trip ids
    if (this.legsForm.length > 1 && this.selectedFormName === 'Medical') {
      const result = this.checkTripIdUnique();
      if (!result) {
        sweetAlert('Error', 'Duplicate Trip Id Found', 'error', 'OK');
        return;
      }
    }

    this.setTimeZone();
    if (this.newTripForm.invalid) {
      return;
    }
    else {
      if (this.selectedFormName === "Dispatch" ||  this.selectedFormName === "Corporate") {
        if (this.newTripForm.value.customerSpecialRate === "") {
          delete this.newTripForm.value.customerSpecialRate;
        }
        delete this.newTripForm.value.noOfEscorts;

      } else if (this.selectedFormName === "Medical") {
        if (this.newTripForm.value.noOfEscorts === "") {
          delete this.newTripForm.value.noOfEscorts;
        }
        delete this.newTripForm.value.customerSpecialRate;
      }
    }

    if (this.isStandingOrdersEnabled) {
      this.newTripForm.patchValue({
        dates: this.enumerateDaysBetweenDates(this.newTripForm.value.schedulerRange[0], this.newTripForm.value.schedulerRange[1]),
        isStandingOrder: true
      });
    }

    if (this.btnName === 'Update') {
      this.assignTripService
        .createNewTrip(this.newTripForm.value, this.unassignedTripId)
        .subscribe(data => {
          if (data) {
            sweetAlert('Success', 'Trip Updated Succesfully', 'success', 'OK')
              .then(() => {
                this.router.navigateByUrl('assignstrips/unassigned');
              });
          }
        });
    } else {
      this.assignTripService
        .createNewTrip(this.newTripForm.value)
        .subscribe(data => {
          if (data) {
            this.router.navigateByUrl('assignstrips/unassigned');
          }
        });
    }
    return;
  }

  getAssignTripsOfNormalDispatch() {

    this.setNewTripFormValidators();
    let data;
    if (this.selectedFormName === 'Corporate') {
      data = {
        cooperate: this.newTripForm.value.cooperate,
        jobType: this.newTripForm.value.jobType,
        status: 'finished'
      };
    } else {
      data = {
        companyType: this.newTripForm.value.companyType,
        isMedical: this.newTripForm.value.isMedical,
        jobType: this.newTripForm.value.jobType,
        rawId: '',
        status: 'finished'
      };
    }
    this.assignTripService
      .getAssignTripsList(data)
      .subscribe(data => {
        if (data) {
          this.assignedTrips = data;
        }
      });
  }

  setNewTripFormValidators() {

    let clearKeys = [];
    let setKeys = [];
    if (this.selectedFormName === 'Dispatch') {
      clearKeys = ['memberId', 'companyType', 'tripRequirement', 'dob', 'jobCarType', 'cooperate'];
      setKeys = ['jobCarType'];
      this.setFormValidation(setKeys, clearKeys);
    } else if (this.selectedFormName === 'Medical') {

      setKeys = ['memberId', 'companyType', 'tripRequirement', 'dob'];
      clearKeys = ['jobCarType', 'cooperate'];
      this.setFormValidation(setKeys, clearKeys);

    } else if (this.selectedFormName === 'Corporate') {

      clearKeys = ['memberId', 'companyType', 'tripRequirement', 'dob'];
      setKeys = ['jobCarType', 'cooperate'];
      this.setFormValidation(setKeys, clearKeys);
    }

  }

  setFormValidation(setKeys?, clearKeys?) {
    if (clearKeys) {
      clearKeys.forEach(element => {
        this.newTripForm.get(element).clearValidators();
        this.newTripForm.get(element).updateValueAndValidity();
      });
    }

    if (setKeys) {
      setKeys.forEach(element => {
        if (element === 'dob') {
          if (this.newTripForm.value.companyType === 'lcp') {
            this.newTripForm.get('dob').setValidators([Validators.required]);
          }
        } else {
          this.newTripForm.get(element).setValidators([Validators.required]);
        }
      });
    }
  }

  checkEditTrip() {
    if (this.activatedRoute.snapshot.url.length === 3 && this.activatedRoute.snapshot.url[2].path === 'edit') {
      this.unassignedTripId = this.activatedRoute.snapshot.url[1].path;
      this.assignTripService
        .getUnassignedTripDetailById(this.unassignedTripId)
        .subscribe(data => {
          if (data) {
            this.unassignedTripDetailById = data;
            this.selectedVehicle(this.unassignedTripDetailById.jobCarType);
            this.setPrefilledTrip(this.unassignedTripDetailById);
            if (this.newTripForm.value.isMedical) {
              this.formSelected('Medical');
            } else if (this.newTripForm.value.cooperate.length > 0) {
              this.formSelected('Corporate');
            }
          this.btnName = 'Update';
          }
        }, err => {
          sweetAlert('Error', err, 'error', 'OK');
        });
    }
  }

  onScheduleTimeChange() {

    if (this.newTripForm.value.schedulePickupHour && this.newTripForm.value.schedulePickupMinutes) {
      if (this.newTripForm.value.schedulePickupHour === '23') {
        this.newTripForm.patchValue({
          appointmentPickupHour: this.newTripForm.value.schedulePickupHour,
          appointmentPickupMinutes: this.newTripForm.value.schedulePickupMinutes,
        });
      } else {
        // tslint:disable-next-line: radix
        let aptPickHour = (parseInt(this.newTripForm.value.schedulePickupHour) + 1).toString();
        if (aptPickHour.length === 1) {
          aptPickHour = '0' + aptPickHour;
        }
        this.newTripForm.patchValue({
          appointmentPickupHour: aptPickHour,
          appointmentPickupMinutes: this.newTripForm.value.schedulePickupMinutes
        });
      }
      this.setTripLegTime();
    }
  }

  onAppointmentTimeChange() {
    if (this.newTripForm.value.appointmentPickupHour && this.newTripForm.value.appointmentPickupMinutes) {
      this.setTripLegTime();
    }
  }

  onTripLegTimeChange(index) {
    for (let i = index; i < this.newTripForm.value.tripLegs.length; i++) {
      if (this.newTripForm.value.tripLegs[i - 1].schedulePickupHour === '23') {
        this.legsForm.at(i).patchValue({
          schedulePickupHour: this.newTripForm.value.tripLegs[i - 1].schedulePickupHour,
          schedulePickupMinutes: this.newTripForm.value.tripLegs[i - 1].schedulePickupMinutes,
        });
      } else {
        if (this.newTripForm.value.tripLegs[i - 1].schedulePickupHour) {

          let schedultPickTime = parseInt(this.newTripForm.value.tripLegs[i - 1].schedulePickupHour) + 1;
          let updatedScheduePickTime = schedultPickTime.toString();
          if (schedultPickTime.toString().length === 1) {
            updatedScheduePickTime = '0' + updatedScheduePickTime;
          }
          this.legsForm.at(i).patchValue({
            schedulePickupHour: updatedScheduePickTime,
            schedulePickupMinutes: this.newTripForm.value.tripLegs[index - 1].schedulePickupMinutes,
          });
        }
      }
    }
  }

  setTripLegTime(index?) {
    if (index) {
      if (index === 1) {
        if (this.newTripForm.value.appointmentPickupHour === '23') {
          this.legsForm.at(index).patchValue({
            schedulePickupHour: this.newTripForm.value.appointmentPickupHour,
            schedulePickupMinutes: this.newTripForm.value.appointmentPickupMinutes,
          });
        } else {
          if (this.newTripForm.value.appointmentPickupHour) {
            // tslint:disable-next-line: radix
            let schedulePickTime = parseInt(this.newTripForm.value.appointmentPickupHour) + 1;
            let updatedScheduePickTime = schedulePickTime.toString();
            if (schedulePickTime.toString().length === 1) {
              updatedScheduePickTime = '0' + updatedScheduePickTime;
            }
            this.legsForm.at(index).patchValue({
              schedulePickupHour: updatedScheduePickTime,
              schedulePickupMinutes: this.newTripForm.value.appointmentPickupMinutes,
            });
          }
        }
      } else {
        if (this.newTripForm.value.tripLegs[index - 1].schedulePickupHour === '23') {
          this.legsForm.at(index).patchValue({
            schedulePickupHour: this.newTripForm.value.tripLegs[index - 1].schedulePickupHour,
            schedulePickupMinutes: this.newTripForm.value.tripLegs[index - 1].schedulePickupMinutes,
          });
        } else {
          if (this.newTripForm.value.tripLegs[index - 1].schedulePickupHour) {

            // tslint:disable-next-line: radix
            let schedulePickTime = parseInt(this.newTripForm.value.tripLegs[index - 1].schedulePickupHour) + 1;
            let updatedScheduePickTime = schedulePickTime.toString();
            if (schedulePickTime.toString().length === 1) {
              updatedScheduePickTime = '0' + updatedScheduePickTime;
            }
            this.legsForm.at(index).patchValue({
              schedulePickupHour: updatedScheduePickTime,
              schedulePickupMinutes: this.newTripForm.value.tripLegs[index - 1].schedulePickupMinutes,
            });
          }
        }

      }
    } else {

      // if (this.newTripForm.value.schedulePickupHour === '23') {
      //   for (let j = 0; j < this.newTripForm.value.tripLegs.length; j++) {
      //     this.legsForm.at(j).patchValue({
      //       schedulePickupHour: this.newTripForm.value.schedulePickupHour,
      //       schedulePickupMinutes: this.newTripForm.value.schedulePickupMinutes,
      //     })
      //   }
      // } else {
      this.legsForm.at(0).patchValue({
        schedulePickupHour: this.newTripForm.value.appointmentPickupHour,
        schedulePickupMinutes: this.newTripForm.value.appointmentPickupMinutes,
      });

      for (let k = 1; k < this.newTripForm.value.tripLegs.length; k++) {

        // tslint:disable-next-line: radix
        const time = parseInt(this.newTripForm.value.appointmentPickupHour) + k;
        if (time >= 23) {
          this.legsForm.at(k).patchValue({
            schedulePickupHour: '23',
            schedulePickupMinutes: this.newTripForm.value.appointmentPickupMinutes,
          });
        } else {
          let updatedTime = time.toString();
          if (time.toString().length === 1) {
            updatedTime = '0' + updatedTime.toString();
          }
          this.legsForm.at(k).patchValue({
            schedulePickupHour: updatedTime,
            schedulePickupMinutes: this.newTripForm.value.appointmentPickupMinutes,
          });
        }
      }
      // }
    }
  }


  createLeg(index): FormGroup {
    return this.fb.group({
      scheduleTime: ['', index > 0 ? Validators.required : null],
      schedulePickupHour: ['', index > 0 ? Validators.required : null],
      schedulePickupMinutes: ['', index > 0 ? Validators.required : null],
      jobOriginAddress: ['', index > 0 ? Validators.required : null],
      jobOriginLatitude: ['', index > 0 ? Validators.required : null],
      jobOriginLongitude: ['', index > 0 ? Validators.required : null],
      jobDestinationAddress: ['', index > 0 ? Validators.required : null],
      jobDestinationLatitude: ['', index > 0 ? Validators.required : null],
      jobDestinationLongitude: ['', index > 0 ? Validators.required : null],
      tripId: ['']
    });
  }

  addLeg(noOfLegs) {
    noOfLegs++;
    this.legsForm.push(this.createLeg(noOfLegs));
    if (noOfLegs === 1) {
      this.legsForm.at(noOfLegs).patchValue({
        jobOriginAddress: this.newTripForm.value.jobDestinationAddress,
        jobOriginLatitude: this.newTripForm.value.jobDestinationLatitude,
        jobOriginLongitude: this.newTripForm.value.jobDestinationLongitude,
        jobDestinationAddress: this.newTripForm.value.jobOriginAddress,
        jobDestinationLatitude: this.newTripForm.value.jobOriginLatitude,
        jobDestinationLongitude: this.newTripForm.value.jobOriginLongitude,
      });
      this.legsForm.at(0).patchValue({
        jobOriginAddress: this.newTripForm.value.jobDestinationAddress,
        jobOriginLatitude: this.newTripForm.value.jobDestinationLatitude,
        jobOriginLongitude: this.newTripForm.value.jobDestinationLongitude,
        jobDestinationAddress: this.newTripForm.value.jobOriginAddress,
        jobDestinationLatitude: this.newTripForm.value.jobOriginLatitude,
        jobDestinationLongitude: this.newTripForm.value.jobOriginLongitude,
      });
    } else if (noOfLegs > 1) {
      this.legsForm.at(noOfLegs - 1).patchValue({
        jobDestinationAddress: '',
        jobDestinationLatitude: '',
        jobDestinationLongitude: ''
      });
      this.legsForm.at(noOfLegs).patchValue({
        jobDestinationAddress: this.newTripForm.value.jobOriginAddress,
        jobDestinationLatitude: this.newTripForm.value.jobOriginLatitude,
        jobDestinationLongitude: this.newTripForm.value.jobOriginLongitude,
      });
    }

    this.setTripLegTime(noOfLegs);
    if (this.newTripForm.value.tripId) {
      this.legsForm.at(noOfLegs).patchValue({
        tripId: this.newTripForm.value.tripId.split('-')[0] + '-Leg(' + this.legId[noOfLegs] + ')'
      });
    }
  }

  removeLeg(index) {
    // if (index === 0) {
    //   this.legsForm.at(index + 1).patchValue({
    //     jobOriginAddress: this.newTripForm.value.jobDestinationAddress,
    //     jobOriginLatitude: this.newTripForm.value.jobDestinationLatitude,
    //     jobOriginLongitude: this.newTripForm.value.jobDestinationLongitude
    //   })
    // } else {
    //   this.legsForm.at(index + 1).patchValue({
    //     jobOriginAddress: this.newTripForm.value.tripLegs[index].jobDestinationAddress,
    //     jobOriginLatitude: this.newTripForm.value.tripLegs[index].jobDestinationLatitude,
    //     jobOriginLongitude: this.newTripForm.value.tripLegs[index].jobDestinationLongitude
    //   })
    // }
    if (index === 0 && this.newTripForm.value.tripLegs.length > 2) {
      this.legsForm.removeAt(index + 1);
      this.legsForm.at(index + 1).patchValue({
        jobOriginAddress: this.newTripForm.value.jobDestinationAddress,
        jobOriginLatitude: this.newTripForm.value.jobDestinationLatitude,
        jobOriginLongitude: this.newTripForm.value.jobDestinationLongitude
      });
    } else if (this.newTripForm.value.tripLegs.length > index + 1) {
      this.legsForm.at(index).patchValue({
        jobDestinationAddress: this.newTripForm.value.tripLegs[index + 1].jobDestinationAddress,
        jobDestinationLatitude: this.newTripForm.value.tripLegs[index + 1].jobDestinationLatitude,
        jobDestinationLongitude: this.newTripForm.value.tripLegs[index + 1].jobDestinationLongitude
      });
      this.legsForm.removeAt(index + 1);

    } else {
      this.legsForm.removeAt(index + 1);
    }
    this.bindReturnTripId();
  }
  get legsElement() {
    this.legsForm = this.newTripForm.controls.tripLegs as FormArray;
    return this.legsForm;
  }

  clearTripLegs() {
    for (let i = this.newTripForm.value.tripLegs.length; i > 0; i--) {
      this.legsForm.removeAt(i);
    }
  }

  bindTripId() {
    if (!this.newTripForm.value.tripId.toUpperCase().includes('-LEG(A)')) {
      this.newTripForm.patchValue({
        tripId: this.newTripForm.value.tripId + '-Leg(A)'
      });
    }
    this.bindReturnTripId();
  }

  bindReturnTripId() {
    if (this.legsForm.length > 0) {
      for (let i = 1; i < this.legsForm.length; i++) {
        this.legsForm.at(i).patchValue({
          tripId: this.newTripForm.value.tripId.split('-')[0] + '-Leg(' + this.legId[i] + ')'
        });
      }
    }
  }

  setTimeZone() {
    if (this.legsForm.length > 0) {
      for (let i = 0; i < this.legsForm.length; i++) {
        this.legsForm.at(i).patchValue({
          scheduleTime: this.newTripForm.value.scheduleTime
        });
      }
      for (let i = 0; i < this.legsForm.length; i++) {
        let scheduleTime = this.newTripForm.value.tripLegs[i].scheduleTime.split('T')[0];
        scheduleTime = moment.tz(scheduleTime + ' ' + this.newTripForm.value.tripLegs[i].schedulePickupHour
          + ':' + this.newTripForm.value.tripLegs[i].schedulePickupMinutes + ':00', this.timeZone).toISOString();
        if (scheduleTime !== null) {
          this.legsForm.at(i).patchValue({
            scheduleTime
          });

        }
      }
    }

    let ScheduleTime = this.newTripForm.value.scheduleTime.split('T')[0];
    ScheduleTime = moment.tz(ScheduleTime + ' ' + this.newTripForm.value.schedulePickupHour
      + ':' + this.newTripForm.value.schedulePickupMinutes + ':00', this.timeZone).toISOString();
    let AppointmentTime = this.newTripForm.value.appointmentTime.split('T')[0];
    AppointmentTime = moment.tz(AppointmentTime + ' ' + this.newTripForm.value.appointmentPickupHour
      + ':' + this.newTripForm.value.appointmentPickupMinutes + ':00', this.timeZone).toISOString();
    if (ScheduleTime !== null && AppointmentTime !== null) {
      this.newTripForm.patchValue({
        scheduleTime: ScheduleTime,
        appointmentTime: AppointmentTime
      });
    }


  }

  checkTripIdUnique() {
    const tempTripIdArray = [];
    tempTripIdArray.push(this.newTripForm.value.tripId);

    for (let i = 1; i < this.legsForm.length; i++) {
      tempTripIdArray.push(this.newTripForm.value.tripLegs[i].tripId);
    }
    if ([...new Set(tempTripIdArray)].length === tempTripIdArray.length) {
      return true;
    }
    return false;
  }

  onScheduleDateChange(event) {
    console.log(event)
    const date = moment.utc(event).local().format();
    console.log(date)
    if (event) {
      this.newTripForm.patchValue({
        scheduleTime: date,
        appointmentTime: date
      });
    }
  }

  setPrefilledTrip(trip) {
    this.newTripForm.patchValue({
      ...trip,
      scheduledTime: trip.scheduleTime
    });
    console.log(trip.appointmentTime);
    console.log(moment.utc(trip.appointmentTime).tz(this.timeZone).format());
    if (trip.appointmentTime) {
      // getting utc time from backend, it will convert the utc time to company timezone
      trip.appointmentTime = moment.utc(trip.appointmentTime).tz(this.timeZone).format();
      // tslint:disable-next-line: radix
      if (parseInt(trip.appointmentTime.split('T')[1].substr(3, 2)) % 10 !== 0) {
        // tslint:disable-next-line: radix
        const min = parseInt(trip.appointmentTime.split('T')[1].substr(3, 2))
          // tslint:disable-next-line: radix
          + (10 - parseInt(trip.appointmentTime.split('T')[1].substr(3, 2)) % 10);
        this.newTripForm.patchValue({
          appointmentPickupHour: trip.appointmentTime.split('T')[1].substr(0, 2),
          appointmentPickupMinutes: min.toString(),
        });
      } else {
        this.newTripForm.patchValue({
          appointmentPickupHour: trip.appointmentTime.split('T')[1].substr(0, 2),
          appointmentPickupMinutes: trip.appointmentTime.split('T')[1].substr(3, 2),
        });
      }
    }
    if (trip.scheduleTime) {
      // getting utc time from backend, it will convert the utc time to company timezone
      trip.scheduleTime = moment.utc(trip.scheduleTime).tz(this.timeZone).format();
      if (parseInt(trip.scheduleTime.split('T')[1].substr(3, 2)) % 10 !== 0) {
        let min = parseInt(trip.scheduleTime.split('T')[1].substr(3, 2)) + (10 - parseInt(trip.scheduleTime.split('T')[1].substr(3, 2)) % 10);
        this.newTripForm.patchValue({
          schedulePickupHour: trip.scheduleTime.split('T')[1].substr(0, 2),
          schedulePickupMinutes: min.toString(),
        });
      } else {
        this.newTripForm.patchValue({
          schedulePickupHour: trip.scheduleTime.split('T')[1].substr(0, 2),
          schedulePickupMinutes: trip.scheduleTime.split('T')[1].substr(3, 2),
        });
      }
    }

  }

  getMiles() {
    if (!this.newTripForm.value.jobOriginAddress || !this.newTripForm.value.jobDestinationAddress) {
      window.alert('Please select Pickup & Drop Off address');
      return;
    }
    this.setUpSocketListener();
    let data = {
      origin: this.newTripForm.value.jobOriginLatitude + ',' + this.newTripForm.value.jobOriginLongitude,
      destination: this.newTripForm.value.jobDestinationLatitude + ',' + this.newTripForm.value.jobDestinationLongitude
    };
    this.socketService.emit(Events.GET_MILES_CALL, data);
  }

  setUpSocketListener() {
    this.socketService.addListener(Events.GET_MILES_CALL)
      .subscribe(data => {
        this.googleMilage = data.message;
        this.socketService.removeListener(Events.GET_MILES_CALL);
      });
  }

  calculateEstimatedFare() {
    if (!this.newTripForm.value.jobOriginAddress || !this.newTripForm.value.jobDestinationAddress) {
      window.alert('Please select Pickup & Drop Off address');
      return;
    }
    this.estimatedFareListener();
    const payload = 
      {
        company: this.user._id,
        jobCarType: this.newTripForm.value.jobCarType,
        jobDestinationLatitude: this.newTripForm.value.jobDestinationLatitude,
        jobDestinationLongitude: this.newTripForm.value.jobDestinationLongitude,
        jobOriginLatitude: this.newTripForm.value.jobOriginLatitude,
        jobOriginLongitude: this.newTripForm.value.jobOriginLongitude,
        passengerNumber: this.newTripForm.value.jobPassengerNumber
      };

    this.socketService.emit(Events.GET_ESTIMATED_FARE, payload); 
  }

  estimatedFareListener() {
    this.socketService.addListener(Events.GET_ESTIMATED_FARE)
      .subscribe(data => {
        if (data) {
          this.estimatedFare = data.totalFare;
        }        
        this.socketService.removeListener(Events.GET_ESTIMATED_FARE);
      });
  }

  toggleSearchRadius(evt) {
    this.newTripForm.patchValue({
      jobOriginAddress: '',
      jobOriginLatitude: 0,
      jobOriginLongitude: 0,

      jobDestinationAddress: '',
      jobDestinationLatitude: 0,
      jobDestinationLongitude: 0,
    });

    console.log(evt.target.checked);
    const isRestrictRadius = evt.target.checked;
    if (isRestrictRadius) {
      this.radius = {
        miles: 200,
        center: { lat: this.user.latitude, lng: this.user.longitude }
      }
      console.log('Google Place: i', this.radius)
      const mybounds = { center: { lat: this.user.latitude, lng: this.user.longitude }, radius: 321869 };
    } else {
      this.radius = null;
    }
  }

  toggleStandingOrders(evt) {
    console.log(evt.target.checked);
    this.isStandingOrdersEnabled = evt.target.checked;
  }

  toggleDay(day, evt) {
    this.days[day].checked = evt.target.checked;
    console.log(this.days);
  }

  onChangeDriver(day, evt) {
    this.days[day].driver = evt.target.value;
    console.log(this.days);
  }

  removeDriver(day) {
    this.days[day].driver = "";
  }

  originalOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
    return 0;
  }

  getAllDays() {
    const weekdays: [] = moment.weekdaysShort();
    console.log('TEST100: weekdays', weekdays);
    let object = [];
    for (let i = 0; i < weekdays.length; i++) {
      console.log('TEST100: M', moment().isoWeeks());
      const innerObject = {
        day: weekdays[i],
        driver: "",
        isSelect: false,
      };
      object.push(innerObject);
    }
    console.log('TEST100: Obj', object);
    return object;
  }


  enumerateDaysBetweenDates(startDate, endDate) {
    let dates = [];
    let weekdays = this.filterWeekdays();
    let isEmpty = Object.keys(weekdays).length === 0;
    console.log('Week days :: ', weekdays, ' is Empty :: ', isEmpty);
    while (moment(startDate) <= moment(endDate)) {
      let day = startDate.format('ddd');
      let date = startDate.format('YYYY-MM-DD');
      if (isEmpty) {
        // dates.push({
        //   driver: '',
        //   date: date
        // });
      } else if (weekdays && weekdays.hasOwnProperty(day)) {
        let weekday = weekdays[day];
        dates.push({
          driver: weekday.driver,
          date: date
        });
      }
      startDate = moment(startDate).add(1, 'days');
    }
    return dates;
  }

  filterWeekdays() {
    return this.scheduler.reduce((obj, item) => {
      if (item && item.isSelect) {
        obj[item.day] = item;
      }
      return obj;
    }, {});
  }

  getWeekDayList() {
    return Array.apply(null, Array(7)).map(function (_, i) {
      return { isSelect: false, driver: '', day: moment(i, 'e').startOf('week').isoWeekday(i + 1).format('ddd') };
    });
  };

  clearDriver(r) {
    if (window.confirm('Are you sure you want to clear?')) {
      r.driver = '';
    }
  }

  onChangeScheduler(evt) {
    console.log(evt, this.newTripForm);
  }

}
