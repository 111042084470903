import { Component, OnInit, OnDestroy } from '@angular/core';
import { sweetAlert } from '../../../utils/swal';
import { addDataTableScript} from '../../../utils/utils.common';
import { StaffService } from '../staff-service/staff.service';
@Component({
  selector: 'app-staff-main',
  templateUrl: './staff-main.component.html',
  styleUrls: ['./staff-main.component.css']
})
export class StaffMainComponent implements OnInit {
  staffList = [];
  search;
  imageModalUrl;

  constructor(
    private staffService: StaffService) {
  }

  ngOnInit() {
    this.getStaffList();
  }

  getStaffList() {
    this.staffService
      .getStaffList()
      .subscribe(data => {
        if(data) {
          this.staffList = data;
          addDataTableScript();
        }
    }, err => {
      sweetAlert('Error', err, 'warning', 'OK');
    });
  }

  openImageModal(url) {
    this.imageModalUrl = url;
  }
}
