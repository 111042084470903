import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-company-note-modal',
  templateUrl: './company-note-modal.component.html',
  styleUrls: ['./company-note-modal.component.css']
})
export class CompanyNoteModalComponent implements OnInit {

  @Input() trip: any;

  constructor() { }

  ngOnInit() {
    console.log('Trip: ', this.trip);
  }

}
