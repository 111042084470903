import * as moment from 'moment-timezone';
import * as _ from 'underscore';
export function getCompanyLogo(driverCar): string {

  switch (driverCar) {
    case 'Standard':
      return '../assets/images/vehicles/standardcab.png';

    case 'Premium':
      return '../assets/images/vehicles/premiumcab.png';

    case 'SUV':
      return '../assets/images/vehicles/suv.png';
    case 'WAV':
      return '../assets/images/vehicles/wav.png';
    case 'Stretcher':
      return '../assets/images/vehicles/stretcher.png';
    default:
      return '../assets/images/vehicles/fleet-img.png';
  }
}

export function getBrokerImage(brokerName): string {
  switch (brokerName) {
    case 'united':
      return '../assets/images/brokers/SouthETrans.png';

    case 'lcp':
      return '../assets/images/brokers/lcp-logo.png';

    case 'first':
      return '../assets/images/brokers/mtm-logo.jpg';
    case 'logisticscare':
      return '../assets/images/brokers/logisticare.jpg';
    case 'medtrans':
      return '../assets/images/brokers/medtrans-logo.png';
    case 'medicaid':
      return '../assets/images/brokers/medicaid.png';
    case 'amera':
      return '../assets/images/brokers/amera.png';
    case 'veyo':
      return '../assets/images/brokers/veyo-logo.png';
    case 'vapremier':
      return '../assets/images/brokers/vapremier.png';
    case 'mas':
      return '../assets/images/brokers/mas.png';
    case 'epic':
      return '../assets/images/brokers/epic.png';
    case 'onecall':
      return '../assets/images/brokers/onecall.png';
    default:
      return '../assets/images/brokers/MixandMatch.png';
  }
}

export const getBrokersList = {
  'default': 'Default',
  'lcp': 'LCP',
  'first': 'MTM',
  'welltrans': 'Welltrans',
  'logisticscare': 'Logisticare',
  'modivcare': 'Modivcare',
  'medtrans': 'NMN',
  'united': 'SouthEastrans',
  'medicaid': 'Medicaid',
  'amera': 'Amera',
  'veyo': 'Veyo',
  'vapremier': 'Va Premier',
  'mas': 'MAS',
  'epic': 'EPIC',
  'onecall': 'OneCall',
  'americanLogistics': 'American Logistics',
  'access2Ride': 'Access2Care',
  'arn': 'Angel Ride Network',
  'medex': 'Medex',
  'callthecar': 'CallTheCar',
  'abcinsurance': 'Abc Insurance',
  'ride2md': 'Ride2MD'
};

export function loadStaticMap(lat, lng) {
  const mapKey = 'AIzaSyC60nn_VZXJTxtmWuIB9SnHGU6USDb1LGo';
  // let url = 'https://maps.googleapis.com/maps/api/staticmap?center='
  //   + lat + ',' + lng + '&zoom=14&size=130x150&markers=color:red|' + lat
  //   + ',' + lng + '&key=' + mapKey;

  let url = 'https://maps.googleapis.com/maps/api/staticmap?center='
    + lat + ',' + lng + '&zoom=14&size=150x150&markers=color:red|' + lat
    + ',' + lng + '&key=' + mapKey;

  return url;
}

export function loadMyScript(url: string) {
  const body = <HTMLDivElement>document.body;
  const script = document.createElement('script');
  script.innerHTML = '';
  script.src = url;
  script.async = false;
  script.defer = true;
  body.appendChild(script);
}

export function addDataTableScript() {
  loadMyScript("assets/vendors/datatables.net/jquery.dataTables.js");
  loadMyScript("assets/vendors/datatables.net-bs4/dataTables.bootstrap4.js");
  loadMyScript("assets/js/data-table.js");
}

export function removeDataTableScript() {
  $("script[src='assets/vendors/datatables.net/jquery.dataTables.js']").remove()
  $("script[src='assets/vendors/datatables.net-bs4/dataTables.bootstrap4.js']").remove()
  $("script[src='assets/js/data-table.js']").remove()
}

export function filterJobs(list, currentTime) {
  list.forEach((trip) => {
    var time = timeCalculation(trip, currentTime);
    if (trip.status === 'inprogress') {
      trip.filter = 'active';
    } else if ((time < 30) || trip.status === 'declined' || trip.status === 'bumped' || trip.status === 'cancelled') {
      trip.filter = 'alarming';
    } else if (time <= 120 && time >= 30) {
      trip.filter = 'upcoming';
    } else if (time > 120) {
      trip.filter = 'schedule';
    }
    trip.scheduleDate = moment(trip.scheduleTime).format('MM/DD/YYYY');
  });
  // return groupListByDate(list);
  return list;
}

export function timeCalculation(trip, currentTime) {

  const end = moment(trip.scheduleTime);
  const startTime = moment(currentTime);
  const duration = moment.duration(end.diff(startTime));
  return duration.asMinutes();
}

export function groupListByDate(list) {
  return _.groupBy(list, (list) => {
    return list.scheduleDate
  });
}

// export  function emailValidator(control: AbstractControl): { [key: string]: boolean } | null {
//     var emailRegex = /^[a-zA-Z0-9]+.{0,1}[a-zA-Z0-9]+@[a-z]{3,}\.[a-z]{2,}$/;
//     const email: string = control.value;
//     if (email === null || email === "" || emailRegex.test(email) === true) {
//       return null;
//     }
//     else if (emailRegex.test(email) === false) {
//       return { "domain": true };
//     }
//   }

export const states = [
  { value: "AK", name: "Alaska" },
  { value: "AL", name: "Alabama" },
  { value: "AR", name: "Arkansas" },
  { value: "AZ", name: "Arizona" },
  { value: "CA", name: "California" },
  { value: "CO", name: "Colorado" },
  { value: "CT", name: "Connecticut" },
  { value: "DC", name: "DistrictofColumbia" },
  { value: "DE", name: "Delaware" },
  { value: "FL", name: "Florida" },
  { value: "GA", name: "Georgia" },
  { value: "HI", name: "Hawaii" },
  { value: "IA", name: "Iowa" },
  { value: "ID", name: "Idaho" },
  { value: "IL", name: "Illinois" },
  { value: "IN", name: "Indiana" },
  { value: "KS", name: "Kansas" },
  { value: "KY", name: "Kentucky" },
  { value: "LA", name: "Louisiana" },
  { value: "MA", name: "Massachusetts" },
  { value: "MD", name: "Maryland" },
  { value: "ME", name: "Maine" },
  { value: "MI", name: "Michigan" },
  { value: "MN", name: "Minnesota" },
  { value: "MO", name: "Missouri" },
  { value: "MS", name: "Mississippi" },
  { value: "MT", name: "Montana" },
  { value: "NC", name: "NorthCarolina" },
  { value: "ND", name: "NorthDakota" },
  { value: "NE", name: "Nebraska" },
  { value: "NH", name: "NewHampshire" },
  { value: "NJ", name: "NewJersey" },
  { value: "NM", name: "NewMexico" },
  { value: "NV", name: "Nevada" },
  { value: "NY", name: "NewYork" },
  { value: "OH", name: "Ohio" },
  { value: "OK", name: "Oklahoma" },
  { value: "OR", name: "Oregon" },
  { value: "PA", name: "Pennsylvania" },
  { value: "RI", name: "RhodeIsland" },
  { value: "SC", name: "SouthCarolina" },
  { value: "SD", name: "SouthDakota" },
  { value: "TN", name: "Tennessee" },
  { value: "TX", name: "Texas" },
  { value: "UT", name: "Utah" },
  { value: "VA", name: "Virginia" },
  { value: "VT", name: "Vermont" },
  { value: "WA", name: "Washington" },
  { value: "WI", name: "Wisconsin" },
  { value: "WV", name: "WestVirginia" },
  { value: "WY", name: "Wyoming" },
];

export function getUserRole(role) {
  let userRole;
  if (Array.isArray(role)) {
    userRole = role && role.length ? role[0] : '';
  } else {
    userRole = role;
  }
  
  return userRole;
}

export function mapStyles() {
  return [
    {
      featureType: "administrative",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "poi",
      stylers: [
        {
          visibility: "simplified",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "labels",
      stylers: [
        {
          visibility: "simplified",
        },
      ],
    },
    {
      featureType: "water",
      stylers: [
        {
          visibility: "simplified",
        },
      ],
    },
    {
      featureType: "transit",
      stylers: [
        {
          visibility: "simplified",
        },
      ],
    },
    {
      featureType: "landscape",
      stylers: [
        {
          visibility: "simplified",
        },
      ],
    },
    {
      featureType: "road.highway",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "road.local",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "water",
      stylers: [
        {
          color: "#84afa3",
        },
        {
          lightness: 52,
        },
      ],
    },
    {
      stylers: [
        {
          saturation: -17,
        },
        {
          gamma: 0.36,
        },
      ],
    },
    {
      featureType: "transit.line",
      elementType: "geometry",
      stylers: [
        {
          color: "#3f518c",
        },
      ],
    },
  ];
}
