import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectAccidentReportState, AccidentReportState } from '../../../ngrx/app.states';
import { AddAccidentReport, AccidentReportActionTypes, GetAccidentReportById } from '../../../ngrx/store/actions/accident-report.actions';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { ActivatedRoute } from '@angular/router';
import { sweetAlert } from 'src/app/utils/swal';
@Component({
  selector: 'app-accident-detail-in-tailwind-form',
  templateUrl: './accident-detail-in-tailwind-form.component.html',
  styleUrls: ['./accident-detail-in-tailwind-form.component.css']
})
export class AccidentDetailInTailwindFormComponent implements OnInit , OnDestroy{

  accidentReportState: Observable<any>;
  accidentReportDetail = null;
  accidentScenes = [];
  otherScenes = [];
  ownVehicle = [];
  constructor(private store: Store<AccidentReportState>, private activatedRoute: ActivatedRoute) {
    this.accidentReportState = this.store.select(selectAccidentReportState);
   }
  

  ngOnInit() {
    document.body.style.zoom = "90%";
    const id = this.activatedRoute.snapshot.url[2].path;
      this.getAccidentDetail(id);

      this.accidentReportState
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((state) => {
        if (state.success) {
          if (state.type === AccidentReportActionTypes.GET_ACCIDNET_REPROT_BY_ID) {
            console.log(state)
            this.accidentReportDetail = state.response;
            console.log(this.accidentReportDetail)
            state.response.accidentScenes.forEach((img) => {
              this.accidentScenes.push('data:image/png;base64,'+img)
            });
            //5ea69996686bb51caa36bc20
            state.response.otherScenes.forEach((img) => {
              this.otherScenes.push('data:image/png;base64,'+img)
            });
            state.response.ownVehicle.forEach((img) => {
              this.ownVehicle.push('data:image/png;base64,'+img)
            });
            // sweetAlert('Success', "Accident Report Added Successfully", 'success', 'OK');
          }
        } else if (state.errorMessage) {
          sweetAlert('Error', state.errorMessage, 'error', 'OK');
        }
      })
  }

  getAccidentDetail(id) {
    this.store.dispatch(new GetAccidentReportById(id));
  }

  ngOnDestroy(): void {
    document.body.style.zoom = "100%";
  }
}
