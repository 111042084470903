import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import {
  imageProcessing,
  ImageSnippet,
} from "src/app/utils/image-processing-utils";
import { getBrokersList } from "src/app/utils/utils.common";
import { sweetAlert } from "src/app/utils/swal";
import { MemberService } from "../member-service/member.service";

@Component({
  selector: "app-create-member",
  templateUrl: "./create-member.component.html",
  styleUrls: ["./create-member.component.css"],
})
export class CreateMemberComponent implements OnInit {
  memberForm: FormGroup;
  parseFileForm: FormGroup;
  submitted = false;
  submittedFile = false;
  selectedFile: ImageSnippet = null;
  memberId;

  constructor(
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private memberService: MemberService
  ) {
  }

  ngOnInit() {
    this.memberId = this.activatedRoute.snapshot.paramMap.get('id');

    this.memberForm = this.fb.group({
      companyType: ["", Validators.required],
      displayName: ["", Validators.required],
      contactNumber: ["", Validators.required],
      jobOriginAddress: ["", Validators.required],
      latitude: [{ value: "", disabled: false }],
      longitude: [{ value: "", disabled: false }],
    });

    this.parseFileForm = this.fb.group({
      companyType: ["", Validators.required],
      uploadExcel: ["", Validators.required],
    });

    if (this.memberId) {
      this.getMember(this.memberId);
    }
  }

  getMember(memberId) {
    this.memberService
      .getMemberById(memberId)
      .subscribe(data => {
        if (data) {
          this.memberForm.patchValue(data);
        }
      });
  }

  saveMember() {
    console.log(this.memberForm.value);

    this.submitted = true;

    if (this.memberForm.invalid) {
      console.log(this.memberForm.value);
      console.log(this.memberForm);
      return;
    }

    this.memberService
      .saveMember(this.memberId, this.memberForm.value)
      .subscribe(data => {
        if (data) {
          sweetAlert(
            "Success",
            "Member Added Succesfully",
            "success",
            "Ok"
          ).then(() => {
            this.submitted = false;
            this.router.navigateByUrl("/members");
          });
        }
      });
  }

  processFile(imageInput: any) {
    imageProcessing(imageInput).subscribe((result) => {
      this.selectedFile = result.file;
      this.parseFileForm.patchValue({
        uploadExcel: this.selectedFile,
      });
    });
  }

  parseFile() {
    console.log(this.parseFileForm);
    this.submittedFile = true;
    if (this.parseFileForm.invalid) {
      return;
    } else if (this.selectedFile == null) {
      return;
    }

    this.memberService
      .parseMemberFile(this.parseFileForm.value)
      .subscribe(data => {
        if (data) {
          sweetAlert(
            "Success",
            "File Parsed Succesfully",
            "success",
            "Ok"
          ).then(() => {
            this.submitted = false;
            this.router.navigateByUrl("/members");
          }); 
        }
      });
  }

  getAddress(place) {
    this.memberForm.patchValue({
      jobOriginAddress: place.formatted_address,
      latitude: place.geometry.location.lat(),
      longitude: place.geometry.location.lng(),
    });
  }

  brokerList() {
    return getBrokersList;
  }

  get form() {
    return this.memberForm.controls;
  }

  get parseForm() {
    return this.parseFileForm.controls;
  }
}
