import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { urls } from '../../../utils/url-utils';
import { errorHandl } from '../../../utils/network-utils';


@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(private http: HttpClient) { }

  getReportsWithNgrx(data): Observable<any> {
    const userId = data._id;
    delete data._id;
    return this.http.put<any>(urls.BASE_URL + urls.GET_REPORTS_URL + `${userId}`, data)
      .pipe(
        catchError(errorHandl)
      );
  }

  getDriverPdfReport(data): Observable<any> {
    let params = new HttpParams();
    params = params.append('check', data.mailCheck);
    params = params.append('endDate', data.endDate);
    params = params.append('startDate', data.startDate);

    return this.http.get<any>(urls.BASE_URL + urls.GET_DRIVER_PDF_REPORT_URL + `${data.driverId}?`, { params })
      .pipe(
        catchError(errorHandl)
      );
  }

  getPaymentPdfReport(data): Observable<any> {
    let params = new HttpParams();
    params = params.append('endDate', data.endDate);
    params = params.append('startDate', data.startDate);

    return this.http.get<any>(urls.BASE_URL + urls.GET_PAYMENT_PDF_REPORT_URL + `${data.id}?`, { params })
      .pipe(
        catchError(errorHandl)
      );
  }
}
