export enum urls {
    // SERVER_URL = 'https://nemtpanel.com/',
    // BASE_URL = 'https://nemtpanel.com/api/',
    SERVER_URL = 'http://provider.qalbs.com/',
    BASE_URL = 'http://provider.qalbs.com/api/',
    SIGNIN_URL = 'auth/signin',
    FORGOT_URL = 'auth/forgot',
    PROFILE_URL = 'users/me',
    STAFF_URL = 'staffs',
    STAFF_CREATE_URL = 'staffs/create',
    CHANGE_PASSWORD_URL = 'users/password',
    CHANGE_PROFILE_IMAGE = 'users/picture',
    DISPATCH_CENTER_HISTOR_URL = 'dispatches/company',
    NOTIFICATIONS_URL = 'notifications',
    DRIVERS_URL = 'drivers/companies',
    ADD_DRIVER_URL = 'drivers',
    DRIVER_RESET_PASSWORD_URL = 'drivers/resetpassword',
    COPORATE_USER_LIST_URL = 'cooperates',
    COPORATE_ADD_USER_URL = '',
    FLEET_LIST_URL = 'fleets?',
    ADD_FLEET_URL = 'fleets',
    DRIVER_DISTRIBUTION_URL = 'distributions',
    INVITES_LIST_URL = 'sms',
    DRIVERS_SMS_URL = 'driveralerts?receiver=',
    QUERIES_URL = 'queries',
    ADD_VEHICLE_EXPENSE_SERVICE_URL = 'maintenances/services',
    DRIVER_EXPENSE_URL = 'maintenances/drivers?driver=',
    MAINTENANCE_STATS_URL = 'maintenances/stats',
    GET_PLANNER_URL = 'planner/range?startDate=',


    GET_UNASSIGN_TRIPS_URL = 'assigns',
    GET_OUTSTANDING_TRIPS_URL = 'assigns/outstanding',
    ASSIGN_MULTIPLE_TRIPS_URL = 'assigns/selectedtrips',
    GET_ASSIGNED_TRIPS_LIST = 'assigns/corporate',
    VERIFIED_CANCEL_TRIP_URL = 'assigns/verifiedcancelled',
    GENRATE_TRIP_SIGNATURE_URL = 'assigns/canvasSignature',
    UPDATE_ALL_TRIPS_SIGNATURE_URL = 'assigns/autosignature',
    BANKED_TRIP_URL = 'assigns/banked',
    SINGLE_TRIP = 'assigns/singletrip',
    MARK_COMPLETED_TRIP = "assigns/markcompleted",
    GENERATE_TRIPS_REPORT = "assigns/assignedtripsexcel",
    SEND_CONFIRMATION_TEXT_URL = "assigns/tripconfirmation",
    AUTO_TRIP_ADJUSTMENT_URL = "assigns/autotripadjustment",
    TRIP_ODOMETER_ADJUST_URL = "assigns/adjustodometer",
    CORPORATE_USER_REPORT_URL = "assigns/corporate/report",

    GET_CORPORATE_USER_LIST = 'cooperates',
    CREATE_NEW_TRIP_URL = 'assigns',
    NEMT_STATISTICS_URL = 'kpis/jobs/stats',
    NEMT_REPORTS_URL = 'kpis',
    NEMT_STATISTICS_DETAIL_URL = 'kpis/jobs/statsByRange',
    NEMT_STATISTICS_DETAIL_BY_MEMBER_ID_URL = 'kpis/jobs/statsdetails',
    GET_DYNAMIC_FARE_LIST = 'dynamicfares',

    GET_REPORTS_URL = 'reports/company/',

    GET_TRIP_DETAILS_URL = 'dispatches',
    DRIVER_TRIP_FARE_URL = 'dispatches/driverfare',

    GET_ADJUSTMENT_DETAIL_URL = 'jobs/adjustment/',
    GET_ADJUSTMENT_DETAIL_TRIPSLOGS_URL = 'adjusttrips/',

    GET_NEARBY_DRIVER_URL = 'drivers/nearby',

    TRIPS_FOR_ASSIGN_DASHBOARD_URL = 'assigns/dashboard',

    DRIVER_GENERATE_QR_URL = 'drivers/generateqr',

    DRIVER_CALCULATE_INVOICE_URL = 'driverinvoices',
    DRIVER_TIMELINES_URL = 'timelines',

    NEMT_STAT_REPORT_PDF_URL = 'kpis/kpistatsreport',
    NEMT_BUSSINESS_REPORT_PDF_URL = 'kpis/businesskpi',
    NEMT_BROKER_REPORT_PDF_URL = 'kpis/brokerkpi',
    NEMT_CORPORATE_REPORT_PDF_URL = 'kpis/corporatekpi',
    NEMT_CORPORATE_TRIP_REPORT_PDF_URL = 'kpis/corporateTrips',
    NEMT_DRIVER_REPORT_PDF_URL = 'kpis/driverkpi',
    SINGLE_FLEET_QR_URL = 'fleets/generateqr/',
    ALL_FLEET_QR_URL = 'fleets/fleetqr',

    GET_DRIVER_PDF_REPORT_URL = 'reports/driver/',
    GET_PAYMENT_PDF_REPORT_URL = 'reports/admin/',
    GET_BROKER_RATE_URL = 'assigns/jobbyid?jobId=',
    SCRAP_TRIPS_URL = 'assigns/scrap/',
    DRIVER_BREAKDOWN_URL = 'kpis/bussiness',
    GET_SWEEP_TRIPS_LIST_URL = 'assigns/parselist',
    GET_UNPARSED_TRIPS_URL = 'assigns/unparsedtrips',
    SEND_TRIP_LIST_EMAIL_URL = 'assigns/files/',

    ACCIDENT_REPORT_URL = 'accidentalreports',
    FACILITY_TRIPS_URL = 'facilitytrips',
    FACILITY_TRIPS_COUNTER_URL = 'facilitytrips/dashboard',

    MEMBER_URL = 'members',

    TRACKING_SMS_URL = 'trackingsms'
}
