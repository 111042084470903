
import { DistributionsActionTypes, All } from '../actions/distributions-actions';

export interface State {
    response: null;
    // error message
    errorMessage: string | null;
    success: boolean;
    type: string | null;
}

export const initialState: State = {
    response: null,
    errorMessage: null,
    success: false,
    type: null
};
export function reducer(state = initialState, action: All): State {
    switch (action.type) {
        case DistributionsActionTypes.COMMON_SUCCESS: {
            return {
                ...state,
                response: action.payload.response,
                errorMessage: null,
                success: true,
                type: action.payload.type
            };
        }
        case DistributionsActionTypes.COMMON_FAILURE: {
            return {
                ...state,
                response: null,
                errorMessage: action.payload.error ? action.payload.error : action.payload.response.message ,
                success: false,
                type: action.payload.type

            };
        }
        default: {
            return {
                ...state,
                errorMessage: null,
                success: false,
                type: 'default'
            };
        }
    }
}

