import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { AssignTripsActionTypes, UpdateTripStatus, AssignTrip, AssignMultipleTrip } from '../../../ngrx/store/actions/assigntrips-actions';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AssignTripsState, selectAssignTripsState } from '../../../ngrx/app.states';
import { sweetAlert } from 'src/app/utils/swal';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { AssigTripsService } from '../assigntrips-service/assigntrips-service';
@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.css']
})
export class ConfirmModalComponent implements OnInit, OnDestroy {


  @Input() tripData;
  @Input() requestType;
  @Input() componentType;
  @Output() tripResponse = new EventEmitter<any>();
  getListObserver: Observable<any>;
  constructor(
    private assignTripService: AssigTripsService,
    private assignStore: Store<AssignTripsState>
  ) {
    this.getListObserver = this.assignStore.select(selectAssignTripsState);
   }

  ngOnInit() {
    this.getListObserver
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((state) => {
        if (state.success) {
          if (state.type === AssignTripsActionTypes.UPDATE_TRIP_STATUS) {
            this.tripResponse.emit(this.tripData);
          } else if (state.type === AssignTripsActionTypes.ASSIGN_TRIP) {
            this.tripResponse.emit(this.tripData);
          }  else if (state.type === AssignTripsActionTypes.ASSIGN_MULTIPLE_TRIPS) {
            this.tripResponse.emit(this.tripData);
          }
        } else if (state.errorMessage) {
          sweetAlert('Error', state.errorMessage, 'error', 'OK');
        }
      });
  }

  updateJob() {
    if (this.componentType === 'Multiple Assign') {
      console.log(this.tripData)
      console.log(this.requestType)
      if (this.requestType === 'archive') {
        const data = {
          selectedTrips: this.tripData,
          status: 'archived'
        }
        this.assignStore.dispatch(new AssignMultipleTrip(data));
      } else if (this.requestType === 'market place') {
        const data = {
          selectedTrips: this.tripData,
          status: 'marketplaced'
        }
        this.assignStore.dispatch(new AssignMultipleTrip(data));
      } else if (this.requestType === 'Un Assign') {
        const data = {
          selectedTrips: this.tripData,
          status: 'unassigned'
        }
        this.assignStore.dispatch(new AssignTrip(data));
      }
    } else {
      if (this.requestType === 'archive') {
        this.tripData.status = 'archived';
        this.assignStore.dispatch(new UpdateTripStatus(this.tripData));
      } else if (this.requestType === 'market place') {
        this.tripData.jobStatus = 'marketplaced';
        this.assignStore.dispatch(new UpdateTripStatus(this.tripData));
      } else if (this.requestType === 'Un Assign') {
        this.tripData.status = 'unassigned';
        this.tripData.jobStatus = 'pending';
  
        this.assignStore.dispatch(new AssignTrip(this.tripData));
      }
    }
  }

  updateTrip(payload) {
    this.assignTripService
      .updateTrip(payload)
      .subscribe(data => {
        if (data) {
          this.tripResponse.emit(this.tripData);
        }
      });
  }


  ngOnDestroy(): void {
  }

}
