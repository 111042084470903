import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { DispatchCenterState, selectDispatchCenterState } from '../../../ngrx/app.states';
import { DispatchCenterActionTypes, GetUnassignedTripDetail } from '../../../ngrx/store/actions/dispatch-center-actions';
import { Observable } from 'rxjs';
import { sweetAlert } from '../../../utils/swal';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth-service/auth.service';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { addDataTableScript, removeDataTableScript } from 'src/app/utils/utils.common';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
import { DispatchCenterService } from '../dispatch-center-service/dispatch-center-service';

declare var $;
@Component({
  selector: 'app-trips-history',
  templateUrl: './trips-history.component.html',
  styleUrls: ['./trips-history.component.css']
})
export class TripsHistoryComponent implements OnInit, OnDestroy {
  user;
  dataList = [];
  search: any;
  scriptLoadCount = 0;

  constructor(
    private dispatchCenterService: DispatchCenterService,
    private sharedDataService: SharedDataService,
    private route: ActivatedRoute,
    private router: Router
  ) {
  }

  ngOnInit() {
    let path = this.route.snapshot.url[1].path;
    this.getUser();
    this.getHistoryList(path === 'history' ? 'list' : path);
  }

  getUser() {
    this.sharedDataService
        .getUser()
        .subscribe(data => {
          console.log('user', this.user);
          if (data) {
            this.user = data;
          }
        });
  }

  refreshPage() {
    let path = this.route.snapshot.url[1].path;
    this.getHistoryList(path === 'history' ? 'list' : path);
  }

  getHistoryList(endPoint) {
    this.dispatchCenterService
      .getDispatchHistoryList(this.user._id, endPoint)
      .subscribe(data => {
        if (data) {
          this.dataList = data;
            if (this.scriptLoadCount > 0) {
              var table = $('#dataTableExample').DataTable();
              table.destroy();
              removeDataTableScript();
            }
            addDataTableScript();
            this.scriptLoadCount++;
        }
      });
      // sweetAlert('Error', state.errorMessage, 'warning', 'OK');
  }

  openPopup() {
    this.router.navigate(['/dispatch/create'], { state: { popup: true,} })
  }

  ngOnDestroy(): void {
  }

}
