import { Action } from '@ngrx/store';


export enum OfferedTripsActionTypes {
    GET_DASHBOARD_COUNT = '[Offered] Get dashboard count ',
    GET_TRIPS_LIST = '[Offered] Get Trips List',
    CHANGE_PROVIDER_STATUS = '[Offered] Change Provider Status',
    GET_LOGS_LIST = '[Offered] Get LOGS List',
    COMMON_SUCCESS = '[Offered] Common Success',
    COMMON_FAILURE = '[Offered] Common Faliure',
   
}

export class GetDashboardCount implements Action {
    readonly type = OfferedTripsActionTypes.GET_DASHBOARD_COUNT;
    constructor() {
    }

}

export class GetTripsList implements Action {
  readonly type = OfferedTripsActionTypes.GET_TRIPS_LIST;
  constructor(public payload: any) {
  }

}

export class ChangeProviderStatus implements Action {
  readonly type = OfferedTripsActionTypes.CHANGE_PROVIDER_STATUS;
  constructor(public payload: any) {
  }

}

export class GetLogsList implements Action {
    readonly type = OfferedTripsActionTypes.GET_LOGS_LIST;
    constructor() {
    }
  
  }
  
  
export class CommonSuccess implements Action {
  readonly type = OfferedTripsActionTypes.COMMON_SUCCESS;
  constructor(public payload: any) {

  }
}

export class CommonFailure implements Action {
  readonly type = OfferedTripsActionTypes.COMMON_FAILURE;
  constructor(public payload: any) {

  }
}
  

export type All =
    | GetDashboardCount
    | GetTripsList  
    | GetLogsList
    | ChangeProviderStatus
    | CommonSuccess
    | CommonFailure;
