import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SignInComponent } from './component/sign-in/sign-in.component';
import { ForgotPasswordComponent } from './component/forgot-password/forgot-password.component';
import { StaffMainComponent } from './component/staffs/staff-main/staff-main.component';
import { StaffsCreateComponent } from './component/staffs/staffs-create/staffs-create.component';
import { TripsHistoryComponent } from './component/dispatch-center/trips-history/trips-history.component';
import { DispatchDashboardComponent } from './component/dispatch-center/dashboard/dashboard.component';
import { EditUserProfileComponent } from './component/user profile/edit-user-profile/edit-user-profile.component';
import { AuthorizationGuard } from './service/auth-guard/authorization-guard.service';
import { DriverListComponent } from './component/drivers/driver-list/driver-list.component';
import { CreateDriverComponent } from './component/drivers/create-driver/create-driver.component';
import { DriverProfileDashboardComponent } from './component/drivers/driver-profile-dashboard/driver-profile-dashboard.component';
import { CorporateuserComponent } from './component/corporate-user/corporate-user-list/corporate-user.component';
import { CorporateAddUserComponent } from './component/corporate-user/corporate-add-user/corporate-add-user.component';
import { FleetListComponent } from './component/fleet/fleet-list/fleet-list.component';
import { CreateFleetComponent } from './component/fleet/create-fleet/create-fleet.component';
import { DriverDistributionsComponent } from './component/driver-distributions/driver-distributions-list/driver-distributions.component';
// tslint:disable-next-line: max-line-length
import { DriverDistributionsCreateComponent } from './component/driver-distributions/driver-distributions-create/driver-distributions-create.component';
import { InviteSmsComponent } from './component/Invite/invite-sms/invite-sms.component';
import { DriverAlertComponent } from './component/driver-alert/driver-alert/driver-alert.component';
import { HelpingMaterialListComponent } from './component/helping-material/helping-material-list/helping-material-list.component';
import { CreateServiceComponent } from './component/vehicle-expense/create-service/create-service.component';
import { ServiceTypesComponent } from './component/vehicle-expense/service-types/service-types.component';
import { DriverExpensesComponent } from './component/vehicle-expense/driver-expenses/driver-expenses.component';
import { ParseTripsComponent } from './component/planner/parse-trips/parse-trips.component';
import { ViewTripsComponent } from './component/planner/view-trips/view-trips.component';
import { MaintenanceStatsComponent } from './component/vehicle-expense/maintenance-stats/maintenance-stats.component';
import { DashboardComponent } from './component/assigned-trips/dashboard/dashboard.component';
import { UnassignedComponent } from './component/assigned-trips/unassigned/unassigned.component';
import { VerifiedTripLogsComponent } from './component/assigned-trips/verified-trip-logs/verified-trip-logs.component';
import { NewTripComponent } from './component/assigned-trips/new-trip/new-trip.component';
import { NemtStatisticsComponent } from './component/kpis/nemt-statistics/nemt-statistics.component';
import { NemtReportComponent } from './component/kpis/nemt-report/nemt-report.component';
import { NemtStatisticsDetailsComponent } from './component/kpis/nemt-statistics-details/nemt-statistics-details.component';
import { DynamicFareListComponent } from './component/dynamic-fare/dynamic-fare-list/dynamic-fare-list.component';
import { DynamicFareAddComponent } from './component/dynamic-fare/dynamic-fare-add/dynamic-fare-add.component';
import { EarningsComponent } from './component/reports/earnings/earnings.component';
import { TripDetailsComponent } from './component/dispatch-center//trip-details/trip-details.component';
import { AdjustmentComponent } from './component/dispatch-center/adjustment/adjustment.component';
import { DriverBreakdownComponent } from './component/kpis/driver-breakdown/driver-breakdown.component';
import { DashboardTripsDetailsComponent } from './component/assigned-trips/dashboard-trips-details/dashboard-trips-details.component';
import { NotificationDetailsComponent } from './notification/notification-details/notification-details.component';
import { AccidnetsListComponent } from './component/accidents/accidnets-list/accidnets-list.component'
import { AddAccidentComponent } from './component/accidents/add-accident/add-accident.component';
import { AccidentDetailInTailwindFormComponent } from './component/accidents/accident-detail-in-tailwind-form/accident-detail-in-tailwind-form.component';
import { HelpingMaterialPdfComponent } from './component/helping-material/helping-material-pdf/helping-material-pdf.component';
import { DashboardOfferedComponent } from './component/offered-trips/dashboard/dashboard-offered.component';
import { LogsComponent} from './component/offered-trips/logs/logs.component';
import { TripsComponent} from './component/offered-trips/trips/trips.component';
import { MemberListComponent } from './component/members/member-list/member-list.component';
import { CreateMemberComponent } from './component/members/create-member/create-member.component';
import { DriverLoadComponent } from './component/assigned-trips/driver-load/driver-load.component';
import { OutstandingComponent } from './component/assigned-trips/outstanding/outstanding.component';
import { DriverInvoicesComponent } from './component/drivers/driver-invoices/driver-invoices.component';
import { StandingOrdersComponent } from './component/assigned-trips/standing-orders/standing-orders.component';
import { DirtRoadComponent } from './component/assigned-trips/dirt-road/dirt-road.component';
import { DriverTimelinesComponent } from './component/drivers/driver-timelines/driver-timelines.component';
import { TripDetailModelComponent } from './component/assigned-trips/trip-detail-model/trip-detail-model.component';
import { ZDatesComponent } from './component/z-dates/z-dates.component';



const routes: Routes = [
  { path: 'login', component: SignInComponent },
  { path: 'forgotpassword', component: ForgotPasswordComponent },

  // Dispatch Center
  { path: '', redirectTo: '/dispatch', pathMatch: 'full'},
  { path: 'home', redirectTo: '/dispatch', pathMatch: 'full'},
  { path: 'dispatch', component: DispatchDashboardComponent, canActivate: [AuthorizationGuard] },

  // Dashboard
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthorizationGuard] },

  // Trips
  { path: 'trips', component: UnassignedComponent, canActivate: [AuthorizationGuard] },
  { path: 'trips/new', component: NewTripComponent, canActivate: [AuthorizationGuard] },
  { path: 'trips/drivers-load', component: DriverLoadComponent, canActivate: [AuthorizationGuard] },
  { path: 'trips/standing-orders', component: StandingOrdersComponent, canActivate: [AuthorizationGuard] },
  { path: 'trips/outstanding', component: OutstandingComponent, canActivate: [AuthorizationGuard] },
  { path: 'trips/dirt-road', component:DirtRoadComponent, canActivate: [AuthorizationGuard] },

  // Credentials
  { path: 'credentials', redirectTo: 'credentials/drivers', pathMatch: 'full' },

  // Credentails - Drivers
  { path: 'credentials/drivers', component: DriverListComponent, canActivate: [AuthorizationGuard] },
  { path: 'credentials/drivers/add', component: CreateDriverComponent, canActivate: [AuthorizationGuard] },
  { path: 'credentials/drivers/:id/edit', component: CreateDriverComponent, canActivate: [AuthorizationGuard] },
  { path: 'credentials/drivers/:id/profile', component: DriverProfileDashboardComponent, canActivate: [AuthorizationGuard] },

  // Credentails - Fleet
  { path: 'credentials/fleet', component: FleetListComponent, canActivate: [AuthorizationGuard] },
  { path: 'credentials/fleet/add', component: CreateFleetComponent, canActivate: [AuthorizationGuard] },
  { path: 'credentials/fleet/:id/edit', component: CreateFleetComponent, canActivate: [AuthorizationGuard] },

  // Credentails - Staff
  { path: 'credentials/staff', component: StaffMainComponent, canActivate: [AuthorizationGuard] },
  { path: 'credentials/staff/add', component: StaffsCreateComponent, canActivate: [AuthorizationGuard] },
  { path: 'credentials/staff/:id/edit', component: StaffsCreateComponent, canActivate: [AuthorizationGuard] },

  // Credentails - Members
  { path: 'credentials/members', component: MemberListComponent, canActivate: [AuthorizationGuard] },
  { path: 'credentials/members/add', component: CreateMemberComponent, canActivate: [AuthorizationGuard] },
  { path: 'credentials/members/:id/edit', component: CreateMemberComponent, canActivate: [AuthorizationGuard] },

  // Credentails - Corporate Users
  { path: 'credentials/corporates', component: CorporateuserComponent, canActivate: [AuthorizationGuard] },
  { path: 'credentials/corporates/add', component: CorporateAddUserComponent, canActivate: [AuthorizationGuard] },
  { path: 'credentials/corporates/:id/edit', component: CorporateAddUserComponent, canActivate: [AuthorizationGuard] },

  // Fares
  { path: 'fares', component: DynamicFareListComponent, canActivate: [AuthorizationGuard] },
  { path: 'fares/add', component: DynamicFareAddComponent, canActivate: [AuthorizationGuard] },
  { path: 'fares/:id/edit', component: DynamicFareAddComponent, canActivate: [AuthorizationGuard] },

  // Reports
  { path: 'reports', component: ZDatesComponent, canActivate: [AuthorizationGuard] },

  // Support
  { path: 'support', component: ZDatesComponent, canActivate: [AuthorizationGuard] },


  // Profile
  { path: 'profile', component: EditUserProfileComponent, canActivate: [AuthorizationGuard] },

  // Notifications
  { path: 'notifications', component: NotificationDetailsComponent, canActivate: [AuthorizationGuard] },

  // Not Found
  { path: '**', component: ZDatesComponent, canActivate: [AuthorizationGuard] },

  
  // Below are the old routes
  { path: 'dynamicfare', redirectTo: 'dynamicfare/list', pathMatch: 'full' },
  { path: 'dynamicfare/list', component: DynamicFareListComponent, canActivate: [AuthorizationGuard] },
  { path: 'dynamicfare/add', component: DynamicFareAddComponent, canActivate: [AuthorizationGuard] },
  { path: 'dynamicfare/:id/edit', component: DynamicFareAddComponent, canActivate: [AuthorizationGuard] },

  { path: 'assignstrips/all', component: UnassignedComponent, canActivate: [AuthorizationGuard] },
  { path: 'tripsdetailmodel', component: TripDetailModelComponent},

  { path: 'assignstrips/unassigned', component: UnassignedComponent, canActivate: [AuthorizationGuard] },
  { path: 'assignstrips/hanging', component: UnassignedComponent, canActivate: [AuthorizationGuard] },
  { path: 'assignstrips/cancelled', component: UnassignedComponent, canActivate: [AuthorizationGuard] },
  { path: 'assignstrips/inprogress', component: UnassignedComponent, canActivate: [AuthorizationGuard] },
  { path: 'assignstrips/archived', component: UnassignedComponent, canActivate: [AuthorizationGuard] },
  { path: 'assignstrips/completed', component: UnassignedComponent, canActivate: [AuthorizationGuard] },
  { path: 'assignstrips/expired', component: UnassignedComponent, canActivate: [AuthorizationGuard] },
  { path: 'assignstrips/dashboard/:type', component: DashboardTripsDetailsComponent, canActivate: [AuthorizationGuard] },
  { path: 'assignstrips/driverload', component: DriverLoadComponent, canActivate: [AuthorizationGuard] },
  { path: 'assignstrips/outstanding', component: OutstandingComponent, canActivate: [AuthorizationGuard] },
  { path: 'assignstrips/standing-orders', component: StandingOrdersComponent, canActivate: [AuthorizationGuard] },

  { path: 'assignstrips', redirectTo: 'assignstrips/dashboard', pathMatch: 'full' },
  { path: 'jobs/:id/adjustment', component: AdjustmentComponent, canActivate: [AuthorizationGuard] },

  { path: 'assignstrips/dashboard', component: DashboardComponent, canActivate: [AuthorizationGuard] },
  { path: 'assignstrips/adjustedtriplogs', component: VerifiedTripLogsComponent, canActivate: [AuthorizationGuard] },
  { path: 'assignstrips/triplogs/adjusted', component: VerifiedTripLogsComponent, canActivate: [AuthorizationGuard] },
  { path: 'assignstrips/newtrip', component: NewTripComponent, canActivate: [AuthorizationGuard] },
  { path: 'assignstrips/:id/edit', component: NewTripComponent, canActivate: [AuthorizationGuard] },
  { path: 'assignstrips/dirtroad', component:DirtRoadComponent, canActivate: [AuthorizationGuard] },

  
  
  
  { path: 'offeredtrips', redirectTo: 'offeredtrips/dashboard', pathMatch: 'full' },
  { path: 'offeredtrips/dashboard', component: DashboardOfferedComponent, canActivate: [AuthorizationGuard] },
  { path: 'offeredtrips/trips', component: TripsComponent, canActivate: [AuthorizationGuard] },
  { path: 'offeredtrips/logs', component: LogsComponent, canActivate: [AuthorizationGuard] },


  { path: 'members', component: MemberListComponent, canActivate: [AuthorizationGuard] },
  { path: 'members/create', component: CreateMemberComponent, canActivate: [AuthorizationGuard] },
  { path: 'members/:id/edit', component: CreateMemberComponent, canActivate: [AuthorizationGuard] },


  { path: 'planner', component: ViewTripsComponent, canActivate: [AuthorizationGuard] },
  { path: 'planner/parsetrips', component: ParseTripsComponent, canActivate: [AuthorizationGuard] },

  { path: 'distributions/:id/edit', component: DriverDistributionsCreateComponent, canActivate: [AuthorizationGuard] },
  { path: 'distributions/add', component: DriverDistributionsCreateComponent, canActivate: [AuthorizationGuard] },
  { path: 'distributions', component: DriverDistributionsComponent, canActivate: [AuthorizationGuard] },

  { path: 'corperate/:id/edit', component: CorporateAddUserComponent, canActivate: [AuthorizationGuard] },
  { path: 'corperate/add', component: CorporateAddUserComponent, canActivate: [AuthorizationGuard] },
  { path: 'corperate', component: CorporateuserComponent, canActivate: [AuthorizationGuard] },

  { path: 'drivers/active', component: DriverListComponent, canActivate: [AuthorizationGuard] },
  { path: 'drivers/inactive', component: DriverListComponent, canActivate: [AuthorizationGuard] },

  { path: 'drivers', redirectTo: 'drivers/active', pathMatch: 'full' },
  { path: 'drivers/timelines', component: DriverTimelinesComponent, canActivate: [AuthorizationGuard] },

  { path: 'dispatchcenter/inprogress', component: TripsHistoryComponent, canActivate: [AuthorizationGuard] },
  { path: 'dispatchcenter/pending', component: TripsHistoryComponent, canActivate: [AuthorizationGuard] },
  { path: 'dispatchcenter/history', component: TripsHistoryComponent, canActivate: [AuthorizationGuard] },
  { path: 'dispatchcenter/history/:id', component: TripDetailsComponent, canActivate: [AuthorizationGuard] },
  { path: 'dispatchcenter/adjustment/:id', component: AdjustmentComponent, canActivate: [AuthorizationGuard] },
  
  { path: 'assignstrips/triplogs/adjusted/:id', component: AdjustmentComponent, canActivate: [AuthorizationGuard] },

  
  { path: 'dispatch/edit/:id', component: DispatchDashboardComponent, canActivate: [AuthorizationGuard] },
  { path: 'dispatch/create', component: DispatchDashboardComponent, canActivate: [AuthorizationGuard] },
  { path: 'drivers/create', component: CreateDriverComponent, canActivate: [AuthorizationGuard] },
  { path: 'drivers/:id/profile', component: DriverProfileDashboardComponent, canActivate: [AuthorizationGuard] },
  { path: 'drivers/:id/edit', component: CreateDriverComponent, canActivate: [AuthorizationGuard] },


  { path: 'staffs', component: StaffMainComponent, canActivate: [AuthorizationGuard] },
  { path: 'staffs/create', component: StaffsCreateComponent, canActivate: [AuthorizationGuard] },
  { path: 'staffs/:id/edit', component: StaffsCreateComponent, canActivate: [AuthorizationGuard] },

  

  { path: 'profile', component: EditUserProfileComponent, canActivate: [AuthorizationGuard] },
  

  { path: 'fleets/active', component: FleetListComponent, canActivate: [AuthorizationGuard] },
  { path: 'fleets', redirectTo: 'fleets/active', pathMatch: 'full' },
  { path: 'fleets/inactive', component: FleetListComponent, canActivate: [AuthorizationGuard] },
  { path: 'fleets/create', component: CreateFleetComponent, canActivate: [AuthorizationGuard] },
  { path: 'fleets/:id/edit', component: CreateFleetComponent, canActivate: [AuthorizationGuard] },

  { path: 'invites', component: InviteSmsComponent, canActivate: [AuthorizationGuard] },

  { path: 'driveralerts', component: DriverAlertComponent, canActivate: [AuthorizationGuard] },

  { path: 'queries', component: DriverAlertComponent, canActivate: [AuthorizationGuard] },

  { path: 'helpingmaterial', component: HelpingMaterialListComponent, canActivate: [AuthorizationGuard] },
  { path: 'helpingmaterial/pdf/:id', component: HelpingMaterialPdfComponent, canActivate: [AuthorizationGuard] },
  
  { path: 'vehicleexpense/services/create', component: CreateServiceComponent, canActivate: [AuthorizationGuard] },
  { path: 'vehicleexpense/services', component: ServiceTypesComponent, canActivate: [AuthorizationGuard] },
  { path: 'vehicleexpense/driversexpense', component: DriverExpensesComponent, canActivate: [AuthorizationGuard] },
  { path: 'vehicleexpense/maintenancestats', component: MaintenanceStatsComponent, canActivate: [AuthorizationGuard] },
  { path: 'vehicleexpense', redirectTo: 'vehicleexpense/driversexpense', pathMatch: 'full' },

  { path: 'maintenances/services/create', component: CreateServiceComponent, canActivate: [AuthorizationGuard] },
  { path: 'maintenances/services', component: ServiceTypesComponent, canActivate: [AuthorizationGuard] },
  { path: 'maintenances/drivers', component: DriverExpensesComponent, canActivate: [AuthorizationGuard] },
  { path: 'kpis/nemtstatistics', component: NemtStatisticsComponent, canActivate: [AuthorizationGuard] },
  { path: 'kpis/nemtreport', component: NemtReportComponent, canActivate: [AuthorizationGuard] },
  { path: 'kpis/nemtstatistics/details', component: NemtStatisticsDetailsComponent, canActivate: [AuthorizationGuard] },
  { path: 'kpis/driverbreakdown', component: DriverBreakdownComponent, canActivate: [AuthorizationGuard] },
  { path: 'kpis', redirectTo: 'kpis/nemtstatistics', pathMatch: 'full' },

  { path: 'reports', redirectTo: 'reports/earnings', pathMatch: 'full' },
  { path: 'reports/earnings', component: EarningsComponent, canActivate: [AuthorizationGuard] },
  

  { path: 'accidents', redirectTo: 'accidents/list', pathMatch: 'full' },
  { path: 'accidents/list', component: AccidnetsListComponent, canActivate: [AuthorizationGuard] },
  { path: 'accidents/add', component: AddAccidentComponent, canActivate: [AuthorizationGuard] },
  { path: 'accidents/view/:id', component: AccidentDetailInTailwindFormComponent, canActivate: [AuthorizationGuard] },
  // { path: 'accidents/test', component: AccidentDetailInTailwindFormComponent, canActivate: [AuthorizationGuard] }

  { path: 'driver-invoice', component: DriverInvoicesComponent, canActivate: [AuthorizationGuard] },
  { path: 'dates', component: ZDatesComponent }
  

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
