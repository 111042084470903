export const userManuals = 
  [{
    url: 'https://s3.amazonaws.com/neighborhood-dev/user-manuals/add-driver.pdf',
    name: 'How To Add Driver?'
  }, {
    url: 'https://s3.amazonaws.com/neighborhood-dev/user-manuals/add-vehicle.pdf',
    name: 'How To Add Vehicle?'
  },{
    url: 'https://s3.amazonaws.com/neighborhood-dev/user-manuals/nemt-panel-compressed.pdf',
    name: 'Nemt-Panel Document'
  }, {
    url: 'https://s3.amazonaws.com/neighborhood-dev/user-manuals/fares-document-compressed.pdf',
    name: 'Fares Document'
  }, {
    url: 'https://s3.amazonaws.com/neighborhood-dev/user-manuals/Corporate-Section_compressed.pdf',
    name: 'Corporate Section'
  }, {
    url: 'https://s3.amazonaws.com/neighborhood-dev/user-manuals/NEMT-App-Manual-compressed.pdf',
    name: 'Nemt Pro App'
  }, {
    url: 'https://s3.amazonaws.com/neighborhood-dev/user-manuals/NEMT-Virtual-Dispatch-Manual-compressed.pdf',
    name: 'Virtual Dispatch Center'
  }, {
    url: 'https://s3.amazonaws.com/neighborhood-dev/user-manuals/LCP-Trips-Parsing_compressed.pdf',
    name: 'LCP File Parsing'
  }, {
    url: 'https://s3.amazonaws.com/neighborhood-dev/user-manuals/LogistiCare-Trips-Parsing_compressed.pdf',
    name: 'LogistiCare File Parsing'
  }, {
    url: 'https://s3.amazonaws.com/neighborhood-dev/user-manuals/MTM-File-parsing_compressed.pdf',
    name: 'MTM File Parsing'
  }, {
    url: 'https://s3.amazonaws.com/neighborhood-dev/user-manuals/NMN-File-parsing_compressed.pdf',
    name: 'NMN File Parsing'
  }, {
    url: 'https://s3.amazonaws.com/neighborhood-dev/user-manuals/SoutheasTrans-Trips-Parsing_compressed.pdf',
    name: 'SoutheasTrans File Parsing'
  }, {
    url: 'https://s3.amazonaws.com/neighborhood-dev/user-manuals/ALC-Trips-Parsing_compressed.pdf',
    name: 'American Logistics File Parsing'
  }]
;