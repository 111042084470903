import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injectable } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SidemenuComponent } from './sidemenu/sidemenu.component';
import { FooterComponent } from './footer/footer.component';
import { SignInComponent } from './component/sign-in/sign-in.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ForgotPasswordComponent } from './component/forgot-password/forgot-password.component';
import { CreateNewTripComponent } from './component/dispatch-center/create-new-trip/create-new-trip.component';
import { RangePipe } from './pipe/range-pipe/range.pipe';
import { FilterPipe } from './pipe/list-filter-pipe/list-filter-pipe';
import { FilterByKeyPipe } from './pipe/list-filterbykey-pipe/list-filterbykey-pipe';
import { DispatchDashboardComponent } from './component/dispatch-center/dashboard/dashboard.component';
import { AgmCoreModule } from '@agm/core';
import { StaffMainComponent } from './component/staffs/staff-main/staff-main.component';
import { StaffsCreateComponent } from './component/staffs/staffs-create/staffs-create.component';
import { TokenInterceptorService } from './service/token-interceptor-service/token-interceptor.service';

import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AuthEffects } from './ngrx/store/effects/ auth.effects';
import { DispatchCenterEffects } from './ngrx/store/effects/dispatch-center-effects';
import { StoreModule } from '@ngrx/store';
import { reducers } from './ngrx/app.states';
import { EditUserProfileComponent } from './component/user profile/edit-user-profile/edit-user-profile.component';
import { AuthorizationGuard } from './service/auth-guard/authorization-guard.service';
// we are using this in edit profile for all time zone list
import { TimezonePickerModule } from 'ng2-timezone-selector';
import { AutocompleteComponent } from './component/common-components/goolge-places-selecters/google-places.component';
import { TripsHistoryComponent } from './component/dispatch-center/trips-history/trips-history.component';
import { DriverListComponent } from './component/drivers/driver-list/driver-list.component';
import { RolePipe } from './pipe/role-value-filter/role.pipe';
import { AccountStatusPipe } from './pipe/account-status-value-filter/account-status.pipe';
import { CreateDriverComponent } from './component/drivers/create-driver/create-driver.component';
import { DriverProfileDashboardComponent } from './component/drivers/driver-profile-dashboard/driver-profile-dashboard.component';
import { ImageModalComponent } from './modal/image-modal/image-modal.component';
import { CamelCasePipe } from './pipe/camelcase.filter/camelcase.filter.pipe';
import { CorporateuserComponent } from './component/corporate-user/corporate-user-list/corporate-user.component';
import { CorporateAddUserComponent } from './component/corporate-user/corporate-add-user/corporate-add-user.component';
import { FleetListComponent } from './component/fleet/fleet-list/fleet-list.component';
import { CreateFleetComponent } from './component/fleet/create-fleet/create-fleet.component';
import { InviteSmsComponent } from './component/Invite/invite-sms/invite-sms.component';
import { DriverDistributionsComponent } from './component/driver-distributions/driver-distributions-list/driver-distributions.component';
// tslint:disable-next-line: max-line-length
import { DriverDistributionsCreateComponent } from './component/driver-distributions/driver-distributions-create/driver-distributions-create.component';
import { DistributionsEffects } from './ngrx/store/effects/distributions-effects';
import { DriverAlertComponent } from './component/driver-alert/driver-alert/driver-alert.component';
import { HelpingMaterialListComponent } from './component/helping-material/helping-material-list/helping-material-list.component';
import { VehicleExpenseEffects } from './ngrx/store/effects/vehicle-expense-effects';
import { UrlSafePipe } from './pipe/url-safe-pipe/url-safe.pipe';
import { CreateServiceComponent } from './component/vehicle-expense/create-service/create-service.component';
import { ServiceTypesComponent } from './component/vehicle-expense/service-types/service-types.component';
import { DriverExpensesComponent } from './component/vehicle-expense/driver-expenses/driver-expenses.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OwlDateTimeModule, OWL_DATE_TIME_FORMATS } from 'ng-pick-datetime';
import { OwlMomentDateTimeModule, OWL_MOMENT_DATE_TIME_ADAPTER_OPTIONS } from 'ng-pick-datetime-moment';
import { ViewTripsComponent } from './component/planner/view-trips/view-trips.component';
import { ParseTripsComponent } from './component/planner/parse-trips/parse-trips.component';
import { MaintenanceStatsComponent } from './component/vehicle-expense/maintenance-stats/maintenance-stats.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { PlannerEffects } from './ngrx/store/effects/planner-effects';
import { DashboardComponent } from './component/assigned-trips/dashboard/dashboard.component';
import { UnassignedComponent } from './component/assigned-trips/unassigned/unassigned.component';
import { VerifiedTripLogsComponent } from './component/assigned-trips/verified-trip-logs/verified-trip-logs.component';
import { NewTripComponent } from './component/assigned-trips/new-trip/new-trip.component';
import { AssignTripsEffects } from './ngrx/store/effects/assigntrips-effects';
import { GooglePlaceDirective } from './directives/google-places-directive/google-place.directive';
import { NemtStatisticsComponent } from './component/kpis/nemt-statistics/nemt-statistics.component';
import { KpisEffects } from './ngrx/store/effects/kpis.effects';
import { NemtReportComponent } from './component/kpis/nemt-report/nemt-report.component';
import { NemtStatisticsDetailsComponent } from './component/kpis/nemt-statistics-details/nemt-statistics-details.component';

import { DynamicFareListComponent } from './component/dynamic-fare/dynamic-fare-list/dynamic-fare-list.component';
import { DynamicFareAddComponent } from './component/dynamic-fare/dynamic-fare-add/dynamic-fare-add.component';
import { HourPipePipe } from './pipe/hour-range-pipe/hour-pipe.pipe';
import { MinPipePipe } from './pipe/minute-range-pipe/min-pipe.pipe';
import { SocketIoModule, SocketIoConfig, Socket } from 'ngx-socket-io';
import { urls } from './utils/url-utils';
import { SocketService } from './service/socket-service/socket-service.service';
import { AgmOverlays } from 'agm-overlays';
import { EarningsComponent } from './component/reports/earnings/earnings.component';
import { ReportsEffects } from './ngrx/store/effects/reports.effects';
import { AccidentReportEffects } from './ngrx/store/effects//accident-report.effects';
import { TripDetailsComponent } from './component/dispatch-center/trip-details/trip-details.component';
import { AdjustmentComponent } from './component/dispatch-center/adjustment/adjustment.component';
import { TripFilterPipe } from './pipe/trip-list-filter-pipe/trip-filter.pipe';
import { TripAssignModalComponent } from './component/assigned-trips/trip-assign-modal/trip-assign-modal.component';
import { TimeFormatDirective } from './directives/time-format-directive/time-format.directive';
import { CompanyNoteModalComponent } from './component/assigned-trips/company-note-modal/company-note-modal.component';
import { CancelTripModalComponent } from './component/assigned-trips/cancel-trip-modal/cancel-trip-modal.component';
import { ConfirmModalComponent } from './component/assigned-trips/confirm-modal/confirm-modal.component';
import { TimePipe } from './pipe/time-pipe/time.pipe';
import { AgmDirectionModule } from 'agm-direction';
import { TripDetailDialogComponent } from './component/common-components/trip-detail-dialog/trip-detail-dialog.component';
import { BillerNoteModalComponent } from './component/assigned-trips/biller-note-modal/biller-note-modal.component';

import { SignaturePadModule } from 'angular2-signaturepad';
import { CommonAlertComponent } from './component/common-components/common-alert/common-alert.component';
import { DriverBreakdownComponent } from './component/kpis/driver-breakdown/driver-breakdown.component';
import { SweepTripsModalComponent } from './component/assigned-trips/sweep-trips-modal/sweep-trips-modal.component';
import { TimeagoModule } from 'ngx-timeago';
import { MySocketWrapper } from './service/socket-service/socket-wrapper';
import {
  NgxUiLoaderModule, NgxUiLoaderHttpModule, NgxUiLoaderConfig,
  SPINNER, POSITION, PB_DIRECTION
} from 'ngx-ui-loader';
import { TripsInProgressModalComponent } from './component/dispatch-center/trips-in-progress-modal/trips-in-progress-modal.component';
import { DatePipe } from './pipe/date-pipe/date.pipe';
import { OwlModule } from 'ngx-owl-carousel';
import { TodayTripsPipe } from './pipe/today-trips-pipe/today-trips.pipe';
import { DashboardTripsDetailsComponent } from './component/assigned-trips/dashboard-trips-details/dashboard-trips-details.component';
import { NotificationDetailsComponent } from './notification/notification-details/notification-details.component';
import { AccidnetsListComponent } from './component/accidents/accidnets-list/accidnets-list.component';
import { AddAccidentComponent } from './component/accidents/add-accident/add-accident.component';
import { HelpingMaterialPdfComponent } from './component/helping-material/helping-material-pdf/helping-material-pdf.component';
import { AccidentDetailInTailwindFormComponent } from './component/accidents/accident-detail-in-tailwind-form/accident-detail-in-tailwind-form.component';
import { PrivacyandpolicyComponent } from './privacyandpolicy/privacyandpolicy.component';
import { TripsComponent } from './component/offered-trips/trips/trips.component';
import { LogsComponent } from './component/offered-trips/logs/logs.component';
import { DashboardOfferedComponent } from './component/offered-trips/dashboard/dashboard-offered.component';

import { OfferedTripsEffects } from './ngrx/store/effects/offered-trips-effects';
import { MemberListComponent } from './component/members/member-list/member-list.component';
import { CreateMemberComponent } from './component/members/create-member/create-member.component';
import { DriverLoadComponent } from './component/assigned-trips/driver-load/driver-load.component';
import { OutstandingComponent } from './component/assigned-trips/outstanding/outstanding.component';
import { ClickStopPropagationDirective } from './directives/click-stop-propagation/click-stop-propagation.directive';
import { DriverInvoicesComponent } from './component/drivers/driver-invoices/driver-invoices.component';
import { DirtRoadComponent } from './component/assigned-trips/dirt-road/dirt-road.component';
import { StandingOrdersComponent } from './component/assigned-trips/standing-orders/standing-orders.component';
import { DriverTimelinesComponent } from './component/drivers/driver-timelines/driver-timelines.component';
import { TripDetailModelComponent } from './component/assigned-trips/trip-detail-model/trip-detail-model.component';
import { OutstandingTripsModalComponent } from './component/assigned-trips/outstanding-trips-modal/outstanding-trips-modal.component';
import { TripAdjustmentComponent } from './component/assigned-trips/trip-adjustment/trip-adjustment.component';
import { CompletedCancelModalComponent } from './component/assigned-trips/completed-cancel-modal/completed-cancel-modal.component';
import { AdditionalNoteModalComponent } from './component/assigned-trips/additional-note-modal/additional-note-modal.component';
import { ZDatesComponent } from './component/z-dates/z-dates.component';
import { SideNavComponent } from './component/shared/layout/side-nav/side-nav.component';
import { HeaderComponent } from './component/shared/layout/header/header.component';
import { NotificationsSlideoutComponent } from './component/shared/slideouts/notifications-slideout/notifications-slideout.component';
import { DispatchInprogressTripsSlideoutComponent } from './component/shared/slideouts/dispatch-inprogress-trips-slideout/dispatch-inprogress-trips-slideout.component';

// const ngxUiLoaderConfig: NgxUiLoaderConfig = {
//   bgsColor: '#00acc1',
//   bgsOpacity: 0.5,
//   bgsPosition: 'bottom-right',
//   bgsSize: 60,
//   bgsType: 'ball-spin-clockwise',
//   blur: 7,
//   fgsColor: '#cbd5e0',//'#727cf5',//#00acc1',
//   fgsPosition: 'center-center',
//   fgsSize: 60,
//   fgsType: 'fading-circle',
//   gap: 24,
//   logoPosition: 'center-center',
//   logoSize: 120,
//   overlayBorderRadius: '0',
//   overlayColor: 'rgba(40, 40, 40, 0.8)',
//   pbColor: '#00acc1',
//   pbDirection: 'ltr',
//   pbThickness: 3,
//   hasProgressBar: true,
//   text: 'Loading...',
//   textColor: '#FFFFFF',
//   textPosition: 'center-center'
// };

const ngxUiLoaderConfig: NgxUiLoaderConfig  = {
  bgsColor: 'red',
  bgsOpacity: 0.5,
  bgsPosition: 'bottom-right',
  bgsSize: 80,
  bgsType: 'ball-spin-clockwise',
  blur: 0,
  fgsColor: '#727cf5',
  fgsPosition: 'center-center',
  fgsSize: 40,
  fgsType: 'ball-spin',
  gap: 24,
  logoPosition: 'center-center',
  logoSize: 150,
  logoUrl: '',
  masterLoaderId: 'master',
  overlayBorderRadius: '0',
  overlayColor: 'rgba(40,40,40,0)',
  pbColor: '#727cf5',
  pbDirection: 'ltr',
  pbThickness: 3,
  hasProgressBar: true,
  text: '',
  textColor: '#FFFFFF',
  textPosition: 'center-center',
};

export const MY_MOMENT_FORMATS = {
  fullPickerInput: 'MM/DD/YYYY HHmm',
  parseInput: 'l LT',
  datePickerInput: 'l',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
};

// export const MY_MOMENT_FORMATS = {
//   fullPickerInput: '',
//   parseInput: 'l LT',
//   datePickerInput: 'l',
//   timePickerInput: 'LT',
//   monthYearLabel: 'MMM YYYY',
//   dateA11yLabel: 'LL',
//   monthYearA11yLabel: 'MMMM YYYY'
// };

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  swipeEasing: true,
  minScrollbarLength: 20
};


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidemenuComponent,
    FooterComponent,
    SignInComponent,
    ForgotPasswordComponent,
    CreateNewTripComponent,
    RangePipe,
    FilterPipe,
    FilterByKeyPipe,
    DispatchDashboardComponent,
    StaffMainComponent,
    StaffsCreateComponent,
    EditUserProfileComponent,
    AutocompleteComponent,
    TripsHistoryComponent,
    DriverListComponent,
    RolePipe,
    AccountStatusPipe,
    CreateDriverComponent,
    DriverProfileDashboardComponent,
    ImageModalComponent,
    CamelCasePipe,
    CorporateuserComponent,
    CorporateAddUserComponent,
    FleetListComponent,
    CreateFleetComponent,
    InviteSmsComponent,
    DriverDistributionsComponent,
    DriverDistributionsCreateComponent,
    DriverAlertComponent,
    HelpingMaterialListComponent,
    UrlSafePipe,
    CreateServiceComponent,
    ServiceTypesComponent,
    DriverExpensesComponent,
    ViewTripsComponent,
    ParseTripsComponent,
    MaintenanceStatsComponent,
    NewTripComponent,
    AutocompleteComponent,
    ParseTripsComponent,
    DispatchDashboardComponent,
    UnassignedComponent,
    VerifiedTripLogsComponent,
    GooglePlaceDirective,
    NemtStatisticsComponent,
    NemtReportComponent,
    NemtStatisticsDetailsComponent,
    DynamicFareListComponent,
    DynamicFareAddComponent,
    HourPipePipe,
    MinPipePipe,
    EarningsComponent,
    TripDetailsComponent,
    AdjustmentComponent,
    DashboardComponent,
    TripFilterPipe,
    TripAssignModalComponent,
    TimeFormatDirective,
    CompanyNoteModalComponent,
    CancelTripModalComponent,
    ConfirmModalComponent,
    TimePipe,
    TripDetailDialogComponent,
    BillerNoteModalComponent,
    CommonAlertComponent,
    DriverBreakdownComponent,
    SweepTripsModalComponent,
    TripsInProgressModalComponent,
    DatePipe,
    TodayTripsPipe,
    DashboardTripsDetailsComponent,
    NotificationDetailsComponent,
    AccidnetsListComponent,
    AddAccidentComponent,
    HelpingMaterialPdfComponent,
    AccidentDetailInTailwindFormComponent,
    PrivacyandpolicyComponent,
    TripsComponent,
    LogsComponent,
    DashboardOfferedComponent,
    MemberListComponent,
    CreateMemberComponent,
    DriverLoadComponent,
    OutstandingComponent,
    ClickStopPropagationDirective,
    DriverInvoicesComponent,
    DirtRoadComponent,
    StandingOrdersComponent,
    DriverTimelinesComponent,
    TripDetailModelComponent,
    OutstandingTripsModalComponent,
    TripAdjustmentComponent,
    CompletedCancelModalComponent,
    AdditionalNoteModalComponent,
    ZDatesComponent,
    SideNavComponent,
    NotificationsSlideoutComponent,
    DispatchInprogressTripsSlideoutComponent,
  ],

  imports: [
    BrowserModule,
    AppRoutingModule,
    SignaturePadModule,
    ReactiveFormsModule,
    FormsModule,
    TimeagoModule.forRoot(),
    HttpClientModule, // import HttpClientModule
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig), // import NgxUiLoaderModule 
    NgxUiLoaderHttpModule.forRoot({ exclude:['http://provider.qalbs.com/api/assigns/dashboard', 'http://provider.qalbs.com/api/notifications', 'http://provider.qalbs.com/api/assigns/corporate',
    'http://provider.qalbs.com/api/users/me',  'http://provider.qalbs.com/api/drivers/nearby'],
     showForeground: true }),
    // import NgxUiLoaderHttpModule. By default, it will show background loader.
    // If you need to show foreground spinner, do as follow:
    // NgxUiLoaderHttpModule.forRoot({ showForeground: true })
    StoreModule.forRoot(reducers, {}),
    EffectsModule.forRoot([AuthEffects, DispatchCenterEffects, DistributionsEffects, PlannerEffects, AssignTripsEffects,
      VehicleExpenseEffects, KpisEffects, ReportsEffects, AccidentReportEffects,OfferedTripsEffects]),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBNoxWXagcapR0tH-bxnJFPK28y_Oro2O0',
      libraries: ['places']
    }),
    SocketIoModule,
    AgmDirectionModule,
    StoreDevtoolsModule.instrument(
      {
        maxAge: 10
      }
    ),
    TimezonePickerModule,
    BrowserAnimationsModule,

    OwlDateTimeModule,
    OwlMomentDateTimeModule,
    PerfectScrollbarModule,
    AgmOverlays,
    OwlModule
  ],

  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },
    { provide: AuthorizationGuard, useClass: AuthorizationGuard },
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS },

    { provide: OWL_MOMENT_DATE_TIME_ADAPTER_OPTIONS, useValue: { useUtc: false } },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    MySocketWrapper,
    SocketService

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
