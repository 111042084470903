import { Component, OnInit, OnDestroy, ViewChild, EventEmitter } from '@angular/core';
import { sweetAlert } from '../../../utils/swal';
import { ActivatedRoute } from '@angular/router';
import { Events } from '../../../utils/event-utils';
import { SocketService } from '../../../service/socket-service/socket-service.service';
import { loadMyScript } from 'src/app/utils/utils.common';
import { DriversService } from '../../drivers/drivers-service/drivers-service';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
declare var $;
@Component({
  selector: 'app-driver-alert',
  templateUrl: './driver-alert.component.html',
  styleUrls: ['./driver-alert.component.css']
})
export class DriverAlertComponent implements OnInit {
  driverList = [];
  driverSms = [];
  endPoint = null;
  selectedUserIndex;
  searchDriver: any;
  searchMessages: any;
  newMessage = null;
  user;

  constructor(
    private driverService: DriversService,
    private sharedDataService: SharedDataService,
    private socketService: SocketService,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.getUser();
    loadMyScript("assets/vendors/feather-icons/feather.min.js");

    this.endPoint = this.activatedRoute.snapshot.url[0].path;
    if (this.endPoint === 'driveralerts') {
      this.getDriverList();
    } else if (this.endPoint === 'queries') {
      this.getQueriesUserList();
    }

    this.setUpDriverAlertSocketListener();
  }

  getUser() {
    this.sharedDataService
      .getUser()
      .subscribe(user => {
        if (user) {
          this.user = user;
        }
      });
  }

  getDriverList() {
    this.driverService
      .getDrivers(this.user._id, 'active')
      .subscribe(data => {
        if (data) {
          this.driverList = data;
          this.fetchSms(this.driverList[0], 0)
          // loadMyScript("assets/vendors/core/core.js");
          loadMyScript("assets/js/chat.js");
        }
      });
  }

  getQueriesUserList() {
    this.driverService
      .getQueriesUserList()
      .subscribe(data => {
        if (data) {
          this.driverList = data;
          this.fetchSms(this.driverList[0], 0)
          // loadMyScript("assets/vendors/core/core.js");
          loadMyScript("assets/js/chat.js");
        }
      });
  }

  fetchSms(userObj, index) {
    this.selectedUserIndex = index;
    if (this.endPoint === 'queries') {

      if (userObj._id) {

        const data = {
          driverObj: userObj,
          endPoint: this.endPoint
        };
        this.getDriverSms(data);
      }
      return;
    } else if (this.endPoint === 'driveralerts') {
      if (userObj._id) {
        // this.selectedUserId = userObj._id;
        const data = {
          id: userObj._id,
          endPoint: this.endPoint
        };
        this.getDriverSms(data);
      }
      return;
    }

  }

  getDriverSms(payload) {
    this.driverService
      .getDriversSms(payload)
      .subscribe(data => {
        if (data) {
          this.driverSms = data;
          loadMyScript("assets/js/chat.js");
        }
      }, err => {
        sweetAlert('Error', err, 'error', 'OK');
      });
  }

  getImage(userObj) {

    if (this.endPoint === 'queries') {
      if (userObj.driver) {
        if (userObj.driver.profileImageURL) {
          return userObj.driver.profileImageURL;
        }
        return '#';
      }
      return '#';
    } else if (this.endPoint === 'driveralerts') {
      if (userObj.profileImageURL) {
        return userObj.profileImageURL;
      }
      return '#';
    }
  }

  getDisplayName(userObj) {

    if (this.endPoint === 'queries') {
      if (userObj.driver) {
        if (userObj.driver.displayName) {
          return userObj.driver.displayName;
        }
        return '#';
      }
      return 'N/A';
    } else if (this.endPoint === 'driveralerts') {
      if (userObj.displayName) {
        return userObj.displayName;
      }
      return 'N/A';
    }
  }

  sendMessage() {
    const message = {
      title: '',
      message: this.newMessage,
      receiver: this.driverList[this.selectedUserIndex]
    };
    console.log(message);
    this.socketService.emit(Events.DRIVER_ALERT_CALL, message);
    this.newMessage = null;
  }

  setUpDriverAlertSocketListener() {
    this.socketService.addListener(Events.DRIVER_ALERT_CALL)
      .subscribe(data => {
        console.log(data);
        if (data.success) {
          this.driverSms.push(data.driverAlert);
          loadMyScript("assets/js/chat.js");
        } else {
          sweetAlert('Error', data.message, 'error', 'OK');

        }
      });

    this.socketService.addListener(Events.RECEIVE_DRIVER_ALERT_MESSAGE_CALL)
      .subscribe(data => {
        console.log(data);
        if (data.success) {
          this.driverSms.push(data.driverAlert);
          loadMyScript("assets/js/chat.js");
        } else {
          sweetAlert('Error', data.message, 'error', 'OK');

        }
      });
  }
}
