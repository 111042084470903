import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { AssignTripsActionTypes, RemoveTrip, AssignMultipleTrip } from '../../../ngrx/store/actions/assigntrips-actions';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AssignTripsState, selectAssignTripsState } from '../../../ngrx/app.states';
import { sweetAlert } from 'src/app/utils/swal';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from "@w11k/ngx-componentdestroyed";
import { AssigTripsService } from '../assigntrips-service/assigntrips-service';
@Component({
  selector: 'app-cancel-trip-modal',
  templateUrl: './cancel-trip-modal.component.html',
  styleUrls: ['./cancel-trip-modal.component.css']
})
export class CancelTripModalComponent implements OnInit {
  @Input() tripData;
  @Input() componentType;
  @Output() cancelTripData = new EventEmitter<any>();
  multipleCancel = {}
  cancelReason;
  componentLoad = false;

  constructor(
    private assignTripService: AssigTripsService
  ) { }

  ngOnInit() {
  }

  setReason(reason) {
    this.cancelReason = reason;
    // if (this.componentType === 'Multiple Assign') {
    //   this.multipleCancel['changeReason'] = reason;
    // } else {
    //   this.tripData['changeReason'] = reason;
    // }
  }

  cancelJob() {
    console.log(this.componentType)
    if (this.componentType === 'Multiple Assign') {
      this.multipleCancel = {
        selectedTrips: this.tripData,
        status: 'cancelled',
        changeReason: this.cancelReason
      }
      this.componentLoad = true;
      console.log(this.multipleCancel)
      this.assignTripService
        .assignMultipleTrip(this.multipleCancel)
        .subscribe(data => {
          if (data) {
            sweetAlert('Succcess', "Trip Cancelled Successfullly", 'success', 'OK')
            this.cancelTripData.emit(this.tripData);
          }
        });
    } else {
      this.tripData['changeReason'] = this.cancelReason;
      this.tripData['status'] = "cancelled";
      this.tripData['jobStatus'] = "cancelled";
      this.componentLoad = true;
      this.assignTripService
        .removeTrip(this.tripData, this.tripData._id)
        .subscribe(data => {
          if (data) {
            sweetAlert('Succcess', "Trip Cancelled Successfullly", 'success', 'OK')
            this.cancelTripData.emit(this.tripData);
          }
        })
    }
  }
}
