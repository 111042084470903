import { AgmMap } from '@agm/core';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { getBrokersList } from 'src/app/utils/utils.common';

@Component({
  selector: 'app-outstanding-trips-modal',
  templateUrl: './outstanding-trips-modal.component.html',
  styleUrls: ['./outstanding-trips-modal.component.css']
})
export class OutstandingTripsModalComponent implements OnInit, AfterViewInit {
  @Input() bucket;
  @Input() index;
  @Input() nonSuggestedTrips;
  @Output() assignTrip = new EventEmitter<any>();
  @Output() removeTrip = new EventEmitter<any>();

  @ViewChild("AgmMap", { static: false }) agmElement: AgmMap;
  agmMap;
  bounds;
  latitude: number;
  longitude: number;
  zoom: number = 12;

  trip;
  tripDeadMiles = 0;
  tripMiles = 0;
  tripFare = 0;

  constructor() { }

  ngOnInit() {
    console.log(' bucket', this.bucket);
  }

  ngAfterViewInit() {
    this.agmElement.mapReady.subscribe((map) => {
      this.agmMap = map;
      setTimeout(() => {
        // this.setBounds();
      this.googleMarkerBounds()
      },500);
    });
  }

  setBounds() {
    if (this.agmMap) {
      let bounds = new google.maps.LatLngBounds();
      bounds.extend(
        new window["google"].maps.LatLng(
          this.trip.jobOriginLatitude,
          this.trip.jobOriginLongitude
        )
      );

      bounds.extend(
        new window["google"].maps.LatLng(
          this.trip.jobDestinationLatitude,
          this.trip.jobDestinationLongitude
        )
      );

      this.agmMap.setCenter(bounds.getCenter());
      this.agmMap.fitBounds(bounds);
      console.log(bounds);
    }
  }

  brokerList(key?) {
    if (key) {
      return getBrokersList[key];
    }
    return getBrokersList;
  }

  getTripRoute(trip) {
    console.log(trip);
    this.trip = trip
    this.setBounds();
  }

  sendToSpecificBucket(trip) {
    trip.driver = this.bucket._id._id;
    this.assignTrip.emit(trip);
  }

  removeFromBucket(trip) {
    const evt = {
      trip: trip,
      bucket: this.bucket,
      bIndex: this.index
    }
    this.removeTrip.emit(evt);
  }

  googleMarkerBounds() {
    if (this.bucket && this.bucket.assigns && this.bucket.assigns.length) {
      // vm.bounds = new $window.google.maps.LatLngBounds();
      this.tripMiles = 0;
      this.tripDeadMiles = 0;
      this.bucket.assigns.forEach((assign, index, assigns) => {
        this.tripMiles += parseFloat(assign.milage);
        // let latlng = new $window.google.maps.LatLng(assign.jobOriginLatitude, assign.jobOriginLongitude);
        // vm.bounds.extend(latlng);
        // vm.tripDeadMiles += parseFloat(assign.milage);
        // calculate distance between trips
        let nextIndex = index + 1;
        console.log('Current index -- ', index, ' --- Next Iteration ---', nextIndex, ' -- Total trip length --- ', assigns.length);
        if (assigns.length > nextIndex) {
          let nextTrip = assigns[nextIndex];
          let distance = this.distance(assign.jobDestinationLatitude, assign.jobDestinationLongitude, nextTrip.jobOriginLatitude, nextTrip.jobOriginLongitude);
          console.log('Trip 1 destination to Trip2 origin ', distance);
          this.tripDeadMiles += distance;
        }
      });
      console.log('After loop iteration');
    }
  };

  distance(lat1, lon1, lat2, lon2) {

    if ((lat1 === lat2) && (lon1 === lon2)) {
      return 0;
    }
    
    return this.getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2);
  }

  getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
    var R = 6371; // Radius of the earth in km
    var dLat = this.deg2rad(lat2 - lat1);
    var dLon = this.deg2rad(lon2 - lon1);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2)
      ;
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km
    return d / 1.609; // divided by 1.609 to convert into miles
  }

  deg2rad(deg) {
    return deg * (Math.PI / 180);
  }
}
