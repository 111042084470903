import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { sweetAlert } from 'src/app/utils/swal';
import * as moment from "moment-timezone";
import { getBrokersList } from 'src/app/utils/utils.common';
import { DriversService } from '../../drivers/drivers-service/drivers-service';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
import { AssigTripsService } from '../assigntrips-service/assigntrips-service';

@Component({
  selector: 'app-outstanding',
  templateUrl: './outstanding.component.html',
  styleUrls: ['./outstanding.component.css']
})
export class OutstandingComponent implements OnInit {
  getTripListObserver: Observable<any>;
  drivers = [];
  timeZone;
  todayDate;
  search;
  standingOrders;
  outstandingTrips = [];
  selectedDriverId;
  buckets = [1, 1, 1, 1, 1, 1];
  bucketTripss = [1, 1, 1, 1];

  suggestedTrips = [];
  nonSuggestedTrips = [];
  bucketTrips;
  row;
  user;
  selectedTrips;
  selectedBucket;
  selectedBucketIndex;

  constructor(
    private assignTripService: AssigTripsService,
    private driverService: DriversService,
    private sharedDataService: SharedDataService
  ) { }

  ngOnInit() {
    this.getUser();
    this.todayDate = moment.tz(new Date(), this.timeZone).startOf('day').toISOString();
    this.getDriverList();
    this.getOutstandingTrips();
  }

  getUser() {
    this.sharedDataService
      .getUser()
      .subscribe(user => {
        if (user) {
          this.user = user;
          this.timeZone = this.user.timeZone;
        }
      });
  }

  getDriverList() {
    this.driverService
      .getDrivers(this.user._id, 'active')
      .subscribe(data => {
        if (data) {
          this.drivers = data;
        }
      }, err => {
        sweetAlert('Error', err, 'warning', 'OK');
      });
  }

  getOutstandingTrips() {
    this.assignTripService
      .getOutstandingTripsForBucket({ scheduleTime: this.todayDate })
      .subscribe(data => {
        if (data) {
          this.standingOrders = data;
          this.filterTrips();
        }
      });
  }

  filterTrips() {
    this.suggestedTrips = [];
    this.nonSuggestedTrips = [];
    this.bucketTrips = 0;
    this.standingOrders.forEach(st => {
      if (st._id) {
        this.bucketTrips += st.assigns.length;
        st.assigns.sort(this.compare);
        this.suggestedTrips.push(st);
      } else {
        this.nonSuggestedTrips = st.assigns;
      }
    });

    console.log('Suggested Trips ', this.suggestedTrips);
    console.log('Non-suggested Trips ', this.nonSuggestedTrips);
  }

  onDateChange() {
    this.todayDate = moment.tz(this.todayDate, this.timeZone).startOf('day').toISOString();
    this.getOutstandingTrips();
  }

  brokerList(key?) {
    if (key) {
      return getBrokersList[key];
    }
    return getBrokersList;
  }

  sendToBucket(assign) {
    debugger;
    if (!assign.driver) return;
    let driver = this.drivers.find(d => d._id === assign.driver);

    let tempBucket = {
      _id: { _id: driver._id, displayName: driver.displayName, profileImageURL: driver.profileImageURL, takeMeHome: driver.takeMeHome },
      assigns: [assign]
    };

    let bucket = this.suggestedTrips.find(bucket => bucket._id._id === assign.driver);

    if (bucket) {
      bucket.assigns.push(assign);
      bucket.assigns.sort(this.compare);
    } else {
      this.suggestedTrips.push(tempBucket);
    }

    const payload = {
      selectedTrips: [assign._id],
      status: 'assignoutstandingorder',
      driver: driver
    }

    this.assignTripService
      .assignMultipleTrip(payload)
      .subscribe(data => {
        if (data) {
          let findTripIndex = this.nonSuggestedTrips.findIndex(t => t._id === assign._id);
          this.nonSuggestedTrips.splice(findTripIndex, 1);
        }
      });
  }

  compare(a, b) {
    if (a.scheduleTime < b.scheduleTime) {
      return -1;
    }
    if (a.scheduleTime > b.scheduleTime) {
      return 1;
    }
    return 0;
  }

  assignBucket(bucket, bIndex) {
    if (window.confirm('Are you sure you want to make bucket live?')) {
      this.selectedTrips = bucket.assigns.map(a => a._id);
      const payload = {
        selectedTrips: this.selectedTrips,
        status: 'offered',
        driver: bucket._id,
        note: ''
      }

      this.assignTripService
      .assignMultipleTrip(payload)
      .subscribe(data => {
        if (data) {
          this.suggestedTrips.splice(bIndex, 1);
        }
      });
    }
  }

  getBucketData (bucket, bIndex) {
    console.log(bucket, bIndex);
    this.selectedBucket = bucket;
    this.selectedBucketIndex = bIndex;
  };

  removeFromBucket(assign, bucket, bIndex) {
    if (window.confirm('Are you sure you want to remove?')) {

      // Remove the trip from bucket
      let findTripIndex = bucket.assigns.findIndex(t => t._id === assign._id);
      bucket.assigns.splice(findTripIndex, 1);

      // Remove the bucket if there is no trip left
      if (bucket.assigns.length === 0) {
        this.suggestedTrips.splice(bIndex, 1);
      }

      // Push the trip back to non-suggested trips
      this.nonSuggestedTrips.push(assign);
      this.nonSuggestedTrips.sort(this.compare);
      this.bucketTrips -= 1;

      const payload = {
        selectedTrips: [assign._id],
        status: 'removeoutstandingorder'
      }

      this.assignTripService
      .assignMultipleTrip(payload)
      .subscribe(data => {
        if (data) {
        }
      });
    }
  };

  removeTrip(evt) {
    let trip = evt.trip;
    let bucket = evt.bucket;
    let bIndex = evt.bIndex;
    this.removeFromBucket(trip, bucket, bIndex);
  }
}
