import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { imageProcessing, ImageSnippet } from '../../../utils/image-processing-utils';
import { sweetAlert } from '../../../utils/swal';
import { takeUntil } from 'rxjs/operators';
import {componentDestroyed} from "@w11k/ngx-componentdestroyed";
import { CorporateService } from '../corporate-service/corporate-service';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';

@Component({
  selector: 'app-corporate-add-user',
  templateUrl: './corporate-add-user.component.html',
  styleUrls: ['./corporate-add-user.component.css']
})
export class CorporateAddUserComponent implements OnInit {
  addNewCorporateUser: FormGroup;
  submitted = false;
  btnName = 'Create';
  selectedFile: ImageSnippet = null;
  profileImageURL = null;
  userId = null;
  passwordType = 'password';
  passwordToggleFlag = true;
  user;

  constructor(
    private fb: FormBuilder, 
    private activeRoute: ActivatedRoute,
    private router: Router,
    private corporateService: CorporateService,
    private sharedDataService: SharedDataService
  ) {  }


  ngOnInit() {

    this.addNewCorporateUser = this.fb.group({
      displayName: ['', [Validators.required, Validators.minLength(3)]],
      contactNumber: ['', Validators.required],
      address: ['', Validators.required],
      accountStatus: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(16)]],
      latitude: [{ value: '', disabled: false }],
      longitude: [{ value: '', disabled: false }],
      corporateUserId: [''],
      images: [''],
      company: ['']
    });

    this.getUser()
    this.fillForm();
  }

  getUser() {
    this.sharedDataService
      .getUser()
      .subscribe(user => {
        if (user) {
          this.user = user;
        }
      });
  }

  get form() {
    return this.addNewCorporateUser.controls;
  }
  fillForm() {

    this.addNewCorporateUser.patchValue({
      company: this.user._id
    });

    if (this.activeRoute.snapshot.url.length === 3 && this.activeRoute.snapshot.url[2].path === 'edit') {
      this.userId = this.activeRoute.snapshot.url[1].path;
      this.getCorporateUserById(this.userId);
      this.btnName = 'Update';
    }
  }

  getCorporateUserById(id) {
    this.corporateService
      .getCorporateUserById(id)
      .subscribe(data => {
        if (data) {
          this.addNewCorporateUser.patchValue({ ...data, password: '******' });
          this.profileImageURL = data.profileImageURL;
        }
      });
  }

  processFile(imageInput: any) {
    imageProcessing(imageInput).subscribe((result) => {
      this.selectedFile = result.file;
      this.profileImageURL = result.src;
    });
  }

  getAddress(place) {
    this.addNewCorporateUser.patchValue({
      address: place.formatted_address,
      latitude: place.geometry.location.lat(),
      longitude: place.geometry.location.lng()
    });
  }

  togglePasswordType() {
    this.passwordType = this.passwordToggleFlag ? 'text' : 'password';
    this.passwordToggleFlag = !this.passwordToggleFlag;
  }

  newCorporateUser() {

    this.submitted = true;
    if (this.addNewCorporateUser.invalid) {
      return;
    } else if (this.selectedFile == null && this.profileImageURL == null) {
      return;
    } else {
      const image = {
        newProfilePicture: this.selectedFile
      };
      if (this.btnName === 'Create') {
        this.addNewCorporateUser.patchValue({
          images: image
        });
        this.saveCorporateUser(this.addNewCorporateUser.value);
        // this.store.dispatch(new AddCorporateUser(this.addNewCorporateUser.value));
      } else {

        delete this.addNewCorporateUser.value.password;
        this.addNewCorporateUser.patchValue({
          corporateUserId: this.userId,
          images: image
        });
        this.saveCorporateUser(this.addNewCorporateUser.value);
        // this.store.dispatch(new AddCorporateUser(this.addNewCorporateUser.value));
      }
    }
  }

  saveCorporateUser(payload) {
    this.corporateService
      .addCorporateUser(payload)
      .subscribe(data => {
        if (data) {
          sweetAlert('Success', 'Corporate User Saved Successfully', 'success', 'OK').then((result) => {
            if (result.value) {
              this.submitted = false;
              this.addNewCorporateUser.reset();
              this.router.navigateByUrl('corperate');
            }
          });
        }
      }, err => {
        sweetAlert('Error', err, 'error', 'Ok');
      });
  }
}
