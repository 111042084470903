import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectVehicleExpenseState, VehicleExpenseState } from '../../../ngrx/app.states';
import { VehicleExpenseActionTypes, GetDriverExpense } from '../../../ngrx/store/actions/vehicle-expense.actions';
import { Observable } from 'rxjs';
import { sweetAlert } from 'src/app/utils/swal';
import { AuthService } from '../../../service/auth-service/auth.service';
import { loadMyScript, addDataTableScript, removeDataTableScript } from 'src/app/utils/utils.common';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
import { DriversService } from '../../drivers/drivers-service/drivers-service';

declare var $;
@Component({
  selector: 'app-driver-expenses',
  templateUrl: './driver-expenses.component.html',
  styleUrls: ['./driver-expenses.component.css']
})
export class DriverExpensesComponent implements OnInit, OnDestroy {
  imgPlaceHolder = '../../../assets/images/image-place-holder/image-placeholder.png';
  getVehicleExpenseState: Observable<any>;
  getDriverListState: Observable<any>;
  driverList;
  imageModalUrl = null;
  driverExpenseDetails;
  searchDriver: any;
  searchExpense: any;
  scriptLoadCount = 0;
  selectedUserIndex;
  user;

  constructor(
    private driverService: DriversService,
    private sharedDataService: SharedDataService,
    private store: Store<VehicleExpenseState>
  ) {
    this.getVehicleExpenseState = this.store.select(selectVehicleExpenseState);
  }

  ngOnInit() {
    this.getUser();

    this.getVehicleExpenseState.subscribe((state) => {
      if (state.type === VehicleExpenseActionTypes.GET_DRIVER_EXPENSE) {
        if (state.success) {
          this.driverExpenseDetails = state.response.maintenances;
          if (this.scriptLoadCount > 0) {
            var table = $('#dataTableExample').DataTable();
            table.destroy();
            removeDataTableScript();
          }
          addDataTableScript();
          this.scriptLoadCount++;
          if (this.driverExpenseDetails.length === 0) {
            sweetAlert('Message', 'No maintenance yet, against this driver?', 'warning', 'OK');
          }
        } else if (state.errorMessage) {
          sweetAlert('Error', state.errorMessage, 'error', 'OK');
        }
      }
    });

    this.getDriverList();
  }

  getUser() {
    this.sharedDataService
      .getUser()
      .subscribe(user => {
        if (user) {
          this.user = user;
        }
      });
  }

  getDriverList() {
    this.driverService
      .getDrivers(this.user._id, 'active')
      .subscribe(data => {
        if (data) {
          this.driverList = data;
          // loadMyScript("assets/vendors/core/core.js");
          loadMyScript("assets/js/chat.js");
        }
      }, err => {
        sweetAlert('Error', err, 'error', 'OK');
      });
  }

  fetchExpenses(driver, index) {
    this.selectedUserIndex = index;
    this.store.dispatch(new GetDriverExpense(driver._id));
  }

  getDriverExpenseList() {
    if (this.driverExpenseDetails) {
      if (this.driverExpenseDetails[0] != null && this.driverExpenseDetails[0].vehicleExpenses.length > 0) {
        return this.driverExpenseDetails[0].vehicleExpenses;
      }
    }
    return null;
  }

  openImageModal(url) {
    this.imageModalUrl = url;
  }

  ngOnDestroy(): void {
  }
}
