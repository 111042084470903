import { Component, OnInit } from "@angular/core";
import * as moment from "moment";
import { SharedDataService } from "src/app/service/shared-service/shared-data.service";
import { downloadFile } from "src/app/utils/pdf-downloader";
import { urls } from "src/app/utils/url-utils";
import { DriversService } from "../drivers-service/drivers-service";

@Component({
  selector: "app-driver-invoices",
  templateUrl: "./driver-invoices.component.html",
  styleUrls: ["./driver-invoices.component.css"],
})
export class DriverInvoicesComponent{
  user;
  drivers: any;
  search: any;
  isSelect: boolean = false;
  selectedDriver: any;
  
  

  i = 0;
  j = 0;
  fromDate = moment().startOf('isoWeek');
  toDate = moment().endOf('isoWeek');
  weekNumber = moment().startOf('isoWeek').week();
  trips;
  fareFormat;

  constructor(
    private driversService: DriversService,
    private sharedDataService: SharedDataService
  ) {}

  ngOnInit() {
    this.getUser();
  }

  getUser() {
    this.sharedDataService
      .getUser()
      .subscribe((data) => {
        if (data) {
          this.user = data;
          this.getDrivers();
        }
    });
  }

  getDrivers() {
    this.driversService
      .getDrivers(this.user._id, "active")
      .subscribe((data) => {
        this.drivers = data;
      });
  }
  
  onSelectDriver(driver) {
    this.selectedDriver = driver;
    this.fareFormat = undefined;
  }

  lastWeek() {
    this.i -= 1;
    this.j += 1;
    this.fromDate = moment().subtract(this.j, 'weeks').startOf('isoWeek');
    this.toDate = moment().subtract(this.j, 'weeks').endOf('isoWeek');
    this.weekNumber = moment().subtract(this.j, 'weeks').endOf('isoWeek').week();
  }

  nextWeek() {
    this.i += 1;
    this.j -= 1;
    this.fromDate = moment().add(this.i, 'weeks').startOf('isoWeek');
    this.toDate = moment().add(this.i, 'weeks').endOf('isoWeek');
    this.weekNumber = moment().add(this.i, 'weeks').endOf('isoWeek').week();
  }

  calculate() {
    const payload = {
      startDate: this.fromDate.format('YYYY-MM-DDT00:00:00.000Z'),
      endDate: this.toDate.format('YYYY-MM-DDT00:00:00.000Z'),
      driver: this.selectedDriver._id
    }

    this.driversService
      .getDriverInvoice(payload)
      .subscribe((data: any) => {
        if (data) {
          this.trips = data.earning.trips;
          this.fareFormat = data.fareFormat;
          this.fareFormat = this.fareFormat.map((i) => {
            let x = `$${this.twodigit(i.value || 0)}`.replace('$-', '-$');
            i.value = x;
            return i;
          });
        }
    });


  }

  twodigit(val) {
    return parseFloat(Number(Math.round(parseFloat(val)) + 'e-2').toFixed(2));
  }

  generateReport() {
    const payload = {
      trips: this.trips,
      fareFormat: this.fareFormat,
      driver: this.selectedDriver,
      yesterday: this.fromDate.format('MM/DD/YYYY'),
      today: this.toDate.format('MM/DD/YYYY'),
      weekNumber: this.weekNumber
    }

    this.driversService
      .getDriverInvoiceReport(payload)
      .subscribe((data: any) => {
        if (data) {
          let name = data.pdfName;
          let url = urls.SERVER_URL + '/pdf/' + name;
          downloadFile(url, '', name);
        }
      });
  }
}
