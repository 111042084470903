import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { tap, map, exhaustMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { VehicleExpenseActionTypes, AddVehicleService, GetVehicleServiceList, GetDriverExpense,
    GetMaintenanceStats, CommonSuccess, CommonFailure} from '../actions/vehicle-expense.actions';
import { VehicleExpenseService } from '../../../component/vehicle-expense/vehicle-expense-service/vehicle-expense.service';

@Injectable()
export class VehicleExpenseEffects {
    constructor(
        private actions: Actions,
        private vehicleExpenseService: VehicleExpenseService,
        private router: Router
    ) { } 

    @Effect()
    AddVehicleService: Observable<any> = this.actions.pipe(ofType(VehicleExpenseActionTypes.ADD_VEHICLE_SERVICE),
        map((action: AddVehicleService) => action.payload),
        // Use `exhaustMap` to wait for Observable respond
        exhaustMap((payload) =>
            this.vehicleExpenseService
                .addVehicleServiceWithNgrx(payload)
                .pipe(
                    map((response) => {
                        console.log(response)
                        if (response) {
                            return new CommonSuccess({ type: VehicleExpenseActionTypes.ADD_VEHICLE_SERVICE, response });
                        }  else {
                            return new CommonFailure({ type: VehicleExpenseActionTypes.ADD_VEHICLE_SERVICE, response });
                        }
                    }),
                    catchError((error) => {
                        return of(new CommonFailure({ type: VehicleExpenseActionTypes.ADD_VEHICLE_SERVICE, error }));
                    })
                )
        )
    );

    @Effect()
    GetVehicleServiceList: Observable<any> = this.actions.pipe(ofType(VehicleExpenseActionTypes.GET_VEHICLE_SERVICE_LIST),

        // Use `exhaustMap` to wait for Observable respond
        exhaustMap(() =>
            this.vehicleExpenseService
                .getVehicleServiceWithNgrx()
                .pipe(
                    map((response) => {
                        if (response.success) {
                            return new CommonSuccess({ type: VehicleExpenseActionTypes.GET_VEHICLE_SERVICE_LIST, response });
                        } else {
                            return new CommonFailure({ type: VehicleExpenseActionTypes.GET_VEHICLE_SERVICE_LIST, response });
                        }

                    }),
                    catchError((error) => {
                        return of(new CommonFailure({ type: VehicleExpenseActionTypes.GET_VEHICLE_SERVICE_LIST, error }));
                    })
                )
        )
    );

    @Effect()
    GetDriverExpense: Observable<any> = this.actions.pipe(ofType(VehicleExpenseActionTypes.GET_DRIVER_EXPENSE),
    map((action: GetDriverExpense) => action.payload),
        // Use `exhaustMap` to wait for Observable respond
        exhaustMap((payload) =>
            this.vehicleExpenseService
                .getDriverExpenseWithNgrx(payload)
                .pipe(
                    map((response) => {
                        if (response.success) {
                            return new CommonSuccess({ type: VehicleExpenseActionTypes.GET_DRIVER_EXPENSE, response });
                        } else {
                            return new CommonFailure({ type: VehicleExpenseActionTypes.GET_DRIVER_EXPENSE, response });
                        }

                    }),
                    catchError((error) => {
                        return of(new CommonFailure({ type: VehicleExpenseActionTypes.GET_DRIVER_EXPENSE, error }));
                    })
                )
        )
    );

    @Effect()
    GetMaintenanceStats: Observable<any> = this.actions.pipe(ofType(VehicleExpenseActionTypes.GET_MAINTENANCE_STATS),

        // Use `exhaustMap` to wait for Observable respond
        exhaustMap(() =>
            this.vehicleExpenseService
                .getMaintenanceStatsWithNgrx()
                .pipe(
                    map((response) => {
                        if (response.success) {
                            return new CommonSuccess({ type: VehicleExpenseActionTypes.GET_MAINTENANCE_STATS, response });
                        } else {
                            return new CommonFailure({ type: VehicleExpenseActionTypes.GET_MAINTENANCE_STATS, response });
                        }

                    }),
                    catchError((error) => {
                        return of(new CommonFailure({ type: VehicleExpenseActionTypes.GET_MAINTENANCE_STATS, error }));
                    })
                )
        )
    );

}