import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../../service/auth-service/auth.service';
import { imageProcessing } from '../../../utils/image-processing-utils';
import { sweetAlert } from '../../../utils/swal';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment-timezone';
import { DriversService } from '../drivers-service/drivers-service';

@Component({
  selector: 'app-create-driver',
  templateUrl: './create-driver.component.html',
  styleUrls: ['./create-driver.component.css']
})
export class CreateDriverComponent implements OnInit {
  createDrivierForm: FormGroup;
  submitted = false;
  selectedProfileImageFile = null;
  selectedLicenseImageFile = null;
  profileImageUrl = null;
  LicenseImageUrl = null;
  passwordToggleFlag = true;
  userId = null;
  btnName = 'Create';

  constructor(
    private driverService: DriversService,
    private fb: FormBuilder, 
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    // this.getDriverState = this.store.select(selectDriversState);
  }


  ngOnInit() {
    this.createDrivierForm = this.fb.group({
      legalName: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      contactNumber: ['', Validators.required],
      address: ['', Validators.required],
      accountStatus: ['', Validators.required],
      email: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(16)]],
      driverLicenseNumber: ['', Validators.required],
      profileRole: ['', Validators.required],
      licenseRegistrationExpiry: ['', Validators.required],
      latitude: [{ value: '', disabled: false }],
      longitude: [{ value: '', disabled: false }],
      images: [''],
      driverId: [''],
      licenseRegistrationExpiryDate: ['']

    });

    this.fillData();
  }

  fillData() {
    if (this.activatedRoute.snapshot.url.length === 3 && this.activatedRoute.snapshot.url[2].path === 'edit') {
      this.userId = this.activatedRoute.snapshot.url[1].path;

      this.driverService
        .getDriverProfile(this.userId)
        .subscribe(data => {
          if (data) {
            this.createDrivierForm.patchValue({
              ...data,
              licenseRegistrationExpiryDate: data.licenseRegistrationExpiry,
              password: '******'
            });
            this.profileImageUrl = data.profileImageURL;
            this.LicenseImageUrl = data.driverLicenseImage;
          }
        });
      // this.getDriverState
      //   .pipe(takeUntil(componentDestroyed(this)))
      //   .subscribe((state) => {
      //     if (state.type === DriversActionTypes.DRIVER_PROFILE_DATA) {
      //       if (state.success) {
      //         this.createDrivierForm.patchValue({
      //           ...state.response,
      //           licenseRegistrationExpiryDate: state.response.licenseRegistrationExpiry,
      //           password: '******'
      //         });
      //         this.profileImageUrl = state.response.profileImageURL;
      //         this.LicenseImageUrl = state.response.driverLicenseImage;
      //       }
      //     }
      //   });
      this.btnName = 'Update';
    }
  }

  get form() {
    return this.createDrivierForm.controls;
  }

  processLicenseFile(ImageInput: any) {
    imageProcessing(ImageInput).subscribe((result) => {

      this.selectedLicenseImageFile = result.file;
      this.LicenseImageUrl = result.src;

    });
  }

  processProfileFile(ImageInput: any) {
    imageProcessing(ImageInput).subscribe((result) => {

      this.selectedProfileImageFile = result.file;
      this.profileImageUrl = result.src;
    });
  }

  getAddress(place) {
    this.createDrivierForm.patchValue({
      address: place.formatted_address,
      latitude: place.geometry.location.lat(),
      longitude: place.geometry.location.lng()
    });
  }

  togglePasswordType() {
    this.passwordToggleFlag = !this.passwordToggleFlag;
  }

  getAccountStatus() {
    return this.authService.accountStatus;
  }

  getProfileRole() {
    return this.authService.driverComponentProfileRoles;
  }

  onCreateDriver() {
    console.log(this.createDrivierForm.value);
    this.submitted = true;

    if (this.createDrivierForm.invalid) {
      return;
    }
    // tslint:disable-next-line: max-line-length
    if ((this.selectedProfileImageFile == null && this.profileImageUrl == null)
    || (this.selectedLicenseImageFile == null && this.LicenseImageUrl == null)) {
      return;
    }
    const image = {
      driverLicenseImage: this.selectedLicenseImageFile,
      profileImageURL: this.selectedProfileImageFile
    };
    this.createDrivierForm.patchValue({
      images: image,
      driverId: this.userId,
    });
    delete this.createDrivierForm.value.licenseRegistrationExpiryDate;
    if (this.btnName === 'Create') {
      this.saveDriver(this.createDrivierForm.value);
    } else {
      delete this.createDrivierForm.value.password;
      this.saveDriver(this.createDrivierForm.value);
    }
  }

  saveDriver(payload) {
    this.driverService
      .saveDriver(payload)
      .subscribe(data => {
        if (data) {
          sweetAlert('Success', `Driver ${payload.driverId ? 'Updated' : 'Added'} Succesfully`, 'success', 'Ok')
            .then(() => {
              this.submitted = false;
              if (payload.driverId) {
                this.router.navigateByUrl('/drivers/' + data._id + '/profile');
              } else {
                this.router.navigateByUrl('/drivers/active');
              }
            });
        }
      });
  }

  fillDummyData() {
    this.createDrivierForm.patchValue({
      legalName: 'Jhon Doe',
      firstName: 'Jhon',
      lastName: 'Doe',
      contactNumber: '123456789',
      accountStatus: 'active',
      email: 'JhonDoe@Test.com',
      password: '123456789',
      driverLicenseNumber: '12345',
      profileRole: 'driver'
    });
  }

  clearData() {
    this.createDrivierForm.reset();
  }

  onDateChange(event) {
    // to convert License Registration Expiry date into ISO string from moment object
    if (event) {
      this.createDrivierForm.patchValue({
        licenseRegistrationExpiry: moment(event).toISOString()
      });
    }

  }
}
