import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';

@Component({
  selector: "app-trip-adjustment",
  templateUrl: "./trip-adjustment.component.html",
  styleUrls: ["./trip-adjustment.component.css"],
})
export class TripAdjustmentComponent implements OnInit {
  
  constructor(private fb:FormBuilder) {}

  ngOnInit() {
    
  }
  tripAdjustmentForm = this.fb.group({

  
    companyNote: [''],
    companyType: [''],
    jobCarType: ['Standard', Validators.required],
    jobDestinationAddress: ['', Validators.required],
    jobDestinationLatitude: [0, Validators.required],
    jobDestinationLongitude: [0, Validators.required],
    jobOriginAddress: ['', Validators.required],
    jobOriginLatitude: [0, Validators.required],
    jobOriginLongitude: [0, Validators.required],
    jobPassengerNumber: [1, Validators.required],
    isMedical: [false],
    iSschUrgentCare: [''],
    isUrgentCare: [''],
    isWheelChair: [''],
    isCarSeat: [''],
    
   
  });
 
}