import { OnDestroy } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { takeUntil } from 'rxjs/operators';
import { OfferedTripsState, selectOfferedTripsState } from 'src/app/ngrx/app.states';
import { GetDashboardCount, OfferedTripsActionTypes } from 'src/app/ngrx/store/actions/offered-trips-actions';
import { sweetAlert } from 'src/app/utils/swal';

@Component({
  selector: 'app-dashboard-offered',
  templateUrl: './dashboard-offered.component.html',
  styleUrls: ['./dashboard-offered.component.css']
})
export class DashboardOfferedComponent implements OnInit, OnDestroy  {

  getCounterObserver; 
  couterData;


  constructor(  private store: Store<OfferedTripsState>,
    private activatedRoute: ActivatedRoute,) { 
      this.getCounterObserver = this.store.select(selectOfferedTripsState);
    }
  ngOnDestroy(): void {
   
  }

  ngOnInit() {

    this.getCounterObserver
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe((state) => {
      if (state.type === OfferedTripsActionTypes.GET_DASHBOARD_COUNT) { 
        if (state.success) {
          this.couterData = state.response;
        } else if (state.errorMessage) {
          sweetAlert('Error', state.errorMessage, 'warning', 'OK');
        }
      }
    });

    this.getCounter();
  }
  getCounter() {
    this.store.dispatch(new GetDashboardCount());
  }

}
