import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { AssignTripsState, selectAssignTripsState } from '../../../ngrx/app.states';
import { AssignTripsActionTypes } from '../../../ngrx/store/actions/assigntrips-actions';
import { Observable, from } from 'rxjs';
import { sweetAlert } from '../../../utils/swal';
import { ActivatedRoute, Router } from '@angular/router';
import { getBrokersList, loadMyScript } from '../../../utils/utils.common';
import { takeUntil } from 'rxjs/operators';
import * as _ from 'underscore';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import * as moment from 'moment-timezone';
import { SocketService } from 'src/app/service/socket-service/socket-service.service';
import { Events } from 'src/app/utils/event-utils';
import { addDataTableScript, removeDataTableScript } from 'src/app/utils/utils.common';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
import { AssigTripsService } from '../assigntrips-service/assigntrips-service';
import { DispatchCenterService } from '../../dispatch-center/dispatch-center-service/dispatch-center-service';
import { downloadFile } from 'src/app/utils/pdf-downloader';
import { urls } from 'src/app/utils/url-utils';
declare var $;
@Component({
  selector: 'app-unassigned',
  templateUrl: './unassigned.component.html',
  styleUrls: ['./unassigned.component.css']
})
export class UnassignedComponent implements OnInit, OnDestroy {
  getListObserver: Observable<any>;
  dataList = [];
  filterdataList = [];
  todayDate ;
  status = 'unassigned';
  isShowAllTrips = false;
  search: any = '';
  selectedBroker = '';
  assignModalObj;
  componentType;
  tripIndex = -1;
  requestType = null;
  imageModalUrl;
  selectedTripsId = [];
  marketPlaceFilter = '';
  medicalFilter = '';
  corporateFilter = '';
  sweepTripsList = null;
  timeZone;
  settingInterval;
  secondaryCompType= null;
  isGridView = true;
  scriptLoadCount = 0;
  specialRate;
  user;
  cancelTripModalObj;
  tripKey;
  selectedTrip;
  billerNoteModalObj;
  additionalNoteModalObj;
  cancelCompletedModalObj
  selectedTrips = [];

  isMedical = false;
  isCorporate = false;
  isMarketplace = false;
  orderBy = 'scheduleTime';

  constructor(
    private assignTripService: AssigTripsService,
    private dispatchCenterService: DispatchCenterService,
    private sharedDataService: SharedDataService,
    private store: Store<AssignTripsState>,
    private socketService: SocketService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {
    this.getListObserver = this.store.select(selectAssignTripsState);
  }

  ngOnInit() {
    if (this.activatedRoute.snapshot.url.length === 2) {
      this.status = this.activatedRoute.snapshot.url[1].path;
    }

    this.getUser();
    this.getTrips();
    this.listenSocket();
    
    this.getListObserver
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((state) => {
        if (state.type === AssignTripsActionTypes.REMOVE_TRIP) {
          if (state.success && this.status !== 'all') {
            this.filterdataList.splice(this.tripIndex, 1);
            this.tripIndex = -1;
          } else if (state.errorMessage) {
            this.tripIndex = -1;
          }

        } else if (state.type === AssignTripsActionTypes.UPDATE_TRIP_STATUS) {
          if (state.success) {
            let tempInd = -1;
            _.find(this.dataList, (item, i) => {
              if (item._id === this.filterdataList[this.tripIndex]._id) {
                tempInd = i;
                return i;
              }
            });
            this.filterdataList[this.tripIndex] = state.response;
            this.dataList[tempInd] = state.response;
            if (this.filterdataList[this.tripIndex].status === 'archived'  && this.status !== 'all') {
              this.filterdataList.splice(this.tripIndex, 1);
            }
            this.tripIndex = -1;
            tempInd = -1;
          } else if (state.errorMessage) {
            this.tripIndex = -1;
          }

        } else if (state.type === AssignTripsActionTypes.ASSIGN_TRIP) {
          console.log('here')
          if (state.success  && this.status !== 'all') {
            let tempInd = -1;
            _.find(this.dataList, (item, i) => {
              if (item._id === this.filterdataList[this.tripIndex]._id) {
                tempInd = i;
                return i;
              }
            });
            this.filterdataList[this.tripIndex] = state.response;
            this.dataList[tempInd] = state.response;
            if (this.filterdataList[this.tripIndex].status === 'offered') {
              this.filterdataList.splice(this.tripIndex, 1);
            }
            this.tripIndex = -1;
            tempInd = -1;
          } else if (state.errorMessage) {
            this.tripIndex = -1;
          }

        } else if (state.type === AssignTripsActionTypes.ASSIGN_MULTIPLE_TRIPS) {
          if (state.success) {
            // tslint:disable-next-line: forin
            for (const x in this.selectedTripsId) {
              console.log(x)
              console.log(this.filterdataList)
              _.find(this.filterdataList, (item, i) => {
                console.log(item)
                if (item._id === this.selectedTripsId[x]) {
                  console.log(this.filterdataList.length)
                  this.filterdataList.splice(i, 1);
                  console.log(this.filterdataList.length)

                  return item;
                }
              });
            }
            this.selectedTripsId = [];
            this.tripIndex = -1;
          } else if (state.errorMessage) {
            this.tripIndex = -1;
          }

        }
      });
  }

  getUser() {
    this.sharedDataService
      .getUser()
      .subscribe(data => {
        if (data) {
          this.timeZone = data.timeZone;
          this.user = data;
          this.todayDate = moment.tz(this.timeZone).startOf('day').toISOString();
        }
      });
  }

  getTrips() {
    this.dataList = [];
    const object = {
      isShowAllTrips: this.isShowAllTrips,
      status: this.status,
      date: this.todayDate
    };
    // this.store.dispatch(new GetUnassignedList(object));
    this.assignTripService
      .getAssignList(object)
      .subscribe(data => {
        if (data) {
          this.selectedBroker = '';
          this.search = '';
          this.dataList = data;
          this.filterdataList = this.dataList;
          // this.addButtonFilter(null)
          if (this.scriptLoadCount > 0) {
            var table = $('#dataTableExample').DataTable();
            table.destroy();
            removeDataTableScript();
          }
          addDataTableScript();
          this.scriptLoadCount++; 
        }
      }, err => {
        sweetAlert('Error', err, 'warning', 'OK');
      });
  }

  listenSocket() {
    this.socketService.addListener(Events.UPDATE_ASSIGN_TRIP)
    .subscribe(data => {
      if (data.success) {
        sweetAlert('Success', data.message, 'success', 'OK');
        this.dataList.filter((item) => {
          if (data.assign._id === item._id) {
            item.isReadyForPickup = data.assign.isReadyForPickup;
            item.isVipTrip = data.assign.isVipTrip;
            item.isConfirmedTrip = data.assign.isConfirmedTrip;
            item.isVoiceMail = data.assign.isVoiceMail;
          }
        });
        this.filterdataList.filter((item) => {
          if (data.assign._id === item._id) {
            item.isReadyForPickup = data.assign.isReadyForPickup;
            item.isVipTrip = data.assign.isVipTrip;
            item.isConfirmedTrip = data.assign.isConfirmedTrip;
            item.isVoiceMail = data.assign.isVoiceMail;
          }
        });
      } else {
        sweetAlert('Error', data.message, 'warning', 'OK');
      }
    });

    this.socketService.addListener(Events.GET_DRIVER_ESTIMATED_FARE)
    .subscribe(data => {
      console.log(data);
      if (data.success) {
          const fares = data.fares;
          var message = `Company Fare: $${fares.brokerRate || fares.customerSpecialRate} and Driver Fare: $${fares.driverRate || 0}`;
          if (window.confirm('Are you sure you want to update? ' + message)) {
            const payload = {driverFareDetail: { jobFee: fares.driverRate }}
            this.updateDriverFare(payload);
          }
        
      } else {
        sweetAlert('Error', data.message, 'warning', 'OK');
      }
    });
  }

  unassignTrip(id, ind) {
    this.tripIndex = ind;
    this.requestType = 'Un Assign';
  }

  onTripUnassigned(event) {
    if (event) {
      this.filterdataList.splice(this.tripIndex, 1);
    }
  }

  onDateChange(event) {
    this.todayDate = event.toISOString();
    this.getTrips();
  }

  setTripFilter(tripType) {
    if (tripType === 'marketplace') {
      this.isMarketplace = !this.isMarketplace;
    } else if (tripType === 'medical') {
      this.isMedical = !this.isMedical;
    } else if (tripType === 'corporate') {
      this.isCorporate = !this.isCorporate;
    }

    this.filterdataList = this.dataList.filter(trip => {
      let checks = [];
      let v = true;

      if (this.isMarketplace) {
        trip.jobStatus === 'marketplaced' ? checks.push(true) : checks.push(false);
      }

      if (this.isMedical) {
        trip.isMedical ? checks.push(true) : checks.push(false);
      }

      if (this.isCorporate) {
        trip.jobType === 'cooperate' ? checks.push(true) : checks.push(false)
      }

      checks.forEach(c => {
        if (!c) {
          v = false;
          return;
        }
      });

      return v;
    }); 
  }

  claimFile(file) {
    var tripIds = this.filterdataList.map(x => x._id);
    if (this.selectedTrips.length) {
      tripIds = this.selectedTrips;
    }
    var payload = { 
      companyType: this.selectedBroker, 
      // tempVin: vm.tempVin,
      status: this.status, 
      _ids: tripIds, 
      scheduleTime: this.todayDate,
      // companyImage: vm.companyImage 
    };
    if (!payload.companyType) {
      delete payload.companyType;
    }

    if (file === 'generateExcelFile') {
      this.assignTripService
        .generateTripsReport(payload)
        .subscribe(data => {
          if (data) {
            let name = data.excelName;
            let url = urls.SERVER_URL + '/excel/' + name;
            downloadFile(url, '', name);
          }
        });
    } else if (file === 'generateItineraryFile') {
      this.assignTripService
        .generateTripsIntineraryReport(payload)
        .subscribe(data => {
          if (data) {
            let name = data.excelName;
            let url = urls.SERVER_URL + '/excel/' + name;
            downloadFile(url, '', name);
          }
        });
    }
  }

  group() {
    if (this.filterdataList && this.filterdataList.length) {
      this.orderBy = (this.orderBy === 'scheduleTime') ? 'priorityClient.displayName' : 'scheduleTime';
      if (this.orderBy === 'priorityClient.displayName') {
        this.filterdataList.sort((a, b) => a.priorityClient.displayName.localeCompare(b.priorityClient.displayName)); // ASC
      } else {
        this.filterdataList.sort((a, b) => <any>new Date(a.scheduleTime) - <any>new Date(b.scheduleTime));
      }
    }
  }

  addButtonFilter(event) {
    if (event.type && event.type.match('click')) {
      if (event.toElement.outerText === 'Marketplace') {
        this.marketPlaceFilter = this.marketPlaceFilter.length > 0 ? '' : event.toElement.outerText;
      } else if (event.toElement.outerText === 'Medical Trips') {
        this.medicalFilter = this.medicalFilter.length > 0 ? '' : event.toElement.outerText;
      } else if (event.toElement.outerText === 'Corporate') {
        this.corporateFilter = this.corporateFilter.length > 0 ? '' : event.toElement.outerText;
      }
    }

    if (typeof Worker !== 'undefined') {
      // Create a new
      const worker = new Worker('../../../app.worker.ts', { type: 'module' });
      // tslint:disable-next-line: no-shadowed-variable
      worker.onmessage = (event) => {
        this.filterdataList = event.data.list;
        this.unSelectAllTrips();
      };

      setTimeout(() => {
        // tslint:disable-next-line: max-line-length
        worker.postMessage({ list: this.dataList, broker: this.selectedBroker, marketPlaceFilter: this.marketPlaceFilter, medicalFilter: this.medicalFilter, corporateFilter: this.corporateFilter, search: this.search });
      }, 1000);
    } else {
      // Web Workers are not supported in this environment.
      // You should add a fallback so that your program still executes correctly.
      console.log('Web Worker are not supported');
    }
  }

  showAllTrips() {
    this.isShowAllTrips = !this.isShowAllTrips;
    this.getTrips();
  }

  brokerList(key?) {
    if (key) {
      return getBrokersList[key];
    }
    return getBrokersList;
  }

  setModalContent(tripDetail, type, ind?) {
    this.secondaryCompType = null;
    if (ind >= 0) {
      this.tripIndex = ind;
    }
    this.assignModalObj = tripDetail;
    if (type === 'companyNote') {
      this.componentType = type;
    } else if (type === 'dsr') {
      this.specialRate = JSON.parse(JSON.stringify(this.assignModalObj));
      this.componentType = type;
    } else if (type === 'Multiple Assign') {
      this.componentType = type;
      this.secondaryCompType = this.status;
    } else {
      if (this.status === 'hanging') {
        this.componentType = 'hanging';
      } else if (this.status === 'cancelled') {
        this.componentType = 'cancelled';
      } else if (this.status === 'archived') {
        this.componentType = 'archived';
      } else if (this.status === 'all') {
        this.componentType = 'all';
      } else {
        this.componentType = type;
      }
    }
    console.log(this.componentType)
    console.log(this.assignModalObj)
  }

  tripAssignedResponse(tripStatus: string): void {
    if (tripStatus === 'Trip Assigned' || tripStatus === 'Trip Un Assigned' || tripStatus === 'All Trips Assigned') {
      sweetAlert('Succcess', tripStatus, 'success', 'OK');
    } else if (tripStatus !== null) {
      sweetAlert('Error', tripStatus, 'error', 'OK');
    }
    this.assignModalObj = null;
    this.getTrips();
  }

  verifiedCancelled(trip, ind) {
    const conf = confirm('Are you sure you want to marked this trip');
    if (conf) {
      this.tripIndex = ind;
      const data = {
        isVerifiedCancelled: !trip.isVerifiedCancelled
      };
      // this.store.dispatch(new VerifiedCancelledTrip(data, trip._id));
      this.assignTripService
        .verifiedCancelTrip(data, trip._id)
        .subscribe(data => {
          if (data) {
            let tempInd = -1;
            _.find(this.dataList, (item, i) => {
              if (item._id === this.filterdataList[this.tripIndex]._id) {
                tempInd = i;
                return i;
              }
            });
            this.filterdataList[this.tripIndex].isVerifiedCancelled = !this.filterdataList[this.tripIndex].isVerifiedCancelled;
            // this.dataList[tempInd].isVerifiedCancelled = !this.dataList[tempInd].isVerifiedCancelled;
            console.log(this.filterdataList[this.tripIndex])
            tempInd = -1;
          }
          this.tripIndex = -1;
        }, err => {
          this.tripIndex = -1;
        });
    }
  }

  openImageModal(url) {
    this.imageModalUrl = url;
  }

  // Select/Un-Select All & Select Single Trip
  onSelectTrip(trip) {
    const index = this.selectedTrips.indexOf(trip._id);
    if (index > -1) {
      trip.isSelected = false;
      this.selectedTrips.splice(index, 1);
    } else {
      trip.isSelected = true;
      this.selectedTrips.push(trip._id);
    }
  }

  selectAllTrips() {
    if (this.selectedTrips.length === this.filterdataList.length) {
      this.unSelectAllTrips();
    } else {
      this.selectedTrips = [];
      this.filterdataList.forEach(assign => {
        if (assign.status === 'cancelled' && assign.job && assign.job._id) {
          return;
        }
        if (assign.issues && assign.issues.length) {
          return;
        }
        assign.isSelected = true;
        this.selectedTrips.push(assign._id);
      });
    }
  }

  unSelectAllTrips() {
    this.selectedTrips = [];
    this.filterdataList.forEach(trip => {
      trip.isSelected = false;
    });
  }

  sendConfirmationText() {
    if (window.confirm('Are you sure you want to send confirmation SMS to members?')) {
      const payload = { selectedTrips: this.selectedTrips };
      this.assignTripService
        .sendConfirmationText(payload)
        .subscribe(data => {
          if (data) {
            sweetAlert('Succcess', 'Confirmation text sent to member successfully', 'success', 'OK');
          }
        });
    }
  }

  adjustTrips() {
    const payload = { selectedTrips: this.selectedTrips };
    this.assignTripService
      .autoTripAdjustment(payload)
      .subscribe(data => {
        if (data) {
          sweetAlert('Succcess', 'Trips adjusted successfully', 'success', 'OK');
        }
      }, err => {
        // this.clearSelection();
      });
  }

  getParseTripList() {
    this.sweepTripsList = [
      {
        _id: '1',
        total: '',
        scraps: [
          {
            _id: '',
            rawId: '',
            created: '',
            type: '',
            company: '',
            jobs: ''
          }
        ]
      }
    ]
    // this.store.dispatch(new GetSweepTripsList());
    this.assignTripService
      .getSweepTripsList()
      .subscribe(data => {
        if (data) {
          this.sweepTripsList = data;
        }
      }, err => {
        sweetAlert('Error', err, 'error', 'OK');
      });
  }

  addAccidentReport(jobId, driverId, fleetId) {
    this.router.navigate(['/accidents/add'], { state: { jobId, driverId, fleetId } });
  }

  toggleView() {
    this.isGridView = !this.isGridView;

    if (!this.isGridView) {
      var table = $('#dataTableExample').DataTable();
      table.destroy();
      removeDataTableScript();
      addDataTableScript();
    }
      
  }

  // **************** ACTIONS ON TRIP ****************
  // Send To Marketplace
  sendToMarketPlace(assign) {
    const jobStatus = assign.jobStatus;
    const status = assign.status;
    assign.jobStatus = 'marketplaced';
    assign.status = 'unassigned';
    if (window.confirm("Are you sure you want to send this trip to marketplace?")) {
      this.assignTripService
        .sendToMarketplace(assign)
        .subscribe(data => {
          if (data) {
            sweetAlert('Success', 'Trip Sent to Marketplace Successfully', 'success', 'OK');
          }
        }, err => {
          assign.jobStatus = jobStatus;
          assign.status = status;
          sweetAlert('Error', 'Operation Failed', 'error', 'OK');
        });
    }
  }

  // Archive Trip
  archiveTrip(assign, index) {
    if (window.confirm("Are you sure you want to archive?")) {
      assign.status = 'archived';
      this.assignTripService
        .updateTrip(assign)
        .subscribe(data => {
          if (data) {
            this.filterdataList.splice(index, 1);
          }
        });
    }
  }

  // Unarchive Trip
  unArchivedTrip(data, ind) {
    this.tripIndex = ind;
    this.requestType = 'Un Assign';
  }

  // Add Biller Note
  addBillerNote(trip, ind) {
    // this.tripIndex = ind;
    this.billerNoteModalObj = trip;
  }

  onBillerNoteAdded(event) {
    if (event) {
      sweetAlert('Succcess', 'Biller Note Added Successfully', 'success', 'OK');
    }
    // this.assignTripService
    //   .addBillerNote(event)
    //   .subscribe(data => {
    //     if (data) {
    //       let tempInd = -1;
    //         _.find(this.dataList, (item, i) => {
    //           if (item._id === this.filterdataList[this.tripIndex]._id) {
    //             tempInd = i;
    //             return i;
    //           }
    //         });
    //         this.filterdataList[this.tripIndex] = this.assignModalObj;
    //         this.dataList[tempInd] = this.assignModalObj;
    //         this.tripIndex = -1;
    //         tempInd = -1;
    //         this.assignModalObj = null;
    //     }
    //   }, err => {
    //     this.tripIndex = -1;
    //     sweetAlert('Error', err, 'error', 'OK');
    //   });
  }

  // Add Additional Note
  addAdditionalNote(trip) {
    this.additionalNoteModalObj = trip;
  }

  onAdditionalNoteAdded(evt) {
    if (evt) {
      sweetAlert('Succcess', 'Additional Note Added Successfully', 'success', 'OK');
    }
  }

  // Add Special Rates
  addSpecialRates(data) {
    this.assignTripService
      .addSpecialRate(data)
      .subscribe(data => {
        if (data) {
          this.dataList.forEach((item, i) => {
            if (item._id === data._id) {
              this.dataList[i] = data;
            }
          });
          this.filterdataList.forEach((item, i) => {
            if (item._id === data._id) {
              this.filterdataList[i] = data;
            }
          });
          sweetAlert('Succcess', 'Trip Special Rate Added Successfully', 'success', 'OK');
        }
      }, err => {
        sweetAlert('Error', err, 'warning', 'OK');
      });
  }

  // Calculate Fare
  calculateFare(assign) {
    this.assignTripService
      .calculateFare(assign._id)
      .subscribe(data => {
        if (data) {
          assign.estimatedSpecialRate = data.totalFare || '';
        }
      }, err => {
          sweetAlert('Error', err, 'error', 'OK');
      });
  }

  // Add Special Rate
  addSpecialRate() {   
    this.addSpecialRates(this.specialRate);
    $('#dsrModal .close').click();
  }

  // Update Job - Mark Ready, Mark VIP, Mark Confirm, Mark Voicemail
  updateJob(assign, assignKey) {
    console.log(assign, assignKey);
    let params = {
      assignId: assign._id
    }
    params[assignKey] = !assign[assignKey];
    this.socketService.emit(Events.UPDATE_ASSIGN_TRIP, params);
  }

  // Clone Trip
  cloneTrip(id) {
    const isConfirmed = confirm('Are you sure you want to clone this trip');
    if (isConfirmed) {
      this.assignTripService
        .cloneTrip(id)
        .subscribe(data => {
          if (data) {
            sweetAlert('Succcess', 'Trip Cloned Successfully', 'success', 'OK');
          }
        }, err => {
          sweetAlert('Error', err, 'error', 'OK');
        });
    }
  }

  // Remove Trip
  cancelTrip(trip) {
    this.cancelTripModalObj = trip;
    console.log(this.cancelTripModalObj);
  }

  onCancelTrip(evt) {
    console.log(evt);
    if (evt) {
      this.cancelTripModalObj = undefined;
      this.filterdataList.splice(this.tripIndex, 1);
    }
  }

  getCompanyDriverEstimatedFare(assign) {
    this.selectedTrip = assign;
    const params = {
      assignId: assign._id,
      driverId: assign.driver._id
    }
    this.socketService.emit(Events.GET_DRIVER_ESTIMATED_FARE, params);
  }

  updateDriverFare(payload) {
    this.dispatchCenterService
      .saveDriverFare(this.selectedTrip.job._id, payload)
      .subscribe(data => {
        if (data) {
          this.filterdataList.forEach(trip => {
            if (trip._id === this.selectedTrip._id) {
              trip.job.driverFareDetail.jobFee = data.driverFareDetail.jobFee;
            }
          });
          this.selectedTrip = null;
          sweetAlert('Success', 'Fare updated successfully', 'success', 'OK');
        }
      });
  }

  isStringExist(substrings, str) {
    if (substrings.some(v => str.toLowerCase().includes(v))) {
      return true;
    }
    return false;
  }

  rejectRide(assign, index) {
    if (window.confirm('Are you sure you want to re-route this ride?')) {
      assign.status = 'returned';
      this.assignTripService
        .assignTrip(assign)
        .subscribe(data => {
          if (data) {
            sweetAlert('Success', 'Ride Re-routed Successfully', 'success', 'OK');
          }
        });
    }
  }

  markCompletedTrip(assign) {
    if (window.confirm('Are you sure you want to complete this trip?')) {
      this.assignTripService
        .markCompletedTrip(assign)
        .subscribe(data => {
          if (data) {
            let findTripIndex = this.filterdataList.findIndex(t => t._id === assign._id);
            this.filterdataList.splice(findTripIndex, 1);
            sweetAlert('Success', 'Trip marked completed successfully', 'success', 'OK');
          }
        });
    }
  }

  cancelCompleted(assign, index) {
    this.cancelCompletedModalObj = assign;
  }

  onCancelCompleted(evt) {
  }

  markDvRequired(assign, index) {
    console.log(assign);
    this.assignTripService
      .updateTrip(assign)
      .subscribe(data => {
        if (data) {
          sweetAlert('Success', `${assign.isDvRequired ? 'Marked' : 'Un-Marked'} DV Required Successfully`, 'success', 'OK');
        }
      });
  }

  isPaidTrip(assign, index) {
    console.log(assign);
    this.assignTripService
      .updateTrip(assign)
      .subscribe(data => {
        if (data) {
          sweetAlert('Success', `${assign.takeSignaturesOnly ? 'Marked' : 'Un-Marked'} Is Paid Successfully`, 'success', 'OK');
        }
      });
  }

  ngOnDestroy(): void {
    clearInterval(this.settingInterval);
    this.socketService.removeListener(Events.UPDATE_ASSIGN_TRIP);
  }
}
