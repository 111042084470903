import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { SharedDataService } from "src/app/service/shared-service/shared-data.service";
import { DriversService } from "../drivers-service/drivers-service";
import * as moment from 'moment-timezone';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { downloadBase64File } from "src/app/utils/pdf-downloader";

@Component({
  selector: "app-driver-timelines",
  templateUrl: "./driver-timelines.component.html",
  styleUrls: ["./driver-timelines.component.css"],
})
export class DriverTimelinesComponent implements OnInit {
  user;
  search;
  drivers;
  selectedDriver;
  timeline;
  timelines;
  view = 'today';
  isShowBreakPopup = false;
  currentDateTime = moment().startOf('day');
  preDateRange = [
    moment().startOf('day'),
    moment().endOf('day')
  ]
  dateRange = [
    moment().startOf('day'),
    moment().endOf('day')
  ];
  break;

  // dates = {
  //   startDate: moment().startOf('day'),
  //   endDate: moment().endOf('day')
  // };
  timeZone;

  isHistoryData: boolean = false;
  isTodayData: boolean = false;
  isSelectedDriver: boolean = false;

  isBreakSubmitted = false;
  isTimelineSubmitted = false;

  @ViewChild('btnCloseTimelineModal', null) btnClose: ElementRef;

  constructor(
    private driverService: DriversService,
    private sharedDataService: SharedDataService
  ) {}

  ngOnInit() {
    this.getUser();
  }

  getUser() {
    this.sharedDataService
      .getUser()
      .subscribe((user) => {
        if (user) {
          this.user = user;
          this.timeZone = this.user.timeZone;
          this.getDrivers();
        }
    });
  }

  getDrivers() {
    this.driverService
      .getDrivers(this.user._id, "active")
      .subscribe((data) => {
        if (data) {
          this.drivers = data;
        }
    });
  }

  setView(view) {
    this.view = view;
    this.getDriverTimelines();
  }

  onSelectDriver(driver) {
    this.selectedDriver = driver;
    this.getDriverTimelines();
  }

  getDriverTimelines() {
    if (!this.selectedDriver) return;
    let queryString = {
      driver: this.selectedDriver._id
    };

    if (this.view === 'history') {
      // queryString['dates'] = {"startDate":"2023-01-09T05:00:00.000Z","endDate":"2023-01-10T04:59:59.999Z"};
      queryString['dates'] = {"startDate":this.dateRange[0],"endDate":this.dateRange[1]};
    }

    this.driverService
      .getDriverTimelines(queryString)
      .subscribe(data => {
        if (data) {
          if (this.view === 'today') {
            this.timeline = data;
          } else if (this.view === 'history') {
            this.timelines = {};
            if (data.length) {
              this.timelines = data[0];
            }
          }
        } else {
          this.timeline = undefined;
          this.timelines = undefined;
        }
      });
  }

  showBreakPopup() {
    this.isShowBreakPopup = !this.isShowBreakPopup;
    if (this.isShowBreakPopup) {
      this.isBreakSubmitted = false;
      this.break = undefined;
    }
  }

  viewHourFormat(milli) {
    let duration = moment.duration(milli, 'milliseconds');
    return `${Math.floor(duration.asHours())}hr ${duration.minutes()}mins`;
  }

  onChangeDate(evt) {
    console.log(evt);
    // moment().tz(this.timeZone).format()
    this.dateRange = [evt[0].tz(this.timeZone).toISOString(), evt[1].tz(this.timeZone).toISOString()];
    console.log(this.dateRange);
    this.getDriverTimelines();
  }

  selectDuration(value) {
    this.break = value;
  }

  // saveBreak() {
  //   this.isBreakSubmitted = true;
  //   if (!this.break) return;
  // }

  saveBreak() {
    this.isBreakSubmitted = true;
    if (!this.break) return;
    
    if (this.break === 15) {
      this.action('approved', { value: 15 })
    } else {
      this.action('open');
    }
  }

  action(status, data?) {
    console.log(status);
    let payload: any = {
      driver: this.selectedDriver._id,
      currDriver: {
        _id: this.selectedDriver._id,
        deviceKey: this.selectedDriver._id.deviceKey,
        deviceType: this.selectedDriver._id.deviceType
      },
      status: status,
      break: {
        status: status,
        label: 'Your break request is ' + status
      }
    };

    if (status === 'none') {
      payload['checkin'] = moment().toISOString();
      delete payload.break;
    }

    if (status === 'approved') {
      let start = moment();
      let stop = moment().add(data.value, 'minutes');
      let duration = moment.duration(stop.diff(start));
      console.log('Duration : ::', duration._milliseconds)
      payload.break.duration = duration._milliseconds;
      payload.break.value = data.value;
      payload.break.start = start.toISOString();
      payload.break.stop = stop.toISOString();
    }

    if (status === 'open') {
      payload.break.label = 'Back office has sent you on a break';
      payload.break.value = -1;
      payload.break.start = moment().toISOString();
    }

    if (status === 'close') {
      let start = moment(data.start);
      let stop = moment();
      let value = parseInt(stop.diff(start, 'minutes'), 10)
      payload.status = 'approved';
      payload.break = {
        source: 'dispatcher',
        label: 'The back office has ended your break',
        status: 'approved',
        start: data.start,
        stop: stop.toISOString(),
        value: value,
        duration: moment.duration(stop.diff(start))._milliseconds
      }
    }

    if (status === 'completed') {
      let start = moment(this.timeline.checkin);
      let stop = moment();
      let duration = moment.duration(stop.diff(start));
      let breakTimeInMillis = 0;
      let isBreak = false;
      let lastBreakTimeEnd;
      this.timeline.breaks.forEach((b) => {
        isBreak = false;
        if (b.status === 'approved') {
          lastBreakTimeEnd = moment(b.stop);
          if (moment.duration(lastBreakTimeEnd.diff(stop)).asSeconds() > 1) {
            isBreak = true;
          }
          breakTimeInMillis += b.duration;
        }
      });
      if (isBreak) {
        return alert('Driver is already on a break. Break ends in ' + lastBreakTimeEnd.format('HHmm'));
      }
      payload.checkin = start;
      payload.breakTimeInMillis = breakTimeInMillis || 0;
      payload.workTimeInMillis = duration._milliseconds - breakTimeInMillis || 0;
      payload.checkout = stop;
      delete payload.break;
    }
    this.sendRequest(payload);
  }

  sendRequest(payload) {
    console.log(payload);
    if (window.confirm('Are you sure you want to proceed?')) {
      this.driverService
        .saveDriverBreak(payload)
        .subscribe(data => {
          if (data) {
            this.break = undefined;
            this.isShowBreakPopup = false;
            this.timeline = data;
          }
        });
    }
  }

  viewDetail(currentTimeline) {
    this.timeline = undefined;
    this.driverService
      .getDriverTimelineById(currentTimeline._id)
      .subscribe(data => {
        if (data) {
          this.timeline = data;
        }
      });
  }

  editDetail(currentTimeline) {
    this.timeline = undefined;
    this.isTimelineSubmitted = false;
    this.driverService
      .getDriverTimelineById(currentTimeline._id)
      .subscribe(data => {
        if (data) {
          this.timeline = data;
        }
      });
  }

  saveTimeline() {
    this.isTimelineSubmitted = true;
    if (!this.timeline.checkin || !this.timeline.checkout) {
      return;
    }

    let start = moment(this.timeline.checkin);
    let stop = moment(this.timeline.checkout);
    let duration = moment.duration(stop.diff(start));
    let breakTimeInMillis = 0;
    console.group('--- Timeline Group --- ');
    console.log('Save timeline :: ', start, stop, duration._milliseconds, breakTimeInMillis);
    // console.groupEnd('--- End Timeline Group --- ');
    let isBreak = false;
    let lastBreakTimeEnd;
    if (this.timeline && this.timeline.breaks) {
      this.timeline.breaks.forEach(function (b) {
        isBreak = false;
        if (b.status === 'approved') {
          lastBreakTimeEnd = moment(b.stop);
          if (moment.duration(lastBreakTimeEnd.diff(stop)).asSeconds() > 1) {
            isBreak = true;
          }
          let lastBreakTimeStart = moment(b.start);
          let duration = moment.duration(lastBreakTimeEnd.diff(lastBreakTimeStart));
          b.duration = duration._milliseconds;
          breakTimeInMillis += b.duration;
        }
      });
    }
    if (isBreak) {
      return alert('Driver is already on a break. Break ends in ' + lastBreakTimeEnd.format('HHmm'));
    }
    this.timeline.breakTimeInMillis = breakTimeInMillis || 0;
    this.timeline.workTimeInMillis = duration._milliseconds - breakTimeInMillis || 0;
    this.timeline.checkin = start;
    this.timeline.checkout = stop;
    this.timeline.status = 'completed';
    if (this.timeline._id) {
      this.saveTimelineRequest(this.timeline);
    } else {
      this.timeline.driver = this.selectedDriver._id;
      this.saveTimelineRequest(this.timeline);
    }
  }

  saveTimelineRequest(payload) {
    this.driverService
      .saveDriverTimeline(payload)
      .subscribe(data => {
        this.timeline = data;
        if (this.timelines) {
          this.btnClose.nativeElement.click();
          this.getDriverTimelines();
        }
      });
  }

  addTimeSheet() {
    this.isTimelineSubmitted = false;
    this.timeline = {
      checkin: null,
      checkout: null
    };
  }

  generateReport(type?) {
    let queryString = {};
    queryString['dates'] = {"startDate":this.dateRange[0],"endDate":this.dateRange[1]};
    if (type === 'driver') {
      queryString['driver'] = this.selectedDriver._id;
    }
    this.driverService
      .getDriverTimelinesReport(queryString)
      .subscribe(data => {
        if (data && data.length) {
          const res = data[0];
          if (res.content && res.pdfName) {
            downloadBase64File(res.content, res.pdfName);
          }
        }
      });
  }
  
}
