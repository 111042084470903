import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  NgZone,
  OnDestroy,
  AfterViewInit,
} from "@angular/core";
import { AgmMap, ControlPosition, MapsAPILoader, MapTypeControlOptions, MouseEvent } from "@agm/core";
import { Events } from "src/app/utils/event-utils";
import { SocketService } from "src/app/service/socket-service/socket-service.service";
import {
  DispatchCenterState,
  selectDispatchCenterState,
} from "../../../ngrx/app.states";
import {
  DispatchCenterActionTypes,
  GetUnassignedTripDetail,
} from "../../../ngrx/store/actions/dispatch-center-actions";
import { Observable } from "rxjs";
import { sweetAlert } from "../../../utils/swal";
import { Store } from "@ngrx/store";
import { takeUntil } from "rxjs/operators";
import { componentDestroyed } from "@w11k/ngx-componentdestroyed";
import { ActivatedRoute } from "@angular/router";
import * as moment from "moment-timezone";
import { AuthService } from "src/app/service/auth-service/auth.service";
import {
  AssignTripsState,
  selectAssignTripsState,
} from "../../../ngrx/app.states";
import {
  AssignTripsActionTypes,
  GetUnassignedList,
} from "../../../ngrx/store/actions/assigntrips-actions";
import { SharedDataService } from "src/app/service/shared-service/shared-data.service";
import { DispatchCenterService } from "../dispatch-center-service/dispatch-center-service";
import { AssigTripsService } from "../../assigned-trips/assigntrips-service/assigntrips-service";
import { mapStyles } from "src/app/utils/utils.common";

declare var $;
@Component({
  selector: "app-main",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
})
export class DispatchDashboardComponent implements OnInit, AfterViewInit, OnDestroy {
  // Map
  @ViewChild("AgmMap", { static: true }) agmElement: AgmMap;
  mapTypeControlOptions: MapTypeControlOptions = {
    position: ControlPosition.TOP_LEFT
  };
  agmMap;
  origin = { lat: 0.0, lng: 0.0 };
  destination = { lat: 0.0, lng: 0.0 };
  latitude: number;
  longitude: number;
  zoom: number = 12;
  styles = mapStyles();
  driverSearch = "";
  notificationSearch = "";
  
  address: string;
  private geoCoder;
  public tripModalObject: any;
  public assignModalObj: any;
  public driverTripModalObject: any;
  live = true;
  isFilter = false;

  public drivers = [];
  public approachingTrips = [];
  public expiringSoonTrips = [];
  public markedTrips = [];
  notifications = [];
  companyDriverStatus;
  tripId = null;
  reDispatchTripDetail;
  selectedDriver;

  timeZone;
  bounds;
  tripsInProgress;
  tripsInProgressObserver;
  selectedApproachingTripOption;
  @ViewChild("search", { static: false })
  public searchElementRef: ElementRef;
  user;

  onlineDrivers = 0;
  busyDrivers = 0;
  offlineDrivers = 0;

  isInprogressRoutes = false;
  isMarkedReadyRoutes = false;
  isExpiredTripRoutes = false;
  isMarkedReadyTrips = false;
  isExpiredTrips = false;
  isApproachingTrips = false;
  isAutoZoom = true;

  openPopup = false;
  showCreateTripModal = true;
  showInprogressTripsSlideout = false;

  constructor(
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private socketService: SocketService,
    private activatedRoute: ActivatedRoute,
    private sharedDataService: SharedDataService,
    private dispatchCenterService: DispatchCenterService,
    private assignTripService: AssigTripsService
  ) {
    this.getUser();
  }

  ngOnInit() {
    if (window.history.state.popup) {
      this.reDispatchTripDetail = null;
      this.openPopup = window.history.state.popup;
    } else if (this.activatedRoute.snapshot.url.length === 3) {
      this.openPopup = false;
      this.tripId = this.activatedRoute.snapshot.url[2].path;
      this.reDispatchCall(this.tripId);
    } else {
      this.reDispatchTripDetail = null;
      this.openPopup = true;
    }

    if (false) {
      // load Places Autocomplete
      this.mapsAPILoader.load().then(() => {
        this.setCurrentLocation();
        this.geoCoder = new google.maps.Geocoder();

        const autocomplete = new google.maps.places.Autocomplete(
          this.searchElementRef.nativeElement,
          {
            types: ["address"],
          }
        );
        autocomplete.addListener("place_changed", () => {
          this.ngZone.run(() => {
            // get the place result
            const place: google.maps.places.PlaceResult = autocomplete.getPlace();

            // verify result
            if (place.geometry === undefined || place.geometry === null) {
              return;
            }

            // set latitude, longitude and zoom
            this.latitude = place.geometry.location.lat();
            this.longitude = place.geometry.location.lng();
            this.zoom = 12;
          });
        });
      });
    }

    this.getNotificationsList();
    this.emitSocketEvents();
    this.listenSocketEvents();
    this.setSocketEventsInterval();
    this.getTripsInProgress();
    this.getDispatchApproachingTrips(3);
  }

  ngAfterViewInit() {
    this.agmElement.mapReady
      .subscribe((map) => {
        this.agmMap = map;
      });
  }

  getUser() {
    this.sharedDataService
      .getUser()
      .subscribe(user => {
        if (user) {
          this.user = user;
          this.timeZone = this.user.timeZone;
        }
      });
  }

  getNotificationsList() {
    this.dispatchCenterService
      .getNotifications()
      .subscribe(data => {
        if (data) {
          this.notifications = data;
          console.log('TEST100: Notifications', this.notifications);
        }
      });
  }

  // Drivers Counts
  setDriverCounts() {
    this.onlineDrivers = 0;
    this.busyDrivers = 0;
    this.offlineDrivers = 0;

    this.drivers.forEach((driver) => {
      if (driver.status === "online") {
        this.onlineDrivers++;
      } else if (driver.status === "buzy") {
        this.busyDrivers++;
      } else if (driver.status === "offline") {
        this.offlineDrivers++;
      }
    });

  }

  getTripsInProgress() {
    const object = {
      isShowAllTrips: true,
      status: "inprogress",
    };
    this.assignTripService
      .getAssignList(object)
      .subscribe(data => {
        if (data && data.length) {
          this.tripsInProgress = data;
        }
      });
  }

  // Map Methods
  setMapZoom() {
    if (!this.isAutoZoom) return;

    if (this.agmMap) {
      let bounds = new google.maps.LatLngBounds();
      const markedReadyTrips = this.expiringSoonTrips.concat(this.markedTrips);
      console.log('TEST100: Marked Ready', markedReadyTrips);

      for (const driver of this.drivers) {
        bounds.extend(
          new window["google"].maps.LatLng(
            driver.latitude ? driver.latitude : 0,
            driver.longitude ? driver.longitude : 0
          )
        );
      }

      for (const trip of markedReadyTrips) {
        bounds.extend(
          new window["google"].maps.LatLng(
            trip.jobOriginLatitude,
            trip.jobOriginLongitude
          )
        );
      }

      this.agmMap.setCenter(bounds.getCenter());
      this.agmMap.fitBounds(bounds);
    }
  }

  private setCurrentLocation() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 8;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }

  setBounds() {
    if (this.isAutoZoom) {
      this.bounds = new window["google"].maps.LatLngBounds();
      const bundsTrip = this.expiringSoonTrips.concat(this.markedTrips);
      for (let item of bundsTrip) {
        this.bounds.extend(
          new window["google"].maps.LatLng(
            item.jobOriginLatitude,
            item.jobOriginLongitude
          )
        );
      }
      for (let driver of this.drivers) {
        this.bounds.extend(
          new window["google"].maps.LatLng(driver.latitude, driver.longitude)
        );
      }
    }
  }

  markerDragEnd($event: MouseEvent) {
    console.log($event);
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.getAddress(this.latitude, this.longitude);
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode(
      { location: { lat: latitude, lng: longitude } },
      (results, status) => {
        console.log(results);
        console.log(status);
        if (status === "OK") {
          if (results[0]) {
            this.zoom = 12;
            this.address = results[0].formatted_address;
          } else {
            window.alert("No results found");
          }
        } else {
          window.alert("Geocoder failed due to: " + status);
        }
      }
    );
  }

  // Socket Events STARTS
  getDispatchApproachingTrips(hour) {
    this.selectedApproachingTripOption = hour;
    this.socketService.emit(Events.GET_APPROCHING_TRIPS, { hours: hour });
  }

  setSocketEventsInterval() {
    this.companyDriverStatus = setInterval(() => {
      this.emitSocketEvents();
      this.getTripsInProgress();
    }, 7000);
  }

  emitSocketEvents() {
    this.socketService.emit(Events.GET_COMPANY_DRIVER_STATUS);

    // Expired Trips
    if (this.isExpiredTrips) {
      this.socketService.emit(Events.SOON_EXPIRING_TRIPS);
    } else {
      this.expiringSoonTrips = [];
    }

    // Marked Ready Trips
    if (this.isMarkedReadyTrips) {
      this.socketService.emit(Events.GET_MARKED_TRIPS);
    } else {
      this.markedTrips = [];
    }
  }

  listenSocketEvents() {
    this.socketService
      .addListener(Events.GET_COMPANY_DRIVER_STATUS)
      .subscribe((data) => {
        if (data.success) {
          this.drivers = [];
          this.drivers.push(...data.drivers);
          this.setDriverCounts();
          this.setMapZoom();
        } else {
          this.drivers = [];
        }
      });

    this.socketService
      .addListener(Events.SOON_EXPIRING_TRIPS)
      .subscribe((data) => {
        if (data.success) {
          if (this.isExpiredTrips) {
            this.expiringSoonTrips = data.assigns;
            // this.setBounds();
            this.setMapZoom();
          } else {
            this.expiringSoonTrips = [];
          }
        } else {
          this.expiringSoonTrips = [];
        }
      });

    this.socketService
      .addListener(Events.GET_MARKED_TRIPS)
      .subscribe((data) => {
        if (data.success) {
          if (this.isMarkedReadyTrips) {
            this.markedTrips = data.assigns;
            // this.setBounds();
            this.setMapZoom()
          } else {
            this.markedTrips = [];
          }
        } else {
          this.markedTrips = [];
        }
      });

    this.socketService
      .addListener(Events.GET_APPROCHING_TRIPS)
      .subscribe((data) => {
        if (data.success) {
          this.approachingTrips = [];
          this.approachingTrips.push(...data.assigns);
        } else {
          this.approachingTrips = [];
        }
      });

    this.socketService
      .addListener(Events.SEND_EXPIRE_DISPATCH)
      .subscribe((data) => {
        if (data.sucess) {
          this.getNotificationsList();
        }
      });
  }
  // Socket Events END

  // Modals
  openCreateTripModal(driver) {
    if (driver.status !== "online") {
      return;
    }
    this.selectedDriver = driver;
    this.openPopup = true;
    $("#createNewTripModal").modal("show");
  }

  openTripDetailModal(object) {
    this.assignModalObj = object;
    $("#assignModal").modal("show");
  }

  openDriverTripModal(object) {
    this.driverTripModalObject = object;
  }

  viewCamera(data) {
  }

  tripAssignedResponse(tripStatus: string): void {
    if (
      tripStatus === "Trip Assigned" ||
      tripStatus === "Trip Un Assigned" ||
      tripStatus === "All Trips Assigned"
    ) {
      sweetAlert("Succcess", tripStatus, "success", "OK");
    } else if (tripStatus !== null) {
      sweetAlert("Error", tripStatus, "error", "OK");
    }
    this.assignModalObj = null;
  }

  reDispatchCall(id) {
    this.dispatchCenterService
      .getUnassignedTripDetail(id)
      .subscribe(data => {
        if (data) {
          this.reDispatchTripDetail = data;
          if (this.reDispatchTripDetail) {
            this.openPopup = true;
          }
        }
      });
  }

  onCreateButtonClick() {
    this.selectedDriver = null;
    this.openPopup = true;
  }
  // Modals Code ENDS

  // Utils Methods
  getFloatingValues(point) {
    console.log(point);
    return parseFloat(point)
  }

  expiringSoonTime(trip) {
    console.log('Expire trips ');
    const now = moment.utc(new Date()).local().format();
    const expiringSoonTime = moment(trip.scheduleTime);
    return expiringSoonTime.diff(now, "minutes");
  }

  getNameChars(assign) {
    if (assign && assign.priorityClient && assign.priorityClient.displayName) {
      return assign.priorityClient.displayName
        .toLowerCase()
        .trim()
        .split(" ")[0];
    }
  }

  // Below are the unused methods
  getBuzyDrivers() {
    let busyDriver = 0;
    this.drivers.forEach((element) => {
      if (element.status === "buzy") {
        busyDriver++;
      }
    });
    return busyDriver;
  }

  getOfflineDrivers() {
    let busyDriver = 0;
    this.drivers.forEach((element) => {
      if (element.status === "offline") {
        busyDriver++;
      }
    });
    return busyDriver;
  }

  getAvailableDrivers() {
    let busyDriver = 0;
    this.drivers.forEach((element) => {
      if (element.status === "online") {
        busyDriver++;
      }
    });
    return busyDriver;
  }

  selectedDriverStatus(status) {
    if (this.driverSearch === status) {
      this.driverSearch = "";
    } else {
      this.driverSearch = status;
    }
  }

  onAutoZoomClick() {
    this.isAutoZoom = !this.isAutoZoom;
  }

  onExpiredTripsClick(event) {

    this.isExpiredTrips = event.target.checked;
  }

  onExpiredTripRoutesClick(event) {
    this.isExpiredTripRoutes = event.target.checked;
  }

  onMarkedReadyTripsClick(event) {
    this.isMarkedReadyTrips = event.target.checked;
  }

  onMarkedReadyRoutesClick(event) {
    this.isMarkedReadyRoutes = event.target.checked;
    console.log(this.markedTrips.length);
  }

  onInprogressRoutesClick(event) {
    console.log(event.target.checked, this.tripsInProgress);
    this.isInprogressRoutes = event.target.checked;
  }

  showFilter() {
    this.isFilter = !this.isFilter;
  }

  ngOnDestroy(): void {
    clearInterval(this.companyDriverStatus);
    this.socketService.removeListener(Events.GET_APPROCHING_TRIPS);
    this.socketService.removeListener(Events.GET_COMPANY_DRIVER_STATUS);
    this.socketService.removeListener(Events.SEND_EXPIRE_DISPATCH);
    this.socketService.removeListener(Events.GET_MARKED_TRIPS);
    this.socketService.removeListener(Events.SOON_EXPIRING_TRIPS);
  }
}
