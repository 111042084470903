import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { getBrokersList } from '../../../utils/utils.common';
import { AssignTripsState, selectAssignTripsState } from '../../../ngrx/app.states';
import {
  AssignTripsActionTypes,
  ViewUnparsedTripsDetail,
  RemvoveSweepTrips,
  SendTripListEmail
} from '../../../ngrx/store/actions/assigntrips-actions';
import { Observable, from } from 'rxjs';
import { sweetAlert } from '../../../utils/swal';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { Store } from '@ngrx/store';
import { downloadFile } from 'src/app/utils/pdf-downloader';
import { AssigTripsService } from '../assigntrips-service/assigntrips-service';
declare var $;
@Component({
  selector: 'app-sweep-trips-modal',
  templateUrl: './sweep-trips-modal.component.html',
  styleUrls: ['./sweep-trips-modal.component.css']
})
export class SweepTripsModalComponent implements OnInit, OnDestroy {

  @Input() object;
  selectedBroker = null;
  getListObserver: Observable<any>;
  unparsedData;
  // sendEmailAndDownloadModal = false;
  removedTripsList = { created: null };
  constructor(private store: Store<AssignTripsState>,
    private assignTripService: AssigTripsService) {
    this.getListObserver = this.store.select(selectAssignTripsState);
  }

  ngOnInit() {
  }

  getBrokerName(name) {
    return getBrokersList[name];
  }

  selectBroker(brokerType) {
    this.selectedBroker = brokerType;
    console.log(this.selectedBroker);
  }

  viewUnparsedTrips(rawId) {
    this.unparsedData = null;
    this.store.dispatch(new ViewUnparsedTripsDetail({ rawId }));
  }

  sortMethod(a, b) {
    console.log(a, b);
    return new Date(a.value.created) < new Date(b.value.created) ? 1 : -1;
  }

  unParsedData() {
    let unparsedData = '';
    // tslint:disable-next-line: forin
    for (const data in this.unparsedData) {
      unparsedData = unparsedData + this.unparsedData[data] + ',';
    }
    return unparsedData;

  }

  generateFile(data) {
    // this.sendEmailAndDownloadModal = false;
    this.assignTripService
      .removeSweepTrips(data)
      .subscribe(data => {
        console.log(data);
        this.selectedBroker = null;
        this.removedTripsList = data.archive ? data.archive : null;
        console.log(this.removedTripsList);
        $('#sendEmailAndDownload').modal('show');
            // this.sendEmailAndDownloadModal = state.response.archive ? true : false;
      })
  }

  sendMail(tripList) {
    this.assignTripService
      .sendTripListEmail(tripList)
      .subscribe(data => {
        if (data) {
          sweetAlert('Success', 'Email Sent', 'success', 'OK');
        }
      }, err => {
        sweetAlert('Error', err, 'error', 'OK');
      });
  }
  download(tripList) {
    let url = 'https://qalbs.com/';
    const fileExtention = 'xlsx';
    const fileName = tripList.name;
    url = url + 'excel/' + fileName;
    downloadFile(url, fileExtention, fileName);
    // sweetAlert('Success', 'File Downloaded', 'success', 'OK');
  }
  ngOnDestroy(): void {
  }

}
