
import {
    AuthActionTypes,
    All
} from '../actions/auth.actions';

export interface State {
    // is a user authenticated?
    isAuthenticated: boolean;
    // jwt token
    token: string;
    // if authenticated, there should be a user object
    user: null;
    // error message
    errorMessage: string | null;
    success: boolean;
    type: string | null;
}

export const initialState: State = {
    isAuthenticated: false,
    token: null,
    user: null,
    errorMessage: null,
    success: false,
    type: null
};
export function reducer(state = initialState, action: All): State {
    switch (action.type) {
        // case AuthActionTypes.LOGIN_SUCCESS: {
        //     return {
        //         ...state,
        //         isAuthenticated: true,
        //         token: action.payload.token,
        //         user: action.payload.user,
        //         errorMessage: null,
        //         success: true,
        //         type: action.payload.type
        //     };
        // }
        case AuthActionTypes.LOGOUT: {
            return initialState;
        }
        case AuthActionTypes.LOGIN_SUCCESS_WITH_VERIFY_TOKEN: {
            return {
                ...state,
                isAuthenticated: true,
                token: action.payload.token,
                user: action.payload.user,
                errorMessage: null,
                success: true,
                type: action.payload.type
            };
        }
        case AuthActionTypes.FORGET_PASSWORD_SUCCESS: {
            return {
                ...state,
                isAuthenticated: false,
                token: null,
                user: null,
                errorMessage: action.payload.user.message,
                success: true,
                type: action.payload.type
            };
        }

        case AuthActionTypes.COMMON_FAILURE: {
            return {
                ...state,
                isAuthenticated: false,
                token: null,
                user: null,
                errorMessage: action.payload.error ? action.payload.error : action.payload.user.message,
                success: false,
                type: action.payload.type
            };
        }

        default: {

            const newState = JSON.parse(localStorage.getItem('token'));
            if (newState != null) {
                return {
                    ...state,
                    token: newState,
                    errorMessage: null,
                    type: 'default'

                };

            } else {
                return {
                    ...initialState,
                    errorMessage: null,
                    type: 'default'
                };
            }
        }
    }
}

