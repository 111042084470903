import * as auth from './store/reducers/auth.reducers';
import * as distribution from './store/reducers/distribution-reducers';
import * as dispatchCenter from './store/reducers/dispatch-center-reducers';
import { createFeatureSelector } from '@ngrx/store';
import * as vehicleExpense from './store/reducers/vehicle-expense.reducers';
import * as planner from './store/reducers/planner.reducers';
import * as assignTrips from './store/reducers/assign-reducers';
import * as offeredTrips from './store/reducers/offered-trips-reducers';
import * as kpis from './store/reducers/kpis.reducers';
import * as report from './store/reducers/reports.reducers';
import * as accidentReprot from './store/reducers/accident-report.reducers';
export interface AppState {
    authState: auth.State;

}

export interface DistributionState {
    distributionState: distribution.State;
}

export interface DispatchCenterState {
    dispatchCenterState: dispatchCenter.State;
}

export interface VehicleExpenseState {
    vehicleExpenseState: vehicleExpense.State;
}
export interface PlannerState {
    plannerState: planner.State;
}
export interface AssignTripsState {
    assignTripsState: assignTrips.State;
}
export interface OfferedTripsState {
    offeredTripsState: offeredTrips.State;
}

export interface KpisState {
    kpisState: kpis.State;
}

export interface ReportState {
    reportState: report.State;
}
export interface AccidentReportState {
    accidentReportState: accidentReprot.State;
}
export const reducers = {
    auth: auth.reducer,
    dispatchCenter: dispatchCenter.reducer,
    distribution : distribution.reducer,
    vehicleExpense: vehicleExpense.reducer,
    planner: planner.reducer,
    assignTrips: assignTrips.reducer,
    offeredTrips: offeredTrips.reducer,
    kpis: kpis.reducer,
    report: report.reducer,
    accidentReprot: accidentReprot.reducer
};

export const selectAuthState = createFeatureSelector<AppState>('auth');
export const selectDispatchCenterState = createFeatureSelector<DispatchCenterState>('dispatchCenter');
export const selectDistributionState = createFeatureSelector<DistributionState>('distribution');
export const selectVehicleExpenseState = createFeatureSelector<VehicleExpenseState>('vehicleExpense');
export const selectPlannerState = createFeatureSelector<PlannerState>('planner');
export const selectAssignTripsState = createFeatureSelector<AssignTripsState>('assignTrips');
export const selectOfferedTripsState = createFeatureSelector<OfferedTripsState>('offeredTrips');
export const selectKpisState = createFeatureSelector<KpisState>('kpis');
export const selectReportState = createFeatureSelector<ReportState>('report');
export const selectAccidentReportState = createFeatureSelector<AccidentReportState>('accidentReprot');
