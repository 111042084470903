import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { tap, map, exhaustMap, catchError } from 'rxjs/operators';
import { AuthService } from '../../../service/auth-service/auth.service';

import { of } from 'rxjs';
import {
    AuthActionTypes,
    ForgetPassword, LogOut,
    CommonFailure, ForgetPasswordSuccess, LoginSuccessWithVerifyToken
} from '../actions/auth.actions';
import { Observable } from 'rxjs';
import { SocketService } from 'src/app/service/socket-service/socket-service.service';



@Injectable()
export class AuthEffects {

    constructor(
        private actions: Actions,
        private authService: AuthService,
        private router: Router,
        private socket : SocketService
    ) { }



    // login request
    // @Effect()
    // LogIn: Observable<any> = this.actions.pipe(ofType(AuthActionTypes.LOGIN),
    //     map((action: LogIn) => action.payload),
    //     // Use `exhaustMap` to wait for Observable respond
    //     exhaustMap((payload) =>
    //         this.authService
    //             .login(payload.email, payload.password, payload.roles)
    //             .pipe(
    //                 map((user) => {
    //                     if (user.success) {
    //                         return new LogInSuccess({ type: AuthActionTypes.LOGIN, token: user.token, user: user.user });
    //                     } else {
    //                         return new CommonFailure({ type: AuthActionTypes.LOGIN, user });
    //                     }

    //                 }),
    //                 catchError((error) => {
    //                     return of(new CommonFailure({ type: AuthActionTypes.LOGIN, error }));
    //                 })
    //             )
    //     )
    // );

    // when login request goes successfull
    // @Effect({ dispatch: false })
    // LogInSuccess: Observable<any> = this.actions
    //     .pipe(
    //         ofType(AuthActionTypes.LOGIN_SUCCESS),
    //         tap((user) => {
    //             this.authService.setToken(user.payload.token);
    //             this.socket.connectSocket();
    //             this.router.navigateByUrl('/');
    //         })
    //     );

    // removing token from local storage
    @Effect({ dispatch: false })
    public LogOut: Observable<any> = this.actions.pipe(
        ofType(AuthActionTypes.LOGOUT),
        tap((user) => {
            this.socket.disconnectSocket();
            of(this.authService.removeToken());
            this.router.navigateByUrl('signIn');
        })
    );

    // when login request fails
    @Effect({ dispatch: false })
    LogInFailure: Observable<any> = this.actions.pipe(
        ofType(AuthActionTypes.COMMON_FAILURE),
        tap((data) => {
            this.socket.disconnectSocket();
            of(this.authService.removeToken());
            this.router.navigateByUrl('signIn');
        })
    );

    // request user object if null and token is not null
    @Effect()
    VerifyToken: Observable<any> = this.actions.pipe(ofType(AuthActionTypes.VERIFY_TOKEN),

        // Use `exhaustMap` to wait for Observable respond
        exhaustMap(() =>

            this.authService
                .VerifyToken()
                .pipe(
                    map((user) => {
                        if (user.user != null) {
                            return new LoginSuccessWithVerifyToken({
                                type: AuthActionTypes.VERIFY_TOKEN,
                                user: user.user, token: this.authService.getToken('token')
                            });
                        } else {
                            return new CommonFailure({ type: AuthActionTypes.VERIFY_TOKEN, user });
                        }

                    }),
                    catchError((error) => {
                        return of(new CommonFailure({ type: AuthActionTypes.VERIFY_TOKEN, error }));
                    })
                )
        )
    );

    // login request
    @Effect()
    ForgetPassword: Observable<any> = this.actions.pipe(ofType(AuthActionTypes.FORGET_PASSWORD),
        map((action: ForgetPassword) => action.payload),
        // Use `exhaustMap` to wait for Observable respond
        exhaustMap((payload) =>
            this.authService
                .forgetPasswordRequestwithNgx(payload.email)
                .pipe(
                    map((user) => {
                        if (user.success) {
                            return new ForgetPasswordSuccess({ type: AuthActionTypes.FORGET_PASSWORD, user });
                        } else {
                            return new CommonFailure({ type: AuthActionTypes.FORGET_PASSWORD, user });
                        }

                    }),
                    catchError((error) => {
                        return of(new CommonFailure({ type: AuthActionTypes.FORGET_PASSWORD, error }));
                    })
                )
        )
    );
}
