import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { sweetAlert } from '../../../utils/swal';
import { DriversService } from '../drivers-service/drivers-service';
@Component({
  selector: 'app-driver-profile-dashboard',
  templateUrl: './driver-profile-dashboard.component.html',
  styleUrls: ['./driver-profile-dashboard.component.css']
})
export class DriverProfileDashboardComponent implements OnInit {
  driverDetails = null;
  password = null;
  imageModalUrl = null;
  path = null;

  constructor(
    private driverService: DriversService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.getDriverProfileDashboard();
  }

  getDriverProfileDashboard() {
    this.path = this.activatedRoute.snapshot.url[1].path;
    // this.store.dispatch(new DriverProfileData(this.path));
    this.driverService
      .getDriverProfile(this.path)
      .subscribe(data => {
        if (data) {
          this.driverDetails = data;
        }
      }, err => {
        sweetAlert('Error', err, 'error', 'Ok')
          .then(() => {
            this.router.navigateByUrl('/drivers/active');
          });
      });
  }

  generatePassword() {
    this.password = (new Date().getTime()).toString(36).substring(0, 9);
  }

  resetPassword() {
    // tslint:disable-next-line: max-line-length
    sweetAlert('Are you sure you want to Reset Password?', 'An Email with updated password will be send to driver\'s email.', 'warning', 'Yes', 'No')
      .then((result) => {
        if (result.value) {
          const newPassData = {
            driverId: this.activatedRoute.snapshot.url[1].path,
            newPassword: this.password
          };
          this.password = null;
          this.driverService
            .resetDriverPassword(newPassData)
            .subscribe(data => {
              if (data) {
                sweetAlert('Success', data.message, 'success', 'Ok');
              }
            }, err => {
              sweetAlert('Error', err, 'error', 'Ok');
            });
        } else {
          this.password = null;
        }
      });
  }

  openImageModal(url) {
    this.imageModalUrl = url;
  }
}
