import { Component, OnInit } from '@angular/core';
import { OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { takeUntil } from 'rxjs/operators';
import { OfferedTripsState, selectOfferedTripsState } from 'src/app/ngrx/app.states';
import {  ChangeProviderStatus, GetTripsList, OfferedTripsActionTypes } from 'src/app/ngrx/store/actions/offered-trips-actions';
import { sweetAlert } from 'src/app/utils/swal';

@Component({
  selector: 'app-trips',
  templateUrl: './trips.component.html',
  styleUrls: ['./trips.component.css']
})
export class TripsComponent implements OnInit, OnDestroy  {

  getTripsObserver; 
  tripsList;
  assignModalObj;
  status = 'offered';
  search;
  constructor( private store: Store<OfferedTripsState>) { 
      this.getTripsObserver = this.store.select(selectOfferedTripsState);
    }
  ngOnDestroy(): void {
   
  }


  ngOnInit() {

    this.getTripsObserver
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe((state) => {
      console.log(state.success)
      if (state.type === OfferedTripsActionTypes.GET_TRIPS_LIST) {
        if (state.success) {
          this.tripsList = state.response;
        } else if (state.errorMessage) {
          sweetAlert('Error', state.errorMessage, 'warning', 'OK');
        }
      }else if (state.type === OfferedTripsActionTypes.CHANGE_PROVIDER_STATUS) {
        if (state.success) {
          this.getTrips();
        } else if (state.errorMessage) {
          sweetAlert('Error', state.errorMessage, 'warning', 'OK');
        }
      }
      
    });

    this.getTrips();
  }
  getTrips() {
    const payload = {
      status:this.status
    };
    this.store.dispatch(new GetTripsList(payload));
  }

  setModalContent(tripDetail) {
    this.assignModalObj = tripDetail;
  }

  onAcceptClick(trip) {
    console.log(trip);
    sweetAlert('Alert','Are you sure you want to accepted??','warning','Yes','No')
    .then((result) => {
      if(result.value){
        const payload = {
          ...trip,
          providerStatus: "accepted"
        };
        this.store.dispatch(new ChangeProviderStatus(payload));
      }
      else if(result.dismiss){
        close();
      }
    });
  }


  onRejectClick(trip) {
    sweetAlert('Alert','Are you sure you want to rejected?','warning','Yes','No')
    .then((result) => {
      if(result.value){
        const payload = {
       ...trip,
       providerStatus: "rejected"
        };
        this.store.dispatch(new ChangeProviderStatus(payload));
      }
      else if(result.dismiss){
        close();
      }
    });
  }
  onChangeStatusClick(statusName) {
    this.status = statusName;
    this.tripsList =[];
    this.  getTrips() ;
  }

  
}
