import { sweetAlert } from "src/app/utils/swal";
import { Observable } from "rxjs";
import { Component, OnInit, OnDestroy } from "@angular/core";
import {
  ScrapFile,
  PlannerActionTypes,
} from "../../../ngrx/store/actions/planner-actions";
import { Store } from "@ngrx/store";
import { selectPlannerState, PlannerState } from "../../../ngrx/app.states";
import { getBrokersList } from "../../../utils/utils.common";
import * as _ from "underscore";
import { componentDestroyed } from "@w11k/ngx-componentdestroyed";
import { takeUntil } from "rxjs/operators";
import { Router } from "@angular/router";
@Component({
  selector: "app-parse-trips",
  templateUrl: "./parse-trips.component.html",
  styleUrls: ["./parse-trips.component.css"],
})
export class ParseTripsComponent implements OnInit, OnDestroy {
  imageSrc;
  selectedFile = null;
  htmlText = null;
  submitted = false;
  invalidFileType = false;
  getParseState: Observable<any>;
  brokerKeys;
  constructor(private store: Store<PlannerState>, private router: Router) {
    this.getParseState = this.store.select(selectPlannerState);
  }

  selectedCompanyName = null;
  ngOnInit() {
    this.getBrokerKeys();
    this.getParseState
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((state) => {
        if (state.success) {
          sweetAlert("Success", state.responce.message, "success", "Ok").then(
            () => {
              this.router.navigateByUrl("/assignstrips/unassigned");
            }
          );
        } else if (state.errorMessage) {
          sweetAlert("Error", state.errorMessage, "error", "Ok");
        }
      });
  }

  getBrokerKeys() {
    this.brokerKeys = getBrokersList;

  }

  selectedCompany(companyName) {
    const name = _.invert(getBrokersList);
    console.log(name[companyName]);
    this.selectedCompanyName = name[companyName];
    this.submitted = false;
    if (this.selectedCompanyName === "lcp") {
      this.selectedFile = null;
    } else {
      this.htmlText = null;
    }
  }

  readURL(event: HTMLInputEvent): void {
    if (event.target && event.target.files && event.target.files[0]) {
      this.selectedFile = event.target.files[0];
    }
    console.log(this.selectedFile);
  }
  submitFile() {
    this.submitted = true;
    console.log(this.selectedFile);
    console.log(this.htmlText);
    console.log(this.selectedCompanyName);
    const data = {
      companyType:
        this.selectedCompanyName === "lcp"
          ? "lcptrips"
          : this.selectedCompanyName,
      scrapData: this.selectedFile ? null : this.htmlText,
      isFile: this.selectedFile ? true : false,
    };
    if (this.selectedFile) {
      if (
        this.selectedFile.type === "application/vnd.ms-excel" ||
        this.selectedFile.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        this.selectedFile.type === "text/csv"
      ) {
        this.store.dispatch(new ScrapFile(this.selectedFile, data));
      } else {
        this.invalidFileType = true;
      }
    } else if (this.htmlText) {
      this.store.dispatch(new ScrapFile(this.selectedFile, data));
    }
  }
  ngOnDestroy(): void {}
}

interface HTMLInputEvent extends Event {
  target: HTMLInputElement & EventTarget;
}
