import { Action } from '@ngrx/store';

export enum VehicleExpenseActionTypes {
    ADD_VEHICLE_SERVICE = '[VehicleExpense] Add Vehicle Service',
    GET_VEHICLE_SERVICE_LIST = '[VehicleExpense] Get Vehicle Service List',
    GET_DRIVER_EXPENSE = '[VehicleExpense] Get Driver Expense',
    GET_MAINTENANCE_STATS = '[VehicleExpense] Get Maintenance Stats',
    COMMON_SUCCESS = '[VehicleExpense] Common Success',
    COMMON_FAILURE = '[VehicleExpense] Common Faliure',
}

export class AddVehicleService implements Action {
    readonly type = VehicleExpenseActionTypes.ADD_VEHICLE_SERVICE;
    constructor(public payload: any) {

    }
}

export class GetVehicleServiceList implements Action {
    readonly type = VehicleExpenseActionTypes.GET_VEHICLE_SERVICE_LIST;
}

export class GetDriverExpense implements Action {
    readonly type = VehicleExpenseActionTypes.GET_DRIVER_EXPENSE;
    constructor(public payload: any) {

    }
}

export class GetMaintenanceStats implements Action {
    readonly type = VehicleExpenseActionTypes.GET_MAINTENANCE_STATS;
}

export class CommonSuccess implements Action {
    type = VehicleExpenseActionTypes.COMMON_SUCCESS;
    constructor(public payload: any) {

    }
}

export class CommonFailure implements Action {
    type = VehicleExpenseActionTypes.COMMON_FAILURE;
    constructor(public payload: any) {

    }
}

export type All =
    | AddVehicleService
    | GetVehicleServiceList
    | GetDriverExpense
    | GetMaintenanceStats
    | CommonFailure
    | CommonSuccess;