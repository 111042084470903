import { Component, OnInit, NgZone, OnDestroy } from '@angular/core';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { DispatchCenterState, selectDispatchCenterState } from '../../../ngrx/app.states';
import { DispatchCenterActionTypes, TripDetails } from '../../../ngrx/store/actions/dispatch-center-actions';
import { Observable } from 'rxjs';
import { sweetAlert } from '../../../utils/swal';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { loadStaticMap } from '../../../utils/utils.common';
import { DispatchCenterService } from '../dispatch-center-service/dispatch-center-service';

@Component({
  selector: 'app-trip-details',
  templateUrl: './trip-details.component.html',
  styleUrls: ['./trip-details.component.css']
})
export class TripDetailsComponent implements OnInit {
  pickUpLatitude: number;
  dropOfflatitude: number;
  pickUpLongitude: number;
  dropOffLongitude: number;
  address: string;
  private geoCoder;
  zoom: number;
  jobId;
  currentFareDetails;
  currentFareBreakDown;
  tripDetails = null;
  jobAcceptedUrl = null;
  jobOnSceneUrl = null;
  jobStartedUrl = null;
  jobEndedUrl = null;
  jobFinishedUrl = null;
  fareType = 'company';
  viewAllDetail = false;

  origin = { lat: 0.00000, lng: 0.00000 };
  destination = { lat: 0.00000, lng: 0.00000 };
  assign;

  constructor(
    private dispatchCenterService: DispatchCenterService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.jobId = this.activatedRoute.snapshot.url[2].path;
    this.getTripDetails(this.jobId);
    this.getBrokerRate(this.jobId);
  }

  getTripDetails(jobId) {
    this.dispatchCenterService
      .getTripDetails(jobId)
      .subscribe(data => {
        if (data) {
          this.tripDetails = data;
          this.currentFareDetails = this.tripDetails.jobFareDetail;
          this.currentFareBreakDown = this.tripDetails.companyFareFormat;
          this.jobAcceptedUrl = loadStaticMap(this.tripDetails.trackInfoJob.jobAccepted.coords[1],
            this.tripDetails.trackInfoJob.jobAccepted.coords[0]);
          this.jobOnSceneUrl = loadStaticMap(this.tripDetails.trackInfoJob.jobArrived.coords[1],
            this.tripDetails.trackInfoJob.jobArrived.coords[0]);
          this.jobStartedUrl = loadStaticMap(this.tripDetails.trackInfoJob.jobStarted.coords[1],
            this.tripDetails.trackInfoJob.jobStarted.coords[0]);
          this.jobEndedUrl = loadStaticMap(this.tripDetails.trackInfoJob.jobCompleted.coords[1],
            this.tripDetails.trackInfoJob.jobCompleted.coords[0]);
          this.jobFinishedUrl = loadStaticMap(this.tripDetails.trackInfoJob.jobFinished.coords[1],
            this.tripDetails.trackInfoJob.jobFinished.coords[0]);

          this.origin = {
            lat: this.tripDetails.jobOriginLatitude,
            lng: this.tripDetails.jobOriginLongitude
          };
          this.destination = {
            lat: this.tripDetails.jobDestinationLatitude,
            lng: this.tripDetails.jobDestinationLongitude
          };
        }
      });
  }

  getBrokerRate(jobId) {
    this.dispatchCenterService
      .getBrokerRate(jobId)
      .subscribe(data => {
        if (data) {
          this.assign = data;
        }
      });
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ location: { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  }

  sortMethod(a, b) {
    return a.value.sort > b.value.sort ? 1 : -1;
  }

  selectFor(fareType) {
    this.fareType = fareType;
    this.currentFareDetails = this.tripDetails.jobFareDetail;
    this.currentFareBreakDown = this.tripDetails.companyFareFormat;
    if (fareType === 'driver') {
      this.currentFareDetails = this.tripDetails.driverFareDetail;
      this.currentFareBreakDown = this.tripDetails.driverFareFormat;
    }
  }

  onViewAllDetail() {
    this.viewAllDetail = !this.viewAllDetail;
  }

  onBack() {
    this.router.navigateByUrl('/dispatchcenter/history');
  }

  updateDriverFare () {
    if (window.confirm('Are you sure you want to update?')) {
      const payload = {driverFareDetail : this.tripDetails};
      this.dispatchCenterService
        .saveDriverFare(this.tripDetails._id, payload)
        .subscribe(data => {
          if (data) {
            sweetAlert('Success', "Fare Updated Successfully", 'success', 'OK');
          }
        });
    }
  }
}
