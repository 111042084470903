import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { sweetAlert } from '../../../utils/swal';
import { AuthService } from '../../../service/auth-service/auth.service';
import { imageProcessing, ImageSnippet } from '../../../utils/image-processing-utils';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
import { UserProfileService } from '../user-profile-service/user-profile.service';

@Component({
  selector: 'app-edit-user-profile',
  templateUrl: './edit-user-profile.component.html',
  styleUrls: ['./edit-user-profile.component.css']
})
export class EditUserProfileComponent implements OnInit {

  editProfileForm: FormGroup;
  submitted = false;
  selectedFile: ImageSnippet;
  userData = null;


  image = null;

  tzone = 'Asia/Karachi';

  placeholderString = 'Select timezone';


  latitude: number;
  longitude: number;
  zoom: number;
  address: string;
  private geoCoder;
  toggleCurrentPassword = true;
  toggleNewPassword = true;
  toggleConfirmPassword = true;
  user;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private sharedDataService: SharedDataService,
    private userProfileService: UserProfileService
  ) {
  }



  /*
    changes in CSS of select2 node module
    In select2.css file

    comment line height in the following selector
    .select2-container--default .select2-selection--single .select2-selection__rendered

    comment border style in the following selector
    .select2-container--default .select2-selection--single .select2-selection__arrow b
  */

  get form() {
    return this.editProfileForm.controls;
  }

  ngOnInit() {
    this.getUser();
    this.editProfileForm = this.fb.group({
      displayName: [{ value: '', disabled: true }, Validators.required],
      email: [{ value: '', disabled: true }, Validators.required],
      address: [{ value: '', disabled: true }, Validators.required],
      timeZone: [this.tzone, Validators.required],
      currentPassword: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(16)]],
      newPassword: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(16)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(16)]],
      latitude: [{ value: '', disabled: true }, Validators.required],
      longitude: [{ value: '', disabled: true }, Validators.required]

    });

    this.userData = this.authService.getUserData();
    this.setUpView();

  }

  getUser() {
    this.sharedDataService
      .getUser()
      .subscribe(user => {
        if (user) {
          this.user = user;
          this.tzone = this.user.timeZone
        }
      });
  }

  afterEditSuccess() {
    this.selectedFile = null;
    this.userData = this.authService.getUserData();
    this.editProfileForm.reset();
    this.submitted = false;
    this.setUpView();
  }

  setUpView() {

    if (this.userData != null) {
      console.log(this.userData);
      this.editProfileForm.patchValue({
        ...this.userData
      });
      this.image = this.userData.profileImageURL;
    }

  }

  togglePassword(fieldName: string) {
    switch (fieldName) {
      case 'current':
        this.toggleCurrentPassword = !this.toggleCurrentPassword;
        break;
      case 'new':
          this.toggleNewPassword = !this.toggleNewPassword;
          break;
      case 'confirm':
          this.toggleConfirmPassword = !this.toggleConfirmPassword;
          break;
    }
  }

  processFile(imageInput: any) {
    this.submitted = false;
    imageProcessing(imageInput).subscribe((result) => {
      this.selectedFile = result.file;
      this.image = result.src;
    });
  }

  changeTimezone(timezone) {
    this.tzone = timezone;
    this.editProfileForm.patchValue({ timeZone: timezone });
  }

  onEditProfileSubmit() {
    this.submitted = true;
    if (this.editProfileForm.invalid) {
      return;
    } else {
      this.userProfileService
        .ChangePassword(this.editProfileForm.value)
        .subscribe(data => {
          if (data) {
            sweetAlert('Succcess', 'Password Updated Successfully', 'success', 'OK');
            this.afterEditSuccess();
          }
        }, err => {
          sweetAlert('Error', err, 'warning', 'OK');
          this.selectedFile = null;
          this.submitted = false;
          this.setUpView();
        });
    }
  }


  uploadImage() {
    if (this.selectedFile) {
      this.submitted = false;
      // this.store.dispatch(new UploadProfileImage(this.selectedFile));
      this.userProfileService
        .changeProfileImage(this.selectedFile)
        .subscribe(data => {
          if (data) {
            this.authService.CheckLoginValidation(data, true);
            this.afterEditSuccess();
            this.image = data.profileImageURL;
            sweetAlert('Succcess', 'Image Uploaded Successfully', 'success', 'OK');
          }
        }, err => {
          sweetAlert('Error', err, 'warning', 'OK');
          this.selectedFile = null;
          this.submitted = false;
          this.setUpView();
        });
    }
  }

  clearImage() {
    this.selectedFile = null;
    this.image = this.userData.profileImageURL;
  }
  getAddress(place) {
    this.editProfileForm.patchValue({
      currentAddress: place.formatted_address,
      latitude: place.geometry.location.lat(),
      longitude: place.geometry.location.lng()
    });

  }
}
