import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth-service/auth.service';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
import { SocketService } from 'src/app/service/socket-service/socket-service.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  subMenus;
  showUserOptions = false;
  showNotifications = false;

  constructor(
    private sharedDataService: SharedDataService,
    private authService: AuthService,
    private socket: SocketService,
    private router: Router
  ) { }

  ngOnInit() {
    this.getSubMenus();
  }

  getSubMenus() {
    this.sharedDataService
      .getSubMenus()
      .subscribe(data => {
        this.subMenus = [];
        if (data) {
          this.subMenus = data;
        }
      });
  }

  closeNotifications() {
    this.showNotifications = false;
  }

  logout() {
    this.socket.disconnectSocket();
    this.authService.removeToken();
    this.sharedDataService.saveUser(null);
    this.router.navigateByUrl('login');
  }
}
