import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from './service/auth-service/auth.service';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { AppState, selectAuthState } from '../../src/app/ngrx/app.states';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { VerifyToken } from './ngrx/store/actions/auth.actions';
import * as moment from 'moment-timezone';
import { DateTimeAdapter } from 'ng-pick-datetime';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { Events } from './utils/event-utils';
import { SocketService } from './service/socket-service/socket-service.service';
import { SharedDataService } from './service/shared-service/shared-data.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  isLoggedIn = false;
  title = 'nemt-angular8';
  state;
  getState: Observable<any>;
  forgotPasswordFlag = false;
  DateTimeAdapter;
  // loader = true;
  user;

  constructor(
    private authService: AuthService,
    private router: Router,
    private store: Store<AppState>,
    dateTimeAdapter: DateTimeAdapter<any>,
    private socketService: SocketService,
    private sharedDataService: SharedDataService
  ) {
    this.getState = this.store.select(selectAuthState);
    this.routerListener();
    this.DateTimeAdapter = dateTimeAdapter;
    this.DateTimeAdapter.setLocale('en-US');

  }


  ngOnInit() {

    // const newYork = moment.tz('2019-11-13T00:00:00.897Z', 'America/New_York');
    // const newYork = moment.tz('2019-11-13T00:00:00.897Z', 'America/New_York');
    // console.log('TimeZone', newYork.format());

    // moment(date).tz('Europe/Berlin')
    // moment.tz.setDefault('America/New_York');
    // moment.tz.setDefault('Asia/Karachi');
    this.sharedDataService
      .getUser()
      .subscribe(user => {
        if (user) {
          this.user = user;
          this.isLoggedIn = true;
          console.log('heree')
          moment.tz.setDefault(user.timeZone);
          // this.authService.CheckLoginValidation(user, this.isLogIn);
        } else {
          this.isLoggedIn = false;
          // this.authService.CheckLoginValidation(null, this.isLogIn);
        }
      });

    // this.getState
    //   .pipe(takeUntil(componentDestroyed(this)))
    //   .subscribe((state) => {
    //     this.state = state;
    //     if (state.user === null && state.token === null) {
    //       this.isLogIn = false;
    //       this.authService.CheckLoginValidation(null, this.isLogIn);

    //     } else {
    //       this.isLogIn = true;
    //       if (state.user != null) {
    //         this.authService.CheckLoginValidation(state.user, this.isLogIn);
    //         // moment.tz.setDefault(this.authService.getUserData().timezone);
    //       }
    //     }
    //     // console.log('AppComponent ErrorMessage', state, this.isLogIn);

    //   });
    if (!this.user) {
      this.verifyToken();
    }
    
  }

  verifyToken() {
    // if (this.state.user === null && this.state.token !== null) {
    //   this.store.dispatch(new VerifyToken());
    // }

    this.authService
      .VerifyToken()
      .subscribe(data => {
        if (data && data.user) {
          this.sharedDataService.saveUser(data.user);
        }
      });
  }

  routerListener() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        console.log('>>>>>> Navi', event.url);
        // if (event.url === '/assignstrips/inprogress') {
        //   this.loader = false;
        // } else {
        //   this.loader = true;
        // }
        if (this.authService.getToken('token')) {
          this.isLoggedIn = true;
          if (event.url === '/login' || event.url === '/forgotPassword') {
            this.router.navigateByUrl('/');
          }
        } else if (this.authService.getToken('token') == null) {
          this.isLoggedIn = false;
          if (event.url === '/forgotpassword') {
            // this.router.navigateByUrl('forgotPassword');
            this.forgotPasswordFlag = true;
          } else if (event.url !== '/login') {
            this.router.navigateByUrl('login');
          } else {
            this.forgotPasswordFlag = false;
          }


        } else {
          this.isLoggedIn = true;
        }
      }
    });
  }

  // setUpDriverAlertSocketListener() {
  //   this.socketService.addListener(Events.DRIVER_ALERT_CALL)
  //     .subscribe(data => {
  //       console.log(data);
  //       // if (data.success) {

  //       // } else {
  //       //   this.approachingTrips = [];
  //       // }
  //     });
  //   }

  ngOnDestroy(): void {
  }

}




