import { Action } from '@ngrx/store';


export enum DispatchCenterActionTypes {
    NOTIFICATIONS_LIST = '[DispatchCenter] Notifications list',
    HISTORY_LIST = '[DispatchCenter] History list',
    GET_UNASSIGNED_TRIP_DETAILS = '[DispatchCenter] Get Unassigned Trip Detail',
    TRIP_DETAILS = '[DispatchCenter] Trip Details',
    ADJUSTMENT_DETAILS = '[DispatchCenter] Adjustment Details',
    BROKER_RATE = '[DispatchCenter] Broker Rate',
    COMMON_SUCCESS = '[DispatchCenter] Common Success',
    COMMON_FAILURE = '[DispatchCenter] Common Faliure'
}

// export class Historylist implements Action {
//     readonly type = DispatchCenterActionTypes.HISTORY_LIST;
//     constructor(public payload: any, public endPoint: any) {
//     }
// }

export class GetUnassignedTripDetail implements Action {
    readonly type = DispatchCenterActionTypes.GET_UNASSIGNED_TRIP_DETAILS;
    constructor(public payload: any) {
    }
}

export class AdjustmentDetails implements Action {
    readonly type = DispatchCenterActionTypes.ADJUSTMENT_DETAILS;
    constructor(public id: any, public payload: any) {
    }

}

export class GetBrokerRate implements Action {
  readonly type = DispatchCenterActionTypes.BROKER_RATE;
  constructor(public payload: any) {
  }

}

export class TripDetails implements Action {
    readonly type = DispatchCenterActionTypes.TRIP_DETAILS;
    constructor(public payload: any) {
    }

}

export class CommonSuccess implements Action {
    type = DispatchCenterActionTypes.COMMON_SUCCESS;
    constructor(public payload: any) {

    }
}

export class CommonFailure implements Action {
    type = DispatchCenterActionTypes.COMMON_FAILURE;
    constructor(public payload: any) {

    }
}

export type All =
    | TripDetails
    | AdjustmentDetails
    | GetUnassignedTripDetail
    | GetBrokerRate
    | CommonSuccess
    | CommonFailure;
