import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { urls } from '../../../utils/url-utils';
import { setFormData } from '../../../utils/network-utils';
import { errorHandl } from '../../../utils/network-utils';
@Injectable({
  providedIn: 'root'
})
export class VehicleExpenseService {

  constructor(private http: HttpClient) { }

  addVehicleServiceWithNgrx(vehicleServiceData): Observable<any> {
    const imageFiles = vehicleServiceData.images;
    delete vehicleServiceData.images;
    const formData = new FormData();
    Object.keys(imageFiles).forEach(key => {
            if (imageFiles[key] !== null) {
                formData.append(key, imageFiles[key], imageFiles[key].name);
            }
        });
    formData.append('name', vehicleServiceData.name);
    return this.http.post<any>(urls.BASE_URL + urls.ADD_VEHICLE_EXPENSE_SERVICE_URL, formData).pipe(
        catchError(errorHandl)
      );
  }

  getVehicleServiceWithNgrx(): Observable<any> {
      return this.http.get<any>(urls.BASE_URL + urls.ADD_VEHICLE_EXPENSE_SERVICE_URL).pipe(
        catchError(errorHandl)
      );
  }

  getDriverExpenseWithNgrx(driverId): Observable<any> {
    return this.http.get<any>(urls.BASE_URL + urls.DRIVER_EXPENSE_URL + driverId).pipe(
      catchError(errorHandl)
    );
}

getMaintenanceStatsWithNgrx(): Observable<any> {
  return this.http.get<any>(urls.BASE_URL + urls.MAINTENANCE_STATS_URL).pipe(
    catchError(errorHandl)
  );
}

}
