import { Component, OnInit } from "@angular/core";
import { sweetAlert } from "../../../utils/swal";
import { ActivatedRoute } from "@angular/router";
import {
  getCompanyLogo,
  addDataTableScript,
} from "../../../utils/utils.common";
import { FleetService } from "../../fleet/fleet-service/fleet.service";
import { SharedDataService } from "src/app/service/shared-service/shared-data.service";
import { DriversService } from "../drivers-service/drivers-service";
import { downloadFile } from "src/app/utils/pdf-downloader";
import { urls } from "src/app/utils/url-utils";

@Component({
  selector: "app-driver-list",
  templateUrl: "./driver-list.component.html",
  styleUrls: ["./driver-list.component.css"],
})
export class DriverListComponent implements OnInit {
  driverList = [];
  search;
  fleetsList = [];
  currentFleet: any;
  selectedDriver: any;
  isSelectedFleet = false;
  path = "";
  user;
  drivers: void[];
  selectedStatus: any = "active";

  constructor(
    private driverService: DriversService,
    private fleetService: FleetService,
    private sharedDataService: SharedDataService,
    private actvatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.getUser();
  }

  getUser() {
    this.sharedDataService
      .getUser()
      .subscribe((data) => {
        if (data) {
          this.user = data;
          this.getDriverList();
        }
      });
  }
  
  getDriverList() {
    this.driverService.getDrivers(this.user._id, this.selectedStatus).subscribe(
      (data) => {
        if (data) {
          this.driverList = data;
          addDataTableScript();
        }
      },
      (err) => {
        sweetAlert("Error", err, "error", "OK");
      }
    );
  }

  onChangeStatus() {
    this.getDriverList();
  }

  getCompanyImage(driverCar) {
    return getCompanyLogo(driverCar);
  }

  currentSelectedFleet(driver) {
    this.selectedDriver = driver;
    this.fleetsList.length = 0;
    this.currentFleet = {};
    if (driver.isFleet) {
      this.isSelectedFleet = true;
      this.getFleetList(`driver=${this.selectedDriver._id}`);
    } else {
      this.isSelectedFleet = false;
      this.getFleetList("");
    }
  }

  getFleetList(param) {
    this.fleetService.getFleetList(param).subscribe(
      (data) => {
        if (data) {
          this.fleetsList = data;
        }
      },
      (err) => {
        sweetAlert("Error", err, "error", "OK");
      }
    );
  }

  selectedFleet(fleet) {
    this.currentFleet = fleet;
  }

  updateFleet() {
    if (this.currentFleet && this.currentFleet._id) {
      const newFleet = this.currentFleet;
      newFleet.fleetId = newFleet._id;
      delete newFleet._id;
      delete newFleet.__v;
      newFleet.isFleet = true;
      $("#fleetListModal .close").click();
      this.driverService
        .assignFleet(newFleet, this.selectedDriver._id)
        .subscribe((data) => {
          if (data) {
            const itemIndex = this.driverList.findIndex(
              (item) => item._id === data._id
            );
            this.driverList[itemIndex] = data;
            sweetAlert(
              "Success",
              "Fleet Updated Successfully",
              "success",
              "OK"
            );
          }
        });
      this.assignFleet(newFleet, this.selectedDriver._id);
    }
  }

  clearFleet(fleet) {
    this.currentFleet = fleet;
    this.assignFleet(this.currentFleet._id, this.selectedDriver._id);
  }

  assignFleet(fleetId, driverId) {
    this.driverService.assignFleet(fleetId, driverId).subscribe((data) => {
      if (data) {
        const itemIndex = this.driverList.findIndex(
          (item) => item._id === data._id
        );
        this.driverList[itemIndex] = data;
        sweetAlert("Success", "Fleet Updated Successfully", "success", "OK");
      }
    });
  }

  generateQRCode(driver) {
    this.driverService.generateDriverQR(driver._id).subscribe((data) => {
      if (data && data.pdfName) {
        const name = data.pdfName;
        const url = urls.SERVER_URL + "pdf/" + name;
        downloadFile(url, "", name);
      }
    });
  }
}
