import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AssigTripsService } from '../assigntrips-service/assigntrips-service';

@Component({
  selector: 'app-additional-note-modal',
  templateUrl: './additional-note-modal.component.html',
  styleUrls: ['./additional-note-modal.component.css']
})
export class AdditionalNoteModalComponent implements OnInit {
  @Input() trip;
  @Output() additionalNoteAdded = new EventEmitter<any>();

  constructor(
    private assignTripService: AssigTripsService
  ) { }

  ngOnInit() {
  }

  addAdditionalNote() {
    this.assignTripService
      .addAdditionalNote(this.trip)
      .subscribe(data => {
        if (data) {
          this.additionalNoteAdded.emit(data); 
        }
      });
  }

}
