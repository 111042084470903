
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { urls } from '../../../utils/url-utils';
import { errorHandl } from '../../../utils/network-utils';
import { element } from 'protractor';


@Injectable({
  providedIn: 'root'
})
export class AssigTripsService {

  constructor(private http: HttpClient) {
  }

  getAssignList(endPoint): Observable<any> {
    let params = new HttpParams();
    params = params.append('isShowAllTrips', endPoint.isShowAllTrips);
    params = params.append('status', endPoint.status);
    if (endPoint.date) {
      params = params.append('scheduleTime', endPoint.date);
    }


    return this.http.get<any>(urls.BASE_URL + urls.GET_UNASSIGN_TRIPS_URL, { params })
      .pipe(
        catchError(errorHandl)
      );
  }

  getOutstandingTrips(payload): Observable<any> {
    // let params = new HttpParams();
    // params = params.append('driver', endPoint.driver);
    // params = params.append('scheduleTime', endPoint.scheduleTime);

    // return this.http.get<any>(urls.BASE_URL + urls.GET_OUTSTANDING_TRIPS_URL, { params })
    //   .pipe(
    //     catchError(errorHandl)
    //   );

    return this.http.post<any>(urls.BASE_URL + urls.GET_OUTSTANDING_TRIPS_URL, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  getStandingOrders(): Observable<any> {

    return this.http.put<any>(urls.BASE_URL + urls.GET_OUTSTANDING_TRIPS_URL, {})
      .pipe(
        catchError(errorHandl)
      );
  }

  getOutstandingTripsForBucket(payload): Observable<any> {
    let params = new HttpParams();
    params = params.append('scheduleTime', payload.scheduleTime);

    return this.http.get<any>(urls.BASE_URL + urls.GET_OUTSTANDING_TRIPS_URL, { params })
      .pipe(
        catchError(errorHandl)
      );
  }

  getUnparsedTrips(endPoint): Observable<any> {
    let params = new HttpParams();
    params = params.append('rawId', endPoint.rawId);

    return this.http.get<any>(urls.BASE_URL + urls.GET_UNPARSED_TRIPS_URL, { params })
      .pipe(
        catchError(errorHandl)
      );
  }

  getSweepTripsList(): Observable<any> {
    return this.http.get<any>(urls.BASE_URL + urls.GET_SWEEP_TRIPS_LIST_URL)
      .pipe(
        catchError(errorHandl)
      );
  }

  removeSweepTrips(data): Observable<any> {

    return this.http.post<any>(urls.BASE_URL + urls.GET_SWEEP_TRIPS_LIST_URL + '?assignId=' + data._id, data)
      .pipe(
        catchError(errorHandl)
      );
  }

  sendTripListEmail(data): Observable<any> {

    return this.http.put<any>(urls.BASE_URL + urls.SEND_TRIP_LIST_EMAIL_URL + data._id, data)
      .pipe(
        catchError(errorHandl)
      );
  }


  calculateFare(id): Observable<any> {

    return this.http.get<any>(urls.BASE_URL + urls.GET_UNASSIGN_TRIPS_URL + `/tempbrokerfare/${id}`)
      .pipe(
        catchError(errorHandl)
      );
  }

  cloneTrip(id): Observable<any> {

    return this.http.get<any>(urls.BASE_URL + urls.GET_UNASSIGN_TRIPS_URL + `/clonetrip/${id}`)
      .pipe(
        catchError(errorHandl)
      );
  }
  removeTrip(data, id): Observable<any> {

    return this.http.post<any>(urls.BASE_URL + urls.GET_UNASSIGN_TRIPS_URL + `/${id}`, data)
      .pipe(
        catchError(errorHandl)
      );
  }
  UpdateTripStatusWithNgrx(payload): Observable<any> {
    const id = payload._id;
    console.log(payload);
    return this.http.put<any>(urls.BASE_URL + urls.GET_UNASSIGN_TRIPS_URL + `/${id}`, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  sendToMarketplace(payload): Observable<any> {
    const id = payload._id;
    return this.http.put<any>(urls.BASE_URL + urls.GET_UNASSIGN_TRIPS_URL + `/${id}`, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  markCompletedTrip(payload): Observable<any> {
    const id = payload._id;
    return this.http.post<any>(urls.BASE_URL + urls.MARK_COMPLETED_TRIP + `?assignId=${id}`, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  addSpecialRate(payload): Observable<any> {
    const id = payload._id;
    return this.http.put<any>(urls.BASE_URL + urls.GET_UNASSIGN_TRIPS_URL + `/${id}`, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  getAssignTripsList(requestData): Observable<any> {

    return this.http.post<any>(urls.BASE_URL + urls.GET_ASSIGNED_TRIPS_LIST, requestData)
      .pipe(
        catchError(errorHandl)
      );
  }

  getCorporateUserList(): Observable<any> {

    return this.http.get<any>(urls.BASE_URL + urls.GET_CORPORATE_USER_LIST)
      .pipe(
        catchError(errorHandl)
      );
  }

  getTripsForAssignDashboard(): Observable<any> {

    return this.http.get<any>(urls.BASE_URL + urls.TRIPS_FOR_ASSIGN_DASHBOARD_URL)
      .pipe(
        catchError(errorHandl)
      );
  }

  assignTrip(data): Observable<any> {

    return this.http.put<any>(urls.BASE_URL + urls.GET_UNASSIGN_TRIPS_URL + `/${data._id}`, data)
      .pipe(
        catchError(errorHandl)
      );
  }

  assignMultipleTrip(data): Observable<any> {
    console.log('assignMultipleTrip', data);
    return this.http.post<any>(urls.BASE_URL + urls.ASSIGN_MULTIPLE_TRIPS_URL, data)
      .pipe(
        catchError(errorHandl)
      );
  }

  addBillerNote(data): Observable<any> {

    return this.http.put<any>(urls.BASE_URL + urls.GET_UNASSIGN_TRIPS_URL + `/${data._id}`, data)
      .pipe(
        catchError(errorHandl)
      );
  }

  addAdditionalNote(data): Observable<any> {

    return this.http.put<any>(urls.BASE_URL + urls.GET_UNASSIGN_TRIPS_URL + `/${data._id}`, data)
      .pipe(
        catchError(errorHandl)
      );
  }

  updateTrip(payload): Observable<any> {
    const id = payload._id;
    return this.http.put<any>(urls.BASE_URL + urls.GET_UNASSIGN_TRIPS_URL + `/${id}`, payload)
      .pipe(
        catchError(errorHandl)
      );
  }


  verifiedCancelTrip(data, id) {
    return this.http.post<any>(urls.BASE_URL + urls.VERIFIED_CANCEL_TRIP_URL + `/${id}`, data)
      .pipe(
        catchError(errorHandl)
      );
  }


  createNewTrip(tripData, tripId?): Observable<any> {
    console.log(tripData)
    // tripData = this.IsoStringConversion(tripData);
    const timeKeys = ['schedulePickupHour', 'schedulePickupMinutes', 'appointmentPickupHour', 'appointmentPickupMinutes', 'scheduledTime'];
    const normalDispatchRemoveKeys = ['iSschUrgentCare', 'isUrgentCare', 'isWheelChair', 'isCarSeat', 'isLongDistance', 'memberId', 'tripId', 'dob', 'cooperate'];
    const lcpDispatchRemoveKeys = ['cooperate'];
    const medicalDispatchRemoveKeys = ['iSschUrgentCare', 'isUrgentCare', 'isWheelChair', 'isCarSeat', 'isLongDistance', 'dob', 'cooperate'];
    const corporateDispatchRemoveKeys = ['iSschUrgentCare', 'isUrgentCare', 'isWheelChair', 'isCarSeat', 'isLongDistance', 'dob', 'memberId', 'tripId'];
    timeKeys.forEach(element => {
      delete tripData[element];
    })
    if (tripData.jobType === 'assigned') {
      if (tripData.isMedical) {
        if (tripData.companyType === 'lcp') {
          lcpDispatchRemoveKeys.forEach(element => delete tripData[element]);
        } else {

          medicalDispatchRemoveKeys.forEach(element => delete tripData[element]);
        }
        tripData.jobCarType = '';
      } else {
        normalDispatchRemoveKeys.forEach(element => delete tripData[element]);
        tripData.tripRequirement = tripData.jobCarType;
      }
    } else if (tripData.jobType === "cooperate") {
      corporateDispatchRemoveKeys.forEach(element => delete tripData[element]);
      // tripData['tripRequirement'] = tripData['jobCarType'];
      tripData.tripRequirement = tripData.jobCarType;

    }
    console.log(tripData)

    tripData.tripLegs = tripData.tripLegs.splice(1, tripData.tripLegs.length)
    if (tripData.tripLegs.length === 0) {
      delete tripData['tripLegs'];
    } else if (tripData.tripLegs.length > 0 && !tripData.isMedical) {
      for (let i = 0; i < tripData.tripLegs.length; i++) {
        delete tripData.tripLegs[i].tripId;
      }

    }
    console.log(tripData);
    if (tripId) {
      return this.http.put<any>(urls.BASE_URL + urls.CREATE_NEW_TRIP_URL + '/' + tripId, tripData)
        .pipe(
          catchError(errorHandl)
        );
    } else {
      return this.http.post<any>(urls.BASE_URL + urls.CREATE_NEW_TRIP_URL, tripData)
        .pipe(
          catchError(errorHandl)
        );
    }
  }

  IsoStringConversion(tripData) {
    console.log(tripData);
    console.log(tripData['scheduleTime'])
    const dates = { scheduleTime: ['schedulePickupHour', 'schedulePickupMinutes'], appointmentTime: ['appointmentPickupHour', 'appointmentPickupMinutes'], returnScheduleTime: ['returnPickupHour', 'returnPickupMinutes'] };
    Object.keys(dates).forEach(key => {
      console.log(key)
      let date = tripData[key].split('T')[0];
      tripData[key] = date + 'T';
      dates[key].forEach(element => {
        console.log(element);
        tripData[key] = tripData[key] + tripData[element] + ':';
      })
      tripData[key] = tripData[key] + '00.000Z';
    })
    return tripData;
  }

  getUnassignedTripDetailById(unassignedTripId): Observable<any> {

    return this.http.get<any>(urls.BASE_URL + urls.CREATE_NEW_TRIP_URL + '/' + unassignedTripId)
      .pipe(
        catchError(errorHandl)
      );
  }

  getAdjustedTrips(queryParams): Observable<any> {
    const params = new HttpParams({fromObject: queryParams});
    return this.http.get<any>(urls.BASE_URL + urls.GET_UNASSIGN_TRIPS_URL, { params })
      .pipe(
        catchError(errorHandl)
      );
  }

  getAssignTripLogsFile(payload, endPoint): Observable<any> {
    return this.http.post<any>(urls.BASE_URL + urls.CREATE_NEW_TRIP_URL + `/${endPoint}`, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  // downlodFile(url, name): Observable<Blob> {
  //     return this.http.get<any>(urls.BASE_URL + url , {responseType: 'blob'})
  //         .pipe(
  //             catchError(errorHandl)
  //         );
  // }



  generateSignature(payload, endPoint): Observable<any> {

    return this.http.put<any>(urls.BASE_URL + urls.GENRATE_TRIP_SIGNATURE_URL + `/${endPoint}`, payload)
      .pipe(
        catchError(errorHandl)
      );
  }


  updateAllTripsSignature(payload): Observable<any> {

    return this.http.post<any>(urls.BASE_URL + urls.UPDATE_ALL_TRIPS_SIGNATURE_URL, payload)
      .pipe(
        catchError(errorHandl)
      );
  }


  bankedTrips(payload): Observable<any> {

    return this.http.post<any>(urls.BASE_URL + urls.BANKED_TRIP_URL, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  getETA(tripId) {
    return this.http.get<any>(urls.BASE_URL + urls.SINGLE_TRIP + '/' + tripId)
      .pipe(
        catchError(errorHandl)
      );
  }

  getDirtRoadTrips(params) {   
    return this.http.get(
      urls.BASE_URL + urls.GET_UNASSIGN_TRIPS_URL, params);
  }

  sendSMS(payload): Observable<any> {
    return this.http.post<any>(urls.BASE_URL + urls.TRACKING_SMS_URL + '?smId=' + payload._id, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  generateTripsReport(payload) {
    return this.http.post<any>(urls.BASE_URL + urls.GENERATE_TRIPS_REPORT, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  generateTripsIntineraryReport(payload) {
    return this.http.put<any>(urls.BASE_URL + urls.GENERATE_TRIPS_REPORT, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  sendConfirmationText(payload) {
    return this.http.post<any>(urls.BASE_URL + urls.SEND_CONFIRMATION_TEXT_URL, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  autoTripAdjustment(payload) {
    return this.http.post<any>(urls.BASE_URL + urls.AUTO_TRIP_ADJUSTMENT_URL, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  adjustTripOdometer(payload) {
    return this.http.post<any>(urls.BASE_URL + urls.TRIP_ODOMETER_ADJUST_URL, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  adjustAllTripsOdometer(payload) {
    return this.http.put<any>(urls.BASE_URL + urls.TRIP_ODOMETER_ADJUST_URL, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  donwloadCallTheCarInvoice(payload) {
    return this.http.post<any>(urls.BASE_URL + urls.GET_UNASSIGN_TRIPS_URL + '/callthecar', payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  donwloadSoutheastransManifest(driverId, payload) {
    return this.http.post<any>(urls.BASE_URL + urls.GET_UNASSIGN_TRIPS_URL + '/manifest/' + driverId, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  donwloadLogisticareManifest(payload) {
    return this.http.post<any>(urls.BASE_URL + urls.GET_UNASSIGN_TRIPS_URL + '/logisticmanifest', payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  donwloadLcpManifest(payload) {
    return this.http.post<any>(urls.BASE_URL + urls.GET_UNASSIGN_TRIPS_URL + '/lcpmanifest', payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  donwloadAmeraManifest(payload) {
    return this.http.post<any>(urls.BASE_URL + urls.GET_UNASSIGN_TRIPS_URL + '/ameramanifest', payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  donwloadCorporateInvoiceReport(corporateUserId, payload) {
    return this.http.put<any>(urls.BASE_URL + urls.CORPORATE_USER_REPORT_URL + `/${corporateUserId}`, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  donwloadCorporateInvoice(corporateUserId, payload) {
    return this.http.post<any>(urls.BASE_URL + urls.CORPORATE_USER_REPORT_URL + `/${corporateUserId}`, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  donwloadMedtransDriverLog(driverId, payload) {
    return this.http.put<any>(urls.BASE_URL + urls.GET_UNASSIGN_TRIPS_URL + `/nmntrf/${driverId}`, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  donwloadMtmTrf(driverId, payload) {
    return this.http.post<any>(urls.BASE_URL + urls.GET_UNASSIGN_TRIPS_URL + `/mtmtrf/${driverId}`, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  donwloadMtmClaim(payload) {
    return this.http.put<any>(urls.BASE_URL + urls.GET_UNASSIGN_TRIPS_URL + '/claimfile', payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  donwloadDriverLog(driverId, payload) {
    return this.http.put<any>(urls.BASE_URL + urls.GET_UNASSIGN_TRIPS_URL + `/mtmtrf/${driverId}`, payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  donwloadAllDriverLog(payload) {
    return this.http.post<any>(urls.BASE_URL + urls.GET_UNASSIGN_TRIPS_URL + '/mtm/alldriverlogs', payload)
      .pipe(
        catchError(errorHandl)
      );
  }


}
