import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { urls } from '../../../utils/url-utils';
import { catchError } from 'rxjs/operators';
import { errorHandl } from '../../../utils/network-utils';
@Injectable({
  providedIn: 'root'
})
export class UserProfileService {

  constructor(private http: HttpClient) { }

  // update user profile
  ChangePassword(data): Observable<any> {
    const obj = {
      newPassword: data.newPassword,
      verifyPassword: data.confirmPassword,
      currentPassword: data.currentPassword
    };
    return this.http.post<any>(urls.BASE_URL + urls.CHANGE_PASSWORD_URL, obj)
      .pipe(
        catchError(errorHandl)
      );

  }

  changeProfileImage(data): Observable<any> {
    const formData = new FormData();
    formData.append('newProfilePicture', data, data.name);
    return this.http.post<any>(urls.BASE_URL + urls.CHANGE_PROFILE_IMAGE, formData)
      .pipe(
        catchError(errorHandl)
      );

  }
}
