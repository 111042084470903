import { Action } from '@ngrx/store';

export enum AccidentReportActionTypes {
    ADD_ACCIDNET_REPROT = '[Accident] Add Accident Report',
    GET_ACCIDNET_REPROT = '[Accident] Get Accident Report',
    GET_ACCIDNET_REPROT_BY_ID = '[Accident] Get Accident Report By Id',
    COMMON_SUCCESS = '[Accident] Common Success',
    COMMON_FAILURE = '[Accident] Common Faliure',
}



export class AddAccidentReport implements Action {
    readonly type = AccidentReportActionTypes.ADD_ACCIDNET_REPROT;
    constructor(public payload: any) {
    }
}

export class GetAccidentReport implements Action {
    readonly type = AccidentReportActionTypes.GET_ACCIDNET_REPROT;
}

export class GetAccidentReportById implements Action {
    readonly type = AccidentReportActionTypes.GET_ACCIDNET_REPROT_BY_ID;
    constructor(public payload: any) {
    }
}



export class CommonSuccess implements Action {
    type = AccidentReportActionTypes.COMMON_SUCCESS;
    constructor(public payload: any) {

    }
}

export class CommonFailure implements Action {
    type = AccidentReportActionTypes.COMMON_FAILURE;
    constructor(public payload: any) {

    }
}

export type All =
    | AddAccidentReport
    | GetAccidentReport
    | GetAccidentReportById
    | CommonFailure
    | CommonSuccess;