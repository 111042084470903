import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { urls } from '../../../utils/url-utils';
import { errorHandl } from '../../../utils/network-utils';


@Injectable({
    providedIn: 'root'
})
export class PlannerService {

    constructor(private http: HttpClient) {
    }

    getPlannerListWithNgrx(endPoint): Observable<any> {
        return this.http.get<any>(urls.BASE_URL + urls.GET_PLANNER_URL + `${endPoint}`)
            .pipe(
                catchError(errorHandl)
            );
    }

    scrapTrips(file, data): Observable<any> {
      const formData = new FormData();
      // formData.append('scrap', data);
      Object.keys(data).forEach(key => {
        formData.append(`scrap[${key}]`, data[key]);
      });
      if (file) {
        formData.append('uploadExcel', file, file.name);
      }
      return this.http.put<any>(urls.BASE_URL + urls.SCRAP_TRIPS_URL, formData)
          .pipe(
              catchError(errorHandl)
          );
  }


}
