import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { urls } from '../../../utils/url-utils';
import { errorHandl, setOnlyFieldFormData } from '../../../utils/network-utils';

@Injectable({
  providedIn: 'root'
})
export class DriverDistributionsService {

  constructor(private http: HttpClient) {
  }

  getDistributionListWithNgrx(): Observable<any> {
    return this.http.get<any>(urls.BASE_URL + urls.DRIVER_DISTRIBUTION_URL)
      .pipe(
        catchError(errorHandl)
      );
  }

  getDistributionByIdWithNgrx(id): Observable<any> {
    return this.http.get<any>(urls.BASE_URL + urls.DRIVER_DISTRIBUTION_URL + '/' + id)
      .pipe(
        catchError(errorHandl)
      );
  }

  addDriverDistributionWithNrx(data): Observable<any> {
    const distributionId = data.distributionId;
    delete data._id;
    if (distributionId) {
      return this.http.put<any>(urls.BASE_URL + urls.DRIVER_DISTRIBUTION_URL + '/' + distributionId, data).pipe(
        catchError(errorHandl)
      );
    } else {
      return this.http.post<any>(urls.BASE_URL + urls.DRIVER_DISTRIBUTION_URL, data).pipe(
        catchError(errorHandl)
      );
    }

  }



}
