import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
import { MenuService } from '../menu-service/menu.service';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.css']
})
export class SideNavComponent implements OnInit {
  user;
  menus;
  selectedMenu;

  constructor(
    private sharedDataService: SharedDataService,
    private menuService: MenuService,
    public sanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.url) {
          if (!this.selectedMenu) {
            const url = event.url.split("/");
            if (url[1] === "dispatch") {
              this.selectedMenu = "home";
            } else {
              this.selectedMenu = url[1];
            }
            this.onSelectMenu(this.menus ? this.menus[this.selectedMenu] : null);
          }
        }
      }
    });
    this.getUser();
  }

  getUser() {
    this.sharedDataService
      .getUser()
      .subscribe(user => {
        if (user) {
          this.user = user;
          if (this.user && this.user.roles) {
            this.menus = this.menuService.getMenu(this.user.roles);
          }
        }
      });
  }

  sortMethod(a, b) {
    return a.value.position > b.value.position ? 1 : -1;
  }

  onSelectMenu(menu) {
    console.log('TEST100: ', menu);
    this.selectedMenu = menu;
    this.sharedDataService.saveSubMenus(menu ? menu.subMenus : null);
    // const subMenus = menu.subMenus;
    // if (subMenus && subMenus.length) {
    //   this.sharedDataService.saveSubMenus(subMenus);
    // } else {
    //   this.sharedDataService.saveSubMenus(null);
    // }
  }
}
