import { Component, OnInit, Renderer2, OnDestroy } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { Validators } from "@angular/forms";
import { AuthService } from "../../service/auth-service/auth.service";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { AppState, selectAuthState } from "../../ngrx/app.states";
import { AuthActionTypes } from "../../ngrx/store/actions/auth.actions";
import { Observable } from "rxjs";
import { sweetAlert } from "../../utils/swal";
import { takeUntil } from "rxjs/operators";
import { componentDestroyed } from "@w11k/ngx-componentdestroyed";
import { SocketService } from "src/app/service/socket-service/socket-service.service";
import { SharedDataService } from "src/app/service/shared-service/shared-data.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-sign-in",
  templateUrl: "./sign-in.component.html",
  styleUrls: ["./sign-in.component.css"],
})
export class SignInComponent implements OnInit, OnDestroy {
  success: boolean;
  passwordToggleFlag = true;
  roles = "company";
  signInForm: FormGroup;
  submitted = false;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private sharedDataService: SharedDataService,
    private socket: SocketService,
    private router: Router,
    private renderere: Renderer2
  ) { }

  ngOnInit() {
    this.signInForm = this.fb.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", [Validators.required, Validators.minLength(6), Validators.maxLength(16)]],
    });

    if (!environment.production) {
      this.signInForm.patchValue({
        email: "medfastcab@yamsol.com",
        password: "123456"
      });
    }
  }

  get form() {
    return this.signInForm.controls;
  }

  togglePasswordType() {
    this.passwordToggleFlag = !this.passwordToggleFlag;
  }

  onSignIn() {
    if (this.signInForm.invalid) {
      this.submitted = true;
      return;
    }
    const payload = {
      email: this.signInForm.value.email,
      password: this.signInForm.value.password,
      roles: this.roles
    };

    this.authService
      .login(this.signInForm.value.email, this.signInForm.value.password, this.roles)
      .subscribe(data => {
        if (data && data.success) {
          this.sharedDataService.saveUser(data.user);
          this.authService.setToken(data.token);
          this.socket.connectSocket();
          this.router.navigateByUrl('/');
        } else {
          sweetAlert("Error", data.message, "warning", "OK");
        }
      });
  }

  addJsToElement(src: string): HTMLScriptElement {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = src;
    this.renderere.appendChild(document.body, script);
    return script;
  }

  ngOnDestroy(): void { }

  changeRole(event) {
    console.log(event.target.checked);
    if (event.target.checked) {
      this.roles = "company";
    } else {
      this.roles = "staff";
    }
  }
}
