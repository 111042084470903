import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { VehicleExpenseState, selectVehicleExpenseState } from '../../../ngrx/app.states';
import { VehicleExpenseActionTypes, GetVehicleServiceList } from '../../../ngrx/store/actions/vehicle-expense.actions';
import { Observable } from 'rxjs';
import { sweetAlert } from '../../../utils/swal';
import { takeUntil } from 'rxjs/operators';
import {componentDestroyed} from '@w11k/ngx-componentdestroyed';
@Component({
  selector: 'app-service-types',
  templateUrl: './service-types.component.html',
  styleUrls: ['./service-types.component.css']
})
export class ServiceTypesComponent implements OnInit, OnDestroy {

  getVehicleExpenseState: Observable<any>;
  serviceTypeList = [];
  search: any;
  imgPlaceHolder = '../../../assets/images/image-place-holder/image-placeholder.png';
  constructor(private store: Store<VehicleExpenseState>) {
    this.getVehicleExpenseState = this.store.select(selectVehicleExpenseState);
  }


  ngOnInit() {
    this.getServiceTypeList();
    this.getVehicleExpenseState
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe((state) => {
      if (state.type === VehicleExpenseActionTypes.GET_VEHICLE_SERVICE_LIST) {
        if (state.success) {

          this.serviceTypeList = state.response.maintenanceservice;
        } else if (state.errorMessage) {
          sweetAlert('Error', state.errorMessage, 'error', 'OK');
        }
      }
    });
  }

  getServiceTypeList() {
    this.store.dispatch(new GetVehicleServiceList());
  }

  ngOnDestroy(): void {
  }

}
