import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-completed-cancel-modal',
  templateUrl: './completed-cancel-modal.component.html',
  styleUrls: ['./completed-cancel-modal.component.css']
})
export class CompletedCancelModalComponent implements OnInit {
  @Input() trip;
  @Output() cancelCompleted = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  cancelTrip() {
    this.trip.status = 'cancelled';
    this.trip.jobStatus = 'cancelled';
    // call api here
  }

}
