import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';
import { selectKpisState, KpisState } from '../../../ngrx/app.states';
import {
  KpisActionTypes, GetDriverBreakdown, DriverBreakdownByDateRange
} from '../../../ngrx/store/actions/kpis.actions';
import { Observable, from, Subscription } from 'rxjs';
import { sweetAlert } from 'src/app/utils/swal';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { addDataTableScript, removeDataTableScript } from 'src/app/utils/utils.common';

declare var $;
@Component({
  selector: 'app-driver-breakdown',
  templateUrl: './driver-breakdown.component.html',
  styleUrls: ['./driver-breakdown.component.css']
})
export class DriverBreakdownComponent implements OnInit, OnDestroy {


  dateForm: FormGroup;
  getKpisState: Observable<any>;
  kpisStats = null;
  drivers = [];
  searchDriver;
  scriptLoadCount = 0;
  constructor(private fb: FormBuilder, private store: Store<KpisState>) {
    this.getKpisState = this.store.select(selectKpisState);
  }

  ngOnInit() {



    this.getKpisState
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((state) => {
        if (state.success) {
          if (state.type === KpisActionTypes.DRIVER_BREAKDOWN || state.type === KpisActionTypes.DRIVER_BREAKDOWN_BY_DATE_RANGE) {

            this.kpisStats = state.response.kpis.length > 0 ? state.response.kpis[0] : null;
            this.drivers = this.kpisStats && this.kpisStats.earnings.length > 0 ? this.kpisStats.earnings : [];
            if (this.scriptLoadCount > 0) {
              var table = $('#dataTableExample').DataTable();
              table.destroy();
              removeDataTableScript();

            }

            addDataTableScript();
            this.scriptLoadCount++;

            // this.brokerKpis = state.response.brokerKpis;
            // this.corporateKpis = state.response.corporateKpis.length > 0 ? state.response.corporateKpis[0].earnings : state.response.corporateKpis;
            // this.noCorporateTrips = state.response.corporateKpis.length > 0 ? state.response.corporateKpis[0].totalJobs : 0;
            // this.driverKpis = state.response.driverKpis.length > 0 ? state.response.driverKpis[0].earnings : state.response.driverKpis;
            // this.brokerKpis.forEach(element => {
            //   this.nobrokerTrips = this.nobrokerTrips + element.totalJobs;
            // });
          }
        } else if (state.errorMessage) {
          sweetAlert('Error', state.errorMessage, 'error', 'Ok');
        }
      });

    this.dateForm = this.fb.group({
      dateRange: ['']
    });
    this.getDriverBreakDownDetails();
  }

  getDriverBreakDownDetails() {
    this.store.dispatch(new GetDriverBreakdown());
  }

  getNemtStatsByDateRange() {
    const startDate = this.dateForm.value.dateRange[0].toISOString();
    const endDate = this.dateForm.value.dateRange[1].toISOString();
    this.store.dispatch(new DriverBreakdownByDateRange(startDate, endDate));
  }

  ngOnDestroy(): void {
  }

}
