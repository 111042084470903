import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, exhaustMap, catchError } from 'rxjs/operators';
import { DispatchCenterService } from '../../../component/dispatch-center/dispatch-center-service/dispatch-center-service';
import { of } from 'rxjs';
import {
    DispatchCenterActionTypes, CommonSuccess, CommonFailure, TripDetails,
    GetUnassignedTripDetail, AdjustmentDetails, GetBrokerRate
} from '../actions/dispatch-center-actions';
import { Observable } from 'rxjs';


@Injectable()
export class DispatchCenterEffects {

    constructor(
        private actions: Actions,
        private dispatchCenterService: DispatchCenterService
    ) { }

    @Effect()
    TripDetails: Observable<any> = this.actions.pipe(ofType(DispatchCenterActionTypes.TRIP_DETAILS),
        map((action: TripDetails) => action.payload),
        // Use `exhaustMap` to wait for Observable respond
        exhaustMap((payload) =>
            this.dispatchCenterService
                .getTripDetails(payload)
                .pipe(
                    map((data) => {
                        if (data) {
                            return new CommonSuccess({ type: DispatchCenterActionTypes.TRIP_DETAILS, data });
                        } else {
                            return new CommonFailure({ type: DispatchCenterActionTypes.TRIP_DETAILS, data });
                        }

                    }),
                    catchError((error) => {
                        return of(new CommonFailure({ type: DispatchCenterActionTypes.TRIP_DETAILS, error }));
                    })
                )
        )
    );

    @Effect()
    AdjustmentDetails: Observable<any> = this.actions.pipe(ofType(DispatchCenterActionTypes.ADJUSTMENT_DETAILS),
        map((action: AdjustmentDetails) => action),
        // Use `exhaustMap` to wait for Observable respond
        exhaustMap((action) =>
            this.dispatchCenterService
                .getAdjustmentDetail(action.id, action.payload)
                .pipe(
                    map((data) => {
                        if (data) {
                            return new CommonSuccess({ type: DispatchCenterActionTypes.ADJUSTMENT_DETAILS, data });
                        } else {
                            return new CommonFailure({ type: DispatchCenterActionTypes.ADJUSTMENT_DETAILS, data });
                        }

                    }),
                    catchError((error) => {
                        return of(new CommonFailure({ type: DispatchCenterActionTypes.ADJUSTMENT_DETAILS, error }));
                    })
                )
        )
    );

    @Effect()
    GetBrokerRate: Observable<any> = this.actions.pipe(ofType(DispatchCenterActionTypes.BROKER_RATE),
        map((action: GetBrokerRate) => action.payload),
        // Use `exhaustMap` to wait for Observable respond
        exhaustMap((payload) =>
            this.dispatchCenterService
                .getBrokerRate(payload)
                .pipe(
                    map((data) => {
                        if (data) {
                            return new CommonSuccess({ type: DispatchCenterActionTypes.BROKER_RATE, data });
                        } else {
                            return new CommonFailure({ type: DispatchCenterActionTypes.BROKER_RATE, data });
                        }

                    }),
                    catchError((error) => {
                        return of(new CommonFailure({ type: DispatchCenterActionTypes.BROKER_RATE, error }));
                    })
                )
        )
    );


}
