import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectAccidentReportState, AccidentReportState } from '../../../ngrx/app.states';
import { AccidentReportActionTypes, GetAccidentReport } from '../../../ngrx/store/actions/accident-report.actions';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { sweetAlert } from 'src/app/utils/swal';
import { addDataTableScript } from 'src/app/utils/utils.common';
@Component({
  selector: 'app-accidnets-list',
  templateUrl: './accidnets-list.component.html',
  styleUrls: ['./accidnets-list.component.css']
})
export class AccidnetsListComponent implements OnInit ,OnDestroy {

  accidentReportState: Observable<any>;
  accidentReportList = null;
  constructor(private store: Store<AccidentReportState>) { 
    this.accidentReportState = this.store.select(selectAccidentReportState);
  }
 

  ngOnInit() {
    this.accidentReportState
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe((state) => {
      if (state.success) {
        if (state.type === AccidentReportActionTypes.GET_ACCIDNET_REPROT) {
          console.log(state)
          this.accidentReportList = state.response;
          addDataTableScript();
        }
      } else if (state.errorMessage) {
        sweetAlert('Error', state.errorMessage, 'error', 'OK');
      }
    });

    this.store.dispatch(new GetAccidentReport());
  }

  ngOnDestroy(): void {
  }

}
