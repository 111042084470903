import { Action } from '@ngrx/store';


export enum ReportsActionTypes {
    GET_REPORTS = '[Report] Get Reports',
    GET_DRIVER_PDF_REPORT = '[Report] Get Driver Pdf Report',
    GET_PAYMENT_REPORT = '[Report] Get Payment Report',
    COMMON_SUCCESS = '[Report] Common Success',
    COMMON_FAILURE = '[Report] Common Faliure'
}

export class GetReports implements Action {
    readonly type = ReportsActionTypes.GET_REPORTS;
    constructor( public payload: any) {
    }

}

export class GetDriverPdfReport implements Action {
    readonly type = ReportsActionTypes.GET_DRIVER_PDF_REPORT;
    constructor( public payload: any) {
    }

}

export class GetPaymentPdfReport implements Action {
    readonly type = ReportsActionTypes.GET_PAYMENT_REPORT;
    constructor( public payload: any) {
    }

}


export class CommonSuccess implements Action {
    type = ReportsActionTypes.COMMON_SUCCESS;
    constructor(public payload: any) {

    }
}

export class CommonFailure implements Action {
    type = ReportsActionTypes.COMMON_FAILURE;
    constructor(public payload: any) {

    }
}

export type All =
    | GetReports
    | GetDriverPdfReport
    | GetPaymentPdfReport
    | CommonSuccess
    | CommonFailure;
