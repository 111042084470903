import { Action } from '@ngrx/store';


export enum PlannerActionTypes {
    GET_PLANNER_LIST = '[Planner] Get Planner List',
    SCRAP_FILE = '[Planner] Scrap File',
    COMMON_SUCCESS = '[Planner] Common Success',
    COMMON_FAILURE = '[Planner] Common Faliure'
}

export class GetPlannerList implements Action {
    readonly type = PlannerActionTypes.GET_PLANNER_LIST;
    constructor( public endPoint: any) {
    }

}

export class ScrapFile implements Action {
  readonly type = PlannerActionTypes.SCRAP_FILE;
  constructor( public file: any, public data: any) {
  }

}

export class CommonSuccess implements Action {
    type = PlannerActionTypes.COMMON_SUCCESS;
    constructor(public payload: any) {

    }
}

export class CommonFailure implements Action {
    type = PlannerActionTypes.COMMON_FAILURE;
    constructor(public payload: any) {

    }
}

export type All =
    | GetPlannerList
    | ScrapFile
    | CommonSuccess
    | CommonFailure;
