import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import * as moment from 'moment-timezone';
import { AuthService } from '../../../service/auth-service/auth.service';
import { imageProcessing } from 'src/app/utils/image-processing-utils';
import { Store } from '@ngrx/store';
import { selectAccidentReportState, AccidentReportState } from '../../../ngrx/app.states';
import { AddAccidentReport, AccidentReportActionTypes, GetAccidentReportById } from '../../../ngrx/store/actions/accident-report.actions';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { sweetAlert } from 'src/app/utils/swal';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-add-accident',
  templateUrl: './add-accident.component.html',
  styleUrls: ['./add-accident.component.css']
})
export class AddAccidentComponent implements OnInit, OnDestroy {

  selectedFormName = 'basicInformation';
  basicInfoForm: FormGroup;
  driversVehicleForm: FormGroup;
  othersVehicleForm: FormGroup;
  driversDamageAccountForm: FormGroup;
  witnessesForm: FormGroup;
  accidentForm: FormGroup;
  submitted = false;
  timeZone;
  todayDate;
  accidentScenes = [];
  accidentScenesCopy = [];
  ownVehicle = [];
  ownVehicleCopy = [];
  otherScenes = [];
  otherScenesCopy = [];
  witnessForm: FormArray;
  accidentReportState: Observable<any>;
  view = false;
  constructor(private fb: FormBuilder, private authService: AuthService, private store: Store<AccidentReportState>, private router: Router, private activatedRoute: ActivatedRoute) {
    this.timeZone = this.authService.getUserData().timeZone;
    this.accidentReportState = this.store.select(selectAccidentReportState);
  }
  

  ngOnInit() {
    
    this.accidentReportState
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((state) => {
        if (state.success) {
          if (state.type === AccidentReportActionTypes.ADD_ACCIDNET_REPROT) {
            console.log(state)
            sweetAlert('Success', "Accident Report Added Successfully", 'success', 'OK');
          }
        } else if (state.errorMessage) {
          sweetAlert('Error', state.errorMessage, 'error', 'OK');
        }
      })

    this.todayDate = moment().tz(this.timeZone).format();
    this.basicInfoForm = this.fb.group({
      driverInjured: [false, Validators.required],
      memberInjured: [false, Validators.required],
      whoseFault: ['currentdriver', Validators.required],
      policeReport: [false, Validators.required],
      revenueVehicle: [false, Validators.required],
      location: ['', Validators.required],
      coords: [],
      accidentAt: [''],
      accidentDate: ['', Validators.required],
      accidentMin: ['', Validators.required],
      accidentHour: ['', Validators.required]
    });

    this.driversVehicleForm = this.fb.group({
      isDriveable: [false, Validators.required],
      vehicleCurrentLocation: ['', Validators.required],
      coords: [],
      speed: ['', Validators.required],
      trafficSignalViolation: [false, Validators.required],
      weather: ['', Validators.required],
      roadContidion: ['', Validators.required],
      isFrontRightDamage: [false, Validators.required],
      isFrontDamage: [false, Validators.required],
      isFrontLeftDamage: [false, Validators.required],
      isLeftMirrorDamage: [false, Validators.required],
      isLeftCenterDamage: [false, Validators.required],
      isLeftRearDamage: [false, Validators.required],
      isBackRightDamage: [false, Validators.required],
      isRightMirrorDamage: [false, Validators.required],
      isRightCenterDamage: [false, Validators.required],
      isBackDamage: [false, Validators.required],
      isRightRearDamage: [false, Validators.required],
      isBackLeftDamage: [false, Validators.required],
      isTopDamage: [false, Validators.required]
    });

    this.othersVehicleForm = this.fb.group({
      isDriveable: [false, Validators.required],
      makeModel: ['', Validators.required],
      licenseNumber: ['', Validators.required],
      state: ['', Validators.required],
      vehicleCurrentLocation: ['', Validators.required],
      coords: [],
      speed: ['', Validators.required],
      ownerName: ['', Validators.required],
      ownerAddress: ['', Validators.required],
      ownerPhone: ['', Validators.required],
      driverName: ['', Validators.required],
      driverAddress: ['', Validators.required],
      driverPhone: ['', Validators.required],
      isFrontRightDamage: [false, Validators.required],
      isFrontDamage: [false, Validators.required],
      isFrontLeftDamage: [false, Validators.required],
      isLeftMirrorDamage: [false, Validators.required],
      isLeftCenterDamage: [false, Validators.required],
      isLeftRearDamage: [false, Validators.required],
      isBackRightDamage: [false, Validators.required],
      isRightMirrorDamage: [false, Validators.required],
      isRightCenterDamage: [false, Validators.required],
      isBackDamage: [false, Validators.required],
      isRightRearDamage: [false, Validators.required],
      isBackLeftDamage: [false, Validators.required],
      isTopDamage: [false, Validators.required]
    });

    this.witnessesForm = this.fb.group({
      witnessess: this.fb.array([this.createWitness()])
    });

    this.driversDamageAccountForm = this.fb.group({
      damageDescription: ['', Validators.required],
      insuredVehicle: ['', Validators.required],
      accidentCaused: ['', Validators.required]
    });

    if (this.activatedRoute.snapshot.url.length === 3) {
      this.view = true;
      const id = this.activatedRoute.snapshot.url[2].path;
      this.getAccidentDetail(id);
      this.accidentReportState
      .subscribe((state) => {
        if (state.success) {
          if (state.type === AccidentReportActionTypes.GET_ACCIDNET_REPROT_BY_ID) {
            console.log(state)
            this.driversDamageAccountForm.patchValue({
              ...state.response.driverDamageAccount
            });
            this.othersVehicleForm.patchValue({
              ...state.response.otherVehicleInformation
            });
            this.driversVehicleForm.patchValue({
              ...state.response.driverVehicleInformation
            });
            this.basicInfoForm.patchValue({
              ...state.response.basicInformation,
              accidentDate: state.response.basicInformation.accidentAt
            });
            if (state.response.basicInformation.accidentAt) {
              const time = state.response.basicInformation.accidentAt;
              if (parseInt(time.split('T')[1].substr(3, 2)) % 10 !== 0) {
                // tslint:disable-next-line: radix
                const min = parseInt(time.split('T')[1].substr(3, 2))
                  // tslint:disable-next-line: radix
                  + (10 - parseInt(time.split('T')[1].substr(3, 2)) % 10);
                this.basicInfoForm.patchValue({
                  accidentHour: time.split('T')[1].substr(0, 2),
                  accidentMin: min.toString(),
                });
              } else {
                this.basicInfoForm.patchValue({ 
                  accidentHour: time.split('T')[1].substr(0, 2),
                  accidentMin: time.split('T')[1].substr(3, 2),
                });
              }
            }
            this.accidentScenes = state.response.accidentScenes;
            state.response.accidentScenes.forEach((img) => {
              this.accidentScenesCopy.push('data:image/png;base64,'+img)
            });
            //5ea69996686bb51caa36bc20
            this.otherScenes = state.response.otherScenes;
            state.response.otherScenes.forEach((img) => {
              this.otherScenesCopy.push('data:image/png;base64,'+img)
            });
            this.ownVehicle = state.response.ownVehicle;
            state.response.ownVehicle.forEach((img) => {
              this.ownVehicleCopy.push('data:image/png;base64,'+img)
            });
            // this.witnessForm.patchValue({
            //   ...state.response.witnessess
            // })
            // state.response.witnessess.forEach((witness, ind) => {
            //   if (state.response.witnessess.length - 1 === ind) {

            //   } else {
            //     this.createWitness();
            //   }
            //   this.addWitness();
            //   console.log(this.witnessForm)
            //   console.log(this.witnessForm.length)
            //   console.log(witness)
            //   console.log(ind)
            //   this.witnessForm.at(ind).patchValue({
            //     witness
            //   })
            // })
          }
        } else if (state.errorMessage) {
          sweetAlert('Error', state.errorMessage, 'error', 'OK');
        }
      })
    }

    if (!this.view) {
      if(!window.history.state.fleetId || !window.history.state.driverId || !window.history.state.jobId) {
        this.router.navigateByUrl('/assignstrips/unassigned')
      }
    }

  }

  createWitness(): FormGroup {
    return this.fb.group({
      name: ['', Validators.required],
      address: ['', Validators.required],
      phone: ['', Validators.required]
    })
  }

  addWitness() {
    this.witnessForm.push(this.createWitness());
  }

  removeWitness(index) {
    this.witnessForm.removeAt(index);
  }

  get witnessElement() {
    this.witnessForm = this.witnessesForm.controls.witnessess as FormArray;
    return this.witnessForm;
  }

  get form() {
    return this.basicInfoForm.controls;
  }

  get driversVehicleform() {
    return this.driversVehicleForm.controls;
  }

  get othersVehicleform() {
    return this.othersVehicleForm.controls;
  }

  get driversDamageAccountform() {
    return this.driversDamageAccountForm.controls;
  }

  getAccidentLocation(place) {
    this.basicInfoForm.patchValue({
      location: place.formatted_address,
      coords: [place.geometry.location.lng(), place.geometry.location.lat()]
    });
  }

  getWitnessAddress(place, index) {
    this.witnessForm.at(index).patchValue({
      address: place.formatted_address
    });
  }

  onAccidentDateChange(event) {
    console.log(event)
    const date = moment.utc(event).local().format();
    console.log(date)
    if (event) {
      this.basicInfoForm.patchValue({
        accidentAt: date,
      });
    }
  }

  setAccidentTime() {
    console.log(this.basicInfoForm.value.accidentDate)
    let accidentTime = this.basicInfoForm.value.accidentAt.split('T')[0];
    accidentTime = moment.tz(accidentTime + ' ' + this.basicInfoForm.value.accidentHour
      + ':' + this.basicInfoForm.value.accidentMin + ':00', this.timeZone).toISOString();

    this.basicInfoForm.patchValue({
      accidentAt: accidentTime,
    });
  }

  getVehicleCurrentLocation(place) {
    this.driversVehicleForm.patchValue({
      vehicleCurrentLocation: place.formatted_address,
      coords: [place.geometry.location.lng(), place.geometry.location.lat()]
    });
  }

  getOtherVehicleCurrentLocation(place) {
    this.othersVehicleForm.patchValue({
      vehicleCurrentLocation: place.formatted_address,
      coords: [place.geometry.location.lng(), place.geometry.location.lat()]
    });
  }

  getOtherVehicleOwnerAddress(place) {
    this.othersVehicleForm.patchValue({
      ownerAddress: place.formatted_address,
    });
  }

  getOtherVehicleDriverAddress(place) {
    this.othersVehicleForm.patchValue({
      driverAddress: place.formatted_address,
    });
  }

  formSelected(name) {
    this.selectedFormName = name;
  }

  addAccidentScenesImages(imageInput) {
    imageProcessing(imageInput).subscribe((result) => {
      console.log(result.src)
      this.accidentScenesCopy.push(result.src)
      result.src = result.src.replace(/^data:image\/png;base64,/, '')
      result.src = result.src.replace(/^data:image\/jpeg;base64,/, '')
      result.src = result.src.replace(/^data:image\/jpg;base64,/, '')
      result.src = result.src.replace(/^data:image\/gif;base64,/, '')
      console.log(result.src)

      this.accidentScenes.push(result.src);
    });
  }

  removeAccidentSceneImg(index) {
    this.accidentScenes.splice(index, 1);
    this.accidentScenesCopy.splice(index, 1);
  }

  addOwnVehicleImages(imageInput) {
    imageProcessing(imageInput).subscribe((result) => {
      this.ownVehicleCopy.push(result.src)
      result.src = result.src.replace(/^data:image\/png;base64,/, '')
      result.src = result.src.replace(/^data:image\/jpeg;base64,/, '')
      result.src = result.src.replace(/^data:image\/jpg;base64,/, '')
      result.src = result.src.replace(/^data:image\/gif;base64,/, '')
      this.ownVehicle.push(result.src);
    });
  }

  removeOwnVehicleImg(index) {
    this.ownVehicle.splice(index, 1);
    this.ownVehicleCopy.splice(index, 1);
  }

  addOtherSceneImages(imageInput) {
    imageProcessing(imageInput).subscribe((result) => {
      this.otherScenesCopy.push(result.src)
      result.src = result.src.replace(/^data:image\/png;base64,/, '')
      result.src = result.src.replace(/^data:image\/jpeg;base64,/, '')
      result.src = result.src.replace(/^data:image\/jpg;base64,/, '')
      result.src = result.src.replace(/^data:image\/gif;base64,/, '')
      this.otherScenes.push(result.src);
    });
  }

  removeOtherSceneImg(index) {
    this.otherScenes.splice(index, 1);
    this.otherScenesCopy.splice(index, 1);
  }

  onSubmit() {
    this.submitted = true;
    this.setAccidentTime();

    if (this.basicInfoForm.invalid) {
      this.formSelected('basicInformation')
      return;
    }
    if (this.driversVehicleForm.invalid) {
      this.formSelected('driversVehicle');
      return;
    }
    if (this.othersVehicleForm.invalid) {
      this.formSelected('othersVehicle');
      return;
    }
    if (this.witnessesForm.invalid) {
      this.formSelected('witnesses');
      return;
    }
    if (this.driversDamageAccountForm.invalid) {
      this.formSelected('driversAccount');
      return;
    }
    this.accidentForm = this.fb.group({
      basicInformation: this.basicInfoForm,
      accidentScenes: [this.accidentScenes],
      ownVehicle: [this.ownVehicle],
      otherScenes: [this.otherScenes],
      driverVehicleInformation: this.driversVehicleForm,
      otherVehicleInformation: this.othersVehicleForm,
      witnessess: this.witnessesForm.controls['witnessess'],
      driverDamageAccount: this.driversDamageAccountForm,
      fleet: window.history.state.fleetId,
      driver: window.history.state.driverId,
      company: this.authService.getUserData()._id,
      job: window.history.state.jobId
    })
    console.log(this.accidentForm.value);
    this.store.dispatch(new AddAccidentReport(this.accidentForm.value));
  }

  getAccidentDetail(id) {
    this.store.dispatch(new GetAccidentReportById(id));
  }

  ngOnDestroy(): void {
  }

}
