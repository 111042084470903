import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { selectKpisState, KpisState } from '../../../ngrx/app.states';
import { KpisActionTypes, NemtStatisticsDetailByDateRange, NemtStatisticsByMemberId } from '../../../ngrx/store/actions/kpis.actions';
import { Observable } from 'rxjs';
import { sweetAlert } from 'src/app/utils/swal';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { loadMyScript } from 'src/app/utils/utils.common';
@Component({
  selector: 'app-nemt-statistics-details',
  templateUrl: './nemt-statistics-details.component.html',
  styleUrls: ['./nemt-statistics-details.component.css']
})
export class NemtStatisticsDetailsComponent implements OnInit, OnDestroy {

  startDate;
  endDate;
  detailType;
  getKpisState: Observable<any>;
  kpisStatDetails;
  showModal = false;
  search: any;
  kpisStatDetailsPopup;
  // kpisStatDetailsPopup = {
  //   priorityClient: {displayName: ''},
  //   tripId: '',
  //   jobOriginAddress: '',
  //   jobDestinationAddress: '',
  //   scheduleTime: '',
  //   changeReason: '',
  //   companyNote: '',
  //   billerNote: ''

  // };

  constructor(private activatedRoute: ActivatedRoute, private router: Router,
    private store: Store<KpisState>) {
    this.getKpisState = this.store.select(selectKpisState);
  }

  ngOnInit() {
    this.getKpisState
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((state) => {
        if (state.success) {
          if (state.type === KpisActionTypes.NEMT_STATISTICS_DETAIL_BY_DATE_RANGE) {
            this.kpisStatDetails = state.response;
            loadMyScript("assets/vendors/datatables.net/jquery.dataTables.js");
            loadMyScript("assets/vendors/datatables.net-bs4/dataTables.bootstrap4.js");
            loadMyScript("assets/js/data-table.js");
          } else if (state.type === KpisActionTypes.NEMT_STATISTICS_DETAIL_BY_MEMBER_ID) {
            this.kpisStatDetailsPopup = state.response;
          }
        } else if (state.errorMessage) {
          sweetAlert('Error', state.errorMessage, 'error', 'Ok');
        }
      });
    this.detailType = window.history.state.detailType;
    this.startDate = window.history.state.dateStart;
    this.endDate = window.history.state.dateEnd;
    if (this.startDate && this.endDate && this.detailType) {
      this.nemtStatDetails(this.startDate, this.endDate, this.detailType);
      this.startDate = this.startDate;
      this.endDate = this.endDate;
    }
  }

  nemtStatDetails(startDate, endDate, status) {
    const data = {
      startDate,
      endDate,
      status
    };
    this.store.dispatch(new NemtStatisticsDetailByDateRange(data));
  }

  openDetailModal(memberId) {
    this.kpisStatDetailsPopup = null;
    // this.kpisStatDetailsPopup = {
    //   priorityClient: {displayName: ''},
    //   tripId: '',
    //   jobOriginAddress: '',
    //   jobDestinationAddress: '',
    //   scheduleTime: '',
    //   changeReason: '',
    //   companyNote: '',
    //   billerNote: ''

    // };
    this.store.dispatch(new NemtStatisticsByMemberId(memberId));
  }

  ngOnDestroy(): void {
  }

}
