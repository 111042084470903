import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, exhaustMap, catchError } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import {
    DistributionsActionTypes, UpdateDriverDistribution, GetDriverDistributionList,
    AddDriverDistribution, GetDriverDistributionById, CommonSuccess, CommonFailure
} from '../actions/distributions-actions';
// tslint:disable-next-line: max-line-length
import { DriverDistributionsService } from 'src/app/component/driver-distributions/driver-distributions-services/driver-distributions-service.service';

@Injectable()
export class DistributionsEffects {

    constructor(
        private actions: Actions,
        private driverDistributionsService: DriverDistributionsService
    ) { }

    // History request
    @Effect()
    GetDriverDistributionList: Observable<any> = this.actions.pipe(ofType(DistributionsActionTypes.LIST_DATA),
        // Use `exhaustMap` to wait for Observable respond
        exhaustMap((action) =>
            this.driverDistributionsService
                .getDistributionListWithNgrx()
                .pipe(
                    map((response) => {
                        if (response) {
                            return new CommonSuccess({ type: DistributionsActionTypes.LIST_DATA, response });
                        } else {
                            return new CommonFailure({ type: DistributionsActionTypes.LIST_DATA, response });
                        }

                    }),
                    catchError((error) => {
                        return of(new CommonFailure({ type: DistributionsActionTypes.LIST_DATA, error }));
                    })
                )
        )
    );


    @Effect()
    AddDriverDistribution: Observable<any> = this.actions.pipe(ofType(DistributionsActionTypes.ADD_DRIVER_DISTRIBUTION),
        map((action: AddDriverDistribution) => action.payload),
        // Use `exhaustMap` to wait for Observable respond
        exhaustMap((payload) =>
            this.driverDistributionsService
                .addDriverDistributionWithNrx(payload)
                .pipe(
                    map((response) => {
                        if (response.success) {
                            return new CommonSuccess({ type: DistributionsActionTypes.ADD_DRIVER_DISTRIBUTION, response });
                        } else {
                            return new CommonFailure({ type: DistributionsActionTypes.ADD_DRIVER_DISTRIBUTION, response });
                        }
                    }),
                    catchError((error) => {
                        return of(new CommonFailure({ type: DistributionsActionTypes.ADD_DRIVER_DISTRIBUTION, error }));
                    })
                )
        )
    );

    @Effect()
    UpdateDriverDistribution: Observable<any> = this.actions.pipe(ofType(DistributionsActionTypes.UPDATE_DRIVER_DISTRIBUTION),
        map((action: UpdateDriverDistribution) => action.payload),
        // Use `exhaustMap` to wait for Observable respond
        exhaustMap((payload) =>
            this.driverDistributionsService
                .addDriverDistributionWithNrx(payload)
                .pipe(
                    map((response) => {
                        if (response) {
                            return new CommonSuccess({ type: DistributionsActionTypes.UPDATE_DRIVER_DISTRIBUTION, response });
                        } else {
                            return new CommonFailure({ type: DistributionsActionTypes.UPDATE_DRIVER_DISTRIBUTION, response });
                        }
                    }),
                    catchError((error) => {
                        return of(new CommonFailure({ type: DistributionsActionTypes.UPDATE_DRIVER_DISTRIBUTION, error }));
                    })
                )
        )
    );

    @Effect()
    GetDriverDistributionById: Observable<any> = this.actions.pipe(ofType(DistributionsActionTypes.GET_DRIVER_DISTRIBUTION_BY_ID),
        map((action: GetDriverDistributionById) => action.payload),
        // Use `exhaustMap` to wait for Observable respond
        exhaustMap((payload) =>
            this.driverDistributionsService
                .getDistributionByIdWithNgrx(payload)
                .pipe(
                    map((response) => {
                        if (response) {
                            return new CommonSuccess({ type: DistributionsActionTypes.GET_DRIVER_DISTRIBUTION_BY_ID, response });
                        } else {
                            return new CommonFailure({ type: DistributionsActionTypes.GET_DRIVER_DISTRIBUTION_BY_ID, response });
                        }
                    }),
                    catchError((error) => {
                        return of(new CommonFailure({ type: DistributionsActionTypes.GET_DRIVER_DISTRIBUTION_BY_ID, error }));
                    })
                )
        )
    );


}
