import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { tap, map, exhaustMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import {
    CommonSuccess, CommonFailure,
    AddAccidentReport, AccidentReportActionTypes, GetAccidentReport, GetAccidentReportById
} from '../actions/accident-report.actions';
import { AccidentService } from '../../../component/accidents/accident-service/accident.service';

@Injectable()
export class AccidentReportEffects {
    constructor(
        private actions: Actions,
        private accidentService: AccidentService,
        private router: Router
    ) { }

    @Effect()
    AddAccidentReport: Observable<any> = this.actions.pipe(ofType(AccidentReportActionTypes.ADD_ACCIDNET_REPROT),
        map((action: AddAccidentReport) => action.payload),
        // Use `exhaustMap` to wait for Observable respond
        exhaustMap((payload) =>
            this.accidentService
                .addAccidentReport(payload)
                .pipe(
                    map((response) => {
                        if (response) {
                            return new CommonSuccess({ type: AccidentReportActionTypes.ADD_ACCIDNET_REPROT, response });
                        } else {
                            return new CommonFailure({ type: AccidentReportActionTypes.ADD_ACCIDNET_REPROT, response });
                        }

                    }),
                    catchError((error) => {
                        return of(new CommonFailure({ type: AccidentReportActionTypes.ADD_ACCIDNET_REPROT, error }));
                    })
                )
        )
    );

    @Effect()
    GetAccidentReport: Observable<any> = this.actions.pipe(ofType(AccidentReportActionTypes.GET_ACCIDNET_REPROT),
        // Use `exhaustMap` to wait for Observable respond
        exhaustMap(() =>
            this.accidentService
                .getAccidentReport()
                .pipe(
                    map((response) => {
                        if (response) {
                            return new CommonSuccess({ type: AccidentReportActionTypes.GET_ACCIDNET_REPROT, response });
                        } else {
                            return new CommonFailure({ type: AccidentReportActionTypes.GET_ACCIDNET_REPROT, response });
                        }

                    }),
                    catchError((error) => {
                        return of(new CommonFailure({ type: AccidentReportActionTypes.GET_ACCIDNET_REPROT, error }));
                    })
                )
        )
    );

    @Effect()
    GetAccidentReportById: Observable<any> = this.actions.pipe(ofType(AccidentReportActionTypes.GET_ACCIDNET_REPROT_BY_ID),
        map((action: GetAccidentReportById) => action.payload),
        // Use `exhaustMap` to wait for Observable respond
        exhaustMap((payload) =>
            this.accidentService
                .getAccidentReportById(payload)
                .pipe(
                    map((response) => {
                        if (response) {
                            return new CommonSuccess({ type: AccidentReportActionTypes.GET_ACCIDNET_REPROT_BY_ID, response });
                        } else {
                            return new CommonFailure({ type: AccidentReportActionTypes.GET_ACCIDNET_REPROT_BY_ID, response });
                        }

                    }),
                    catchError((error) => {
                        return of(new CommonFailure({ type: AccidentReportActionTypes.GET_ACCIDNET_REPROT_BY_ID, error }));
                    })
                )
        )
    );


}