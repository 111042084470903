import { Injectable } from "@angular/core";
import { HeadingModel,  MenuModel } from "./menu-model";
import { SubMenuModel } from "./menu-model";
@Injectable({
  providedIn: "root",
})
export class DynamicMenuService {
  constructor() {}
  menu = {};
  roles = "*";
  company = "admin";

  addSectionItem(menuObj: HeadingModel): void {
    if (menuObj.roles.includes(this.roles) || menuObj.roles.includes("*")) {
      menuObj.subMenu = {};
      this.menu[menuObj.state] = menuObj;
    }
  }

  addMenuItem(object, menuObj: MenuModel) {
    if (
      (object && menuObj.roles.includes(this.roles)) ||
      menuObj.roles.includes("*")
    ) {
      object.subMenu[menuObj.state] = menuObj;
    }
  }

  addSubMenuItem(object, subMenuObj: SubMenuModel) {
    console.log(object);
    console.log(subMenuObj);
    if (
      (object && subMenuObj.roles.includes(this.roles)) ||
      subMenuObj.roles.includes("*")
    ) {
      object.subMenu.push(subMenuObj);
    }
  }

  generateMenu(role) {
    this.roles = 'admin';
    this.addHeadings();
    this.addMenu();
    console.log(this.menu);
    return this.menu;
  }
// Heading
  addHeadings() {

    // Section Start
    this.addSectionItem({
      position: 1,
      title: "Main",
      state: "main",
      roles: [this.company],
      subMenu: {},
    });

    this.addSectionItem({
      position: 2,
      title: "Business",
      state: "business",
      roles: [this.company],
      subMenu:  {},
    });
    this.addSectionItem({
      position: 3,
      title: "Fare",
      state: "fare",
      roles: [this.company],
      subMenu:  {},
    });

    // this.addSectionItem({
    //   position: 4,
    //   title: "REPORT",
    //   state: "report",
    //   roles: [this.company],
    //   subMenu:  {},
    // }); 

    // this.addSectionItem({
    //   position: 5,
    //   title: "Support",
    //   state: "support",
    //   roles: [this.company],
    //   subMenu:  {},
    // });

 // Section End
  }

  addMenu() {
    // Menu Start
 this.addMainMenu();
 this.addBusinessMenu();
 this.addReportMenu();
 this.addSupportMenu();
 this.addFareMenu();

 // Menu End
  }
// Menu
  addFareMenu() {
    // this.addMenuItem(this.menu["fare"],{
    //   url: "vehicleexpense",
    //   class: "fa fa-car-crash",
    //   position: 1,
    //   title: "Vehicle Expense",
    //   state: "expense",
    //   type: "dropdown",
    //   roles: [this.company],
    //   subMenu: [],
    // });
    this.addMenuItem(this.menu["fare"],{
      url: "dynamicfare",
      class: "fa fa-car",
      position: 2,
      title: "Dynamic Fare",
      state: "fare",
      type: "dropdown",
      roles: [this.company],
      subMenu: [],
    });
    this.addMenuItem(this.menu["fare"], {
      url: "distributions",
      class: "fab fa-pagelines",
      position: 3,
      title: "Driver Distributions",
      state: "distribution",
      type: "dropdown",
      roles: [this.company],
      subMenu: [],
    });
  }

  addSupportMenu() {
    this.addMenuItem(this.menu["support"],{
      url: "/driveralerts",
      class: "fa fa-bell",
      position: 1,
      title: "Driver Alerts",
      state: "alerts",
      type: "dropdown",
      roles: [this.company],
      subMenu: [],
    });
    this.addMenuItem(this.menu["support"],{
      url: "/helpingmaterial",
      class: "fa fa-car",
      position: 2,
      title: "Helping Material",
      state: "help",
      type: "dropdown",
      roles: [this.company],
      subMenu: [],
    });
    this.addMenuItem(this.menu["support"],{
      url: "/invites",
      class: "fa fa-comments",
      position: 3,
      title: "Invites",
      state: "invite",
      type: "dropdown",
      roles: [this.company],
      subMenu: [],
    });
    this.addMenuItem(this.menu["support"],{
      url: '/queries',
      class: 'fa fa-headphones',
      position: 4,
      title: 'Support Center',
      state: 'support',
      type: 'dropdown',
      roles: [this.company],
      subMenu: []
    });
    this.supportSubMenu();
  }

  addReportMenu() {
    this.addMenuItem(this.menu["report"],{
      url: "reports",
      class: "fas fa-file-alt",
      position: 1,
      title: "Reports",
      state: "report",
      type: "dropdown",
      roles: [this.company, "admin"],
      subMenu: [],
    });
    this.addMenuItem(this.menu["report"], {
      url: "kpis",
      class: " fas fa-chart-bar",
      position: 2,
      title: "NEMT Statistics",
      state: "kpis",
      type: "dropdown",
      roles: [this.company, "admin"],
      subMenu: [],
    });
    this.addMenuItem(this.menu["report"],{
      url: "kpis/nemtreport",
      class: " fas fa-chart-bar",
      position: 3,
      title: "NEMT Reports",
      state: "NEMTReports",
      type: "dropdown",
      roles: [this.company, "admin"],
      subMenu: [],
    });
    this.addMenuItem(this.menu["report"], {
      url: "kpis/driverbreakdown",
      class: " fas fa-chart-bar",
      position: 4,
      title: "Driver Breakdown",
      state: "DriverBreakdown",
      type: "dropdown",
      roles: [this.company, "admin"],
      subMenu: [],
    });

    this.addMenuItem(this.menu["report"], {
      url: 'accidents',
      class: 'fa fa-car-crash',
      position: 6,
      title: 'Accidents',
      state: 'accidents',
      type: 'dropdown',
      roles: [this.company],
      subMenu: []
    });
    this.reportSubMenu();
  }

  addBusinessMenu() {
    this.addMenuItem(this.menu["business"], {
      url: "/staffs",
      class: "fa fa-user",
      position: 1,
      title: "Staff",
      state: "staff",
      type: "dropdown",
      roles: [this.company],
      subMenu: [],
    });
    this.addMenuItem(this.menu["business"],{
      url: "corperate",
      class: "fa fa-briefcase",
      position: 2,
      title: "Corporate Users",
      state: "corporate",
      type: "dropdown",
      roles: [this.company],
      subMenu: [],
    });
    this.addMenuItem(this.menu["business"],{
      url: "drivers/",
      class: "fa fa-users",
      position: 3,
      title: "Drivers",
      state: "drivers",
      type: "dropdown",
      roles: [this.company],
      subMenu: [],
    });
    this.addMenuItem(this.menu["business"], {
      url: "fleets",
      class: "fa fa-car-side",
      position: 4,
      title: "Fleet",
      state: "fleet",
      type: "dropdown",
      roles: [this.company],
      subMenu: [],
    });

    this.businessSubMenu();
  }

  addMainMenu() {
    this.addMenuItem(this.menu["main"],{
      url: "/",
      class: "fa fa-car",
      position: 1,
      title: "Dispatch Center",
      state: "dispatches",
      type: "dropdown",
      roles: [this.company],
      subMenu: [],
    });
    
    this.addMenuItem( this.menu["main"],{
      url: "assignstrips",
      class: "fa fa-paperclip",
      position: 2,
      title: "Assigned Trips",
      state: "assigns",
      type: "dropdown",
      roles: [this.company],
      subMenu: [],
    });
    // this.addMenuItem(this.menu["main"],{
    //   url: "offeredtrips",
    //   class: "fa fa-paperclip",
    //   position: 3,
    //   title: "Offered Trips",
    //   state: "offered",
    //   type: "dropdown",
    //   roles: [this.company],
    //   subMenu: [],
    // });
    this.addMenuItem(this.menu["main"], {
      url: "members",
      class: "fa fa-users",
      position: 4,
      title: "Members",
      state: "members",
      type: "dropdown",
      roles: [this.company],
      subMenu: [],
    });
    // this.addMenuItem(this.menu["main"],{
    //   url: 'brocker',
    //   class: 'fa fa-car',
    //   position: 5,
    //   title: 'Broker Trips',
    //   state: 'marketPlace',
    //   type: 'dropdown',
    //   roles: [this.company],
    //   subMenu: []
    // });
    // this.addMenuItem(this.menu["main"], {
    //   url: 'accidents',
    //   class: 'fa fa-car-crash',
    //   position: 6,
    //   title: 'Accidents',
    //   state: 'accidents',
    //   type: 'dropdown',
    //   roles: [this.company],
    //   subMenu: []

    // });
    // this.addMenuItem(this.menu["main"],{
    //   url: 'planner',
    //   class: 'fa fa-car',
    //   position: 7,
    //   title: 'Planner',
    //   state: 'plan',
    //   type: 'dropdown',
    //   roles: [this.company],
    //   subMenu: []
    // });



    this.mainSubMenu();
  }

// Sub Menu
  mainSubMenu() {
// dispatches
    // this.addSubMenuItem(this.menu["main"].subMenu["dispatches"], {
    //   title: "Dashboard",
    //   position: 1,
    //   url: "/",
    //   roles: [this.company],
    // });
    // this.addSubMenuItem(this.menu["main"].subMenu["dispatches"], {
    //   title: "Trips in Progress",
    //   position: 2,
    //   url: "dispatchcenter/inprogress",
    //   roles: [this.company],
    // });
    // this.addSubMenuItem(this.menu["main"].subMenu["dispatches"], {
    //   title: "Pending Trips",
    //   position: 3,
    //   url: "dispatchcenter/pending",
    //   roles: [this.company],
    // });
    // this.addSubMenuItem(this.menu["main"].subMenu["dispatches"], {
    //   title: "Trips History",
    //   position: 4,
    //   url: "dispatchcenter/history",
    //   roles: [this.company],
    // });
// assigns
    this.addSubMenuItem(this.menu["main"].subMenu["assigns"], {
      title: "Dashboard",
      position: 1,
      url: "assignstrips/dashboard",
      roles: [this.company],
    });

    this.addSubMenuItem(this.menu["main"].subMenu["assigns"], {
      title: "Driver Load",
      position: 1.5,
      url: "assignstrips/driverload",
      roles: [this.company],
    });

    this.addSubMenuItem(this.menu["main"].subMenu["assigns"], {
      title: "All Trips",
      position: 2,
      url: "assignstrips/all",
      roles: [this.company],
    });
    this.addSubMenuItem(this.menu["main"].subMenu["assigns"], {
      title: "Unassigned",
      position: 3,
      url: "assignstrips/unassigned",
      roles: [this.company],
    });
    this.addSubMenuItem(this.menu["main"].subMenu["assigns"], {
      title: "Hanging",
      position: 4,
      url: "assignstrips/hanging",
      roles: [this.company],
    });
    this.addSubMenuItem(this.menu["main"].subMenu["assigns"], {
      title: "Inprogress",
      position: 5,
      url: "assignstrips/inprogress",
      roles: [this.company],
    });
    this.addSubMenuItem(this.menu["main"].subMenu["assigns"], {
      title: "Cancelled",
      position: 6,
      url: "assignstrips/cancelled",
      roles: [this.company],
    });
    this.addSubMenuItem(this.menu["main"].subMenu["assigns"], {
      title: "Archived",
      position: 7,
      url: "assignstrips/archived",
      roles: [this.company],
    });
    this.addSubMenuItem(this.menu["main"].subMenu["assigns"], {
      title: "Completed",
      position: 8,
      url: "assignstrips/completed",
      roles: [this.company],
    });

    this.addSubMenuItem(this.menu["main"].subMenu["assigns"], {
      title: "Standing Orders",
      position: 8.1,
      url: "assignstrips/standing-orders",
      roles: [this.company],
    });

    this.addSubMenuItem(this.menu["main"].subMenu["assigns"], {
      title: "Oustanding",
      position: 8.2,
      url: "assignstrips/outstanding",
      roles: [this.company],
    });

    this.addSubMenuItem(this.menu["main"].subMenu["assigns"], {
      title: "Dirt Road",
      position: 8.3,
      url: "assignstrips/dirtroad",
      roles: [this.company],
    });

    // this.addSubMenuItem(this.menu["main"].subMenu["assigns"], {
    //   title: "Expired",
    //   position: 9,
    //   url: "assignstrips/expired",
    //   roles: [this.company],
    // });

  
    // this.addSubMenuItem(this.menu["main"].subMenu["assigns"], {
    //   title: "Verified Trips Logs & Claim File",
    //   position: 10,
    //   url: "assignstrips/triplogs",
    //   roles: [this.company],
    // });
    // this.addSubMenuItem(this.menu["main"].subMenu["assigns"], {
    //   title: "Verified Files",
    //   position: 11,
    //   url: "assignstrips/triplogs/adjusted",
    //   roles: [this.company],
    // });
    this.addSubMenuItem(this.menu["main"].subMenu["assigns"], {
      title: "New Trip",
      position: 12,
      url: "assignstrips/newtrip",
      roles: [this.company],
    });
// offered
    this.addSubMenuItem(this.menu["main"].subMenu["offered"], {
      title: "Dashboard",
      position:1,
      url: "offeredtrips/dashboard",
      roles: [this.company],
    });
    this.addSubMenuItem(this.menu["main"].subMenu["offered"], {
      title: "Trips",
      position:2,
      url: "offeredtrips/trips",
      roles: [this.company],
    });
    this.addSubMenuItem(this.menu["main"].subMenu["offered"], {
      title: "Logs",
      position:3,
      url: "offeredtrips/logs",
      roles: [this.company],
    });
  // members
    this.addSubMenuItem(this.menu["main"].subMenu["members"], {
      title: "List",
      url: "members",
      position:1,
      roles: [this.company],
    });
    this.addSubMenuItem(this.menu["main"].subMenu["members"], {
      title: "Add",
      url: "members/create",
      position:2,
      roles: [this.company],
    });
  }

  businessSubMenu() {
    // Staff
 this.addSubMenuItem(this.menu["business"].subMenu["staff"], {
      title: 'List',
      position:1,
      url: 'staffs',
      roles: [this.company]
    });
    this.addSubMenuItem(this.menu["business"].subMenu["staff"], {
      title: 'Add',
      position:2,
      url: 'staffs/create',
      roles: [this.company]
    });
// Fleet
 this.addSubMenuItem(this.menu["business"].subMenu["corporate"], {
      title: 'List',
      position:1,
      url: 'corperate',
      roles: [this.company]
    });
 this.addSubMenuItem(this.menu["business"].subMenu["corporate"], {
      title: 'Add',
      position:2,
      url: 'corperate/add',
      roles: [this.company]
    });

// Driver
 this.addSubMenuItem(this.menu["business"].subMenu["drivers"], {
      title: 'Add',
      position: 1,
      url: 'drivers/create',
      roles: [this.company]
    });
    this.addSubMenuItem(this.menu["business"].subMenu["drivers"], {
      title: "Active",
      position: 2,
      url: "drivers/active",
      roles: [this.company],
    });
    this.addSubMenuItem(this.menu["business"].subMenu["drivers"], {
      title: "Inactive",
      position: 3,
      url: "drivers/inactive",
      roles: [this.company],
    });

    this.addSubMenuItem(this.menu["business"].subMenu["drivers"], {
      title: "Weekly Invoices",
      position: 4,
      url: "driver-invoice",
      roles: [this.company],
    });

    this.addSubMenuItem(this.menu["business"].subMenu["drivers"], {
      title: "Time In/Out",
      position: 5,
      url: "drivers/timelines",
      roles: [this.company],
    });
// Fleet
  this.addSubMenuItem(this.menu["business"].subMenu["fleet"], {
      title: 'Add',
      position: 1,
      url: 'fleets/create',
      roles: [this.company]
    });
    this.addSubMenuItem(this.menu["business"].subMenu["fleet"], {
      title: "Active",
      position: 2,
      url: "fleets/active",
      roles: [this.company],
    });
    this.addSubMenuItem(this.menu["business"].subMenu["fleet"], {
      title: "Inactive",
      position:3,
      url: "fleets/inactive",
      roles: [this.company],
    });

  }

  reportSubMenu(){
    // Report
   this.addSubMenuItem(this.menu["main"].subMenu["report"], {
      title: 'Earnings',
      position: 1,
      url: 'reports/earnings',
      roles: [this.company]
    });

  }

  supportSubMenu(){

    this.addSubMenuItem(this.menu["main"].subMenu["expense"], {
      title: "Driver Expenses",
      position:1,
      url: "vehicleexpense/driversexpense",
      roles: [this.company],
    });
    this.addSubMenuItem(this.menu["main"].subMenu["expense"], {
      title: "Service Types",
      position:2,
      url: "vehicleexpense/services",
      roles: [this.company],
    });
    this.addSubMenuItem(this.menu["main"].subMenu["expense"], {
      title: 'Add Service',
      position:3,
      url: 'vehicleexpense/services/create',
      roles: [this.company]
    });
    this.addSubMenuItem(this.menu["main"].subMenu["expense"], {
      title: "Maintenance Stats",
      position:4,
      url: "vehicleexpense/maintenancestats",
      roles: [this.company],
    });

  }
}
