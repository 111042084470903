import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { urls } from '../../../utils/url-utils';
import { errorHandl } from '../../../utils/network-utils';

@Injectable({
  providedIn: 'root'
})
export class AccidentService {

  constructor(private http: HttpClient) { }

  addAccidentReport(data) : Observable<any> {
    console.log(data);
    delete data.basicInformation.accidentDate;
    delete data.basicInformation.accidentHour;
    delete data.basicInformation.accidentMin;
    console.log(data);

    return this.http.post<any>(urls.BASE_URL + urls.ACCIDENT_REPORT_URL, data)
      .pipe(
        catchError(errorHandl)
      );

  }

  getAccidentReport() : Observable<any> {

    return this.http.get<any>(urls.BASE_URL + urls.ACCIDENT_REPORT_URL)
      .pipe(
        catchError(errorHandl)
      );

  }

  getAccidentReportById(id) : Observable<any> {

    return this.http.get<any>(urls.BASE_URL + urls.ACCIDENT_REPORT_URL + `/${id}`)
      .pipe(
        catchError(errorHandl)
      );

  }
}
