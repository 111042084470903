import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { imageProcessing, ImageSnippet } from '../../../utils/image-processing-utils';
import { VehicleExpenseActionTypes, AddVehicleService } from '../../../ngrx/store/actions/vehicle-expense.actions';
import { Store } from '@ngrx/store';
import { VehicleExpenseState, selectVehicleExpenseState } from '../../../ngrx/app.states';
import { Observable } from 'rxjs';
import { sweetAlert } from '../../../utils/swal';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import {componentDestroyed} from '@w11k/ngx-componentdestroyed';
@Component({
  selector: 'app-create-service',
  templateUrl: './create-service.component.html',
  styleUrls: ['./create-service.component.css']
})
export class CreateServiceComponent implements OnInit, OnDestroy {


  getVehicleExpenseState: Observable<any>;
  constructor(private fb: FormBuilder, private store: Store<VehicleExpenseState>, private router: Router) {
    this.getVehicleExpenseState = this.store.select(selectVehicleExpenseState);
  }

  createServiceForm: FormGroup;
  serviceImageUrl = null;
  selectedFile = null;
  submitted = false;
  ngOnInit() {
    this.createServiceForm = this.fb.group({
      name: ['', Validators.required],
      images: ['']
    });

    this.getVehicleExpenseState
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe((state) => {
      if (state.type === VehicleExpenseActionTypes.ADD_VEHICLE_SERVICE) {
        if (state.success) {
          sweetAlert('Success', 'Service Type added Successfully', 'success', 'OK')
          .then(() => {
            this.router.navigateByUrl('maintenances/services');
          });
        } else if (state.errorMessage) {
          sweetAlert('Error', state.errorMessage, 'error', 'OK')
          .then(() => {
            this.router.navigateByUrl('maintenances/services');
          });
        }
      }
    });
  }

  processFile(imageInput: any) {
    imageProcessing(imageInput).subscribe((result) => {
      this.selectedFile = result.file;
      this.serviceImageUrl = result.src;
      console.log(this.serviceImageUrl);
    });
  }

  get form() {
    return this.createServiceForm.controls;
  }

  newService() {
    this.submitted = true;
    if (this.createServiceForm.invalid) {
      return;
    }
    const image = {
      imageURL: this.selectedFile
    };
    this.createServiceForm.patchValue({
      images: image
    });
    this.store.dispatch(new AddVehicleService(this.createServiceForm.value));
  }

  ngOnDestroy(): void {
  }

}
