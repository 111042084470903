
import {
    DispatchCenterActionTypes, All
} from '../actions/dispatch-center-actions';

export interface State {
    dispatchCenterHistoryList: null;
    // error message
    errorMessage: string | null;
    success: boolean;
    type: string | null;
}

export const initialState: State = {
    dispatchCenterHistoryList: null,
    errorMessage: null,
    success: false,
    type: null
};
export function reducer(state = initialState, action: All): State {
    switch (action.type) {
        case DispatchCenterActionTypes.COMMON_SUCCESS: {
            return {
                ...state,
                dispatchCenterHistoryList: action.payload.data,
                errorMessage: null,
                success: true,
                type: action.payload.type
            };
        }
        case DispatchCenterActionTypes.COMMON_FAILURE: {
            return {
                ...state,
                dispatchCenterHistoryList: null,
                errorMessage: action.payload.error ? action.payload.error : action.payload.response.message,
                success: false,
                type: action.payload.type
            };
        }
        default: {
            return {
                ...state,
                errorMessage: null,
                success: false,
                type: 'default'
            };
        }
    }
}

