import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { urls } from '../../../utils/url-utils';
import { errorHandl } from '../../../utils/network-utils';
@Injectable({
  providedIn: 'root'
})
export class KpisService {

  constructor(private http: HttpClient) { }

  getNemtStatistics(): Observable<any> {
    return this.http.get<any>(urls.BASE_URL + urls.NEMT_STATISTICS_URL)
    .pipe(
      catchError(errorHandl)
    );
  }

  getDriverBreakdownDetails(): Observable<any> {
    return this.http.get<any>(urls.BASE_URL + urls.DRIVER_BREAKDOWN_URL)
    .pipe(
      catchError(errorHandl)
    );
  }

  getDriverBreakdownDetailsByDate(startDate, endDate): Observable<any> {
    return this.http.get<any>(urls.BASE_URL + urls.DRIVER_BREAKDOWN_URL + '?endDate=' + endDate + '&startDate=' + startDate)
    .pipe(
      catchError(errorHandl)
    );
  }

  getNemtStatisticsByDate(startDate, endDate): Observable<any> {
    return this.http.get<any>(urls.BASE_URL + urls.NEMT_STATISTICS_URL + '?endDate=' + endDate + '&startDate=' + startDate)
    .pipe(
      catchError(errorHandl)
    );
  }

  getNemtStatisticsReportPdf(data): Observable<any> {
    return this.http.post<any>(urls.BASE_URL + urls.NEMT_STAT_REPORT_PDF_URL, data)
    .pipe(
      catchError(errorHandl)
    );
  }

  getNemtBusinessReportPdf(data): Observable<any> {
    return this.http.post<any>(urls.BASE_URL + urls.NEMT_BUSSINESS_REPORT_PDF_URL, data)
    .pipe(
      catchError(errorHandl)
    );
  }

  getNemtBrokerReportPdf(data): Observable<any> {
    return this.http.post<any>(urls.BASE_URL + urls.NEMT_BROKER_REPORT_PDF_URL, data)
    .pipe(
      catchError(errorHandl)
    );
  }

  getNemtCorporateReportPdf(data): Observable<any> {
    return this.http.post<any>(urls.BASE_URL + urls.NEMT_CORPORATE_REPORT_PDF_URL, data)
    .pipe(
      catchError(errorHandl)
    );
  }

  getNemtDriverReportPdf(data): Observable<any> {
    return this.http.post<any>(urls.BASE_URL + urls.NEMT_DRIVER_REPORT_PDF_URL, data)
    .pipe(
      catchError(errorHandl)
    );
  }

  getNemtCorporateTripReportPdf(data, corporateId): Observable<any> {
    return this.http.post<any>(urls.BASE_URL + urls.NEMT_CORPORATE_TRIP_REPORT_PDF_URL + `/${corporateId}`, data)
    .pipe(
      catchError(errorHandl)
    );
  }


  getNemtReports(): Observable<any> {
    return this.http.get<any>(urls.BASE_URL + urls.NEMT_REPORTS_URL)
    .pipe(
      catchError(errorHandl)
    );
  }

  getNemtReportsByDate(startDate, endDate): Observable<any> {
    return this.http.get<any>(urls.BASE_URL + urls.NEMT_REPORTS_URL + '?endDate=' + endDate + '&startDate=' + startDate)
    .pipe(
      catchError(errorHandl)
    );
  }

  getNemtStatsDetailsByDate(detailsData): Observable<any> {
    return this.http.post<any>(urls.BASE_URL + urls.NEMT_STATISTICS_DETAIL_URL, detailsData)
    .pipe(
      catchError(errorHandl)
    );
  }

  getNemtStatsById(memberId): Observable<any> {
    return this.http.get<any>(urls.BASE_URL + urls.NEMT_STATISTICS_DETAIL_BY_MEMBER_ID_URL + '/' + memberId)
    .pipe(
      catchError(errorHandl)
    );
  }
}
