import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectReportState, ReportState } from '../../../ngrx/app.states';
import { ReportsActionTypes, GetReports, GetDriverPdfReport, GetPaymentPdfReport } from '../../../ngrx/store/actions/reports.actions';
import { Observable } from 'rxjs';
import { sweetAlert } from 'src/app/utils/swal';
import { AuthService } from '../../../service/auth-service/auth.service';
import * as moment from 'moment-timezone';
import { takeUntil } from 'rxjs/operators';
import {componentDestroyed} from '@w11k/ngx-componentdestroyed';
import { downloadBase64File } from '../../../utils/pdf-downloader';
import { loadMyScript } from 'src/app/utils/utils.common';

@Component({
  selector: 'app-earnings',
  templateUrl: './earnings.component.html',
  styleUrls: ['./earnings.component.css']
})
export class EarningsComponent implements OnInit, OnDestroy {

  getReportState: Observable<any>;
  reportDetails = [];
  startDate = null;
  endDate = null;
  selectedDate = null;
  driversDetails = [];
  imageModalUrl;
  mailCheck = false;
  selectedDriver = null;
  constructor(private store: Store<ReportState>, private authService: AuthService) {
    this.getReportState = this.store.select(selectReportState);
  }

  ngOnInit() {
    // $.getScript('../assets/js/template.js');
        loadMyScript('../assets/vendors/feather-icons/feather.min.js');

    this.getReportState
    .pipe(takeUntil(componentDestroyed(this)))
    .subscribe((state) => {
      if (state.success) {
        if (state.type === ReportsActionTypes.GET_REPORTS) {
          this.reportDetails = state.response;
          if (this.reportDetails.length > 0) {
            this.driversDetails = state.response[0].drivers;
            this.selectedDate = [new Date(this.reportDetails[0].dates.startDate) , new Date(this.reportDetails[0].dates.endDate)];
            this.startDate = this.reportDetails[0].dates.startDate;
            this.endDate = this.reportDetails[0].dates.endDate;
          } else {
            this.driversDetails = null;
            this.startDate = this.selectedDate? this.selectedDate[0].toISOString(): '';
            this.endDate = this.selectedDate? this.selectedDate[1].toISOString(): '';
          }
        } else if (state.type === ReportsActionTypes.GET_DRIVER_PDF_REPORT || state.type === ReportsActionTypes.GET_PAYMENT_REPORT) {
          downloadBase64File(state.response.content, state.response.pdfName);
        }
      } else if (state.errorMessage) {
        sweetAlert('Error', state.errorMessage, 'error', 'Ok');
      }
    });

    this.getReportDetails();
  }

  getReportDetails(type?) {
    console.log(this.selectedDate);
    let data;
    if (type === 'reset') {
       data = {
        _id: this.authService.getUserData()._id,
        startDate:  null,
        endDate:  null,
      };
    } else {
       data = {
        _id: this.authService.getUserData()._id,
        startDate: this.selectedDate ? this.selectedDate[0].toISOString() : null,
        endDate: this.selectedDate ? this.selectedDate[1].toISOString() : null,
      };
    }
    this.store.dispatch(new GetReports(data));
  }

  openImageModal(url) {
    this.imageModalUrl = url;
  }

  openPdfDownloadModal(driverReport) {
    this.selectedDriver = driverReport._id._id;
  }

  downloadDriverEarningReport(driverId, mailCheck) {
    this.selectedDriver = null;
    this.mailCheck = false;
    console.log(driverId, mailCheck);
    const data = {
      mailCheck,
      driverId,
      startDate: this.startDate,
      endDate: this.endDate

    };
    this.store.dispatch(new GetDriverPdfReport(data));
  }

  downloaPaymentReport() {
    const data = {
      id: this.authService.getUserData()._id,
      startDate: this.startDate,
      endDate: this.endDate

    };
    this.store.dispatch(new GetPaymentPdfReport(data));
  }

  ngOnDestroy(): void {
  }


}
