import { AfterContentChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AssigTripsService } from '../assigntrips-service/assigntrips-service';

@Component({
  selector: 'app-standing-orders',
  templateUrl: './standing-orders.component.html',
  styleUrls: ['./standing-orders.component.css']
})
export class StandingOrdersComponent implements OnInit {
  cancelTripModalObj;
  cancelType;

  standingOrders;
  drivers = [];
  driverList = [];

  constructor(
    private assignTripService: AssigTripsService,
  ) { }

  ngOnInit() {
    this.getStandingOrders();
  }

  getStandingOrders() {
    this.assignTripService
      .getStandingOrders()
      .subscribe(data => {
        if (data && data.assigns && data.assigns.length) {
          this.standingOrders = data.assigns;
          this.drivers = data.drivers;
          this.driverList = this.drivers.reduce((obj, item) => (obj[item._id] = item, obj) ,{});
          console.log('standing orders: ', this.standingOrders);
        } else {
          this.standingOrders = undefined;
        }
      });
  }

  cancelTrip(trip) {
    this.cancelType = 'Single';
    this.cancelTripModalObj = trip;
    console.log(this.cancelTripModalObj);
  }

  cancelAllTrips(trips) {
    this.cancelType = 'Multiple Assign';
    this.cancelTripModalObj = trips.map(trip => trip._id);
    console.log(this.cancelTripModalObj);
  }

  onCancelTrip(evt) {
    this.cancelType = '';
    this.cancelTripModalObj = undefined;
    this.getStandingOrders();
  }

}
