import { Component, OnInit, ViewChild, ElementRef, NgZone, OnDestroy, AfterViewInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AgmMap, MapsAPILoader, MouseEvent } from "@agm/core";
import { AuthService } from "src/app/service/auth-service/auth.service";
import { SocketService } from "src/app/service/socket-service/socket-service.service";
import { Observable } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { sweetAlert } from "../../../utils/swal";
import { componentDestroyed } from "@w11k/ngx-componentdestroyed";
import * as moment from "moment-timezone";
import { Store } from "@ngrx/store";
import { AssignTripsState, selectAssignTripsState } from "src/app/ngrx/app.states";
import { DispatchCenterActionTypes, GetUnassignedTripDetail } from "../../../ngrx/store/actions/dispatch-center-actions";
import { AssignMultipleTrip, AssignTripsActionTypes, GetOutstandingList, GetUnassignedList } from "../../../ngrx/store/actions/assigntrips-actions";
import { Events } from "src/app/utils/event-utils";
import { stat } from "fs";
import { getBrokersList } from "src/app/utils/utils.common";
import { DriversService } from "../../drivers/drivers-service/drivers-service";
import { SharedDataService } from "src/app/service/shared-service/shared-data.service";
declare var $;

@Component({
  selector: 'app-driver-load',
  templateUrl: './driver-load.component.html',
  styleUrls: ['./driver-load.component.css']
})
export class DriverLoadComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild("AgmMap", {static : false}) agmElement: AgmMap;
  agmMap;
  bounds;
  latitude: number;
  longitude: number;
  zoom: number = 12;
  
  drivers = [];
  selectedDriverId = '';
  timeZone;
  todayDate;

  search = '';
  tripFare = 0;
  tripMiles = 0;
  tripDeadMiles = 0;

  standingOrders;
  unassignedTrips = [];
  nonSuggestedTrips = [];
  selectedTrips;
  row : any = {};
  assign = null;

  getDriverListObserver: Observable<any>;
  getTripListObserver: Observable<any>;
  user;

  constructor(
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private store: Store<AssignTripsState>,
    private authService: AuthService,
    private driverService: DriversService,
    private sharedDataService: SharedDataService,  
  ) {
      // this.timeZone = this.authService.getUserData().timeZone;
      this.getTripListObserver = this.store.select(selectAssignTripsState);
  }

  ngOnInit() {
    this.getUser();
    this.todayDate = moment.tz(new Date(), this.timeZone).startOf('day').toISOString();

    this.getTripListObserver
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((state) => {
        if (state.type === AssignTripsActionTypes.GET_OUTSTANDING_LIST) {
          if (state.success) {
            this.standingOrders = state.response;
            this.filterTrips();
          } else if (state.errorMessage) {
            sweetAlert('Error', state.errorMessage, 'warning', 'OK');
          }
        } else if (state.type === AssignTripsActionTypes.ASSIGN_MULTIPLE_TRIPS) {
          if (state.success) {
            sweetAlert('Succcess', 'Trip Processed Successfully', 'success', 'OK');
          } else if (state.errorMessage) {
            sweetAlert('Error', state.errorMessage, 'warning', 'OK');
          }
        }
      });

    this.getDriverList();
    
  }

  getUser() {
    this.sharedDataService
      .getUser()
      .subscribe(user => {
        if (user) {
          this.user = user;
          this.timeZone = this.user.timeZone;
        }
      });
  }

  ngAfterViewInit() {
    this.agmElement.mapReady.subscribe((map) => {
      this.agmMap = map;
      this.googleMarkerBounds();
    });
  }

  getDriverList() {
    this.driverService
      .getDrivers(this.user._id, 'active')
      .subscribe(data => {
        if (data) {
          this.drivers = data;
        }
      }, err => {
        sweetAlert('Error', err, 'warning', 'OK');
      });
  }

  onDateChange() {
    this.todayDate = moment.tz(this.todayDate , this.timeZone).startOf('day').toISOString();
    if (this.selectedDriverId) {
      this.getStandingOrders();
    }
  }

  getStandingOrders() {
    const object = {
      driver: this.selectedDriverId,
      scheduleTime: this.todayDate
    };
    this.store.dispatch(new GetOutstandingList(object));
  }

  filterTrips() {
    this.tripFare = 0;
    this.tripMiles = 0;
    this.tripDeadMiles = 0;
    this.nonSuggestedTrips = [];
    this.row = {};
    this.assign = null;
    this.standingOrders.forEach(st => {
      if (st._id) {
        this.row = st;
      } else {
        this.nonSuggestedTrips = st.assigns;
      }
    });
    this.googleMarkerBounds();
  }

  sendToSpecificBucket(assign, index) {
    assign.status = 'offered';
    let findDriver = this.drivers.find(d => d._id === this.selectedDriverId);
    let tempBucket = {
      _id: { _id: findDriver._id, displayName: findDriver.displayName, profileImageURL: findDriver.profileImageURL, takeMeHome: findDriver.takeMeHome },
      assigns: [assign]
    };
    if (this.row && this.row._id) {
      console.log('Bucket  found add new trip to bucket', this.row);
      this.row.assigns.push(assign);
    } else {
      console.log('Bucket Not found add new temp bucket', tempBucket);
      this.row = tempBucket;
    }

    let findTripIndex = this.nonSuggestedTrips.findIndex(t => t._id === assign._id);
    this.nonSuggestedTrips.splice(findTripIndex, 1);
    this.selectedTrips = [assign._id];
    this.processBucket({
      selectedTrips: this.selectedTrips,
      status: 'offered',
      driver: findDriver
    });
  };

  removeFromBucket(assign) {
    if (window.confirm('Are you sure you want to unassign this trip?')) {
      assign.status = 'unassigned';
      let findTripIndex = this.row.assigns.findIndex(t => t._id === assign._id);
      this.row.assigns.splice(findTripIndex, 1);
      this.nonSuggestedTrips.push(assign);
      this.selectedTrips = [assign._id];
      this.processBucket({
        selectedTrips: this.selectedTrips,
        status: 'unassigned'
      });
    }
  };

  processBucket(data) {
    this.store.dispatch(new AssignMultipleTrip(data));
  }

  // Map Functions Start
  googleMarkerBounds() {
    if (this.row && this.row.assigns && this.row.assigns.length) {
      this.tripMiles = 0;
      this.tripDeadMiles = 0;
      this.tripFare = 0;
      let driverHomeCoords = this.row._id.takeMeHome;
      console.log('Driver home coords --- ', driverHomeCoords);

      this.bounds = new window["google"].maps.LatLngBounds();

      this.bounds.extend(
        new window["google"].maps.LatLng(
          this.row._id.latitude,
          this.row._id.longitude
        )
      );

      this.row.assigns.forEach((assign, index, assigns) => {
        let nextIndex = index + 1;
        if (index === 0) {
          let baseToTripDistance = this.distance(parseFloat(driverHomeCoords[1]), parseFloat(driverHomeCoords[0]), parseFloat(assign.jobOriginLatitude), parseFloat(assign.jobOriginLongitude));
          console.log('Home to first trip', baseToTripDistance);
          // this.tripDeadMiles += parseFloat(baseToTripDistance);
          this.tripDeadMiles += baseToTripDistance;
        }
        this.tripMiles += parseFloat(assign.milage);
        this.tripFare += parseFloat(assign.preSpecialRate);
        this.bounds.extend(
          new window["google"].maps.LatLng(
            parseFloat(assign.jobOriginLatitude),
            parseFloat(assign.jobOriginLongitude)
          )
        );

        this.bounds.extend(
          new window["google"].maps.LatLng(
            parseFloat(assign.jobDestinationLatitude),
            parseFloat(assign.jobDestinationLongitude)
          )
        );

        console.log('Current index -- ', index, ' --- Next Iteration ---', nextIndex, ' -- Total trip length --- ', assigns.length);

        if (assigns.length > nextIndex) {
          let nextTrip = assigns[nextIndex];
          let distance = this.distance(parseFloat(assign.jobDestinationLatitude), parseFloat(assign.jobDestinationLongitude), parseFloat(nextTrip.jobOriginLatitude), parseFloat(nextTrip.jobOriginLongitude));
          console.log(`Trip ${index} destination to Trip ${nextIndex} origin  distance is ${distance}`);
          // this.tripDeadMiles += parseFloat(distance);
          this.tripDeadMiles += distance;

        } 
        
        if (assigns.length === nextIndex) {
          let tripToBase = this.distance(parseFloat(assign.jobDestinationLatitude), parseFloat(assign.jobDestinationLongitude), parseFloat(driverHomeCoords[1]), parseFloat(driverHomeCoords[0]));
          console.log('Last trip to destination home', tripToBase);
          // this.tripDeadMiles += parseFloat(tripToBase);
          this.tripDeadMiles += tripToBase;

        }
      });

      console.log('After loop iteration -- ', this.bounds);

      this.agmMap.setCenter(this.bounds.getCenter());
      this.agmMap.fitBounds(this.bounds);
    }
  }

  distance(lat1, lon1, lat2, lon2) {

    if ((lat1 === lat2) && (lon1 === lon2)) {
      return 0;
    }
    
    return this.getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2);
  }

  getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
    var R = 6371; // Radius of the earth in km
    var dLat = this.deg2rad(lat2 - lat1);
    var dLon = this.deg2rad(lon2 - lon1);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2)
      ;
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km
    return d / 1.609; // divided by 1.609 to convert into miles
  }

  deg2rad(deg) {
    return deg * (Math.PI / 180);
  }

  getTripRoute = function (assign) {
    this.assign = assign;
    this.bounds = new window["google"].maps.LatLngBounds();
    this.bounds.extend(
      new window["google"].maps.LatLng(
        parseFloat(this.assign.jobOriginLatitude),
        parseFloat(this.assign.jobOriginLongitude)
      )
    );  
    this.bounds.extend(
      new window["google"].maps.LatLng(
        parseFloat(this.assign.jobDestinationLatitude),
        parseFloat(this.assign.jobDestinationLongitude)
      )
    );  
    this.agmMap.setCenter(this.bounds.getCenter());
    this.agmMap.fitBounds(this.bounds);
  };
  // Map Functions End

  brokerList(key?) {
    if (key) {
      return getBrokersList[key];
    }
    return getBrokersList;
  }

  ngOnDestroy(): void {
  }
}
