import { Action } from '@ngrx/store';


export enum AssignTripsActionTypes {
    GET_UNASSIGNED_LIST = '[Assign] Get Assign unassigned List',
    GET_OUTSTANDING_LIST = '[Assign] Get Assign outstanding List',
    GET_OUTSTANDING_LIST_FOR_BUCKETS = '[Assign] Get Assign Outstanding Bucket List',
    GET_ASSIGNED_TRIPS_LIST = '[Assign] Get Assigned Trips List',
    GET_CORPORATE_USER_LIST = '[Assign] Get Corporate User List',
    ADD_SPECIAL_RATES = '[Assign] Add Special Rates',
    CALCULATE_FARE = '[Assign] Calculate Fare',
    CLONE_TRIP = '[Assign] Clone Trip',
    REMOVE_TRIP = '[Assign] Remove Trip',
    UPDATE_TRIP_STATUS = '[Assign] Update Trip Status',
    CREATE_NEW_TRIP = '[Assign] Create New Trip',
    UPDATE_NEW_TRIP = '[Assign] Update New Trip',
    GET_UNASSIGNED_TRIP_DETAIL_BY_ID = '[Assign] Get Unassigned Trip Detail By Id',
    GET_ASSIGNED_TRIPS_LOGS_LIST = '[Assign] Get Assign Trip Logs List',
    GET_ASSIGNED_TRIPS_LOGS_FILE = '[Assign] Get Assign Trip Logs File',
    GET_TRIPS_FOR_ASSIGN_DASHBOARD = '[Assign] Get Trips For Assign Dashboard',
    ASSIGN_TRIP = '[Assign] Assign Trip',
    VERIFIED_CANCEL_TRIP = '[Assign] Verified Cancel Trip',
    ADD_BILLER_NOTE = '[Assign] Add Biller Note',
    COMMON_SUCCESS = '[Assign] Common Success',
    COMMON_FAILURE = '[Assign] Common Faliure',
    GENERATE_SIGNATURE = '[Assign] Generate Signature',
    BANKED_TRIPS = '[Assign] Banked Trips',
    ASSIGN_MULTIPLE_TRIPS = '[Assign] Assign Multiple Trips',
    UPDATE_ALL_TRIPS_SIGNATURE = '[Assign] Update All Trips Signature',
    GET_SWEEP_TRIPS_LIST = '[Assign] Get Sweep Trips List',
    REMOVE_SWEEP_TRIPS = '[Assign] Remove Sweep Trips',
    SEND_TRIP_LIST_EMAIL = '[Assign] Send Trip List Email',
    VIEW_UNPARSED_TRIPS = '[Assign] View Unparsed Trips'
}

export class GetUnassignedList implements Action {
    readonly type = AssignTripsActionTypes.GET_UNASSIGNED_LIST;
    constructor(public endPoint: any) {
    }

}

export class GetOutstandingList implements Action {
    readonly type = AssignTripsActionTypes.GET_OUTSTANDING_LIST;
    constructor(public endPoint: any) {
    }
}

export class GetOutstandingListForBuckets implements Action {
    readonly type = AssignTripsActionTypes.GET_OUTSTANDING_LIST_FOR_BUCKETS;
    constructor(public endPoint: any) {
    }
}

export class ViewUnparsedTripsDetail implements Action {
  readonly type = AssignTripsActionTypes.VIEW_UNPARSED_TRIPS;
  constructor(public endPoint: any) {
  }

}

export class GetSweepTripsList implements Action {
  readonly type = AssignTripsActionTypes.GET_SWEEP_TRIPS_LIST;
  constructor() {
  }

}

export class RemvoveSweepTrips implements Action {
  readonly type = AssignTripsActionTypes.REMOVE_SWEEP_TRIPS;
  constructor(public payload: any) {
  }

}

export class SendTripListEmail implements Action {
  readonly type = AssignTripsActionTypes.SEND_TRIP_LIST_EMAIL;
  constructor(public payload: any) {
  }

}


export class AddBillerNote implements Action {
  readonly type = AssignTripsActionTypes.ADD_BILLER_NOTE;
    constructor(public payload: any) {
    }

}

export class VerifiedCancelledTrip implements Action {
    readonly type = AssignTripsActionTypes.VERIFIED_CANCEL_TRIP;
    constructor(public payload: any, public id: any) {
    }

}

export class CalculateFare implements Action {
    readonly type = AssignTripsActionTypes.CALCULATE_FARE;
    constructor(public payload: any) {
    }
}

export class GetTripsForAssignDashboard implements Action {
    readonly type = AssignTripsActionTypes.GET_TRIPS_FOR_ASSIGN_DASHBOARD;
}


export class CloneTrip implements Action {
    readonly type = AssignTripsActionTypes.CLONE_TRIP;
    constructor(public payload: any) {
    }

}
export class RemoveTrip implements Action {
    readonly type = AssignTripsActionTypes.REMOVE_TRIP;
    constructor(public payload: any, public tripId: any) {
    }

}

export class UpdateTripStatus implements Action {
    readonly type = AssignTripsActionTypes.UPDATE_TRIP_STATUS;
    constructor(public payload: any) {
    }

}
export class AddSpecialRates implements Action {
    readonly type = AssignTripsActionTypes.ADD_SPECIAL_RATES;
    constructor(public payload: any) {
    }

}

export class GetAssignedTripsList implements Action {
    readonly type = AssignTripsActionTypes.GET_ASSIGNED_TRIPS_LIST;
    constructor(public payload: any) {
    }
}

export class GetCorporateUserList implements Action {
    readonly type = AssignTripsActionTypes.GET_CORPORATE_USER_LIST;
}

export class CreateNewTrip implements Action {
    readonly type = AssignTripsActionTypes.CREATE_NEW_TRIP;
    constructor(public payload: any) {

    }
}

export class GetUnassignedTripDetailById implements Action {
    readonly type = AssignTripsActionTypes.GET_UNASSIGNED_TRIP_DETAIL_BY_ID;
    constructor(public payload: any) {

    }
}

export class UpdateNewTrip implements Action {
    readonly type = AssignTripsActionTypes.UPDATE_NEW_TRIP;
    constructor(public payload: any, public tripId: any) {

    }
}

export class AssignTrip implements Action {
    readonly type = AssignTripsActionTypes.ASSIGN_TRIP;
    constructor(public payload: any) {
    }
}


export class AssignMultipleTrip implements Action {
  readonly type = AssignTripsActionTypes.ASSIGN_MULTIPLE_TRIPS;
  constructor(public payload: any) {
  }
}


export class CommonSuccess implements Action {
    readonly type = AssignTripsActionTypes.COMMON_SUCCESS;
    constructor(public payload: any) {

    }
}

export class CommonFailure implements Action {
    readonly type = AssignTripsActionTypes.COMMON_FAILURE;
    constructor(public payload: any) {

    }
}


export class GetAssignTripLogsList implements Action {
    readonly type = AssignTripsActionTypes.GET_ASSIGNED_TRIPS_LOGS_LIST;
    constructor(public endPoint: any) {
    }

}

export class GetAssignTripLogsFile implements Action {
    readonly type = AssignTripsActionTypes.GET_ASSIGNED_TRIPS_LOGS_FILE;
    constructor(public payload: any, public endPoint: any) {
    }

}


export class GenerateSignature implements Action {
    readonly type = AssignTripsActionTypes.GENERATE_SIGNATURE;
    constructor(public payload: any, public endPoint: any) {
    }

}

export class UpdateAllTripsSignature implements Action {
    readonly type = AssignTripsActionTypes.UPDATE_ALL_TRIPS_SIGNATURE;
    constructor(public payload: any) {
    }

}

export class BankedTrips implements Action {
    readonly type = AssignTripsActionTypes.BANKED_TRIPS;
    constructor(public payload: any) {
    }

}




export type All =
    | GetUnassignedList
    | GetOutstandingList
    | GetOutstandingListForBuckets
    | GetAssignedTripsList
    | GetCorporateUserList
    | CreateNewTrip
    | UpdateNewTrip
    | AddSpecialRates
    | CalculateFare
    | CloneTrip
    | RemoveTrip
    | UpdateTripStatus
    | GetUnassignedTripDetailById
    | GetAssignTripLogsList
    | GetAssignTripLogsFile
    | AssignTrip
    | GetTripsForAssignDashboard
    | VerifiedCancelledTrip
    | AddBillerNote
    | CommonSuccess
    | CommonFailure
    | GenerateSignature
    | AssignMultipleTrip
    | BankedTrips
    | GetSweepTripsList
    | ViewUnparsedTripsDetail
    | RemvoveSweepTrips
    | SendTripListEmail
    | UpdateAllTripsSignature;
