import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { selectAssignTripsState, AssignTripsState } from '../../../ngrx/app.states';
import { AssignTripsActionTypes, GetTripsForAssignDashboard } from '../../../ngrx/store/actions/assigntrips-actions';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { filterJobs, getBrokersList, loadMyScript } from 'src/app/utils/utils.common';
import * as moment from 'moment-timezone';
import { ActivatedRoute } from '@angular/router';
import { sweetAlert } from 'src/app/utils/swal';
@Component({
  selector: 'app-dashboard-trips-details',
  templateUrl: './dashboard-trips-details.component.html',
  styleUrls: ['./dashboard-trips-details.component.css']
})
export class DashboardTripsDetailsComponent implements OnInit, OnDestroy {
 

  getAssignTripsState: Observable<any>;
  currentTime;
  tripsList = [];
  tripType;
  search = '';
  broker = '';
  assignModalObj;
  componentType;
  constructor(private store: Store<AssignTripsState>, private activatedRoute: ActivatedRoute) { 
    this.getAssignTripsState = this.store.select(selectAssignTripsState);
  }


  ngOnInit() {
    this.tripType = this.activatedRoute.snapshot.url[2].path
    console.log(this.tripType)
    this.getAssignTripsState
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((state) => {
        if (state.type === AssignTripsActionTypes.GET_TRIPS_FOR_ASSIGN_DASHBOARD) {
          if (state.success) {
            this.currentTime = state.response.currentTime;
            this.tripsList = filterJobs(state.response.assigns, this.currentTime);
          }
        }
      });

      this.getAllTrips();

  }

  
  getAllTrips() {
    this.store.dispatch(new GetTripsForAssignDashboard());
  }

  getBrokersList() {
    return getBrokersList;
  }

  sortMethod(a, b) {
    return new Date(a.key) > new Date(b.key) ? 1 : -1;
  }


  countTrips(trips) {
    let count = 0;
    const todayDate = moment(this.currentTime).format('MM/DD/YYYY');
    for (var x in trips) {
      if (x === todayDate) {
        count += trips[x].length;
      }
    }
    return count;
  }

  
  setAssignModalObj(trip, type) {
    this.componentType = type;
    this.assignModalObj = trip;
  }

   ngOnDestroy(): void {
  }

  tripAssignedResponse(tripStatus: string): void {
    if (tripStatus === "Trip Assigned" || tripStatus === 'Trip Un Assigned') {
      sweetAlert('Succcess', tripStatus, 'success', 'OK')
        .then(() => {
          this.getAllTrips();
        })
    } else if (tripStatus !== null) {
      sweetAlert('Error', tripStatus, 'error', 'OK');
    }
    this.assignModalObj = null;
    this.componentType = null;
  }



}
