import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { sweetAlert } from '../../../utils/swal';
import { getBrokersList, getUserRole } from 'src/app/utils/utils.common';
import { ActivatedRoute, Router } from '@angular/router';
import { DriversService } from '../../drivers/drivers-service/drivers-service';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
import { AssigTripsService } from '../../assigned-trips/assigntrips-service/assigntrips-service';
import { DynamicFareServiceService } from '../dynamic-fare-service/dynamic-fare-service.service';

@Component({
  selector: 'app-dynamic-fare-add',
  templateUrl: './dynamic-fare-add.component.html',
  styleUrls: ['./dynamic-fare-add.component.css']
})
export class DynamicFareAddComponent implements OnInit {
  submitted = false;
  singleZoneFormSubmitted = false;
  companies;
  corporateUsers;
  insuranceCompanies;
  drivers;
  addDynamicFareForm: FormGroup;
  zoneFareForm: FormGroup;
  user;
  userRole;

  constructor(
    private dynamicFareService: DynamicFareServiceService,
    private assignTripService: AssigTripsService,
    private driverService: DriversService,
    private sharedDataService: SharedDataService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
  ) { }

  ngOnInit() {
    this.getUser();
    this.initForms();

    if (this.activatedRoute.snapshot.url[2] && this.activatedRoute.snapshot.url[2].path === 'edit') {
      this.addDynamicFareForm.patchValue({
        _id: this.activatedRoute.snapshot.url[1].path
      });
      this.getDynamicFareById();
    }

    this.selectFor('company');
    this.getDriverList();
    this.getCorporateUserList();
    this.getInsuranceBrokerList();
  }

  initForms() {
    this.addDynamicFareForm = this.fb.group({
      _id: [''],

      driver: [''],
      cooperate: [''],
      companyType: [''],
      company: [this.user._id],

      vehicleType: ['Standard', Validators.required],
      for: ['company', Validators.required],

      perMile: ['', Validators.required],
      perMin: ['', Validators.required],
      minimumFare: ['', Validators.required],
      cancelCharges: ['', Validators.required],
      additionalPassengerCharges: ['', Validators.required],

      pickupSurcharge: this.fb.group({
        fare: ['', Validators.required],
        per: ['', Validators.required],
        after: ['', Validators.required]
      }),

      baseFare: this.fb.group({
        fare: ['', Validators.required],
        for: ['', Validators.required],
      }),

      waitTime: this.fb.group({
        fare: ['', Validators.required],
        per: ['', Validators.required],
        after: ['', Validators.required],
      }),

      zones: [[]]
    });

    this.zoneFareForm = this.fb.group({
      fare: ['', Validators.required],
      from: ['', Validators.required],
      to: ['', Validators.required]
    });
  }

  getUser() {
    this.sharedDataService
      .getUser()
      .subscribe(user => {
        this.user = user;
        this.userRole = getUserRole(this.user.roles);
      });
  }

  getDynamicFareById() {
    this.dynamicFareService
      .getDynamicFareById(this.addDynamicFareForm.value._id)
      .subscribe(data => {
        if (data) {
          this.addDynamicFareForm.patchValue({
            ...data
          });
        }
      });
  }

  getDriverList() {
    this.driverService
      .getDrivers(this.user._id, 'active')
      .subscribe(data => {
        if (data) {
          this.drivers = data;
        }
      }, err => {
        sweetAlert('Error', err, 'error', 'OK');
      });
  }

  getCorporateUserList() {
    this.assignTripService
      .getCorporateUserList()
      .subscribe(data => {
        if (data) {
          this.corporateUsers = data;
        }
      });
  }

  getInsuranceBrokerList() {
    this.insuranceCompanies = getBrokersList;
    return this.insuranceCompanies;
  }

  addZone() {
    this.singleZoneFormSubmitted = true;
    if (this.zoneFareForm.invalid) {
      return;
    }
    this.addDynamicFareForm.value.zones.push(this.zoneFareForm.value);
    this.zoneFareForm.reset();
    this.singleZoneFormSubmitted = false;
  }

  removeZone(index) {
    const z = this.addDynamicFareForm.value.zones;
    z.splice(index, 1);
    this.addDynamicFareForm.patchValue({
      zones: z
    });
  }

  setVehicle(vehicle: string) {
    this.addDynamicFareForm.patchValue({
      vehicleType: vehicle
    });
  }

  selectFor(selectFor: string) {
    this.addDynamicFareForm.patchValue({
      for: selectFor
    });
    let clearKeys = [];
    let setKeys = [];

    if (this.addDynamicFareForm.value.for === 'company') {
      clearKeys = ['companyType', 'cooperate', 'driver'];
      setKeys = ['company'];
    } else if (this.addDynamicFareForm.value.for === 'broker') {
      clearKeys = [, 'cooperate', 'driver', 'company'];
      setKeys = ['companyType'];
    } else if (this.addDynamicFareForm.value.for === 'driver') {
      clearKeys = ['company', 'companyType', 'cooperate'];
      setKeys = ['driver'];
    } else {
      clearKeys = ['company', 'companyType', 'driver'];
      setKeys = ['cooperate'];
    }
    this.setFormValidation(setKeys, clearKeys);
  }

  setFormValidation(setKeys?, clearKeys?) {
    clearKeys.forEach(element => {
      this.addDynamicFareForm.get(element).clearValidators();
      this.addDynamicFareForm.get(element).updateValueAndValidity();
      this.addDynamicFareForm.get(element).disable();
    });
    setKeys.forEach(element => {
      this.addDynamicFareForm.get(element).setValidators([Validators.required]);
      this.addDynamicFareForm.get(element).enable();
    });
  }

  onSubmit() {
    this.submitted = true;

    if (this.addDynamicFareForm.invalid) {
      return;
    }

    this.dynamicFareService
      .saveDynamicFare(this.addDynamicFareForm.value)
      .subscribe(data => {
        if (data) {
          this.router.navigateByUrl('dynamicfare/list');
        }
      }, err => {
        sweetAlert('Error', err, 'error', 'OK');
      });
  }

  get form() {
    return this.addDynamicFareForm.controls;
  }

  get zoneForm() {
    return this.zoneFareForm.controls;
  }
}
