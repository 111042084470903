import { Action } from '@ngrx/store';


export enum AuthActionTypes {
    LOGIN = '[Auth] Login',
    LOGIN_SUCCESS = '[Auth] Login Success',
    COMMON_FAILURE = '[Auth] Common Failure',
    LOGOUT = '[Auth] Logout',
    VERIFY_TOKEN = '[Auth] Verify Token',
    LOGIN_SUCCESS_WITH_VERIFY_TOKEN = '[Auth] Login Success With Verify Token',
    FORGET_PASSWORD = '[Auth] Forget Password',
    FORGET_PASSWORD_SUCCESS = '[Auth] Forget Password Success',
    COMMON_SUCCESS = '[Auth] Common Success',
}

// login reuest
// export class LogIn implements Action {
//     readonly type = AuthActionTypes.LOGIN;
//     constructor(public payload: any) { }
// }

// when login request goes successfull
// export class LogInSuccess implements Action {
//     readonly type = AuthActionTypes.LOGIN_SUCCESS;
//     constructor(public payload: any) { }
// }


// calling logout to remove token from local storage
export class LogOut implements Action {
    readonly type = AuthActionTypes.LOGOUT;
}

// request user object if null and token is not null
export class VerifyToken implements Action {
    readonly type = AuthActionTypes.VERIFY_TOKEN;
}

// when got user object by 'me' endpoint against token
export class LoginSuccessWithVerifyToken implements Action {
    readonly type = AuthActionTypes.LOGIN_SUCCESS_WITH_VERIFY_TOKEN;
    constructor(public payload: any) { }
}

export class ForgetPassword implements Action {
    readonly type = AuthActionTypes.FORGET_PASSWORD;
    constructor(public payload: any) { }
}

export class ForgetPasswordSuccess implements Action {
    readonly type = AuthActionTypes.FORGET_PASSWORD_SUCCESS;
    constructor(public payload: any) { }
}



// Common request fails
export class CommonFailure implements Action {
    readonly type = AuthActionTypes.COMMON_FAILURE;
    constructor(public payload: any) { }
}
export class CommonSuccess implements Action {
    readonly type = AuthActionTypes.COMMON_SUCCESS;
    constructor(public requestType: any, public payload: any) { }
}


export type All =
    // | LogIn
    // | LogInSuccess
    | LogOut
    | VerifyToken
    | LoginSuccessWithVerifyToken
    | ForgetPassword
    | ForgetPasswordSuccess
    | CommonSuccess
    | CommonFailure;
